import React, { SVGProps } from "react"

export const Holiday: React.FC<SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M14.2222 2.83022V2.66667C14.2222 1.19378 13.0284 0 11.5556 0C10.0827 0 8.88889 1.19378 8.88889 2.66667H7.11111C7.11111 1.19378 5.91733 0 4.44444 0C2.97156 0 1.77778 1.19378 1.77778 2.66667V2.83022C0.745778 3.19911 0 4.176 0 5.33333V13.3333C0 14.8036 1.19644 16 2.66667 16H13.3333C14.8036 16 16 14.8036 16 13.3333V5.33333C16 4.176 15.2542 3.19911 14.2222 2.83022ZM10.6667 2.66667C10.6667 2.176 11.064 1.77778 11.5556 1.77778C12.0471 1.77778 12.4444 2.176 12.4444 2.66667V4.44444C12.4444 4.93511 12.0471 5.33333 11.5556 5.33333C11.064 5.33333 10.6667 4.93511 10.6667 4.44444V2.66667ZM3.55556 2.66667C3.55556 2.176 3.95289 1.77778 4.44444 1.77778C4.936 1.77778 5.33333 2.176 5.33333 2.66667V4.44444C5.33333 4.93511 4.936 5.33333 4.44444 5.33333C3.95289 5.33333 3.55556 4.93511 3.55556 4.44444V2.66667ZM14.2222 13.3333C14.2222 13.8231 13.824 14.2222 13.3333 14.2222H2.66667C2.176 14.2222 1.77778 13.8231 1.77778 13.3333V8H14.2222V13.3333ZM14.2222 7.11111H1.77778V5.33333C1.77778 4.84356 2.176 4.44444 2.66667 4.44444C2.66667 5.42578 3.46311 6.22222 4.44444 6.22222C5.42578 6.22222 6.22222 5.42578 6.22222 4.44444H9.77778C9.77778 5.42578 10.5742 6.22222 11.5556 6.22222C12.5369 6.22222 13.3333 5.42578 13.3333 4.44444C13.824 4.44444 14.2222 4.84356 14.2222 5.33333V7.11111Z"
				fill={props.fill || "#6B7280"}
			></path>
		</svg>
	)
}
