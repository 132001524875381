import React from "react"
import { format } from "date-fns"

export type MealProps = {
	mealInfo: any
}

const MealDetail: React.FC<MealProps> = ({ mealInfo }) => {
	return (
		<div className="container mx-auto rounded-lg  flex flex-col gap-4">
			<div className="w-80 px-6 py-4 flex gap-4 shadow-sm border-2 border-gray-100 rounded-lg hover:cursor-pointer hover:bg-blue-100  flex-wrap relative">
				<div className="flex flex-col gap-2">
					<p className="text-sm font-medium text-gray-800">{mealInfo.name}</p>
					<p className="text-sm font-normal text-gray-400">Name</p>
				</div>

				<div className="flex flex-col gap-2">
					<p className="text-sm font-medium text-gray-800">{format(new Date(mealInfo.createdAt), "PPp")}</p>
					<p className="text-sm font-normal text-gray-400">CreateAt</p>
				</div>

				<div className="flex flex-col gap-2">
					<p className="text-sm font-medium text-gray-800">{format(new Date(mealInfo.updatedAt), "PPp")}</p>
					<p className="text-sm font-normal text-gray-400">UpdateAt</p>
				</div>
			</div>
		</div>
	)
}

export default MealDetail
