import React, { useCallback, useState } from "react"
import { useAttendanceSlotsQuery, AttendanceSlotsQueryVariables } from "../../../graphql"
import { ErrorLabel, Skeleton, VStack } from "../../ui"
import { MealList } from "./MealList"

type SearchResultMealsProps = {
	keyword?: string
}

export const SearchResultMeals: React.FC<SearchResultMealsProps> = ({ keyword = "" }) => {
	const [pagination, setPagination] = useState<AttendanceSlotsQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useAttendanceSlotsQuery({ variables: { keyword, pagination } })

	const nextPage = useCallback(() => {
		if (data?.attendanceSlots.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data?.attendanceSlots.hasNextPage])

	return (
		<VStack className="items-stretch">
			{fetching && !data?.attendanceSlots ? (
				<VStack className="w-full items-stretch gap-2">
					<Skeleton className="w-full h-24" />
					<Skeleton className="w-full h-24" />
					<Skeleton className="w-full h-24" />
					<Skeleton className="w-full h-24" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.attendanceSlots && data.attendanceSlots.attendanceSlots?.length ? (
				<MealList meals={data?.attendanceSlots.attendanceSlots} loadMore={nextPage} />
			) : (
				<ErrorLabel>Couldn&apos;t find any meals.</ErrorLabel>
			)}
		</VStack>
	)
}
