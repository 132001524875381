/* eslint-disable @typescript-eslint/indent */

import React from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { InputError, Button, Label, Input } from "../../../components/ui"

type CreateMealSlotFormValues = {
	name: string
}

const schema = z.object({
	name: z.string().nonempty({ message: "name is required" }),
})

const CreateMealSlotForm: React.FC = () => {
	const {
		register,
		handleSubmit,
		formState: { isLoading, errors, isSubmitting },
	} = useForm<CreateMealSlotFormValues>({ resolver: zodResolver(schema) as any })

	const onSubmit: SubmitHandler<CreateMealSlotFormValues> = async (values) => {
		console.log("submit...")
		console.log("values", values)
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className=" flex flex-col gap-4 lg:w-96 ">
				<div className="flex flex-col gap-2">
					<Label htmlFor="name">Meal Slot Name</Label>
					<Input placeholder="meal name" type="text" {...register("name")} />
					<InputError>{errors.name?.message}</InputError>
				</div>

				<Button isLoading={isLoading || isSubmitting} className="mt-6">
					Create Meal Slot
				</Button>
			</form>
		</>
	)
}

export default CreateMealSlotForm
