import React from "react"

import { useValidTillBannerContext } from "../../hooks"

export type ValidTillBannerType = {
	isClosable?: boolean
}

export const ValidTillBanner: React.FC<ValidTillBannerType> = ({ isClosable = true }) => {
	const { isVisible, children, dispatch, setInvisible } = useValidTillBannerContext()

	if (!isVisible) return <></>

	return (
		<div className="w-full flex justify-between relative">
			<div className="w-full">{children}</div>
			{isClosable && (
				<div className="text-sm absolute flex justify-center font-medium -top-1 items-center md:right-2  right-1 hover:cursor-pointer md:p-2" onClick={() => dispatch(setInvisible())}>
					<span className="text-lg text-white">x</span>
				</div>
			)}
		</div>
	)
}
