import { startCase } from "lodash"
import React from "react"
import Select from "react-select"
import { Input, VStack } from "../../../components"

import { BmiCategory } from "../../../graphql"
import { useSearchParams } from "../../../hooks"

import { StudentsBmiBySchool } from "../../../components/common/BMI/StudentsBmiBySchool"

export const BeneficiaryBmiPage: React.FC = () => {
	const [searchParams, { handleSearchParamUpdate, handleSearchParamDelete }] = useSearchParams()

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2 lg:gap-4">
			<VStack className="lg:flex-row items-stretch lg:items-center">
				<h1 className="w-full text-sm lg:text-md font-medium">Beneficiaries</h1>
				<div className="w-full grid lg:grid-cols-3 gap-2">
					<Input
						type="search"
						placeholder="Search..."
						value={(searchParams.get("keyword") as string) || ""}
						onChange={(e) => {
							if (e.target.value) handleSearchParamUpdate("keyword", e.target.value)
							else handleSearchParamDelete("keyword")
						}}
					/>

					<Select
						name="category"
						placeholder="Select Category"
						options={Object.values(BmiCategory).map((m) => ({ label: startCase(m), value: m }))}
						value={(searchParams.get("category") as BmiCategory) ? { label: startCase(searchParams.get("category") as string), value: searchParams.get("category") as BmiCategory } : undefined}
						onChange={(m) => {
							if (m?.value) handleSearchParamUpdate("category", m.value)
							else handleSearchParamDelete("category")
						}}
						className="basic-single w-full max-w-36"
					/>
				</div>
			</VStack>
			<StudentsBmiBySchool keyword={(searchParams.get("keyword") as string) || ""} category={(searchParams.get("category") as BmiCategory) || undefined} />
		</div>
	)
}

export default BeneficiaryBmiPage
