import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { SchoolFragment } from "../../graphql"
import { Avatar, AvatarFallback, AvatarImage, HStack, VStack } from "../ui"

export type NGOPartnerTagProps = {
	ngoPartner: SchoolFragment
	disableLink?: boolean
}

export const NGOPartnerTag: React.FC<NGOPartnerTagProps> = ({ ngoPartner, disableLink = false }) => {
	const user = useMemo(
		() => (
			<HStack className="gap-4">
				<Avatar>
					<AvatarImage src={ngoPartner.picture?.url} />
					<AvatarFallback>
						<span className="text-xs text-slate-400">
							{ngoPartner.name
								.split(" ")
								.slice(0, 2)
								.map((o) => o[0])
								.join("")}
						</span>
					</AvatarFallback>
				</Avatar>
				<VStack className="items-start gap-0">
					<span className="text-slate-600 text-sm font-bold">{ngoPartner.name}</span>
					<span className="text-slate-500 text-xs">@{ngoPartner.username}</span>
				</VStack>
			</HStack>
		),
		[ngoPartner]
	)

	return !disableLink ? <Link to={`/ngo-partners/${ngoPartner._id}`}>{user}</Link> : user
}
