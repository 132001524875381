import { format } from "date-fns"
import React from "react"
import { Link } from "react-router-dom"
import { ReportFragment, ReportStatus } from "../../../graphql"
import { Badge, Card, CardContent, CardHeader, CardTitle } from "../../ui"

export type ReportListItemProps = {
	report: ReportFragment
	linkTo: string
}

export const ReportListItem: React.FC<ReportListItemProps> = ({ report, linkTo }) => {
	return (
		<Link to={linkTo}>
			<Card className="hover:shadow-purple-500">
				<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
					<CardTitle className="text-sm font-medium text-slate-500">{report.label.name}</CardTitle>
					{report.status === ReportStatus.Queued ? (
						<Badge variant="outline" className="border-yellow-500 text-yellow-500">
							{report.status}
						</Badge>
					) : report.status === ReportStatus.Processing ? (
						<Badge className="bg-yellow-500">{report.status}</Badge>
					) : report.status === ReportStatus.Failed ? (
						<Badge variant="destructive">{report.status}</Badge>
					) : (
						<Badge className="bg-green-500">
							{report.status} on {format(new Date(report.createdAt), "MMM dd, yyyy")}
						</Badge>
					)}
				</CardHeader>
				<CardContent>{report.dateRange?.length ? <p className="text-xs text-slate-500">{report.dateRange.map((d) => format(new Date(d), "MMM dd, yyyy")).join(" - ")}</p> : <></>}</CardContent>
			</Card>
		</Link>
	)
}
