import * as React from "react"
import { cn } from "../../lib/utils"
import { Icons } from "./icons"

export type LoadingLabelProps = React.HTMLAttributes<HTMLDivElement> & React.PropsWithChildren

const LoadingLabel = React.forwardRef<HTMLDivElement, LoadingLabelProps>(({ className, children, ...props }, ref) => {
	return (
		<div className={cn("flex items-center", className)} ref={ref} {...props}>
			<Icons.spinner className="mr-1 h-4 w-4 animate-spin text-purple-600" />
			<span className="text-sm text-purple-600">{children}</span>
		</div>
	)
})

LoadingLabel.displayName = "LoadingLabel"

export { LoadingLabel }
