import { Device } from "../../../graphql"
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardContent, CardHeader } from "../../../components/ui"
import { format } from "date-fns"
import differenceInMinutes from "date-fns/differenceInMinutes"

export type DeviceListItemProps = {
	device: Device
}

const DeviceListItem: React.FC<DeviceListItemProps> = ({ device }) => {
	return (
		<Link to={`/devices/${device._id}`}>
			<Card className="hover:shadow-purple-500">
				<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">{/* <CardTitle className="text-sm font-medium text-slate-500">{device.name}</CardTitle> */}</CardHeader>
				<CardContent>
					<div className="flex gap-6 flex-wrap justify-center">
						<div className="flex flex-col gap-1">
							<p className="text-gray-700 text-sm font-medium">{device.name}</p>
							<p className="text-sm text-gray-500 font-light">Name</p>
						</div>

						<div className="flex flex-col gap-1">
							<p className="text-gray-700 text-sm font-medium">{device.deviceId}</p>
							<p className="text-sm text-gray-500 font-light">Device ID</p>
						</div>

						<div className="flex flex-col gap-1">
							{/* <p className="text-green-600 text-sm font-medium">Active</p> */}
							{device.isActive && device.lastHeartbeatAt && differenceInMinutes(new Date(), new Date(device.lastHeartbeatAt)) < 5 ? (
								<p className="text-green-600 text-sm font-medium">Active</p>
							) : (
								<p className="text-red-600 text-sm font-medium">Inactive</p>
							)}
							<p className="text-sm text-gray-500 font-light">Status</p>
						</div>

						<div className="flex flex-col gap-1">
							<p className="text-gray-700 text-sm font-medium">{device.lastHeartbeatAt ? format(new Date(device.lastHeartbeatAt), "PPp") : "Not available"}</p>

							<p className="text-sm text-gray-500 font-light">Last Heartbeat</p>
						</div>
					</div>
				</CardContent>
			</Card>
		</Link>
	)
}

export default DeviceListItem
