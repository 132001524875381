import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"

import { z } from "zod"
import { Form, FormControl, FormItem, FormMessage } from "../../../components"

import { UpdateStudentsSelector } from "./UpdateStudentSelectorForm"
import { ExtendedSchoolWiseAttendanceSlotFragment } from "../../../graphql"

type UpdateStudentListFormValues = { studentIds: string[] }

type UpdateStudentListFormProps = {
	schoolId: string
	onStudentIdsChange: (selectedStudentIds: string[]) => void
	slot: ExtendedSchoolWiseAttendanceSlotFragment
}

const schema = z.object({
	studentIds: z.array(z.string()).min(1, { message: "At least one Beneficiary is required" }),
})

export const UpdateStudentListForm: React.FC<UpdateStudentListFormProps> = ({ schoolId, onStudentIdsChange, slot }) => {
	const form = useForm<UpdateStudentListFormValues>({ resolver: zodResolver(schema) as any })

	const onSubmit = async (values: UpdateStudentListFormValues) => {
		onStudentIdsChange(values.studentIds)
	}

	useEffect(() => {
		onStudentIdsChange(form.getValues("studentIds"))
	}, [form.watch("studentIds")])

	return (
		<div>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<div>
						<FormItem>
							<FormControl>
								<UpdateStudentsSelector selectedStudentIds={(form.watch("studentIds") as string[]) || []} onChange={(BeneficariesIds) => form.setValue("studentIds", BeneficariesIds)} schoolId={schoolId} slot={slot} />
							</FormControl>
							<FormMessage />
						</FormItem>
					</div>
				</form>
			</Form>
		</div>
	)
}

export default UpdateStudentListForm
