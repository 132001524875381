import { format, startOfDay } from "date-fns"
import { CalendarIcon } from "lucide-react"
import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { Button, Calendar, ErrorLabel, NGOPartnerAttendanceStats, Popover, PopoverContent, PopoverTrigger, Skeleton, VStack } from "../../../components"
import { SchoolWiseAttendanceStatsQueryVariables, useMeQuery } from "../../../graphql"
import { useAttendanceStatsContext } from "../../../hooks"
import { cn } from "../../../lib/utils"

export const NGOPartnerAttendancePage: React.FC = () => {
	const [{ data: meData }] = useMeQuery()

	const { state } = useLocation()

	const [filter, setFilter] = useState<Required<SchoolWiseAttendanceStatsQueryVariables["filter"]>>({ sinceDate: startOfDay(new Date()), untilDate: startOfDay(new Date()), ...state?.filter, schoolIds: [] })

	useEffect(() => {
		if (meData?.me?.school?._id) {
			setFilter((prev) => ({ ...prev, schoolIds: [meData.me?.school?._id || ""] }))
		}
	}, [meData])

	const [{ queries }, queryNew] = useAttendanceStatsContext()

	const currentQuery = useMemo(() => queries.find((q) => q.id === JSON.stringify(filter)), [filter, queries])

	useEffect(() => {
		if (filter.schoolIds?.length === 1) {
			queryNew({ filter })
		}
	}, [filter])

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2">
			<VStack className="lg:flex-row">
				<h1 className="w-full text-sm lg:text-md font-medium">Attendance in {meData?.me?.school?.name || "NGO Partner"}</h1>
				<VStack className="lg:flex-row items-stretch">
					<Popover>
						<PopoverTrigger asChild>
							<Button id="date" variant="outline" className={cn("flex-1/2 justify-start text-left font-normal", !(filter.sinceDate && filter.untilDate) && "text-muted-foreground")}>
								<CalendarIcon className="mr-2 h-4 w-4" />
								{filter.sinceDate ? (
									filter.untilDate ? (
										<>
											{format(filter.sinceDate, "MMM dd, yyyy")} - {format(filter.untilDate, "MMM dd, yyyy")}
										</>
									) : (
										format(filter.sinceDate, "MMM dd, yyyy")
									)
								) : (
									<span>Pick a date</span>
								)}
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-auto p-0" align="start">
							<Calendar
								initialFocus
								mode="range"
								defaultMonth={filter.sinceDate}
								selected={{ from: filter.sinceDate, to: filter.untilDate }}
								onSelect={(date) => setFilter((prev) => ({ ...prev, sinceDate: date?.from || date?.to, untilDate: date?.to || date?.from }))}
								toDate={new Date()}
								numberOfMonths={1}
							/>
						</PopoverContent>
					</Popover>
				</VStack>
			</VStack>

			{currentQuery?.waiting ? (
				<VStack className="w-full max-w-xl items-stretch gap-2">
					<Skeleton className="w-full max-w-xl h-36" />
					<Skeleton className="w-full max-w-xl h-36" />
					<Skeleton className="w-full max-w-xl h-36" />
				</VStack>
			) : currentQuery?.failed || !currentQuery?.data || !meData?.me?.school ? (
				<ErrorLabel>Could not load NGO partners&apos; attendance</ErrorLabel>
			) : (
				<VStack className="w-full max-w-xl items-stretch">
					<NGOPartnerAttendanceStats ngoPartnerId={meData.me.school._id} sinceDate={filter.sinceDate} untilDate={filter.untilDate} />
				</VStack>
			)}
		</div>
	)
}

export default NGOPartnerAttendancePage
