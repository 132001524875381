import React from "react"
import { ErrorLabel } from "../ui"

export const EmptyChart: React.FC = () => {
	return (
		<div className="relative w-full h-full flex flex-col gap-2">
			<div className="w-full h-full grid grid-cols-6 gap-2 place-items-end">
				<div className="w-full h-full rounded-md bg-red-50" />
				<div className="w-full h-[50%] rounded-md bg-red-50" />
				<div className="w-full h-[60%] rounded-md bg-red-50" />
				<div className="w-full h-[80%] rounded-md bg-red-50" />
				<div className="w-full h-[10%] rounded-md bg-red-50" />
				<div className="w-full h-[40%] rounded-md bg-red-50" />
			</div>
			<div className="w-full flex justify-center gap-4">
				<div className="flex justify-center gap-1">
					<div className="w-4 h-2 rounded-md bg-red-50" />
					<div className="w-28 h-2 rounded-md bg-red-50" />
				</div>

				<div className="flex justify-center gap-1">
					<div className="w-4 h-2 rounded-md bg-red-50" />
					<div className="w-28 h-2 rounded-md bg-red-50" />
				</div>
			</div>
			<div className="absolute inset-0 flex items-center justify-center">
				<ErrorLabel>No data available</ErrorLabel>
			</div>
		</div>
	)
}
