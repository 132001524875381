/* eslint-disable react/react-in-jsx-scope */

import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
import twcolors from "tailwindcss/colors"
import { useAllBmiStatsQuery } from "../../../../graphql"
import { useBreakpoint } from "../../../../hooks"
import { EmptyChart } from "../../../common"
import { AspectRatio, Card, CardContent, ChartSkeleton, VStack, CardHeader, CardTitle } from "../../../ui"
import { useNavigate } from "react-router-dom"

const colors: string[] = [twcolors.blue[400], twcolors.pink[400], twcolors.gray[400], twcolors.cyan[400]]

export const BeneficiariesBMIStatsChart: React.FC = () => {
	const [{ data, fetching }] = useAllBmiStatsQuery()

	const { isXl } = useBreakpoint("xl")
	const { isMd } = useBreakpoint("md")

	const navigate = useNavigate()

	return (
		<Card className="h-full">
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Beneficiaries&apos; BMIs</CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="h-full gap-4 select-none">
					<AspectRatio ratio={isXl ? 3 / 4 : isMd ? 16 / 9 : 4 / 3}>
						<ResponsiveContainer width="100%" height="100%">
							{fetching ? (
								<ChartSkeleton />
							) : !data?.allBMIStats.length ? (
								<EmptyChart />
							) : (
								<PieChart>
									<Tooltip />
									<Legend />

									<Pie
										data={data.allBMIStats.map((d) => ({ name: d.category, value: d.count }))}
										cx="50%"
										cy="50%"
										outerRadius={isXl ? 100 : 80}
										dataKey="value"
										activeIndex={0}
										labelLine={false}
										label={renderCustomizedLabel}
									>
										{data.allBMIStats.map((entry, index) => (
											<Cell key={`cell-${index}`} className="cursor-pointer" fill={colors[index]} onClick={() => navigate(`/beneficiaries/bmi?category=${entry.category}`)} />
										))}
									</Pie>
								</PieChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }: any) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5
	const x = cx + radius * Math.cos(-midAngle * RADIAN)
	const y = cy + radius * Math.sin(-midAngle * RADIAN)

	return (
		<text x={x} y={y} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
			{value}
		</text>
	)
}
