/* eslint-disable @typescript-eslint/indent */

import React, { useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { InputError, useToast, Button, Label, Input, Tabs, TabsList, TabsTrigger, VStack, LoadingLabel, ErrorLabel } from "../../../components/ui"
import {
	useAllSchoolsWithoutPaginationQuery,
	useCreateSchoolWiseAttendanceSlotOfSchoolMutation,
	useOccupiedSlotsBySchoolQuery,
	useSchoolWiseAttendanceSlotsOfSchoolQuery,
	useSchoolWiseAttendanceSlotsOfSchoolWithTappingQuery,
} from "../../../graphql"
import StudentListForm from "./StudentListForm"
import { useNavigate } from "react-router-dom"
import SelectSlotForm from "./SelectSlotForm"
import SlotForm from "./SlotForm"
import { parseTimeString } from "../../../utils"

type AssignMealSlotFormValues = {
	ngo: string
	slot: string
	startTime: string
	endTime: string
}

const schema = z.object({
	ngo: z.string().nonempty({ message: "Ngo is required" }),
	slot: z.string().nonempty({ message: "slot is required" }),
	startTime: z.string().nonempty({ message: "start time is required" }),
	endTime: z.string().nonempty({ message: "end time is required" }),
})

const AssignMealSlotForm: React.FC = () => {
	const navigate = useNavigate()

	const { toast } = useToast()

	const [tap, setTap] = useState<boolean>(true)

	const [list, setList] = useState<boolean>(true)
	const [parentSlot, setParentSlot] = useState<boolean>(false)
	const [otherSlot, setOtherSlot] = useState<string | null>(null)
	const [selectedStudents, setSelectedStudents] = useState<string[]>([]) // State to store selected student IDs

	const [selectedNgo, setSelectedNgo] = useState<string>("")
	const [error, setError] = useState<string | null>(null)
	const [, createSchoolWiseAttendanceSlotOfSchool] = useCreateSchoolWiseAttendanceSlotOfSchoolMutation()

	const [{ data: ngos, fetching: ngoFetching, error: ngoError }] = useAllSchoolsWithoutPaginationQuery()

	const [tappingAttendenceSlotsOfSchool] = useSchoolWiseAttendanceSlotsOfSchoolWithTappingQuery({
		variables: { schoolId: selectedNgo },
		pause: !selectedNgo,
	})

	const [notoccupiedSlotBySchool] = useOccupiedSlotsBySchoolQuery({
		variables: { schoolId: selectedNgo },
		pause: !selectedNgo,
	})

	const [{ data: allMealSlotsOfSchool }] = useSchoolWiseAttendanceSlotsOfSchoolQuery({ variables: { schoolId: selectedNgo }, pause: !selectedNgo })

	const {
		register,
		handleSubmit,
		getValues,
		formState: { isLoading, errors, isSubmitting },
	} = useForm<AssignMealSlotFormValues>({ resolver: zodResolver(schema) as any })

	const onSubmit: SubmitHandler<AssignMealSlotFormValues> = async (values) => {
		try {
			let isStartTimeOverlapping = false as boolean
			let isEndTimeOverlapping = false as boolean

			const startTime = parseTimeString(getValues("startTime"), new Date())
			const endTime = parseTimeString(getValues("endTime"), new Date())

			// check if the start  time is greater then the end  time
			if (startTime > endTime) {
				toast({
					variant: "destructive",
					title: "Invalid Start time",
					description: "Start time cannot be greater then the end time",
				})

				setError("Invalid Start time")
				return
			}

			// check if the end  time is smaller then the start time
			if (endTime < startTime) {
				toast({
					variant: "destructive",
					title: "Invalid End time",
					description: "End time cannot be smaller then the start time",
				})

				setError("Invalid End time")
				return
			}

			//  Check if the selected start time overlaps with existing slots start At time
			if (allMealSlotsOfSchool?.schoolWiseAttendanceSlotsOfSchool.length) {
				isStartTimeOverlapping = allMealSlotsOfSchool.schoolWiseAttendanceSlotsOfSchool
					.filter((slot) => !slot.isDeleted)
					.some((slot) => {
						const slotSaveStartAt = parseTimeString(slot.startAt, new Date())
						const slotSaveEndAt = parseTimeString(slot.endAt, new Date())

						const selectedStartAt = parseTimeString(getValues("startTime"), new Date())

						return selectedStartAt >= slotSaveStartAt && selectedStartAt <= slotSaveEndAt
					})

				isEndTimeOverlapping = allMealSlotsOfSchool.schoolWiseAttendanceSlotsOfSchool
					.filter((slot) => !slot.isDeleted)
					.some((slot) => {
						const slotSaveStartAt = parseTimeString(slot.startAt, new Date())
						const slotSaveEndAt = parseTimeString(slot.endAt, new Date())

						const selectedStartAt = parseTimeString(getValues("startTime"), new Date())
						const selectedEndAt = parseTimeString(getValues("endTime"), new Date())

						// Check if the end time  overlaps with an existing slots end time
						return (
							(selectedEndAt >= slotSaveStartAt && selectedEndAt <= slotSaveEndAt) ||
							(selectedStartAt >= slotSaveStartAt && selectedStartAt <= slotSaveEndAt) ||
							(selectedStartAt <= slotSaveStartAt && selectedEndAt >= slotSaveEndAt)
						)
					})
			}

			if (isStartTimeOverlapping) {
				toast({
					variant: "destructive",
					title: "Start time is Overlapping",
					description: "Start time is overlaps with the existing slots start time Please select another start time",
				})

				setError("Start time is overlaps with the existing slots start time Please select another start time")
				return
			}

			if (isEndTimeOverlapping) {
				toast({
					variant: "destructive",
					title: "End time is Overlapping",
					description: "End time is overlaps with the existing slots end time Please select another end time",
				})

				setError("End time is overlaps with the existing slots End time Please select another end time")
				return
			}

			if (otherSlot) {
				const { data, error } = await createSchoolWiseAttendanceSlotOfSchool({
					input: {
						attendanceSlotId: values.slot,
						endAt: values.endTime,
						startAt: values.startTime,
						schoolId: selectedNgo,
						isTappingRequired: tap,
						studentIds: selectedStudents,
						parentSlotId: otherSlot,
					},
				})

				if (error) {
					toast({
						variant: "destructive",
						title: "Creating new Meal Slot  Failed",
						description: error.message.replace("[GraphQL] ", ""),
					})

					setError(error.message.replace("[GraphQL] ", ""))
				}

				if (data?.createSchoolWiseAttendanceSlotOfSchool) {
					toast({
						variant: "success",
						title: "Meal Slot Created Successfully",
						description: "",
					})
					navigate(`/mealSlots/${data.createSchoolWiseAttendanceSlotOfSchool._id}`, { replace: true })
				}
			} else {
				const { data, error } = await createSchoolWiseAttendanceSlotOfSchool({
					input: {
						attendanceSlotId: values.slot,
						endAt: values.endTime,
						startAt: values.startTime,
						schoolId: selectedNgo,
						isTappingRequired: tap,
						studentIds: selectedStudents,
					},
				})

				if (error) {
					toast({
						variant: "destructive",
						title: "Creating new Meal Slot  Failed",
						description: error.message.replace("[GraphQL] ", ""),
					})
					setError(error.message.replace("[GraphQL] ", ""))
				}

				if (data?.createSchoolWiseAttendanceSlotOfSchool) {
					toast({
						variant: "success",
						title: "Meal Slot Created Successfully",
						description: "",
					})
					navigate(`/mealSlots/${data.createSchoolWiseAttendanceSlotOfSchool._id}`, { replace: true })
				}
			}
		} catch (err: any) {
			toast({
				variant: "destructive",
				title: "Updating  Meal Slot  Failed",
				description: err.message.replace("[GraphQL] ", ""),
			})
		}
	}

	const handleTapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTap(e.target.checked) // Update 'tap' state based on the checkbox being checked or unchecked
	}

	const handleStudentIdsChange = (studentIds: string[]) => {
		setSelectedStudents(studentIds) // Update selected student IDs in the parent component
	}

	const handleOtherSlot = (slot: string) => {
		if (slot) setOtherSlot(slot)
	}

	return (
		<>
			<div className="relative">
				<form onSubmit={handleSubmit(onSubmit)} onChange={() => setError(null)}>
					<div className="flex flex-col  gap-2 md:gap-6 lg:w-96 ">
						{ngoFetching ? (
							<VStack className="w-full max-w-xl items-stretch gap-2">
								<LoadingLabel />
							</VStack>
						) : ngoError ? (
							<ErrorLabel>{ngoError.message.replace("[GraphQL] ", "")}</ErrorLabel>
						) : ngos?.allSchoolsWithoutPagination && ngos.allSchoolsWithoutPagination.length ? (
							<div className="flex flex-col gap-2">
								<Label>NGO Partner</Label>

								<select className="px-4 py-2 outline-none" {...register("ngo")} value={selectedNgo} onChange={(e) => setSelectedNgo(e.target.value)}>
									<option value="">Select Ngo Partner</option>
									{ngos.allSchoolsWithoutPagination.map((school) => (
										<option key={school._id} value={school._id}>
											{school.name}
										</option>
									))}
								</select>
								<InputError>{errors.ngo?.message}</InputError>
							</div>
						) : (
							<ErrorLabel>Couldn&apos;t find any NgoPartners.</ErrorLabel>
						)}

						{selectedNgo && (
							<>
								{notoccupiedSlotBySchool.fetching ? (
									<VStack className="w-full max-w-xl items-stretch gap-2">
										<LoadingLabel />
									</VStack>
								) : notoccupiedSlotBySchool.error ? (
									<ErrorLabel>{notoccupiedSlotBySchool.error.message.replace("[GraphQL] ", "")}</ErrorLabel>
								) : notoccupiedSlotBySchool.data?.occupiedSlotsBySchool && notoccupiedSlotBySchool.data.occupiedSlotsBySchool.length ? (
									<div className="flex flex-col gap-2">
										<Label>Slot</Label>

										<select className=" px-4 py-2 outline-none" defaultValue="" {...register("slot")}>
											<option value="" disabled>
												Select Meal Slot
											</option>

											{notoccupiedSlotBySchool.data?.occupiedSlotsBySchool.map((meal) => (
												<option key={meal._id} value={meal._id}>
													{meal.name}
												</option>
											))}
										</select>
										<InputError>{errors.slot?.message}</InputError>
									</div>
								) : (
									<ErrorLabel>Couldn&apos;t find any meal slot.</ErrorLabel>
								)}

								<div className="flex justify-between">
									<div className="flex flex-col gap-2">
										<Label htmlFor="startTime">Start Time</Label>
										<input type="time" {...register("startTime")} />
										<InputError>{errors.startTime?.message}</InputError>
									</div>

									<div className="flex flex-col gap-2">
										<Label htmlFor="endTime">End Time</Label>

										<input type="time" {...register("endTime")} />
										<InputError>{errors.endTime?.message}</InputError>
									</div>
								</div>
							</>
						)}

						{selectedNgo && (
							<div className="flex gap-2">
								<Input type="Checkbox" checked={tap} className="w-4 h-4" onChange={handleTapChange} />
								<Label htmlFor="tapping">Tapping required</Label>
							</div>
						)}
					</div>

					{tap && selectedNgo && (
						<div className="w-80 mt-4 md:mt-10">
							<div className="flex flex-col gap-4">
								<h2 className="text-lg text-gray-800 font-medium">Select the Benficiaries from</h2>

								<Tabs defaultValue="list">
									<TabsList>
										<TabsTrigger
											value="list"
											onClick={() => {
												setParentSlot(false)
												setList(true)
											}}
										>
											New Beneficiary list
										</TabsTrigger>
										{tappingAttendenceSlotsOfSchool.data && tappingAttendenceSlotsOfSchool.data?.schoolWiseAttendanceSlotsOfSchoolWithTapping.length > 0 ? (
											<TabsTrigger
												value="parentSlot"
												onClick={() => {
													setParentSlot(true)
													setList(false)
												}}
											>
												Other slots Beneficiaries
											</TabsTrigger>
										) : (
											<TabsTrigger
												value="parentSlot"
												onClick={() => {
													setParentSlot(true)
													setList(false)
												}}
												disabled={true}
												className="hover:cursor-not-allowed"
											>
												Other slots Beneficiaries
											</TabsTrigger>
										)}
									</TabsList>
								</Tabs>
							</div>
						</div>
					)}

					{tap && list && selectedNgo && (
						<>
							<div className="md:absolute md:top-0 md:right-0 mt-2">
								<StudentListForm schoolId={selectedNgo} onStudentIdsChange={handleStudentIdsChange} />
							</div>
						</>
					)}

					{tap && parentSlot && selectedNgo && tappingAttendenceSlotsOfSchool.data && tappingAttendenceSlotsOfSchool.data.schoolWiseAttendanceSlotsOfSchoolWithTapping.length > 0 && (
						<div className=" mt-2 md:mt-10">
							<SlotForm schoolId={selectedNgo} onOtherSlotChange={handleOtherSlot} />
						</div>
					)}

					{!tap && selectedNgo && tappingAttendenceSlotsOfSchool.data && tappingAttendenceSlotsOfSchool.data.schoolWiseAttendanceSlotsOfSchoolWithTapping.length > 0 && (
						<div className=" mt-2 md:mt-10">
							<SelectSlotForm schoolId={selectedNgo} onOtherSlotChange={handleOtherSlot} />
						</div>
					)}

					{!tap && selectedNgo && tappingAttendenceSlotsOfSchool.data && tappingAttendenceSlotsOfSchool.data.schoolWiseAttendanceSlotsOfSchoolWithTapping.length === 0 && (
						<div className="mt-2 md:mt-10 max-w-sm text-sm font-medium text-red-600">
							<p>Their is no parent slot exist for the selected ngo. please create atleast one slot with tapping required to able to work </p>
						</div>
					)}

					<p className="mt-2 mb-2 md:mt-6 md:mb-6 max-w-sm text-sm font-medium text-red-600">{error}</p>
					<Button className="lg:w-96  mt-2 md:mt-10" isLoading={isLoading || isSubmitting}>
						Submit
					</Button>
				</form>
			</div>
		</>
	)
}

export default AssignMealSlotForm
