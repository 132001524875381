import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { ExtendedStudentFragment, useActivateStudentMutation } from "../../../graphql"

import { useToast, Button } from "../../../components"

export type ActivateBeneficiaryProps = {
	beneficiary: ExtendedStudentFragment
}

export const ActivateBeneficiary: React.FC<ActivateBeneficiaryProps> = ({ beneficiary }) => {
	const [{ fetching }, activate] = useActivateStudentMutation()

	const navigate = useNavigate()
	const { toast } = useToast()

	const handleActivate = async () => {
		const { error, data } = await activate({ studentId: beneficiary._id })

		if (error) {
			toast({
				variant: "destructive",
				title: "Beneficiary Activation Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})
		}

		if (data?.activateStudent) {
			toast({
				variant: "success",
				title: "Beneficiary Activated Successfully",
			})

			navigate(`/beneficiaries/${data.activateStudent._id}`)
		} else {
			return toast({ description: "Couldn't activate the user" })
		}
	}

	return (
		<div className="mt-6 w-fit">
			<h2 className="text-sm text-gray-600 font-normal">Are you sure you want to activate {beneficiary.name}?</h2>
			<div className="flex gap-6 mt-4 justify-end">
				<Link className="w-fit rounded-lg px-4 py-2 font-medium bg-slate-500 text-white hover:cursor-pointer hover:opacity-70" to={`/beneficiaries/${beneficiary._id}`}>
					Cancel
				</Link>
				<Button className="w-fit rounded-lg px-4 py-2 font-medium bg-green-500 text-white hover:cursor-pointer hover:opacity-70" onClick={handleActivate} isLoading={fetching}>
					Activate
				</Button>
			</div>
		</div>
	)
}
