import React from "react"
import UpdateMealSlotForm from "../../../forms/admin/meal/UpdateMealSlotForm"
import { ExtendedSchoolWiseAttendanceSlotFragment } from "../../../graphql"

export type UpdateMealSlotProps = {
	slot: ExtendedSchoolWiseAttendanceSlotFragment
}

export const UpdateMealSlot: React.FC<UpdateMealSlotProps> = ({ slot }) => {
	return (
		<div className="md:px-10 md:py-4">
			<h1 className="text-gray-700 text-lg font-bold">Update Meal Slot</h1>
			<div className="mt-2 md:mt-10">
				<UpdateMealSlotForm slot={slot} />
			</div>
		</div>
	)
}
