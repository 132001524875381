import React from "react"

import { Input, VStack } from "../../../components"
import { SearchDevices } from "../../../components/admin/devices/SearchDevices"

import { useSearchParams } from "../../../hooks"
import { DeviceStatusChart } from "../../../components/admin/charts/device/DeviceStatusChart"

export const DevicesPage: React.FC = () => {
	const [searchParams, { handleSearchParamUpdate, handleSearchParamDelete }] = useSearchParams()

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2 lg:gap-4">
			<VStack className="lg:flex-row items-stretch lg:items-center">
				<h1 className="w-full text-sm lg:text-md font-medium">Devices</h1>
				<div className="flex justify-end">
					<Input
						type="search"
						placeholder="Search..."
						value={(searchParams.get("keyword") as string) || ""}
						onChange={(e) => {
							if (e.target.value) handleSearchParamUpdate("keyword", e.target.value)
							else handleSearchParamDelete("keyword")
						}}
					/>
				</div>
			</VStack>
			<div className="flex  flex-col-reverse gap-y-4 md:flex-row md:gap-y-0 md:gap-x-20 ">
				<SearchDevices keyword={(searchParams.get("keyword") as string) || ""} />
				<div className="w-full flex justify-center">
					<DeviceStatusChart />
				</div>
			</div>
		</div>
	)
}

export default DevicesPage
