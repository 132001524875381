/* eslint-disable @typescript-eslint/indent */

import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { Button, ErrorLabel, useToast, Input, InputError, Label, LoadingLabel, Tabs, TabsList, TabsTrigger, VStack } from "../../../components/ui"
import {
	ExtendedSchoolWiseAttendanceSlotFragment,
	useAllAttendanceSlotsWithoutPaginationQuery,
	useAllSchoolsWithoutPaginationQuery,
	useSchoolWiseAttendanceSlotsOfSchoolQuery,
	useSchoolWiseAttendanceSlotsOfSchoolWithTappingQuery,
	useUpdateSchoolWiseAttendanceSlotOfSchoolMutation,
} from "../../../graphql"

import { useNavigate } from "react-router-dom"

import UpdateSelectSlotForm from "./UpdateSelectSlotForm"
import UpdateStudentListForm from "./UpdateStudentListForm"

import UpdateSlotForm from "./UpdateSlot"
import { parseTimeString } from "../../../utils"

type UpdateMealSlotFormValues = {
	ngo: string
	slot: string
	startTime: string
	endTime: string
}

export type UpdateMealSlotFormProps = {
	slot: ExtendedSchoolWiseAttendanceSlotFragment
}

const schema = z.object({
	ngo: z.string().nonempty({ message: "Ngo is required" }),
	slot: z.string().nonempty({ message: "Slot is required" }),
	startTime: z.string().nonempty({ message: "Start time is required" }),
	endTime: z.string().nonempty({ message: "End time is required" }),
})

const UpdateMealSlotForm: React.FC<UpdateMealSlotFormProps> = ({ slot }) => {
	const initialValues: UpdateMealSlotFormValues = {
		endTime: slot.endAt,
		ngo: slot.schoolId,
		startTime: slot.startAt,
		slot: slot.attendanceSlotId,
	}

	const navigate = useNavigate()
	const { toast } = useToast()

	const [tap, setTap] = useState<boolean>(slot.isTappingRequired)

	const [list, setList] = useState<boolean>(true)
	const [parentSlot, setParentSlot] = useState<boolean>(false)
	const [otherSlot, setOtherSlot] = useState(slot.parentSlotId)

	const [selectedStudents, setSelectedStudents] = useState<string[]>([]) // State to store selected student IDs

	const [selectedNgo, setSelectedNgo] = useState<string>(slot.schoolId)
	const [error, setError] = useState<string | null>(null)
	const [, updateMealSlot] = useUpdateSchoolWiseAttendanceSlotOfSchoolMutation()

	const [{ data: ngos, fetching: ngoFetching, error: ngoError }] = useAllSchoolsWithoutPaginationQuery()
	const [attendanceSlots] = useAllAttendanceSlotsWithoutPaginationQuery()
	const [tappingAttendenceSlotsOfSchool] = useSchoolWiseAttendanceSlotsOfSchoolWithTappingQuery({
		variables: { schoolId: selectedNgo },
		pause: !selectedNgo,
	})

	const [{ data: allMealSlotsOfSchool }] = useSchoolWiseAttendanceSlotsOfSchoolQuery({ variables: { schoolId: selectedNgo }, pause: !selectedNgo })

	useEffect(() => {
		setSelectedStudents(slot.studentIds)
		setSelectedNgo(slot.schoolId)
	}, [slot])

	const {
		register,
		getValues,
		handleSubmit,

		formState: { isLoading, errors, isSubmitting },
	} = useForm<UpdateMealSlotFormValues>({ resolver: zodResolver(schema) as any, defaultValues: initialValues })

	const onSubmit: SubmitHandler<UpdateMealSlotFormValues> = async (values) => {
		try {
			let isStartTimeOverlapping = false as boolean
			let isEndTimeOverlapping = false as boolean

			const startTime = parseTimeString(getValues("startTime"), new Date())
			const endTime = parseTimeString(getValues("endTime"), new Date())

			// check if the start  time is greater then the end  time
			if (startTime > endTime) {
				toast({
					variant: "destructive",
					title: "Invalid Start time",
					description: "Start time cannot be greater then the end time",
				})

				setError("Invalid Start time")
				return
			}

			// check if the end  time is smaller then the start time
			if (endTime < startTime) {
				toast({
					variant: "destructive",
					title: "Invalid End time",
					description: "End time cannot be smaller then the start time",
				})

				setError("Invalid End time")
				return
			}

			//  Check if the selected start time overlaps with existing slots start At time
			if (allMealSlotsOfSchool?.schoolWiseAttendanceSlotsOfSchool.length) {
				isStartTimeOverlapping = allMealSlotsOfSchool.schoolWiseAttendanceSlotsOfSchool
					.filter((_slot) => !_slot.isDeleted && _slot._id !== slot._id)
					.some((slot) => {
						const slotSaveStartAt = parseTimeString(slot.startAt, new Date())
						const slotSaveEndAt = parseTimeString(slot.endAt, new Date())

						const selectedStartAt = parseTimeString(getValues("startTime"), new Date())

						console.table({ slotSaveStartAt, slotSaveEndAt, selectedStartAt })

						return selectedStartAt >= slotSaveStartAt && selectedStartAt <= slotSaveEndAt
					})

				isEndTimeOverlapping = allMealSlotsOfSchool.schoolWiseAttendanceSlotsOfSchool
					.filter((_slot) => !_slot.isDeleted && _slot._id !== slot._id)
					.some((slot) => {
						const slotSaveStartAt = parseTimeString(slot.startAt, new Date())
						const slotSaveEndAt = parseTimeString(slot.endAt, new Date())

						const selectedStartAt = parseTimeString(getValues("startTime"), new Date())
						const selectedEndAt = parseTimeString(getValues("endTime"), new Date())

						// Check if the end time  overlaps with an existing slots end time
						return (
							(selectedEndAt >= slotSaveStartAt && selectedEndAt <= slotSaveEndAt) ||
							(selectedStartAt >= slotSaveStartAt && selectedStartAt <= slotSaveEndAt) ||
							(selectedStartAt <= slotSaveStartAt && selectedEndAt >= slotSaveEndAt)
						)
					})
			}

			if (isStartTimeOverlapping) {
				toast({
					variant: "destructive",
					title: "Start time is overlapping",
					description: "Start time overlaps with another slot, please change start time",
				})

				setError("Start time overlaps with another slot, please change start time")
				return
			}

			if (isEndTimeOverlapping) {
				toast({
					variant: "destructive",
					title: "End time is overlapping",
					description: "End time overlaps with another slot, please change end time",
				})

				setError("End time overlaps with another slot, please change end time")
				return
			}

			const { data, error } = await updateMealSlot({
				input: {
					endAt: values.endTime,
					startAt: values.startTime,
					isTappingRequired: tap,
					studentIds: selectedStudents,
					parentSlotId: otherSlot,
				},
				schoolWiseAttendanceSlotId: slot._id,
			})

			if (error) {
				toast({
					variant: "destructive",
					title: "Updating  Meal Slot  Failed",
					description: error.message.replace("[GraphQL] ", ""),
				})
				setError(error.message.replace("[GraphQL] ", ""))
			}

			if (data?.updateSchoolWiseAttendanceSlotOfSchool) {
				toast({
					variant: "success",
					title: "Meal Slot Updated Successfully",
					description: "",
				})
				navigate(`/mealSlots/${data.updateSchoolWiseAttendanceSlotOfSchool._id}`, { replace: true })
			}
		} catch (err: any) {
			toast({
				variant: "destructive",
				title: "Updating  Meal Slot  Failed",
				description: err.message.replace("[GraphQL] ", ""),
			})
		}
	}

	const handleTapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTap(e.target.checked) // Update 'tap' state based on the checkbox being checked or unchecked
	}

	const handleStudentIdsChange = (studentIds: string[]) => {
		setSelectedStudents(studentIds) // Update selected student IDs in the parent component
	}

	const handleOtherSlot = (slot: string) => {
		setOtherSlot(slot)
	}

	useEffect(() => {
		if (tap) {
			setSelectedStudents([])
			setOtherSlot(undefined)
		}
	}, [tap])

	return (
		<>
			<div className="relative">
				<form onSubmit={handleSubmit(onSubmit)} onChange={() => setError(null)}>
					<div className="flex flex-col gap-2 md:gap-6 lg:w-96 ">
						{ngoFetching ? (
							<VStack className="w-full max-w-xl items-stretch gap-2">
								<LoadingLabel />
							</VStack>
						) : ngoError ? (
							<ErrorLabel>{ngoError.message.replace("[GraphQL] ", "")}</ErrorLabel>
						) : ngos?.allSchoolsWithoutPagination && ngos.allSchoolsWithoutPagination.length ? (
							<div className="flex flex-col gap-2">
								<Label>NGO Partner</Label>

								<select className="px-4 py-2 outline-none hover:cursor-not-allowed" disabled {...register("ngo")} value={selectedNgo} onChange={(e) => setSelectedNgo(e.target.value)}>
									<option value="">Select Ngo Partner</option>
									{ngos.allSchoolsWithoutPagination.map((school) => (
										<option key={school._id} value={school._id}>
											{school.name}
										</option>
									))}
								</select>
								<InputError>{errors.ngo?.message}</InputError>
							</div>
						) : (
							<ErrorLabel>Couldn&apos;t find any NgoPartners.</ErrorLabel>
						)}

						{attendanceSlots.fetching ? (
							<VStack className="w-full max-w-xl items-stretch gap-2">
								<LoadingLabel />
							</VStack>
						) : attendanceSlots.error ? (
							<ErrorLabel>{attendanceSlots.error.message.replace("[GraphQL] ", "")}</ErrorLabel>
						) : attendanceSlots.data?.allAttendanceSlotsWithoutPagination && attendanceSlots.data.allAttendanceSlotsWithoutPagination.length ? (
							<div className="flex flex-col gap-2">
								<Label>Slot</Label>

								<select className=" px-4 py-2 outline-none hover:cursor-not-allowed" disabled defaultValue="" {...register("slot")}>
									<option value="" disabled>
										Select Meal Slot
									</option>

									{attendanceSlots.data?.allAttendanceSlotsWithoutPagination.map((meal) => (
										<option key={meal._id} value={meal._id}>
											{meal.name}
										</option>
									))}
								</select>
								<InputError>{errors.slot?.message}</InputError>
							</div>
						) : (
							<ErrorLabel>Couldn&apos;t find any meal slot.</ErrorLabel>
						)}

						<div className="flex justify-between">
							<div className="flex flex-col gap-2">
								<Label htmlFor="startTime">Start Time</Label>
								<input type="time" {...register("startTime")} />
								<InputError>{errors.startTime?.message}</InputError>
							</div>

							<div className="flex flex-col gap-2">
								<Label htmlFor="endTime">End Time</Label>

								<input type="time" {...register("endTime")} />
								<InputError>{errors.endTime?.message}</InputError>
							</div>
						</div>
						{selectedNgo && (
							<div className="flex gap-2">
								<Input type="Checkbox" checked={tap} className="w-4 h-4" onChange={handleTapChange} />
								<Label htmlFor="tapping">Tapping required</Label>
							</div>
						)}
					</div>

					{tap && selectedNgo && (
						<div className="w-80 mt-2 md:mt-10">
							<div className="flex flex-col gap-2 md:gap-4">
								<h2 className="text-lg text-gray-800 font-medium">Select the Benficiaries from</h2>

								<Tabs defaultValue="list">
									<TabsList>
										<TabsTrigger
											value="list"
											onClick={() => {
												setParentSlot(false)
												setList(true)
											}}
										>
											New Beneficiary list
										</TabsTrigger>
										{tappingAttendenceSlotsOfSchool.data && tappingAttendenceSlotsOfSchool.data?.schoolWiseAttendanceSlotsOfSchoolWithTapping.length >= 1 ? (
											<TabsTrigger
												value="parentSlot"
												onClick={() => {
													setParentSlot(true)
													setList(false)
												}}
											>
												Other slot&apos;s beneficiaries
											</TabsTrigger>
										) : (
											<TabsTrigger
												value="parentSlot"
												onClick={() => {
													setParentSlot(true)
													setList(false)
												}}
												disabled={true}
											>
												Other slot&apos;s beneficiaries
											</TabsTrigger>
										)}
									</TabsList>
								</Tabs>
							</div>
						</div>
					)}

					{tap && list && selectedNgo && (
						<div className="md:absolute md:top-0 md:right-0 mt-2">
							<UpdateStudentListForm schoolId={selectedNgo} onStudentIdsChange={handleStudentIdsChange} slot={slot} />
						</div>
					)}

					{tap && parentSlot && selectedNgo && tappingAttendenceSlotsOfSchool.data && tappingAttendenceSlotsOfSchool.data.schoolWiseAttendanceSlotsOfSchoolWithTapping.length >= 1 && (
						<div className="mt-2 md:mt-10">
							<UpdateSlotForm schoolId={selectedNgo} onOtherSlotChange={handleOtherSlot} slot={slot} />
						</div>
					)}

					{!tap && selectedNgo && tappingAttendenceSlotsOfSchool.data && tappingAttendenceSlotsOfSchool.data.schoolWiseAttendanceSlotsOfSchoolWithTapping.length >= 1 && (
						<div className="mt-2 md:mt-10">
							<UpdateSelectSlotForm schoolId={selectedNgo} selectedOtherSlotId={otherSlot ?? undefined} onOtherSlotChange={handleOtherSlot} slot={slot} />
						</div>
					)}

					{!tap && selectedNgo && tappingAttendenceSlotsOfSchool.data && tappingAttendenceSlotsOfSchool.data.schoolWiseAttendanceSlotsOfSchoolWithTapping.length === 0 && (
						<div className="mt-2 md:mt-10 max-w-sm text-sm font-medium text-red-600">
							<p>Please create atleast one slot with tapping</p>
						</div>
					)}

					<p className="mt-2 mb-2 max-w-sm text-sm font-medium text-red-600">{error}</p>
					<Button className="mt-2 lg:w-96   md:mt-10" isLoading={isLoading || isSubmitting}>
						Update
					</Button>
				</form>
			</div>
		</>
	)
}

export default UpdateMealSlotForm
