import { SchoolWisePartialAttendanceStatsQuery, SchoolWisePartialAttendanceStatsQueryVariables } from "../../graphql"

export enum AttendanceStatsContextActions {
	NEW,
	HANDLING,
	LOADED,
	FAILED,
	LINK,
}

export type AttendanceStatsContextAction = {
	type: AttendanceStatsContextActions
	payload: Omit<Query, "waiting" | "handling" | "id"> & { superQueryIds?: string[] }
}

export type Query = {
	id: string
	superQueryIds?: string[]
	filter: SchoolWisePartialAttendanceStatsQueryVariables["filter"]
	waiting: boolean
	handling?: boolean
	failed?: boolean
	data?: SchoolWisePartialAttendanceStatsQuery
}

export type AttendanceStatsContextState = {
	queries: Query[]
}
