import React, { useCallback, useState } from "react"
import { SearchClassesQueryVariables, useSearchClassesQuery } from "../../../graphql"
import { ErrorLabel, Skeleton, VStack } from "../../ui"
import { ClassList } from "./ClassList"

type SearchResultClassesProps = {
	keyword?: string
}

export const SearchResultClasses: React.FC<SearchResultClassesProps> = ({ keyword = "" }) => {
	const [pagination, setPagination] = useState<SearchClassesQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useSearchClassesQuery({ variables: { keyword, pagination } })

	const nextPage = useCallback(() => {
		if (data?.searchClasses.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data?.searchClasses.hasNextPage])

	return (
		<VStack>
			{fetching && !data?.searchClasses ? (
				<VStack className="w-full items-stretch gap-2">
					<Skeleton className="w-full h-24" />
					<Skeleton className="w-full h-24" />
					<Skeleton className="w-full h-24" />
					<Skeleton className="w-full h-24" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.searchClasses.classes && data.searchClasses.classes.length ? (
				<ClassList classes={data?.searchClasses.classes} loadMore={nextPage} />
			) : (
				<ErrorLabel>Couldn&apos;t find any classes.</ErrorLabel>
			)}
		</VStack>
	)
}
