import React, { SVGProps } from "react"

export const Home: React.FC<SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M15.4268 8.27395C15.3483 8.48767 15.2059 8.67202 15.0189 8.80192C14.8319 8.93182 14.6095 9.00097 14.3818 8.99995H13.9998V14.5C13.9998 14.6326 13.9471 14.7597 13.8534 14.8535C13.7596 14.9473 13.6324 15 13.4998 15H10.4998V11.5C10.4998 10.1215 9.3783 8.99995 7.9998 8.99995C6.6213 8.99995 5.4998 10.1215 5.4998 11.5V15H2.4998C2.36719 15 2.24001 14.9473 2.14625 14.8535C2.05248 14.7597 1.9998 14.6326 1.9998 14.5V8.99995H1.6173C1.38967 9.00107 1.16722 8.93203 0.980239 8.80221C0.793253 8.6724 0.650811 8.48812 0.572299 8.27445C0.490861 8.06168 0.476927 7.829 0.532392 7.60803C0.587858 7.38706 0.710031 7.18854 0.882299 7.03945L6.7218 1.50945C7.06705 1.18263 7.52439 1.00049 7.9998 1.00049C8.47521 1.00049 8.93255 1.18263 9.2778 1.50945L15.1313 7.05245C15.4703 7.34795 15.5923 7.83295 15.4268 8.27395Z"
				fill={props.fill || "#6B7280"}
			/>
		</svg>
	)
}
