/* eslint-disable react/react-in-jsx-scope */

import { format, startOfDay } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import twcolors from "tailwindcss/colors"
import { SchoolWisePartialAttendanceStatsQueryVariables, useAllSchoolsWithoutPaginationQuery, useSchoolWisePartialAttendanceStatsQuery } from "../../../../graphql"
import { cn } from "../../../../lib/utils"

import { roundDecimalNumber } from "../../../../utils"
import { EmptyChart } from "../../../common"
import { AspectRatio, Button, Calendar, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, ErrorLabel, LoadingLabel, Popover, PopoverContent, PopoverTrigger, VStack } from "../../../ui"

const mealColors: { [key: string]: string } = {
	Breakfast: twcolors.red[400],
	Lunch: twcolors.purple[400],
	Dinner: twcolors.teal[400],
	Snacks: twcolors.orange[400],
	["First Half"]: twcolors.emerald[600],
	["Second Half"]: twcolors.blue[400],
	["Full Day"]: twcolors.green[400],
}

type MealStats = {
	total: number
	persent: number
	percent: number
}

type SchoolStats = {
	[key: string]: MealStats
}

type SchoolData = {
	school: string
	[key: string]: string | number | undefined
}

export const NGOPartnerWiseAttendanceStatsChart: React.FC = () => {
	const [filter, setFilter] = useState<Required<SchoolWisePartialAttendanceStatsQueryVariables["filter"]>>({ sinceDate: startOfDay(new Date()), untilDate: startOfDay(new Date()), schoolIds: [] })

	const [{ data, fetching }] = useSchoolWisePartialAttendanceStatsQuery({ variables: { filter } })

	const [{ data: ngoPartnersData, fetching: ngoPartnersFetching, error: ngoPartnersError }] = useAllSchoolsWithoutPaginationQuery()

	const attendanceData = useMemo(() => {
		return data?.schoolWisePartialAttendanceStats.map((s) => {
			const schoolStats: SchoolStats = {}

			s.stats
				.map((x) => x)

				.map((d) => {
					const key = d.meal?.name as string

					if (schoolStats[key]) {
						const total = d.meal.totalStudentsCount + schoolStats[key].total
						const persent = d.meal.presentStudentsCount + schoolStats[key].persent
						schoolStats[key].total = total
						schoolStats[key].persent = persent

						schoolStats[key].percent = roundDecimalNumber((persent / total) * 100)
					} else {
						schoolStats[key] = {
							total: d.meal.totalStudentsCount,
							persent: d.meal.presentStudentsCount,
							percent: roundDecimalNumber((d.meal.presentStudentsCount / d.meal.totalStudentsCount) * 100) || 0,
						}
					}
				})

			return {
				school: s.school.name.split(" ")[0],
				...schoolStats,
			}
		})
	}, [JSON.stringify(data?.schoolWisePartialAttendanceStats ?? {})])

	const transformedData: SchoolData[] = useMemo(() => {
		if (!attendanceData) {
			return []
		}

		return attendanceData.map((item) => {
			const schoolData: SchoolData = { school: item.school }

			Object.entries(item).forEach(([key, value]) => {
				if (key !== "school" && typeof value === "object" && "percent" in value) {
					schoolData[key] = (value as { percent?: number }).percent || 0
				}
			})

			return schoolData
		})
	}, [attendanceData])

	const navigate = useNavigate()

	const renderedMealKeys = new Set() // Track rendered meal keys for each school

	const ngoPartnerByName = useCallback((ngoPartnerName: string) => ngoPartnersData?.allSchoolsWithoutPagination.find((s) => s.name.split(" ")[0] === ngoPartnerName), [ngoPartnersData])

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Daily Attendance</CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="gap-4 select-none">
					<VStack className="lg:flex-row items-stretch">
						{ngoPartnersFetching ? (
							<LoadingLabel className="flex-1">Loading NGO partners</LoadingLabel>
						) : ngoPartnersError ? (
							<ErrorLabel className="flex-1">{ngoPartnersError.message.replace("[GraphQL] ", "")}</ErrorLabel>
						) : !ngoPartnersData?.allSchoolsWithoutPagination.length ? (
							<ErrorLabel className="flex-1">Couldn&apos;t fetch NGO partners</ErrorLabel>
						) : (
							<Select
								isMulti
								name="NGO Partners"
								placeholder="Select NGO partners"
								options={ngoPartnersData.allSchoolsWithoutPagination.map((s) => ({ label: s.name, value: s._id }))}
								onChange={(ngoPartners) => setFilter((prev) => ({ ...prev, schoolIds: ngoPartners.map((o) => o.value) }))}
								className="basic-multi-select flex-1"
								isSearchable
							/>
						)}
						<Popover>
							<PopoverTrigger asChild>
								<Button id="date" variant="outline" className={cn("flex-1/2 justify-start text-left font-normal", !(filter.sinceDate && filter.untilDate) && "text-muted-foreground")}>
									<CalendarIcon className="mr-2 h-4 w-4" />
									{filter.sinceDate ? (
										filter.untilDate ? (
											<>
												{format(filter.sinceDate, "MMM dd, yyyy")} - {format(filter.untilDate, "MMM dd, yyyy")}
											</>
										) : (
											format(filter.sinceDate, "MMM dd, yyyy")
										)
									) : (
										<span>Pick a date</span>
									)}
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-auto p-0" align="start">
								<Calendar
									initialFocus
									mode="range"
									defaultMonth={filter.sinceDate}
									selected={{ from: filter.sinceDate, to: filter.untilDate }}
									onSelect={(date) => setFilter((prev) => ({ ...prev, sinceDate: date?.from || date?.to, untilDate: date?.to || date?.from }))}
									toDate={new Date()}
									numberOfMonths={1}
								/>
							</PopoverContent>
						</Popover>
					</VStack>

					<AspectRatio ratio={16 / 9}>
						<ResponsiveContainer width="100%" height="100%">
							{fetching ? (
								<ChartSkeleton />
							) : !attendanceData?.length ? (
								<EmptyChart />
							) : (
								<BarChart
									width={500}
									height={300}
									data={transformedData}
									margin={{
										top: 40,
										bottom: 5,
									}}
									onClick={(o) => {
										const ngoPartnerId = ngoPartnerByName(o?.activeLabel || "")?._id

										if (ngoPartnerId) navigate("/attendance/ngo-partners", { state: { filter: { ...filter, schoolIds: [ngoPartnerId] } } })
										else navigate("/attendance/ngo-partners", { state: { filter } })
									}}
								>
									<XAxis dataKey="school" />
									<YAxis domain={[1, 100]} />
									<Tooltip formatter={(value) => (value ? <span>{value}%</span> : <span>0%</span>)} />

									<Legend verticalAlign="bottom" height={30} />

									{transformedData.map((schoolData, schoolIndex) => {
										return Object.keys(schoolData)
											.filter((key) => key !== "school") // Exclude the 'school' key
											.map((mealKey) => {
												if (!renderedMealKeys.has(mealKey)) {
													renderedMealKeys.add(mealKey)
													return (
														<Bar
															key={`school-${schoolIndex}-${mealKey}`}
															name={mealKey}
															dataKey={mealKey}
															fill={mealColors[mealKey]}
															label={{
																position: "top",
																fill: mealColors[mealKey],
																fontSize: 15,
																dy: -20,
																dx: 4,
																angle: 270,
																formatter: (value: number) => (value ? value + "%" : "0%"),
															}}
														/>
													)
												}
												return null
											})
									})}
								</BarChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}
