import { ValidTillBannerContextAction, ValidTillBannerContextActions, ValidTillBannerStatus } from "./types"

export const setVisible = (): ValidTillBannerContextAction => ({
	type: ValidTillBannerContextActions.SET_VISIBLE,
})

export const setInvisible = (): ValidTillBannerContextAction => ({
	type: ValidTillBannerContextActions.SET_INVISIBLE,
})

export const setStatus = (status: ValidTillBannerStatus): ValidTillBannerContextAction => ({
	type: ValidTillBannerContextActions.SET_STATUS,
	payload: { status },
})

export const setChildren = (children: React.ReactNode): ValidTillBannerContextAction => ({
	type: ValidTillBannerContextActions.SET_CHILDREN,
	payload: { children },
})
