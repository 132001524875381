import loadable from "@loadable/component"
import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

const LoginPage = loadable(() => import("../pages/auth/Login"))

export const UnauthorizedRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<LoginPage />} />
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	)
}
