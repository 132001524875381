/* eslint-disable react/react-in-jsx-scope */

import { differenceInDays, startOfDay, subDays, subMonths } from "date-fns"
import { useEffect, useMemo, useState } from "react"
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis } from "recharts"
import colors from "tailwindcss/colors"
import { SchoolWiseAttendanceStatsQueryVariables, useMeQuery } from "../../../../graphql"
import { useAttendanceStatsContext, useBreakpoint } from "../../../../hooks"
import { roundDecimalNumber } from "../../../../utils"
import { AspectRatio, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, ErrorLabel, VStack } from "../../../ui"

const sinceDate = startOfDay(subMonths(new Date(), 1))
const untilDate = startOfDay(new Date())

export const MonthlyAttendanceStatsChart: React.FC = () => {
	const [{ data: meData }] = useMeQuery()

	const [filter, setFilter] = useState<Required<SchoolWiseAttendanceStatsQueryVariables["filter"]>>({ sinceDate, untilDate, schoolIds: [] })

	useEffect(() => {
		if (meData?.me?.school?._id) {
			setFilter((prev) => ({ ...prev, schoolIds: [meData.me?.school?._id || ""] }))
		}
	}, [meData])

	const [{ queries }, queryNew] = useAttendanceStatsContext()

	const currentQuery = useMemo(() => queries.find((q) => q.id === JSON.stringify(filter)), [filter, queries])

	useEffect(() => {
		if (filter.schoolIds?.length === 1) {
			queryNew({ filter })
		}
	}, [filter])

	const [hiddenDataKeys, setHiddenDataKeys] = useState<string[]>([])

	const toggleDataKeys = (dataKey: string) => {
		const hidden = [...hiddenDataKeys]
		if (hidden.includes(dataKey)) {
			hidden.splice(hidden.indexOf(dataKey), 1)
			setHiddenDataKeys(hidden)
		} else {
			setHiddenDataKeys([...hidden, dataKey])
		}
	}

	const data = useMemo(() => {
		const _data: Record<string, string | number>[] = []

		for (let i = differenceInDays(untilDate, sinceDate); i > 0; i--) {
			const date = subDays(untilDate, i - 1).toLocaleString("en-US", { month: "short", day: "numeric" })

			const o: Record<string, any> = { name: date, Attendance: 0 }

			currentQuery?.data?.schoolWisePartialAttendanceStats.forEach(({ school, stats }) => {
				if (!hiddenDataKeys.includes(school.name.split(" ")[0])) {
					const total = stats
						.flat(1)
						.filter((s) => new Date(s.date).toLocaleString("en-US", { month: "short", day: "numeric" }) === date)
						.map((o) => o.meal.totalStudentsCount)
						.reduce((a, b) => a + b, 0)

					const actual = stats
						.flat(1)
						.filter((s) => new Date(s.date).toLocaleString("en-US", { month: "short", day: "numeric" }) === date)
						.map((o) => o.meal.presentStudentsCount)
						.reduce((a, b) => a + b, 0)

					o[school.name.split(" ")[0]] = roundDecimalNumber((actual / total) * 100)
				}
			})

			_data.push(o)
		}

		return _data
	}, [currentQuery, hiddenDataKeys])

	const { isXl } = useBreakpoint("xl")

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Monthly Attendance</CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="h-full gap-4 select-none items-stretch">
					<AspectRatio ratio={isXl ? 20 / 9 : 4 / 3}>
						<ResponsiveContainer width="100%" height="100%">
							{currentQuery?.waiting ? (
								<ChartSkeleton />
							) : !data?.length ? (
								<div className="w-full h-full flex items-center justify-center">
									<ErrorLabel>No data</ErrorLabel>
								</div>
							) : (
								<AreaChart data={data} margin={{ top: 24, right: 40, left: 40, bottom: 0 }}>
									<XAxis dataKey="name" />

									<Tooltip formatter={(v) => <span>{v || 0}%</span>} />
									<Legend
										cursor="pointer"
										onClick={({ dataKey }) => toggleDataKeys(dataKey)}
										formatter={(value) => {
											if (hiddenDataKeys.includes(value)) return <span className="line-through cursor-pointer">{value}</span>

											return <span className="cursor-pointer">{value}</span>
										}}
									/>

									<defs>
										<linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
											<stop offset="5%" stopColor={colors.purple[400]} stopOpacity={0.8} />
											<stop offset="95%" stopColor={colors.purple[400]} stopOpacity={0.2} />
										</linearGradient>
									</defs>

									<Area type="monotone" dataKey={meData?.me?.school?.name.split(" ")[0] || ""} stroke="none" fillOpacity={1} fill="url(#color)" label={<CustomLabel />} />
								</AreaChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}

const CustomLabel = ({ x, y, stroke, value }: any) => {
	if (!value) return <></>

	return (
		<text x={x} y={y} dy={-8} fill={stroke} fontSize={10} textAnchor="middle">
			{value || 0}%
		</text>
	)
}
