export enum ValidTillBannerContextActions {
	SET_VISIBLE,
	SET_STATUS,
	SET_INVISIBLE,
	SET_CHILDREN,
}

export enum ValidTillBannerStatus {
	SUCCESS,
	ERROR,
	WARNING,
	INFO,
}

export type ValidTillBannerContextState = {
	isVisible: boolean
	status: ValidTillBannerStatus
	children?: React.ReactNode
}

export type ValidTillBannerContextAction = { type: ValidTillBannerContextActions; payload?: Partial<ValidTillBannerContextState> }
