/* eslint-disable @typescript-eslint/indent */

import React, { Fragment, useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { InputError, Label, LoadingLabel, VStack, ErrorLabel } from "../../../components/ui"
import { ExtendedSchoolWiseAttendanceSlotFragment, useSchoolWiseAttendanceSlotsOfSchoolQuery } from "../../../graphql"

type SlotFormValues = {
	slot: string
}

type SlotFormProps = {
	schoolId: string
	onOtherSlotChange: (slot: string) => void
}

const schema = z.object({
	slot: z.string().nonempty({ message: "slot is required" }),
})

const SlotForm: React.FC<SlotFormProps> = ({ schoolId, onOtherSlotChange }) => {
	const [selectedSlot, setSelectedSlot] = useState<string>("")

	const [{ data, fetching, error }] = useSchoolWiseAttendanceSlotsOfSchoolQuery({
		variables: {
			schoolId,
		},
	})

	const { handleSubmit } = useForm<SlotFormValues>({ resolver: zodResolver(schema) as any })

	const onSubmit: SubmitHandler<SlotFormValues> = async (values) => {
		console.log("submit...")
		console.log("values", values)
	}

	return (
		<>
			{fetching ? (
				<VStack className="w-full max-w-xl items-stretch gap-2">
					<LoadingLabel />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.schoolWiseAttendanceSlotsOfSchool && data.schoolWiseAttendanceSlotsOfSchool.length ? (
				<form onSubmit={handleSubmit(onSubmit)} className=" flex flex-col gap-4 lg:w-96 ">
					<div className="flex flex-col gap-4">
						<Label htmlFor="slot" className="text-lg font-medium text-gray-800">
							Select the slot from which you want to copy the Beneficiaries Details
						</Label>
						<select
							className="px-4 py-2 outline-none  hover:cursor-pointer "
							onChange={(e) => {
								onOtherSlotChange(e.target.value)
								setSelectedSlot(e.target.value)
							}}
						>
							<option value="" disabled selected>
								Select slot
							</option>
							{data.schoolWiseAttendanceSlotsOfSchool
								.filter((slot) => !slot.isDeleted)
								.map((slot: ExtendedSchoolWiseAttendanceSlotFragment) => (
									<Fragment key={slot._id}>{<option value={slot._id}>{slot.attendanceSlot.name}</option>}</Fragment>
								))}
						</select>
						{selectedSlot === "" && <InputError>Please select a slot</InputError>}
					</div>
				</form>
			) : (
				<ErrorLabel>Couldn&apos;t find any slot.</ErrorLabel>
			)}
		</>
	)
}

export default SlotForm
