import React, { useState } from "react"
import { Holiday, useAllHolidaysOfSchoolWithoutPaginationQuery, useDeleteHolidayMutation, useEditHolidayMutation, useMeQuery, useSchoolWiseAttendanceSlotsOfSchoolQuery } from "../../graphql"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { Button, ErrorLabel, Input, InputError, LoadingLabel, useToast, Label, Dialog, DialogContent, DialogHeader } from "../../components"

import { format, parseISO, endOfDay } from "date-fns"
import { Cross2Icon } from "@radix-ui/react-icons"

export type EditHolidayFormProps = {
	holiday: Holiday
	setEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
	editDialogOpen: boolean
}

type EditHolidayFormValues = {
	name: string
	slotIds: string[]
	startDate: string
	endDate: string
}

const schema = z.object({
	name: z.string().nonempty({ message: "Name is required" }),
	slotIds: z.array(z.string()).default([]),
	startDate: z.string(),
	endDate: z.string(),
})

export const EditHolidayForm: React.FC<EditHolidayFormProps> = ({ holiday, editDialogOpen, setEditDialogOpen }) => {
	// state for the delete conform  message
	const [conformDelete, setConformDelete] = useState<boolean>(false)
	const [selectedSlotIds, setSelectedSlotIds] = useState<string[]>([...holiday.slotIds])

	const [{ data: meData }] = useMeQuery()
	const [{ data: allHolidays }] = useAllHolidaysOfSchoolWithoutPaginationQuery()

	const [{ data: schoolWiseSlots, error: schoolWiseSlotsError, fetching: schoolWiseSlotsFetching }] = useSchoolWiseAttendanceSlotsOfSchoolQuery({
		variables: { schoolId: meData?.me?.school?._id || "" },
		pause: !meData?.me?.school?._id,
	})

	const [{ fetching }, editHoliday] = useEditHolidayMutation()
	const [{ fetching: deleteFetching }, deleteHoliday] = useDeleteHolidayMutation()

	const { toast } = useToast()

	const {
		register,
		handleSubmit,
		setValue,
		formState: { isLoading, errors, isSubmitting },
	} = useForm<EditHolidayFormValues>({
		resolver: zodResolver(schema) as any,
		defaultValues: { slotIds: holiday.slotIds, startDate: format(new Date(holiday.startDate), "yyyy-MM-dd"), endDate: format(new Date(holiday.endDate), "yyyy-MM-dd"), name: holiday.name },
	})

	const handleSlotChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const slotId = event.target.value
		const isChecked = event.target.checked
		// if the parent meal slot is selected
		if (isChecked) {
			// get the child slot of the parent slot
			const childSlotIds = schoolWiseSlots?.schoolWiseAttendanceSlotsOfSchool
				.filter((slot) => String(slot._id) === slotId)
				.map((slot) => slot.childSlotIds)
				.flat()

			// Enable child slots based on the parent slot's checked state
			if (childSlotIds && childSlotIds.length) {
				childSlotIds.forEach((childSlotId) => {
					const childSlotCheckbox = document.getElementById(childSlotId!) as HTMLInputElement
					if (childSlotCheckbox) {
						childSlotCheckbox.checked = isChecked
					}
				})
			}

			// Update slotIds in the form
			setSelectedSlotIds((prev) => [...prev, ...(childSlotIds as string[]), slotId])
			setValue("slotIds", [...selectedSlotIds])
			// setValue("slotIds", [...(getValues("slotIds") as string[]), ...(childSlotIds as string[]), slotId])
		} else {
			// Remove the parent slot and all its child slots from slotIds
			// Filter the selectedSlotIds array
			const updatedSelectedSlotIds = selectedSlotIds.filter(
				(id: string) =>
					id !== slotId &&
					!schoolWiseSlots?.schoolWiseAttendanceSlotsOfSchool
						.filter((slot) => String(slot._id) === slotId)
						.map((slot) => slot.childSlotIds)
						.flat()
						.includes(id)
			)

			// Update the selectedSlotIds state with the filtered array
			setSelectedSlotIds(updatedSelectedSlotIds)
			setValue("slotIds", [...updatedSelectedSlotIds])

			// get the child slot of the parent slot
			const childSlotIds = schoolWiseSlots?.schoolWiseAttendanceSlotsOfSchool
				.filter((slot) => String(slot._id) === slotId)
				.map((slot) => slot.childSlotIds)
				.flat()

			// disable child slots based on the parent slot's unchecked state
			if (childSlotIds && childSlotIds.length) {
				childSlotIds.forEach((childSlotId) => {
					const childSlotCheckbox = document.getElementById(childSlotId!) as HTMLInputElement
					if (childSlotCheckbox) {
						childSlotCheckbox.checked = false
					}
				})
			}
		}
	}

	const onSubmit: SubmitHandler<EditHolidayFormValues> = async (values) => {
		// check for the invalid date
		if (new Date(values.endDate) < new Date(values.startDate)) {
			toast({
				variant: "destructive",
				title: "Invalid date input",
				description: "End date should be greater than start date",
			})
			// close the modal
			setEditDialogOpen(false)
			return
		}

		// check if the edit holiday is in future or not.Allow editing for future holiday only
		if (new Date(holiday.startDate) <= new Date()) {
			toast({
				variant: "destructive",
				title: "Editing past holidays is not allowed",
			})
			// close the modal
			setEditDialogOpen(false)
			return
		}

		// past value is not allow for the edit holiday
		if (new Date(values.startDate) <= new Date()) {
			toast({
				variant: "destructive",
				title: "Invalid Date",
				description: "Please select a future date",
			})
			// close the modal
			setEditDialogOpen(false)
			return
		}

		const startDate = values.startDate
		const endDate = values.endDate

		// Parse the IST date string to a Date object
		const parsedStartDate = parseISO(startDate)
		const parsedEndDate = endOfDay(parseISO(endDate))

		// Convert the parsed date to its equivalent UTC Date object
		const utcStartDate = new Date(parsedStartDate.toUTCString())
		const utcEndDate = new Date(parsedEndDate.toUTCString())

		//  Check if the selected start date overlaps with existing holidays
		const isStartDateOverlapping = allHolidays?.allHolidaysOfSchoolWithoutPagination.some((holi) => {
			const holidayStartDate = new Date(holi.startDate)
			const holidayEndDate = new Date(holi.endDate)
			if (holi._id !== holiday._id) return utcStartDate >= holidayStartDate && utcStartDate <= holidayEndDate
		})

		if (isStartDateOverlapping) {
			toast({
				variant: "destructive",
				title: "Holiday Editing Failed",
				description: "The selected start date overlaps with an existing holiday.",
			})

			// close the modal
			setEditDialogOpen(false)
			return
		}

		// // Check if the selected end date overlaps with existing holidays
		const isEndDateOverlapping = allHolidays?.allHolidaysOfSchoolWithoutPagination.some((holi) => {
			const holidayStartDate = new Date(holi.startDate)
			const holidayEndDate = new Date(holi.endDate)

			if (holi._id !== holiday._id) {
				// Check if the end date overlaps with an existing holiday
				return (
					(utcEndDate >= holidayStartDate && utcEndDate <= holidayEndDate) ||
					// Check if the start date overlaps with an existing holiday
					(utcStartDate >= holidayStartDate && utcStartDate <= holidayEndDate) ||
					// Check if the new holiday completely overlaps with an existing holiday
					(utcStartDate <= holidayStartDate && utcEndDate >= holidayEndDate)
				)
			}
		})

		if (isEndDateOverlapping) {
			toast({
				variant: "destructive",
				title: "Holiday Editing Failed",
				description: "The selected date overlaps with an existing holiday.",
			})
			// close the modal
			setEditDialogOpen(false)
			return
		}

		const { data: editData, error } = await editHoliday({
			holidayId: holiday._id,
			input: {
				...values,
				slotIds: [...new Set(selectedSlotIds)],
				startDate: utcStartDate,
				endDate: utcEndDate,
			},
		})

		if (error) {
			toast({
				variant: "destructive",
				title: "Holiday Editing Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})

			return
		}

		if (editData?.editHoliday) {
			toast({
				variant: "success",
				title: "Holiday Edited Successfully",
				description: "",
			})
			setEditDialogOpen((prev) => !prev)

			return
		}
	}

	// handler to delete the holiday
	const deleteHolidayHandler = async () => {
		// check if the deleted holiday is in future or not.Allow deleting for future holiday only
		if (new Date(holiday.startDate) <= new Date()) {
			toast({
				variant: "destructive",
				title: "Deleting past holiday is not allowed",
			})
			// close the edit modal
			setEditDialogOpen(false)
			return
		}

		const { data, error } = await deleteHoliday({
			holidayId: holiday._id,
		})

		if (error) {
			toast({
				variant: "destructive",
				title: "Holiday Deleting Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})

			return
		}

		if (data?.deleteHoliday) {
			toast({
				variant: "success",
				title: "Holiday Deleted Successfully",
				description: "",
			})

			// close the dialog on delete
			setEditDialogOpen(false)

			return
		}
	}

	return (
		<>
			<Dialog open={editDialogOpen} onOpenChange={() => setEditDialogOpen(false)}>
				<DialogContent>
					<DialogHeader className="text-md font-semibold">Edit Holiday</DialogHeader>
					<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-4">
						<div>
							<div className="flex flex-col gap-2">
								<Label className="text-sm font-medium text-gray-500">Name</Label>
								<Input type="text" placeholder="Enter name of Holiday" className="max-w-md bg-white rounded-xl py-1" {...register("name")} />
							</div>
							<InputError>{errors.name?.message}</InputError>
						</div>
						<div>
							<div className="flex flex-col gap-2">
								<Label className="text-sm font-medium text-gray-500">Start Date</Label>

								<Input type="date" className="max-w-md bg-white rounded-xl py-1" {...register("startDate")} />
							</div>
							<InputError>{errors.startDate?.message}</InputError>
						</div>
						<div>
							<div className="flex flex-col gap-2">
								<Label className="text-sm font-medium text-gray-500">End Date</Label>
								<Input type="date" className="max-w-md bg-white rounded-xl py-1" {...register("endDate")} />
							</div>
							<InputError>{errors.endDate?.message}</InputError>
						</div>

						{schoolWiseSlotsFetching ? (
							<LoadingLabel className="flex-1">Loading NGO partners</LoadingLabel>
						) : schoolWiseSlotsError ? (
							<ErrorLabel className="flex-1">{schoolWiseSlotsError.message.replace("[GraphQL] ", "")}</ErrorLabel>
						) : !schoolWiseSlots?.schoolWiseAttendanceSlotsOfSchool.length ? (
							<ErrorLabel className="flex-1">Couldn&apos;t fetch NGO meal slots</ErrorLabel>
						) : (
							<>
								<div>
									<div className="flex flex-col gap-2">
										<Label className="text-sm font-medium text-gray-500">Select Slots</Label>
										<div className="flex gap-x-4 flex-wrap  justify-start align-middle">
											{schoolWiseSlots.schoolWiseAttendanceSlotsOfSchool
												.filter((s) => !s.isDeleted)
												.map((slot) => (
													<div key={slot._id}>
														<input
															type="checkbox"
															className={`${slot.parentSlotId ? "hover:cursor-not-allowed" : "hover:cursor-pointer"}`}
															disabled={slot.parentSlotId ? true : false}
															id={slot._id}
															onChange={handleSlotChange}
															value={slot._id}
															defaultChecked={holiday.slotIds.includes(slot._id)}
														/>
														<Label htmlFor={slot._id} className={`ml-2 ${slot.parentSlotId ? "hover:cursor-not-allowed" : "hover:cursor-pointer"}`}>
															{slot.attendanceSlot.name}
														</Label>
													</div>
												))}
										</div>
									</div>
									<InputError>{errors.slotIds?.message}</InputError>
								</div>
							</>
						)}
						<div className="flex justify-end gap-x-4 w-full">
							<Button onClick={() => setConformDelete(true)} type="button" className="bg-red-500 w-32 hover:bg-red-700 hover:opacity-50 hover:cursor-pointer">
								Delete
							</Button>

							<Button type="submit" isLoading={isLoading || isSubmitting || fetching} className="bg-green-600 hover:cursor-pointer hover:opacity-80  w-32">
								Save
							</Button>
						</div>
					</form>

					<div className="absolute right-4 top-4 z-50 hover:cursor-pointer ">
						<Cross2Icon onClick={() => setEditDialogOpen(false)} />
					</div>
				</DialogContent>
			</Dialog>

			<Dialog open={conformDelete} onOpenChange={() => setConformDelete(false)}>
				<DialogHeader>Delete Holiday</DialogHeader>
				<DialogContent>
					<h2 className="text-sm font-medium text-gray-500">Are you sure you want to delete holiday {holiday.name || ""}?</h2>
					<div className="mt-4 w-full flex justify-end gap-2">
						<Button
							type="button"
							className=" hover:cursor-pointer hover:opacity-80 w-32"
							onClick={() => {
								setConformDelete(false)
							}}
						>
							Cancel
						</Button>
						<Button type="button" isLoading={deleteFetching} onClick={() => deleteHolidayHandler()} className="bg-red-500  hover:cursor-pointer hover:opacity-80 hover:bg-red-600 w-32">
							Delete
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}
