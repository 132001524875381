/* eslint-disable @typescript-eslint/indent */

import React, { useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useToast, InputError, Button, Label, Input } from "../../components/ui"
import { useNavigate } from "react-router-dom"
import { useCreateClassMutation } from "../../graphql"

type AddNewClassFormValues = {
	name: string
}

const schema = z.object({
	name: z.string().nonempty({ message: "name is required" }),
})

const AddNewClassForm: React.FC = () => {
	const [, createClass] = useCreateClassMutation()

	const [error, setError] = useState<string | null>(null)

	const { toast } = useToast()

	const navigate = useNavigate()

	const {
		register,
		handleSubmit,
		formState: { isLoading, errors, isSubmitting },
	} = useForm<AddNewClassFormValues>({ resolver: zodResolver(schema) as any })

	const onSubmit: SubmitHandler<AddNewClassFormValues> = async (values) => {
		setError(null) // Clear any previous errors

		const { data, error } = await createClass({
			input: { ...values },
		})

		if (error) {
			toast({
				variant: "destructive",
				title: "Class Creation Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})
			setError(error.message.replace("[GraphQL] ", ""))
		}

		if (data?.createClass) {
			toast({
				variant: "success",
				title: "Class Created Successfully",
				description: "",
			})
			navigate(`/classes/${data.createClass._id}`)
			return
		}
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className=" flex flex-col gap-4 lg:w-96 ">
				<div className="flex flex-col gap-2">
					<Label htmlFor="name">Name</Label>
					<Input placeholder="class name" type="text" {...register("name")} onChange={() => setError(null)} />
					<InputError>{errors.name?.message}</InputError>
					{error && <div className="text-red-500 text-sm mb-2">{error}</div>}
				</div>

				<Button isLoading={isLoading || isSubmitting} className="mt-6">
					Create Class
				</Button>
			</form>
		</>
	)
}

export default AddNewClassForm
