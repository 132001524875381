import React from "react"
import { UpdateWorkingWeekdaysOfSchoolForm } from "../../../forms/holiday/UpdateWorkingWeeksOfSchoolForm"
import { useSchoolByIdQuery } from "../../../graphql"

import { ErrorLabel, LoadingLabel, VStack } from "../../ui"

type UpdateWorkingWeekdaysOfSchoolProps = {
	schoolId: string
}

export const UpdateWorkingWeekdaysOfSchool: React.FC<UpdateWorkingWeekdaysOfSchoolProps> = ({ schoolId }) => {
	const [{ data, fetching, error }] = useSchoolByIdQuery({ variables: { schoolId }, pause: !schoolId })

	return (
		<>
			<VStack className="max-w-xl items-stretch">
				{fetching ? (
					<VStack className="w-full max-w-xl items-stretch gap-2">
						<LoadingLabel />
					</VStack>
				) : error ? (
					<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
				) : data?.schoolById ? (
					<UpdateWorkingWeekdaysOfSchoolForm school={data.schoolById} />
				) : (
					<ErrorLabel>Couldn&apos;t find any school.</ErrorLabel>
				)}
			</VStack>
		</>
	)
}

export default UpdateWorkingWeekdaysOfSchool
