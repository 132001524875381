import React, { useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Badge, Card, CardContent, CardHeader, CardTitle, HStack, Separator, VStack } from "../.."
import { PartialStudentFragment, SchoolWiseAttendanceStatsResponse } from "../../../graphql"

type AttendanceOverviewNGOPartnerListItemProps = {
	data: SchoolWiseAttendanceStatsResponse
}

export const AttendanceOverviewNGOPartnerListItem: React.FC<AttendanceOverviewNGOPartnerListItemProps> = ({ data }) => {
	const { criticalBeneficiaries, atRiskBeneficiaries, wellBeneficiaries } = useMemo(() => {
		const criticalBeneficiaries: { beneficiary: PartialStudentFragment; percent: number }[] = []
		const atRiskBeneficiaries: { beneficiary: PartialStudentFragment; percent: number }[] = []
		const wellBeneficiaries: { beneficiary: PartialStudentFragment; percent: number }[] = []

		const beneficiaries: { beneficiary: PartialStudentFragment; total: number; present: number; percent: number }[] = []

		data.stats.forEach((dayStat) => {
			dayStat.totalStudents.forEach((beneficiary) => {
				const isPresent = !!dayStat.presentStudents.find((s) => s._id === beneficiary._id)

				const index = beneficiaries.findIndex((s) => s.beneficiary._id === beneficiary._id)

				if (index >= 0) {
					beneficiaries[index].total++
					beneficiaries[index].present = beneficiaries[index].present + (isPresent ? 1 : 0)
					beneficiaries[index].percent = (beneficiaries[index].present / beneficiaries[index].total) * 100
				} else {
					beneficiaries.push({
						beneficiary: beneficiary,
						total: 1,
						present: isPresent ? 1 : 0,
						percent: isPresent ? 100 : 0,
					})
				}
			})
		})

		beneficiaries.map(({ percent, beneficiary }) => {
			if (percent >= 80) {
				wellBeneficiaries.push({ percent, beneficiary })
			} else if (percent >= 50) {
				atRiskBeneficiaries.push({ percent, beneficiary })
			} else {
				criticalBeneficiaries.push({ percent, beneficiary })
			}
		})

		return { criticalBeneficiaries, atRiskBeneficiaries, wellBeneficiaries }
	}, [data])

	const navigate = useNavigate()

	const { state } = useLocation()

	return (
		<Card tabIndex={0} className="group hover:shadow-purple-500 cursor-pointer" onClick={() => navigate(`/attendance/overview/${data.school._id}`, { state })}>
			<CardHeader>
				<HStack className="w-full justify-between">
					<CardTitle className="text-xs lg:text-sm font-medium group-hover:font-bold text-slate-500 group-hover:text-purple-500">{data.school.name}</CardTitle>
					<Badge variant="secondary">{data.stats.length} slots</Badge>
				</HStack>
			</CardHeader>
			<CardContent>
				<div className="w-full flex">
					<VStack className="items-start gap-0">
						<span className="text-sm text-slate-400">Beneficiaries</span>
						<span className="text-xl lg:text-2xl font-bold text-slate-500">{criticalBeneficiaries.length + atRiskBeneficiaries.length + wellBeneficiaries.length}</span>
					</VStack>

					<Separator orientation="vertical" />

					<VStack className="items-start gap-0">
						<span className="text-sm text-slate-400">Critical</span>
						<span className="text-xl lg:text-2xl font-bold text-red-500">{criticalBeneficiaries.length}</span>
					</VStack>

					<Separator orientation="vertical" />

					<VStack className="items-start gap-0">
						<span className="text-sm text-slate-400">At Risk</span>
						<span className="text-xl lg:text-2xl font-bold text-orange-500">{atRiskBeneficiaries.length}</span>
					</VStack>

					<Separator orientation="vertical" />

					<VStack className="items-start gap-0">
						<span className="text-sm text-slate-400">Well</span>
						<span className="text-xl lg:text-2xl font-bold text-green-500">{wellBeneficiaries.length}</span>
					</VStack>
				</div>
			</CardContent>
		</Card>
	)
}
