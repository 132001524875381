import loadable from "@loadable/component"
import React from "react"
import { useMeQuery } from "./graphql"
import { useAttendanceStatsContext } from "./hooks"
import { AdminRouter } from "./router/AdminRoot.router"
import { NGOPartnerRootRouter } from "./router/NGOPartnerRoot.router"
import { UnauthorizedRouter } from "./router/Unauthorized.router"
import { CheckValidTillDate, Toaster } from "./components"
import { ValidTillBannerContextProvider } from "./context"

const SplashPage = loadable(() => import("./pages/common/Splash"))

export const App: React.FC = () => {
	const [{ data, fetching }] = useMeQuery()

	useAttendanceStatsContext(true)

	if (fetching) {
		return <SplashPage />
	}

	return (
		<ValidTillBannerContextProvider>
			<CheckValidTillDate />
			{data?.me?.admin ? <AdminRouter /> : data?.me?.school ? <NGOPartnerRootRouter /> : <UnauthorizedRouter />}
			<Toaster />
		</ValidTillBannerContextProvider>
	)
}
