import React, { useMemo } from "react"
import { format } from "date-fns"
import { Button } from "../../ui"

import { useNavigate } from "react-router-dom"
import { useMeQuery } from "../../../graphql"

export type ClassProps = {
	classInfo: any
}

const ClassDetail: React.FC<ClassProps> = ({ classInfo }) => {
	const navigate = useNavigate()
	const [meQuery] = useMeQuery()

	const canCreateClassSection: boolean = useMemo(() => {
		// if the login user is admin then it create the class section
		if (meQuery && meQuery.data && meQuery.data.me && meQuery.data.me.admin) return true

		return false
	}, [meQuery])

	return (
		<div className="container mt-10">
			<h1 className="text-lg text-gray-700 font-bold">Class {classInfo.name}</h1>
			<p className="font-medium text-sm text-gray-500">Created {format(new Date(classInfo.createdAt), "PPPp")}</p>

			<div className="flex gap-3 mt-6 items-center ">
				<h2 className=" text-lg font-medium text-gray-600 ">Sections</h2>
				{canCreateClassSection && (
					<Button className="w-4 h-4 bg-blue-600 hover:cursor-pointer px-3 py-3" onClick={() => navigate(`/classes/${classInfo._id}/addNewSection`)}>
						+
					</Button>
				)}
			</div>
			<div className="flex flex-wrap gap-6 mt-2">
				{classInfo.sections.length > 0 &&
					classInfo.sections.map((section: any) => {
						return (
							<div className="w-36 h-36 px-4 py-2 rounded-lg border-2 border-gray-300 " key={section._id}>
								<p className="text-sm font-medium text-gray-600">Section-{section.name}</p>
								<p className="font-medium text-xs text-gray-400">Created {format(new Date(section.createdAt), "PPPp")}</p>
							</div>
						)
					})}

				{classInfo.sections.length === 0 && <p className="text-xs font-medium text-center text-red-400">Not Created yet</p>}
			</div>
		</div>
	)
}

export default ClassDetail
