import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"

import { VStack } from "../../ui"
import { format } from "date-fns"
import { useNavigate } from "react-router-dom"

export type MealListProps = {
	meals: any[]
	loadMore: () => void
}

export const MealList: React.FC<MealListProps> = ({ meals, loadMore }) => {
	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	const navigate = useNavigate()

	return (
		<VStack>
			<div className="container mx-auto rounded-lg  flex flex-col gap-4">
				{meals.map((meal) => {
					return (
						<div key={meal._id} onClick={() => navigate(`/meals/${meal._id}`)}>
							<div className="w-80 px-6 py-4 flex gap-4 shadow-lg hover:cursor-pointer hover:bg-blue-100  flex-wrap">
								<div className="flex flex-col gap-2">
									<p className="text-sm font-medium text-gray-800">{meal.name}</p>
									<p className="text-sm font-normal text-gray-400">Name</p>
								</div>

								<div className="flex flex-col gap-2">
									<p className="text-sm font-medium text-gray-800">{format(new Date(meal.createdAt), "PPp")}</p>
									<p className="text-sm font-normal text-gray-400">CreateAt</p>
								</div>

								<div className="flex flex-col gap-2">
									<p className="text-sm font-medium text-gray-800">{format(new Date(meal.updatedAt), "PPp")}</p>
									<p className="text-sm font-normal text-gray-400">UpdateAt</p>
								</div>
							</div>
						</div>
					)
				})}
			</div>
			<div ref={bottomRef as any} className="w-full h-4 pb-4" />
		</VStack>
	)
}
