import React, { Fragment } from "react"
import { useParams } from "react-router-dom"
import { useStudentByIdQuery } from "../../../graphql"
import { BeneficiaryTag } from "../../../components"
import { LoadingLabel } from "../../../components/ui/loading-label"

import UploadBMIOfStudent from "../../../components/ngo-partners/beneficiary/UploadBMIOfStudent"

const UploadBMIOfStudentPage: React.FC = () => {
	const { beneficiaryId = "" } = useParams<{ beneficiaryId: string }>()

	const [{ data, fetching, error }] = useStudentByIdQuery({ variables: { studentId: beneficiaryId }, pause: !beneficiaryId })

	return (
		<Fragment>
			<div className="container mx-auto py-4 px-8">
				<h1 className="text-lg font-bold text-gray-900 mt-8 mb-6">Upload BMI of Student</h1>

				{fetching ? (
					<div className="mx-auto w-44">
						<div className=" flex gap-1 text-gray-500 ">
							<h2 className="text-sm">Loading student</h2>
							<LoadingLabel />
						</div>
					</div>
				) : error ? (
					<div className="p-8 mx-auto w-fit">
						<h2 className="text-sm text-red-600">{error.message.replace("[GraphQL] ", "")}</h2>
					</div>
				) : data?.studentById ? (
					<>
						<BeneficiaryTag beneficiary={data?.studentById} />
						<UploadBMIOfStudent beneficiary={data.studentById} />
					</>
				) : (
					<div className="p-8 mx-auto w-fit">
						<h2 className="text-sm text-red-600">Couldn&apos;t find the student.</h2>
					</div>
				)}
			</div>
		</Fragment>
	)
}

export default UploadBMIOfStudentPage
