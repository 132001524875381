/* eslint-disable @typescript-eslint/indent */

import React, { useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useToast, InputError, Button, Label, LoadingLabel } from "../../components/ui"
import { useNavigate } from "react-router-dom"
import { ExtendedStudentFragment, useEditClassOfStudentMutation, useAllClassesOfSchoolQuery } from "../../graphql"

type EditBeneficiaryClassFormValues = {
	classId: string
	classSectionId: string | null
}

const schema = z.object({
	classId: z.string().nonempty({ message: "class is required" }),
	classSectionId: z.string().optional(),
})

export type EditBeneficiaryClassFormProps = {
	beneficiary: ExtendedStudentFragment
}

const EditBeneficiaryClassForm: React.FC<EditBeneficiaryClassFormProps> = ({ beneficiary }) => {
	const initialValues: EditBeneficiaryClassFormValues = {
		classId: beneficiary.class?._id || "",
		classSectionId: beneficiary.classSection?._id || "",
	}

	const [selectedClassId, setSelectedClassId] = useState<string | undefined>(initialValues.classId)

	const [selectedClassSectionId, setSelectedClassSectionId] = useState<string | null>(initialValues.classSectionId)

	const onClassIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const newClassId = e.target.value
		setSelectedClassId(newClassId)

		const selectedClass = classData?.allClassesOfSchool.find((_class) => _class._id === newClassId)
		if (selectedClass && selectedClass.sections.length > 0) {
			// If the selected class has sections, set the section ID to the first section.
			setSelectedClassSectionId(selectedClass.sections[0]._id)
		} else {
			// If the selected class has no sections, set the section ID to null.
			setSelectedClassSectionId(null)
		}
	}

	const onClassSectionIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedClassSectionId(e.target.value)
	}

	const [{ fetching }, editStudentClass] = useEditClassOfStudentMutation()

	const { toast } = useToast()
	const navigate = useNavigate()

	const {
		register,
		handleSubmit,
		formState: { isLoading, errors, isSubmitting },
	} = useForm<EditBeneficiaryClassFormValues>({ resolver: zodResolver(schema) as any, defaultValues: initialValues })

	const onSubmit: SubmitHandler<EditBeneficiaryClassFormValues> = async (values) => {
		const { data, error } = await editStudentClass({
			studentId: beneficiary._id,
			input: { ...values, classId: selectedClassId || initialValues.classId, classSectionId: selectedClassSectionId || null },
		})

		if (error) {
			toast({
				variant: "destructive",
				title: "Student class updation Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})

			return
		}

		if (data?.editClassOfStudent) {
			toast({
				variant: "success",
				title: "Student class updated Successfully",
				description: "",
			})
			navigate(`/beneficiaries/${data.editClassOfStudent._id}`, { replace: true })

			return
		}
	}

	const [{ data: classData, fetching: classFetching, error: classError }] = useAllClassesOfSchoolQuery()

	return (
		<>
			{classFetching ? (
				<div className="container mx-auto">
					<h1 className="text-sm font-bold">Loading Classes</h1>
					<LoadingLabel />
				</div>
			) : classError ? (
				<div className="container mx-auto">
					<p className="text-sm text-red-500 font-bold">{classError.message.replace("[GraphQL] ", "")}</p>
				</div>
			) : (
				<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
					<div className="flex gap-1 flex-wrap">
						<div className="flex gap-1 flex-col">
							<Label htmlFor="ClassId" className="text-sm font-semibold text-gray-700">
								Class
							</Label>

							<select {...register("classId")} className="px-4 py-2 outline-none hover:cursor-pointer " onChange={onClassIdChange}>
								{classData?.allClassesOfSchool.map(({ _id, name }) => (
									<option key={_id} value={_id}>
										{name}
									</option>
								))}
							</select>

							<InputError>{errors.classId?.message}</InputError>
						</div>

						<div className="flex gap-1 flex-col">
							<Label htmlFor="ClassSectionId" className="text-sm text-gray-600 font-semibold">
								Class Section
							</Label>

							<select {...register("classSectionId")} className="px-4 py-2  hover:cursor-pointer outline-none" onChange={onClassSectionIdChange}>
								{classData?.allClassesOfSchool?.find((_class) => _class._id === selectedClassId)?.sections.length === 0 && (
									<option value="" disabled selected>
										section not available
									</option>
								)}

								{classData?.allClassesOfSchool?.find((_class) => _class._id === selectedClassId)?.sections.length !== 0 &&
									classData?.allClassesOfSchool
										?.find((_class) => _class._id === selectedClassId)
										?.sections?.map((section) => (
											<option key={section.name} value={section._id}>
												{section.name}
											</option>
										))}
							</select>
							<InputError>{errors.classSectionId?.message}</InputError>
						</div>
					</div>

					<Button isLoading={isLoading || isSubmitting || classFetching || fetching} className="w-full">
						Update
					</Button>
				</form>
			)}
		</>
	)
}

export default EditBeneficiaryClassForm
