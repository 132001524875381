import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useInViewport } from "react-in-viewport"
import Select from "react-select"
import { Card, CardContent, ErrorLabel, HStack, Input, NGOPartnerTag, Skeleton, VStack } from "../.."
import { Models, Regions, SearchSchoolsQueryVariables, useSearchSchoolsQuery } from "../../../graphql"
import { cn } from "../../../lib/utils"
import { Checkbox } from "../../ui/checkbox"

export type NGOPartnersSelectorProps = {
	selectedNGOPartnerIds: string[]
	onChange: (ngoPartnerIds: string[]) => void
}

export const NGOPartnersSelector: React.FC<NGOPartnersSelectorProps> = ({ selectedNGOPartnerIds, onChange }) => {
	const [filter, setFilter] = useState<SearchSchoolsQueryVariables>({ keyword: "", model: undefined, region: undefined, pagination: { limit: 20, page: 1 } })

	const [{ data, fetching, error }] = useSearchSchoolsQuery({
		variables: { ...filter },
	})

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	const nextPage = useCallback(() => {
		if (data?.searchSchools.hasNextPage) {
			setFilter((prev) => ({
				...prev,
				pagination: { ...prev.pagination, page: (prev.pagination.page || 0) + 1 },
			}))
		}
	}, [data?.searchSchools.hasNextPage])

	useEffect(() => {
		if (inViewport && data?.searchSchools.hasNextPage) nextPage()
	}, [inViewport, nextPage])

	const handleNGOPartnerClick = (ngoPartnerId: string) => {
		if (selectedNGOPartnerIds.includes(ngoPartnerId)) {
			return onChange(selectedNGOPartnerIds.filter((id) => id !== ngoPartnerId))
		} else {
			return onChange([...selectedNGOPartnerIds, ngoPartnerId])
		}
	}

	const isSelected = useMemo(() => (ngoPartnerId: string) => selectedNGOPartnerIds.includes(ngoPartnerId), [selectedNGOPartnerIds])
	const areAllSelected = useMemo(
		() => selectedNGOPartnerIds.length === data?.searchSchools.schools?.length && data.searchSchools.schools.map((s) => s._id).every((sid) => selectedNGOPartnerIds.includes(sid)),
		[selectedNGOPartnerIds, data?.searchSchools.schools]
	)

	const selectAllNGOPartners = useCallback(() => onChange(data?.searchSchools.schools?.map((s) => s._id) || []), [data?.searchSchools.schools])
	const deselectAllNGOPartners = useCallback(() => onChange([]), [])

	return (
		<Card className="items-stretch lg:max-h-[60dvh]">
			<CardContent className="p-4 flex flex-col gap-2 lg:gap-4 overflow-y-auto">
				<div className="w-full grid lg:grid-cols-3 gap-2">
					<Input type="search" placeholder="Search..." value={filter.keyword || ""} onChange={(e) => setFilter((prev) => ({ ...prev, keyword: e.target.value }))} />
					<Select
						name="Models"
						placeholder="Select model"
						options={Object.values(Models).map((m) => ({ label: m, value: m }))}
						value={filter.model ? { label: filter.model, value: filter.model } : undefined}
						onChange={(e) => setFilter((prev) => ({ ...prev, model: e?.value }))}
						className="basic-single w-full max-w-36"
						isSearchable
						isClearable
					/>
					<Select
						name="Regions"
						placeholder="Select region"
						options={Object.values(Regions).map((m) => ({ label: m, value: m }))}
						value={filter.region ? { label: filter.region, value: filter.region } : undefined}
						onChange={(e) => setFilter((prev) => ({ ...prev, region: e?.value }))}
						className="basic-single w-full max-w-36"
						isSearchable
						isClearable
					/>
				</div>
				{fetching ? (
					<VStack className="w-full max-w-xl items-stretch gap-2">
						<Skeleton className="w-full max-w-xl h-24" />
						<Skeleton className="w-full max-w-xl h-24" />
						<Skeleton className="w-full max-w-xl h-24" />
						<Skeleton className="w-full max-w-xl h-24" />
					</VStack>
				) : error ? (
					<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
				) : data?.searchSchools.schools && data.searchSchools.schools.length ? (
					<VStack className="items-stretch">
						<label htmlFor="select-all-ngo-partners" className="cursor-pointer">
							<CardContent className="py-2 px-4">
								<HStack className="gap-4">
									<Checkbox id="select-all-ngo-partners" checked={areAllSelected} onCheckedChange={() => (areAllSelected ? deselectAllNGOPartners() : selectAllNGOPartners())} />
									<span className="text-sm text-purple-400">{selectedNGOPartnerIds.length} NGO partners selected</span>
								</HStack>
							</CardContent>
						</label>

						{data.searchSchools.schools.map((ngoPartner) => (
							<Card key={ngoPartner._id} className={cn("cursor-pointer", isSelected(ngoPartner._id) ? "shadow-purple-500 hover:shadow-red-500" : "hover:shadow-purple-500")}>
								<label htmlFor={ngoPartner._id} className="cursor-pointer">
									<CardContent className="py-2 px-4">
										<HStack className="gap-4">
											<Checkbox id={ngoPartner._id} checked={isSelected(ngoPartner._id)} onCheckedChange={() => handleNGOPartnerClick(ngoPartner._id)} />

											<NGOPartnerTag ngoPartner={ngoPartner} disableLink />
										</HStack>
									</CardContent>
								</label>
							</Card>
						))}
						<div ref={bottomRef as any} className="w-full h-4 pb-4" />
					</VStack>
				) : (
					<ErrorLabel>Couldn&apos;t find any NGO partner.</ErrorLabel>
				)}
			</CardContent>
		</Card>
	)
}
