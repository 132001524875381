/* eslint-disable @typescript-eslint/indent */

import React from "react"
import { ErrorLabel, InputError, Label, LoadingLabel, VStack } from "../../../components/ui"
import { ExtendedSchoolWiseAttendanceSlotFragment, useSchoolWiseAttendanceSlotsOfSchoolQuery } from "../../../graphql"

type UpdateSelectSlotFormProps = {
	schoolId: string
	selectedOtherSlotId?: string
	onOtherSlotChange: (slotId: string) => void
	slot: ExtendedSchoolWiseAttendanceSlotFragment
}

const UpdateSelectSlotForm: React.FC<UpdateSelectSlotFormProps> = ({ schoolId, selectedOtherSlotId, onOtherSlotChange, slot }) => {
	const [{ data, fetching, error }] = useSchoolWiseAttendanceSlotsOfSchoolQuery({
		variables: {
			schoolId,
		},
	})

	return (
		<>
			{fetching ? (
				<VStack className="w-full max-w-xl items-stretch gap-2">
					<LoadingLabel />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.schoolWiseAttendanceSlotsOfSchool && data.schoolWiseAttendanceSlotsOfSchool.length > 1 ? (
				<div className="flex flex-col gap-4 lg:w-96">
					<Label htmlFor="slot" className="text-lg font-medium text-gray-800">
						Select the slot from which you want to copy the Beneficiaries Details
					</Label>
					<select
						className="px-4 py-2 outline-none  hover:cursor-pointer "
						onChange={(e) => {
							onOtherSlotChange(e.target.value)
						}}
						value={selectedOtherSlotId}
					>
						<option value="">Select Parent Slot</option>
						{data.schoolWiseAttendanceSlotsOfSchool
							.filter((s) => s._id !== slot._id && !s.isDeleted && s.isTappingRequired && !s.parentSlotId)
							.map((slot: ExtendedSchoolWiseAttendanceSlotFragment) => (
								<option key={slot._id} value={slot._id}>
									{slot.attendanceSlot.name}
								</option>
							))}
					</select>
					{selectedOtherSlotId === "" && <InputError>Please select a slot</InputError>}
				</div>
			) : (
				<ErrorLabel>Couldn&apos;t find any parent slot.</ErrorLabel>
			)}
		</>
	)
}

export default UpdateSelectSlotForm
