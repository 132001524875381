import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "urql"
import { App } from "./App"
import { AttendanceStatsContextProvider } from "./context"
import { createUrqlClient } from "./graphql"
import "./global.css"

export const urqlClient = createUrqlClient()

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<Provider value={urqlClient}>
			<BrowserRouter>
				<AttendanceStatsContextProvider>
					<App />
				</AttendanceStatsContextProvider>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
)
