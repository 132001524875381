import React, { useEffect } from "react"
import { UpdateMealSlot } from "../../../components/admin/meal/UpdateMealSlot"
import { useParams } from "react-router-dom"
import { useSchoolAttendanceSlotByIdQuery } from "../../../graphql"
import { LoadingLabel } from "../../../components"

const UpdateMealSlotPage: React.FC = () => {
	const { slotId = "" } = useParams<{ slotId: string }>()

	const [{ data, fetching, error }] = useSchoolAttendanceSlotByIdQuery({ variables: { slotId }, pause: !slotId })

	useEffect(() => {
		// Disable scrolling when the component mounts
		document.body.classList.add("overflow-hidden")

		// Re-enable scrolling when the component unmounts
		return () => {
			document.body.classList.remove("overflow-hidden")
		}
	}, [])

	return (
		<div className="container">
			{fetching ? (
				<LoadingLabel />
			) : error ? (
				<div className="p-8  w-fit">
					<h2 className="text-sm text-red-600">{error.message.replace("[GraphQL] ", "")}</h2>
				</div>
			) : data?.schoolAttendanceSlotById ? (
				<UpdateMealSlot slot={data.schoolAttendanceSlotById} />
			) : (
				<div className="p-8  w-fit">
					<h2 className="text-sm text-red-600">Couldnt find the meal slot.</h2>
				</div>
			)}
		</div>
	)
}

export default UpdateMealSlotPage
