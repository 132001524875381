import axios from "axios"
import format from "date-fns/format"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Badge, Button, ErrorLabel, Skeleton, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, VStack } from "../../../../components"
import { Genders, ReportStatus, ReportTypes, useReportByIdQuery, useReportUpdateSubscription } from "../../../../graphql"

type Report = { "NGO Partner": string; "NGO Partner Code": string; Region: string; Model: string; Beneficiaries: number; [Genders.Male]: number; [Genders.Female]: number; [Genders.Others]: number }

export const NGOPartnersReportPage: React.FC = () => {
	const { reportId = "" } = useParams<{ reportId: string }>()

	const [{ fetching, data, error }] = useReportByIdQuery({ variables: { reportId }, pause: !reportId })

	useReportUpdateSubscription({ variables: { reportId } })

	const [report, setReport] = useState<{ data?: Report[]; fetching?: boolean; error?: Error }>({})

	useEffect(() => {
		if (!data) {
			setReport({ fetching: true })
		} else if (data?.reportById?.json?.url) {
			setReport({ fetching: true })

			axios
				.get(data.reportById.json.url)
				.then(({ data }: { data: Report[] }) => {
					setReport({ data, fetching: false })
				})
				.catch((err: any) => {
					setReport({ error: err, fetching: false })
				})
		} else {
			setReport({ fetching: false })
		}
	}, [data])

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2 lg:gap-4">
			{fetching ? (
				<VStack className="w-full items-stretch py-2 lg:py-4 gap-2 lg:gap-4">
					<Skeleton className="w-full max-w-sm h-12" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : !data?.reportById || data.reportById.type !== ReportTypes.Schools ? (
				<ErrorLabel>Cound not find the report</ErrorLabel>
			) : (
				<VStack className="w-full items-stretch py-2 lg:py-4 gap-2 lg:gap-4">
					<VStack className="lg:flex-row items-stretch lg:items-center">
						<VStack className="w-full items-start">
							{data.reportById.status === ReportStatus.Queued ? (
								<Badge variant="outline" className="border-yellow-500 text-yellow-500">
									{data.reportById.status}
								</Badge>
							) : data.reportById.status === ReportStatus.Processing ? (
								<Badge className="bg-yellow-500">{data.reportById.status}</Badge>
							) : data.reportById.status === ReportStatus.Failed ? (
								<Badge variant="destructive">{data.reportById.status}</Badge>
							) : (
								<Badge className="bg-green-500">{data.reportById.status}</Badge>
							)}
							<h1 className="text-sm lg:text-sm font-medium group-hover:font-bold text-slate-500">{data.reportById.label.name}</h1>
							<p className="text-xs text-slate-500">Generated on {format(new Date(data.reportById.createdAt), "MMM dd, yyyy")}</p>
						</VStack>
						{data.reportById.status === ReportStatus.Generated && data.reportById.csv?.url && <Button onClick={() => window.open(data.reportById?.csv?.url)}>Download</Button>}
					</VStack>
					{report.fetching ? (
						<VStack className="w-full items-stretch py-2 lg:py-4 gap-2 lg:gap-4">
							<Skeleton className="w-full max-w-sm h-12" />
						</VStack>
					) : report.error ? (
						<ErrorLabel>{report.error.message.replace("[GraphQL] ", "")}</ErrorLabel>
					) : !report?.data ? (
						<ErrorLabel>Cound not find the report</ErrorLabel>
					) : (
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead>NGO Partner</TableHead>
									<TableHead>NGO Partner Code</TableHead>
									<TableHead>Region</TableHead>
									<TableHead>Model</TableHead>
									<TableHead>Beneficiaries</TableHead>
									<TableHead>Male</TableHead>
									<TableHead>Female</TableHead>
									<TableHead>Others</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{report.data.map((r) => (
									<TableRow key={r["NGO Partner Code"]}>
										<TableCell>{r["NGO Partner"]}</TableCell>
										<TableCell>{r["NGO Partner Code"]}</TableCell>
										<TableCell>{r.Region}</TableCell>
										<TableCell>{r.Model}</TableCell>
										<TableCell>{r.Beneficiaries}</TableCell>
										<TableCell>{r.Male}</TableCell>
										<TableCell>{r.Female}</TableCell>
										<TableCell>{r.Others}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					)}
				</VStack>
			)}
		</div>
	)
}

export default NGOPartnersReportPage
