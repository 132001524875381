import React, { useState } from "react"

import { Link, useNavigate } from "react-router-dom"
import { Button, useToast } from "../../ui"
import { ExtendedSchoolWiseAttendanceSlotFragment, useDeleteSchoolWiseAttendanceSlotOfSchoolMutation } from "../../../graphql"

export type DeleteMealSlotProps = {
	slot: ExtendedSchoolWiseAttendanceSlotFragment
}

export const DeleteMealSlot: React.FC<DeleteMealSlotProps> = ({ slot }) => {
	const [{ fetching }, deleteMeal] = useDeleteSchoolWiseAttendanceSlotOfSchoolMutation()
	const [error, setError] = useState<string>("")

	const { toast } = useToast()
	const navigate = useNavigate()

	const handleDelete = async () => {
		const { error, data } = await deleteMeal({ schoolWiseAttendanceSlotId: slot._id })

		if (error) {
			toast({
				variant: "destructive",
				title: "Deleting  Meal Slot  Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})
			setError(error.message.replace("[GraphQL] ", ""))
		}

		if (data?.deleteSchoolWiseAttendanceSlotOfSchool) {
			toast({
				variant: "success",
				title: "Meal Slot Deleted Successfully",
			})
			navigate("/mealSlots")
		} else {
			toast({
				variant: "destructive",
				title: "Deleting  Meal Slot  Failed",
			})
		}
	}

	return (
		<div className="mt-6 w-fit">
			<h2 className="text-sm text-gray-600 font-normal">Are you sure you want to delete ?</h2>
			{error && <p className="text-sm text-red-500 font-medium">{error}</p>}
			<div className="flex gap-6 mt-4 justify-end">
				<Link className="w-fit rounded-lg px-4 py-2 font-medium bg-slate-500 text-white hover:cursor-pointer hover:opacity-70" to={`/mealSlots/${slot._id}`}>
					Cancel
				</Link>
				<Button className="w-fit rounded-lg px-4 py-2 font-medium bg-red-500 text-white hover:cursor-pointer hover:opacity-70" onClick={handleDelete} isLoading={fetching}>
					Delete
				</Button>
			</div>
		</div>
	)
}
