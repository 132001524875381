import { format } from "date-fns"
import React from "react"
import { ErrorLabel, Skeleton, VStack } from "../.."
import { useTodayAttendanceOfStudentQuery } from "../../../graphql"

export type BeneficiaryTodayAttendanceProps = {
	beneficiaryId: string
}

export const BeneficiaryTodayAttendance: React.FC<BeneficiaryTodayAttendanceProps> = ({ beneficiaryId }) => {
	const [{ data, fetching, error }] = useTodayAttendanceOfStudentQuery({ variables: { studentId: beneficiaryId } })

	return (
		<>
			{fetching ? (
				<VStack className="w-full items-stretch py-2 lg:py-4 gap-2 lg:gap-4">
					<Skeleton className="w-24 h-10" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : (
				<VStack className="w-full items-start py-2 lg:py-4 gap-2 lg:gap-4">
					<div className="w-full grid grid-cols-2 md:grid-cols-3 gap-4">
						<VStack className="w-fit items-start gap-0">
							{data?.todayAttendanceOfStudent?.inAt ? (
								<>
									<span className="text-xs lg:text-sm text-slate-400">Time</span>
									<span className="text-sm lg:text-md font-bold text-slate-500">{format(new Date(data.todayAttendanceOfStudent.inAt), "p")}</span>
								</>
							) : (
								<>
									<span className="text-xs lg:text-sm text-slate-400">Status</span>
									<span className="text-sm lg:text-md font-bold text-red-500">Absent</span>
								</>
							)}
						</VStack>
					</div>
				</VStack>
			)}
		</>
	)
}
