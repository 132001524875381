import React from "react"
import { useCurrentBmIofStudentQuery } from "../../../graphql"
import { ErrorLabel } from "../../ui"
import { BMI, BMISkeleton } from "./BMI"

export type BeneficiaryLatestBMIProps = {
	beneficiaryId: string
}

export const BeneficiaryLatestBMI: React.FC<BeneficiaryLatestBMIProps> = ({ beneficiaryId }) => {
	const [{ data, fetching, error }] = useCurrentBmIofStudentQuery({ variables: { studentId: beneficiaryId } })

	return (
		<>
			{fetching ? (
				<BMISkeleton />
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : !data?.currentBMIofStudent ? (
				<ErrorLabel>Cound not find latest BMI of the beneficiary</ErrorLabel>
			) : (
				<BMI bmi={data.currentBMIofStudent} />
			)}
		</>
	)
}
