import React from "react"
import { EditHolidayForm } from "../../../forms/holiday/EditHolidayForm"
import { useHolidayByIdQuery } from "../../../graphql"
import { ErrorLabel, LoadingLabel } from "../../ui"

export type EditHolidayProps = {
	holidayId: string
	setEditDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
	editDialogOpen: boolean
}

export const EditHoliday: React.FC<EditHolidayProps> = ({ holidayId, editDialogOpen, setEditDialogOpen }) => {
	const [{ fetching, error, data }] = useHolidayByIdQuery({
		variables: { holidayId },
		pause: !holidayId,
	})

	return (
		<>
			{fetching ? (
				<div className="w-full flex justify-center">
					<LoadingLabel>Loading Edit Form </LoadingLabel>
				</div>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.holidayById ? (
				<>
					<EditHolidayForm holiday={data.holidayById} editDialogOpen={editDialogOpen} setEditDialogOpen={setEditDialogOpen} />
				</>
			) : (
				<div className="w-full py-4 mx-auto">
					<h2 className="text-md font-semibold text-gray-400">Couldn&apos;t find any holidays.</h2>
				</div>
			)}
		</>
	)
}
