import * as React from "react"
import { cn } from "../../lib/utils"
import { Icons } from "./icons"

export type ErrorLabelProps = React.HTMLAttributes<HTMLDivElement> & React.PropsWithChildren

const ErrorLabel = React.forwardRef<HTMLDivElement, ErrorLabelProps>(({ className, children, ...props }, ref) => {
	return (
		<div className={cn("flex items-center", className)} ref={ref} {...props}>
			<Icons.warning className="mr-1 h-4 w-4 text-red-600" />
			<span className="text-sm text-red-600">{children}</span>
		</div>
	)
})

ErrorLabel.displayName = "ErrorLabel"

export { ErrorLabel }
