/* eslint-disable react/react-in-jsx-scope */

import { format, startOfDay, subMonths } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from "recharts"
import colors from "tailwindcss/colors"

import { School, SchoolWisePartialAttendanceStatsQueryVariables, useAllSchoolsWithoutPaginationQuery, useSchoolWisePartialAttendanceStatsQuery } from "../../../../graphql"
import { useBreakpoint } from "../../../../hooks"
import { cn } from "../../../../lib/utils"
import { AspectRatio, Button, Calendar, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, ErrorLabel, LoadingLabel, Popover, PopoverContent, PopoverTrigger, VStack } from "../../../ui"

export const CriticalityWiseAttendanceStatsChart: React.FC = () => {
	const [filter, setFilter] = useState<Required<SchoolWisePartialAttendanceStatsQueryVariables["filter"]>>({ sinceDate: subMonths(startOfDay(new Date()), 1), untilDate: startOfDay(new Date()), schoolIds: [] })

	const [{ data, fetching }] = useSchoolWisePartialAttendanceStatsQuery({ variables: { filter } })

	const { criticalNGOPartners, atRiskNGOPartners, wellNGOPartners } = useMemo(() => {
		const criticalNGOPartners: School[] = []
		const atRiskNGOPartners: School[] = []
		const wellNGOPartners: School[] = []

		data?.schoolWisePartialAttendanceStats.forEach((s) => {
			let total = 0
			let actual = 0

			for (const stat of s.stats) {
				total += stat.meal.totalStudentsCount
				actual += stat.meal.presentStudentsCount
			}

			if (!actual) {
				return criticalNGOPartners.push(s.school)
			}

			const attendancePercentage = (actual / total) * 100

			if (attendancePercentage >= 80) {
				wellNGOPartners.push(s.school)
			} else if (attendancePercentage >= 50) {
				atRiskNGOPartners.push(s.school)
			} else {
				criticalNGOPartners.push(s.school)
			}
		})

		return { criticalNGOPartners, atRiskNGOPartners, wellNGOPartners }
	}, [JSON.stringify(data?.schoolWisePartialAttendanceStats ?? {})])

	const [{ data: ngoPartnersData, fetching: ngoPartnersFetching, error: ngoPartnersError }] = useAllSchoolsWithoutPaginationQuery()

	const navigate = useNavigate()

	const criticalChartData = useMemo(
		() => [
			{
				name: "Critical",
				value: criticalNGOPartners.length,
			},
			{ name: "Remaining", value: (ngoPartnersData?.allSchoolsWithoutPagination.length || criticalNGOPartners.length) - criticalNGOPartners.length },
		],
		[criticalNGOPartners, ngoPartnersData]
	)

	const atRiskChartData = useMemo(
		() => [
			{
				name: "At-risk",
				value: atRiskNGOPartners.length,
			},
			{ name: "Remaining", value: (ngoPartnersData?.allSchoolsWithoutPagination.length || atRiskNGOPartners.length) - atRiskNGOPartners.length },
		],
		[atRiskNGOPartners, ngoPartnersData]
	)

	const wellChartData = useMemo(
		() => [
			{
				name: "Well",
				value: wellNGOPartners.length,
			},
			{ name: "Remaining", value: (ngoPartnersData?.allSchoolsWithoutPagination.length || wellNGOPartners.length) - wellNGOPartners.length },
		],
		[wellNGOPartners, ngoPartnersData]
	)

	const { isXl } = useBreakpoint("xl")

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Attendance Overview for NGO partners</CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="h-full gap-4 select-none">
					<VStack className="lg:flex-row items-stretch">
						{ngoPartnersFetching ? (
							<LoadingLabel className="flex-1">Loading NGO partners</LoadingLabel>
						) : ngoPartnersError ? (
							<ErrorLabel className="flex-1">{ngoPartnersError.message.replace("[GraphQL] ", "")}</ErrorLabel>
						) : !ngoPartnersData?.allSchoolsWithoutPagination.length ? (
							<ErrorLabel className="flex-1">Couldn&apos;t fetch NGO partners</ErrorLabel>
						) : (
							<Select
								isMulti
								name="NGO Partners"
								placeholder="Select NGO partners"
								options={ngoPartnersData.allSchoolsWithoutPagination.map((s) => ({ label: s.name, value: s._id }))}
								onChange={(ngoPartners) => setFilter((prev) => ({ ...prev, schoolIds: ngoPartners.map((o) => o.value) }))}
								className="basic-multi-select flex-1"
								isSearchable
							/>
						)}
						<Popover>
							<PopoverTrigger asChild>
								<Button id="date" variant="outline" className={cn("flex-1/2 justify-start text-left font-normal", !(filter.sinceDate && filter.untilDate) && "text-muted-foreground")}>
									<CalendarIcon className="mr-2 h-4 w-4" />
									{filter.sinceDate ? (
										filter.untilDate ? (
											<>
												{format(filter.sinceDate, "MMM dd, yyyy")} - {format(filter.untilDate, "MMM dd, yyyy")}
											</>
										) : (
											format(filter.sinceDate, "MMM dd, yyyy")
										)
									) : (
										<span>Pick a date</span>
									)}
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-auto p-0" align="start">
								<Calendar
									initialFocus
									mode="range"
									defaultMonth={filter.sinceDate}
									selected={{ from: filter.sinceDate, to: filter.untilDate }}
									onSelect={(date) => setFilter((prev) => ({ ...prev, sinceDate: date?.from || date?.to, untilDate: date?.to || date?.from }))}
									toDate={new Date()}
									numberOfMonths={1}
								/>
							</PopoverContent>
						</Popover>
					</VStack>

					{fetching ? (
						<div className="w-full h-full min-h-[200px]">
							<AspectRatio ratio={16 / 9}>
								<ChartSkeleton />
							</AspectRatio>
						</div>
					) : (
						<div className="w-full h-full  min-h-[200px] grid md:grid-cols-3 gap-2">
							<AspectRatio ratio={isXl ? 3 / 4 : 16 / 9}>
								<VStack className="h-full">
									<ResponsiveContainer width="100%" height="100%">
										<PieChart width={400} height={400}>
											<Tooltip />
											<Legend payload={[{ value: "<50%", legendIcon: <></> }]} />
											<defs>
												<linearGradient id="critical" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.red[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.red[400]} stopOpacity={0.2} />
												</linearGradient>
												<linearGradient id="remaining" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.gray[400]} stopOpacity={0.2} />
												</linearGradient>
											</defs>
											<Pie
												data={criticalChartData}
												cx="50%"
												cy="50%"
												innerRadius={isXl ? 60 : 40}
												outerRadius={isXl ? 80 : 60}
												paddingAngle={5}
												dataKey="value"
												activeIndex={0}
												activeShape={renderActiveShape}
												onClick={() => {
													navigate("/attendance/overview/critical", { state: { filter } })
												}}
											>
												{criticalChartData.map((entry, index) => (
													<Cell key={`cell-${index}`} fill={["url(#critical)", "url(#remaining)"][index]} />
												))}
											</Pie>
										</PieChart>
									</ResponsiveContainer>
								</VStack>
							</AspectRatio>

							<AspectRatio ratio={isXl ? 3 / 4 : 16 / 9}>
								<VStack className="h-full">
									<ResponsiveContainer width="100%" height="100%">
										<PieChart width={400} height={400}>
											<Tooltip />
											<Legend payload={[{ value: "50-80%", legendIcon: <></> }]} />
											<defs>
												<linearGradient id="at-risk" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.orange[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.orange[400]} stopOpacity={0.2} />
												</linearGradient>
												<linearGradient id="remaining" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.gray[400]} stopOpacity={0.2} />
												</linearGradient>
											</defs>

											<Pie
												data={atRiskChartData}
												cx="50%"
												cy="50%"
												innerRadius={isXl ? 60 : 40}
												outerRadius={isXl ? 80 : 60}
												paddingAngle={5}
												dataKey="value"
												activeIndex={0}
												activeShape={renderActiveShape}
												onClick={() => {
													navigate("/attendance/overview/at-risk", { state: { filter } })
												}}
											>
												{atRiskChartData.map((entry, index) => (
													<Cell key={`cell-${index}`} fill={["url(#at-risk)", "url(#remaining)"][index]} />
												))}
											</Pie>
										</PieChart>
									</ResponsiveContainer>
								</VStack>
							</AspectRatio>

							<AspectRatio ratio={isXl ? 3 / 4 : 16 / 9}>
								<VStack className="h-full">
									<ResponsiveContainer width="100%" height="100%">
										<PieChart width={400} height={400}>
											<Tooltip />
											<Legend payload={[{ value: "80% +", legendIcon: <></> }]} />
											<defs>
												<linearGradient id="well" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.green[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.green[400]} stopOpacity={0.2} />
												</linearGradient>
												<linearGradient id="remaining" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.gray[400]} stopOpacity={0.2} />
												</linearGradient>
											</defs>
											<Pie
												data={wellChartData}
												cx="50%"
												cy="50%"
												innerRadius={isXl ? 60 : 40}
												outerRadius={isXl ? 80 : 60}
												paddingAngle={5}
												dataKey="value"
												activeIndex={0}
												activeShape={renderActiveShape}
												onClick={() => {
													navigate("/attendance/overview/well", { state: { filter } })
												}}
											>
												{wellChartData.map((entry, index) => (
													<Cell key={`cell-${index}`} fill={["url(#well)", "url(#remaining)"][index]} />
												))}
											</Pie>
										</PieChart>
									</ResponsiveContainer>
								</VStack>
							</AspectRatio>
						</div>
					)}
				</VStack>
			</CardContent>
		</Card>
	)
}

const renderActiveShape = (props: any) => {
	const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, value } = props

	return (
		<g>
			<text x={cx} y={cy} dy={8} className="text-3xl font-bold" textAnchor="middle" fill={fill}>
				{value}
			</text>
			<Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
		</g>
	)
}
