/* eslint-disable @typescript-eslint/indent */

import React, { useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useToast, InputError, Button, Label, Input } from "../../components/ui"
import { useNavigate, useParams } from "react-router-dom"
import { useCreateClassSectionMutation } from "../../graphql"

type AddNewSectionFormValues = {
	name: string
}

const schema = z.object({
	name: z.string().nonempty({ message: "name is required" }),
})

const AddNewSectionForm: React.FC = () => {
	const { classId = "" } = useParams<{ classId: string }>()

	const [, createSection] = useCreateClassSectionMutation()

	const [error, setError] = useState<string | null>(null)

	const { toast } = useToast()
	const navigate = useNavigate()

	const {
		register,
		handleSubmit,
		formState: { isLoading, errors, isSubmitting },
	} = useForm<AddNewSectionFormValues>({ resolver: zodResolver(schema) as any })

	const onSubmit: SubmitHandler<AddNewSectionFormValues> = async (values) => {
		setError(null) // Clear any previous errors
		const { data, error } = await createSection({
			input: { ...values, classId },
		})

		if (error) {
			setError(error.message.replace("[GraphQL] ", ""))
			toast({
				variant: "destructive",
				title: "Creating new Class Section  Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})

			return
		}

		if (data?.createClassSection) {
			toast({
				variant: "success",
				title: "Class Section Created Successfully",
				description: "",
			})
			navigate(`/classes/${classId}`)

			return
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className=" flex flex-col gap-4 lg:w-96">
			<div className="flex flex-col gap-2">
				<Label htmlFor="name">Name</Label>
				<Input placeholder="class section name" type="text" {...register("name")} onChange={() => setError(null)} />
				<InputError>{errors.name?.message}</InputError>
				{error && <div className="text-red-500 text-sm mb-2">{error}</div>}
			</div>

			<Button isLoading={isLoading || isSubmitting} className="mt-6">
				Create Class Section
			</Button>
		</form>
	)
}

export default AddNewSectionForm
