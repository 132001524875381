import React, { SVGProps } from "react"

export const Report: React.FC<SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.4348 16H1.3913C0.622956 16 0 15.377 0 14.6087V1.3913C0 0.622956 0.622956 0 1.3913 0H7.99061C7.9913 0 7.99235 0 7.99304 0H8C8.1113 0 8.20626 0.056 8.26991 0.137391L11.6887 3.55617C11.7704 3.61983 11.8261 3.71478 11.8261 3.82609V3.83339C11.8261 3.83409 11.8261 3.83443 11.8261 3.83513V14.6087C11.8261 15.377 11.2031 16 10.4348 16ZM8.34783 1.176V3.47826H10.6501L8.34783 1.176ZM11.1304 4.17391H8C7.80765 4.17391 7.65217 4.01809 7.65217 3.82609V0.695652H1.3913C1.0073 0.695652 0.695652 1.0073 0.695652 1.3913V14.6087C0.695652 14.9927 1.0073 15.3043 1.3913 15.3043H10.4348C10.8188 15.3043 11.1304 14.9927 11.1304 14.6087V4.17391ZM9.04348 13.2174H2.78261C2.59026 13.2174 2.43478 13.0619 2.43478 12.8696C2.43478 12.6776 2.59026 12.5217 2.78261 12.5217H9.04348C9.23583 12.5217 9.3913 12.6776 9.3913 12.8696C9.3913 13.0619 9.23583 13.2174 9.04348 13.2174ZM9.04348 10.4348H2.78261C2.59026 10.4348 2.43478 10.2793 2.43478 10.087C2.43478 9.89496 2.59026 9.73913 2.78261 9.73913H9.04348C9.23583 9.73913 9.3913 9.89496 9.3913 10.087C9.3913 10.2793 9.23583 10.4348 9.04348 10.4348ZM9.04348 7.65217H2.78261C2.59026 7.65217 2.43478 7.4967 2.43478 7.30435C2.43478 7.11235 2.59026 6.95652 2.78261 6.95652H9.04348C9.23583 6.95652 9.3913 7.11235 9.3913 7.30435C9.3913 7.4967 9.23583 7.65217 9.04348 7.65217Z"
				fill={props.fill || "#6B7280"}
			/>
		</svg>
	)
}
