import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { Button, Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, Input, Textarea, useToast, VStack } from "../../../../components"
import { NGOPartnersSelector } from "../../../../components/admin"
import { useGenerateSchoolsReportMutation } from "../../../../graphql"

type NGOPartnersReportFormValues = { label: { name: string; description?: string }; schoolIds: string[] }

const schema = z.object({
	label: z.object({ name: z.string().nonempty({ message: "Name is required" }), description: z.string() }),
	schoolIds: z.array(z.string()).min(1, { message: "At least one NGO Partner is required" }),
})

export const NGOPartnersNewReportPage: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	const form = useForm<NGOPartnersReportFormValues>({ resolver: zodResolver(schema) as any, defaultValues: { label: { name: "", description: "" }, schoolIds: [] } })

	const [, generate] = useGenerateSchoolsReportMutation()

	const { toast } = useToast()

	const navigate = useNavigate()

	const onSubmit = async (values: NGOPartnersReportFormValues) => {
		const { data, error } = await generate({ input: values })

		if (error) {
			toast({
				title: "Error",
				description: error.message,
				variant: "destructive",
			})
		}

		if (data?.generateSchoolsReport) {
			navigate(`/ngo-partners/reports/${data.generateSchoolsReport._id}`)
		}
	}

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2 lg:gap-4">
			<VStack className="lg:flex-row items-stretch lg:items-center">
				<h1 className="w-full text-sm lg:text-md font-medium">NGO Partners Report</h1>
			</VStack>

			{/* @ts-ignore */}
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-2 lg:gap-4">
					<div className="grid lg:grid-cols-2 gap-2 lg:gap-4">
						<div className="w-full flex flex-col gap-2 lg:gap-4">
							<FormField
								control={form.control}
								name="label.name"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Name</FormLabel>
										<FormControl>
											<Input {...field} />
										</FormControl>
										<FormDescription>This is to reference this report in future.</FormDescription>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="label.description"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Description</FormLabel>
										<FormControl>
											<Textarea {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>

						<FormItem>
							<FormLabel>NGO Partners</FormLabel>
							<FormControl>
								<NGOPartnersSelector selectedNGOPartnerIds={(form.watch("schoolIds") as string[]) || []} onChange={(ngoPartnerIds) => form.setValue("schoolIds", ngoPartnerIds)} />
							</FormControl>
							<FormMessage />
						</FormItem>
					</div>

					<Button className="w-full lg:max-w-sm lg:self-end" isLoading={form.formState.isLoading || form.formState.isSubmitting}>
						Generate
					</Button>
				</form>
			</Form>
		</div>
	)
}

export default NGOPartnersNewReportPage
