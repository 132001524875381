import React from "react"
import { UploadBMIsButton } from "../../../components/ngo-partners/beneficiary/UploadBMIsButton"
import { ErrorLabel, LoadingLabel } from "../../../components"
import { useMeQuery } from "../../../graphql"
import { GenrateBMITemplate } from "../../../components/ngo-partners/beneficiary/GenrateBMITemplate"

const UploadBMIsPage: React.FC = () => {
	const [{ data: meData, fetching, error }] = useMeQuery()

	return (
		<>
			<div className="container mx-auto md:mt-10 mt-4">
				<div>
					<div className="">
						{fetching ? (
							<LoadingLabel />
						) : error ? (
							<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
						) : !meData ? (
							<ErrorLabel>Not login</ErrorLabel>
						) : (
							<>{meData.me?.school && <GenrateBMITemplate schoolId={meData.me?.school?._id} />}</>
						)}
					</div>
				</div>

				<h1 className="text-lg text-gray-700 font-semibold text-left mt-4 md:mt-10">Upload Beneficiaries BMI</h1>

				<div className="mt-4">
					<UploadBMIsButton />
				</div>
			</div>
		</>
	)
}

export default UploadBMIsPage
