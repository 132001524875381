import { Button, useToast, Table, Popover, PopoverContent, PopoverTrigger } from "../../ui"
import React, { useEffect, useRef, useState } from "react"

import * as xlsx from "xlsx"
import { useUploadBmIsMutation } from "../../../graphql"

import { UploadIcon, BellIcon } from "@radix-ui/react-icons"

export const UploadBMIsButton: React.FC = () => {
	const [isIncorrectlyFormatted, setIsIncorrectlyFormatted] = useState(false)
	const [file, setFile] = useState<Blob>()
	const [rows, setRows] = useState<{ regNo: string; height: number; weight: number; name: string }[]>([])
	const [isOpen, setIsOpen] = useState(false)

	const [error, setError] = useState<string | null>(null)

	const readUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setFile(e.target.files?.[0])
	}

	useEffect(() => {
		if (file) {
			const reader = new FileReader()
			reader.onload = (e) => {
				const data = e.target?.result
				const workbook = xlsx.read(data, { type: "array" })
				const sheetName = workbook.SheetNames[0]
				const worksheet = workbook.Sheets[sheetName]
				const rows = xlsx.utils.sheet_to_json<{
					RegNo: string
					"Height in cms": string
					"Weight in kgs": string
				}>(worksheet)

				let incorrect = false

				let i = 0

				while (!incorrect && i < rows.length) {
					// if (!("RegNo" in rows[i]) || !("Height in cms" in rows[i]) || !("Weight in kgs" in rows[i])) {
					// 	incorrect = true
					// }
					if (!("RegNo" in rows[i])) {
						incorrect = true
					}

					i += 1
				}

				if (incorrect) {
					setIsIncorrectlyFormatted(true)
				} else {
					setRows(
						rows.map((row: any) => ({
							name: row.Name,
							regNo: row["RegNo"],
							height: parseFloat(row["Height in cms"]),
							weight: parseFloat(row["Weight in kgs"]),
						}))
					)
				}
			}
			reader.readAsArrayBuffer(file)
		}
	}, [file])

	const [{ fetching }, uploadBMIs] = useUploadBmIsMutation()

	const { toast } = useToast()

	const handleUploadBMIs = async () => {
		try {
			if (isIncorrectlyFormatted || !rows.length) return
			const { error, data } = await uploadBMIs({
				bmis: rows.map((row) => {
					return {
						regNo: row.regNo,
						height: row.height,
						weight: row.weight,
					}
				}),
			})

			if (error) {
				setError(error.message.replace("[GraphQL]", ""))

				toast({ title: "Error", variant: "destructive", description: error.message.replace("[GraphQL] ", "") })
				return
			}

			if (data?.uploadBMIs) {
				setRows([])
				toast({ title: "Success", variant: "success", description: "BMIs uploaded successfully" })
				setIsOpen(true)
				setError(null)
			}
		} catch (error: any) {
			console.log("error", error)
		}
	}

	const ref = useRef()

	const handleModalClose = () => {
		setIsIncorrectlyFormatted(false)
		setFile(undefined)
		setRows([])
		setIsOpen(false)
		setError(null)
	}

	const handleNotificationClose = () => {
		setIsOpen(false)
	}

	const handleOverlayClick = (e: any) => {
		if (e.target === e.currentTarget) {
			// Clicking outside the modal
			handleNotificationClose()
		}
	}

	return (
		<>
			<Popover onOpenChange={handleModalClose}>
				<PopoverTrigger>{rows.length === 0 && <Button className="hidden md:block">Upload BMI</Button>}</PopoverTrigger>
				{!isOpen && rows.length === 0 && (
					<PopoverContent className="flex gap-4 flex-col w-fit relative" align="center" side="right">
						<div>
							{rows.length === 0 && !isOpen && !isIncorrectlyFormatted && (
								<>
									<input id="upload-bmis-xlsx" type="file" name="upload" onChange={(e) => readUploadFile(e)} style={{ display: "none" }} />
									<label htmlFor="upload-bmis-xlsx">
										<div className="p-4 border-2 flex  flex-col gap-2  border-dashed border-gray-400">
											<div className="w-full flex justify-center">
												<UploadIcon width={50} height={50} color="orange" />
											</div>
											<div className="bg-blue-700 px-4 py-2  text-center  text-sm   font-medium text-white hover:cursor-pointer rounded-lg hover:opacity-70">Select File</div>
											<p className="text-gray-700 font-medium text-sm max-w-sm ">click here to upload the file containing beneficiaries BMIs</p>
											{/* <p className="text-red-400 font-medium text-xs max-w-xs">*Note: file should contain three column with heading RegNo , Height in cms , Weight in kgs and only file with extension .xlsx is valid</p> */}
										</div>
									</label>
								</>
							)}
						</div>

						{isIncorrectlyFormatted && (
							<div className="container mx-auto" onClick={handleOverlayClick}>
								<h2 className="text-sm text-red-500 font-semibold mb-2">The file is incorrectly formatted</h2>
								<Button onClick={handleModalClose}>Close</Button>
							</div>
						)}
					</PopoverContent>
				)}
			</Popover>

			{!isOpen && rows.length === 0 && (
				<>
					<div className="block md:hidden">
						{rows.length === 0 && !isOpen && !isIncorrectlyFormatted && (
							<>
								<input id="upload-bmis-xlsx" type="file" name="upload" onChange={(e) => readUploadFile(e)} style={{ display: "none" }} />
								<label htmlFor="upload-bmis-xlsx">
									<div className="p-4 border-2 flex  flex-col gap-2  border-dashed border-gray-400">
										<div className="w-full flex justify-center">
											<UploadIcon width={50} height={50} color="orange" />
										</div>
										<div className="bg-blue-700 px-4 py-2  text-center  text-sm   font-medium text-white hover:cursor-pointer rounded-lg hover:opacity-70">Select File</div>
										<p className="text-gray-700 font-medium text-sm max-w-sm ">click here to upload the file containing beneficiaries BMIs</p>
										{/* <p className="text-red-400 font-medium text-xs max-w-xs">*Note: file should contain three column with heading RegNo , Height in cms , Weight in kgs and only file with extension .xlsx is valid</p> */}
									</div>
								</label>
							</>
						)}
					</div>

					{isIncorrectlyFormatted && (
						<div className="container mx-auto md:hidden" onClick={handleOverlayClick}>
							<h2 className="text-sm text-red-500 font-semibold mb-2">The file is incorrectly formatted</h2>
							<Button onClick={handleModalClose}>Close</Button>
						</div>
					)}
				</>
			)}
			{isOpen && (
				<div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={handleOverlayClick}>
					<div className="bg-white p-4 rounded-lg shadow-lg">
						<div className="flex flex-col items-center gap-4">
							<h1 className="text-lg text-green-700 font-medium">BMIs uploaded successfully</h1>
							<Button onClick={handleNotificationClose}>Close</Button>
						</div>
					</div>
				</div>
			)}

			{error && (
				<div className="flex gap-2 items-center">
					<span>
						<BellIcon style={{ width: "20px", height: "20px", color: "#dc2626" }} />
					</span>
					<p className="text-lg text-center text-red-600 font-medium">{error.replace("[GraphQL] ", "")}</p>
				</div>
			)}

			{rows.length > 0 && (
				<div className="container mx-auto mt-10">
					<div className="flex w-full justify-end gap-4 mb-4">
						<Button isLoading={fetching} onClick={handleUploadBMIs} className="bg-blue-600 text-white">
							Upload
						</Button>
						<Button ref={ref as any} onClick={handleModalClose} className="bg-red-500 text-white">
							Cancel
						</Button>
					</div>
					<Table className=" rounded-xl ">
						<thead>
							<tr className="text-center">
								<th className="lg:px-6 md:px-2 md:py-2 lg:py-3 px-1 py-1 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Name</th>
								<th className="lg:px-6 md:px-2 md:py-2 lg:py-3 px-1 py-1 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">RegNo</th>
								<th className="lg:px-6 md:px-2 md:py-2 lg:py-3 px-1 py-1 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Height in cms</th>
								<th className="lg:px-6 md:px-2 md:py-2 lg:py-3 px-1 py-1 bg-gray-100 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Weight in kgs</th>
							</tr>
						</thead>

						<tbody>
							{rows.map((row, index) => (
								<tr key={index} className="text-center">
									<td>{row.name}</td>
									<td>{row["regNo"]}</td>
									<td>{row["height"] || ""} </td>
									<td>{row["weight"] || ""}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			)}
		</>
	)
}
