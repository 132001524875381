import { VStack } from "../../../components"
import React from "react"
import UpdateBeneficiaryProfileForm from "../../../forms/UpdateBeneficiaryProfileForm"
import { ExtendedStudentFragment } from "../../../graphql"

export type UpdateBeneficiaryProfileProps = {
	beneficiary: ExtendedStudentFragment
}

const UpdateBeneficiaryProfile: React.FC<UpdateBeneficiaryProfileProps> = ({ beneficiary }) => {
	return (
		<VStack className="w-full mx-auto">
			<UpdateBeneficiaryProfileForm beneficiary={beneficiary} />
		</VStack>
	)
}

export default UpdateBeneficiaryProfile
