import React, { SVGProps } from "react"

export const Attendance: React.FC<SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clipPath="url(#clip0_2_58)">
				<path
					d="M15.1111 1.77861C15.1113 1.41013 14.997 1.05069 14.7839 0.750022C14.5709 0.449354 14.2697 0.222309 13.922 0.100293C13.5743 -0.0217239 13.1973 -0.0326827 12.8431 0.068932C12.4889 0.170547 12.175 0.379713 11.9449 0.667501L9.46578 3.76617L7.28889 2.13417C6.91933 1.85701 6.45639 1.73458 5.99813 1.79282C5.53987 1.85105 5.12227 2.08538 4.83378 2.44617L1.27822 6.89061C1.02608 7.2057 0.88876 7.59727 0.888889 8.00083V10.6675H15.1111V1.77861ZM15.1111 13.3342H0.888889C0.653141 13.3342 0.427048 13.4278 0.260349 13.5945C0.0936505 13.7612 0 13.9873 0 14.2231C0 14.4588 0.0936505 14.6849 0.260349 14.8516C0.427048 15.0183 0.653141 15.1119 0.888889 15.1119H15.1111C15.3469 15.1119 15.573 15.0183 15.7397 14.8516C15.9064 14.6849 16 14.4588 16 14.2231C16 13.9873 15.9064 13.7612 15.7397 13.5945C15.573 13.4278 15.3469 13.3342 15.1111 13.3342Z"
					fill={props.fill || "#6B7280"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_2_58">
					<rect width="16" height="16" fill={props.fill || "#6B7280"} />
				</clipPath>
			</defs>
		</svg>
	)
}
