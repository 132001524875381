/* eslint-disable react/react-in-jsx-scope */

import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import twcolors from "tailwindcss/colors"
import { useClassWiseStatsQuery } from "../../../../graphql"
import { EmptyChart } from "../../../common"
import { AspectRatio, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, VStack } from "../../../ui"

const colors = [twcolors.red[400], twcolors.purple[400], twcolors.teal[400], twcolors.orange[400], twcolors.slate[400], twcolors.gray[400]]

export const GradeWiseBeneficiariesChart: React.FC = () => {
	const [{ data, fetching }] = useClassWiseStatsQuery({
		requestPolicy: "network-only",
	})

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Grade Wise distribution of beneficiaries </CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="gap-4 select-none">
					<AspectRatio ratio={16 / 9}>
						<ResponsiveContainer width="100%" height="100%">
							{fetching ? (
								<ChartSkeleton />
							) : !data?.classWiseStats.length ? (
								<EmptyChart />
							) : (
								<BarChart
									width={500}
									height={400}
									data={data.classWiseStats.map((d) => ({ label: d.label, Beneficiaries: d.count }))}
									margin={{
										top: 20,
										bottom: 50,
									}}
									barCategoryGap="2%"
								>
									<XAxis fontSize={12} dataKey="label" angle={-90} textAnchor="end" />
									<YAxis />
									<Tooltip />

									<Bar
										dataKey="Beneficiaries"
										fillOpacity={0.7}
										label={{
											position: "top",
										}}
									>
										{data.classWiseStats.map((_, index) => (
											<Cell key={`cell-${index}`} fill={colors[4]} className="cursor-pointe" />
										))}
									</Bar>
								</BarChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}
