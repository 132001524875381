import React, { SVGProps } from "react"

export const Devices: React.FC<SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clipPath="url(#clip0_2_62)">
				<path
					d="M11.3333 16C12.0406 16 12.7189 15.719 13.219 15.219C13.719 14.7189 14 14.0406 14 13.3333V2.66667C14 1.95942 13.719 1.28115 13.219 0.781049C12.7189 0.280952 12.0406 0 11.3333 0L4.66667 0C3.95942 0 3.28115 0.280952 2.78105 0.781049C2.28095 1.28115 2 1.95942 2 2.66667V13.3333C2 14.0406 2.28095 14.7189 2.78105 15.219C3.28115 15.719 3.95942 16 4.66667 16H11.3333ZM3.33333 13.3333V2.66667C3.33333 2.31304 3.47381 1.97391 3.72386 1.72386C3.97391 1.47381 4.31304 1.33333 4.66667 1.33333H11.3333C11.687 1.33333 12.0261 1.47381 12.2761 1.72386C12.5262 1.97391 12.6667 2.31304 12.6667 2.66667V13.3333C12.6667 13.687 12.5262 14.0261 12.2761 14.2761C12.0261 14.5262 11.687 14.6667 11.3333 14.6667H4.66667C4.31304 14.6667 3.97391 14.5262 3.72386 14.2761C3.47381 14.0261 3.33333 13.687 3.33333 13.3333Z"
					stroke={props.fill || "#6B7280"}
				/>
				<path
					d="M5.33317 12.6667H10.6665C10.8433 12.6667 11.0129 12.5964 11.1379 12.4714C11.2629 12.3464 11.3332 12.1768 11.3332 12C11.3332 11.8232 11.2629 11.6536 11.1379 11.5286C11.0129 11.4036 10.8433 11.3333 10.6665 11.3333H5.33317C5.15636 11.3333 4.98679 11.4036 4.86177 11.5286C4.73674 11.6536 4.6665 11.8232 4.6665 12C4.6665 12.1768 4.73674 12.3464 4.86177 12.4714C4.98679 12.5964 5.15636 12.6667 5.33317 12.6667ZM7.33317 3.33333H8.6665C8.84331 3.33333 9.01288 3.2631 9.13791 3.13807C9.26293 3.01305 9.33317 2.84348 9.33317 2.66667C9.33317 2.48986 9.26293 2.32029 9.13791 2.19526C9.01288 2.07024 8.84331 2 8.6665 2H7.33317C7.15636 2 6.98679 2.07024 6.86177 2.19526C6.73674 2.32029 6.6665 2.48986 6.6665 2.66667C6.6665 2.84348 6.73674 3.01305 6.86177 3.13807C6.98679 3.2631 7.15636 3.33333 7.33317 3.33333Z"
					stroke={props.fill || "#6B7280"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_2_62">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}
