import { format, startOfDay, subMonths } from "date-fns"
import { CalendarIcon } from "lucide-react"
import React, { useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { Button, Calendar, ErrorLabel, Popover, PopoverContent, PopoverTrigger, Skeleton, VStack } from "../../../../components"
import { AttendanceOverviewNGOPartnerListItem } from "../../../../components/admin"
import { SchoolWiseAttendanceStatsQueryVariables, SchoolWiseAttendanceStatsResponse, useSchoolWiseAttendanceStatsQuery } from "../../../../graphql"
import { cn } from "../../../../lib/utils"

export const AtRiskSchoolsPage: React.FC = () => {
	const { state } = useLocation()

	const [filter, setFilter] = useState<SchoolWiseAttendanceStatsQueryVariables["filter"]>({ sinceDate: startOfDay(subMonths(new Date(), 1)), untilDate: startOfDay(new Date()), schoolIds: [], ...state?.filter })

	const [{ data, fetching, error }] = useSchoolWiseAttendanceStatsQuery({ variables: { filter }, pause: !filter.sinceDate || !filter.untilDate })

	const atRiskSchools = useMemo(() => {
		const atRiskSchools: SchoolWiseAttendanceStatsResponse[] = []

		data?.schoolWiseAttendanceStats.forEach((s) => {
			const totalStudents = s.stats.map((s) => s.totalStudents.length).reduce((a, b) => a + b, 0)
			const presentStudents = s.stats.map((s) => s.presentStudents.length).reduce((a, b) => a + b, 0)

			const attendancePercentage = (presentStudents / totalStudents) * 100

			if (attendancePercentage >= 50 && attendancePercentage < 80) {
				atRiskSchools.push(s as SchoolWiseAttendanceStatsResponse)
			}
		})

		return atRiskSchools
	}, [data?.schoolWiseAttendanceStats])

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2">
			<VStack className="w-full lg:flex-row">
				<h1 className="w-full text-sm lg:text-md font-medium">At-risk NGO partners</h1>

				<Popover>
					<PopoverTrigger asChild>
						<Button id="date" variant="outline" className={cn("w-full lg:max-w-xs justify-start text-left font-normal", !(filter.sinceDate && filter.untilDate) && "text-muted-foreground")}>
							<CalendarIcon className="mr-2 h-4 w-4" />
							{filter.sinceDate ? (
								filter.untilDate ? (
									<>
										{format(filter.sinceDate, "MMM dd, yyyy")} - {format(filter.untilDate, "MMM dd, yyyy")}
									</>
								) : (
									format(filter.sinceDate, "MMM dd, yyyy")
								)
							) : (
								<span>Pick a date</span>
							)}
						</Button>
					</PopoverTrigger>
					<PopoverContent className="w-auto p-0" align="start">
						<Calendar
							initialFocus
							mode="range"
							defaultMonth={filter.sinceDate}
							selected={{ from: filter.sinceDate, to: filter.untilDate }}
							onSelect={(date) => setFilter((prev) => ({ ...prev, sinceDate: date?.from || date?.to, untilDate: date?.to || date?.from }))}
							toDate={new Date()}
							numberOfMonths={1}
						/>
					</PopoverContent>
				</Popover>
			</VStack>
			{fetching ? (
				<VStack className="w-full max-w-xl items-stretch gap-2">
					<Skeleton className="w-full max-w-xl h-36" />
					<Skeleton className="w-full max-w-xl h-36" />
					<Skeleton className="w-full max-w-xl h-36" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message}</ErrorLabel>
			) : !data?.schoolWiseAttendanceStats || !atRiskSchools.length ? (
				<ErrorLabel>No at-risk schools found</ErrorLabel>
			) : (
				<VStack className="w-full max-w-xl items-stretch gap-2">
					{atRiskSchools.map((s) => (
						<AttendanceOverviewNGOPartnerListItem key={s.school._id} data={s} />
					))}
				</VStack>
			)}
		</div>
	)
}

export default AtRiskSchoolsPage
