/* eslint-disable @typescript-eslint/indent */

import React from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useToast, InputError, Button, Label, Input } from "../components/ui"
import { useNavigate } from "react-router-dom"
import { ExtendedStudentFragment, useUploadBmiOfStudentMutation } from "../graphql"
import { format } from "date-fns"

type UploadBMIOfStudentFormValues = {
	height: number
	weight: number

	date: Date
}

const schema = z.object({
	height: z.string().nonempty({ message: "height is required" }),
	weight: z.string().nonempty({ message: "weight is required" }),
	date: z.string(),
})

export type UploadBMIOfStudentFormProps = {
	beneficiary: ExtendedStudentFragment
}

const UploadBMIOfStudentForm: React.FC<UploadBMIOfStudentFormProps> = ({ beneficiary }) => {
	const [{ fetching }, uploadBMI] = useUploadBmiOfStudentMutation()

	const { toast } = useToast()
	const navigate = useNavigate()

	const {
		register,
		handleSubmit,
		formState: { isLoading, errors, isSubmitting },
	} = useForm<UploadBMIOfStudentFormValues>({ resolver: zodResolver(schema) as any })

	const onSubmit: SubmitHandler<UploadBMIOfStudentFormValues> = async (values) => {
		const height = Number(values.height)
		const weight = Number(values.weight)

		const { data, error } = await uploadBMI({
			bmi: { height, weight, regNo: beneficiary.regNo },
			date: new Date(values.date),
		})

		if (error) {
			toast({
				variant: "destructive",
				title: "Student updation Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})

			return
		}

		if (data?.uploadBMIOfStudent) {
			toast({
				variant: "success",
				title: "Student BMI updated Successfully",
				description: "",
			})
			navigate(`/beneficiaries/${beneficiary._id}`, { replace: true })

			return
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col lg:justify-center justify-start px-4 gap-4 lg:w-96">
			<div>
				<Label htmlFor="height">Height</Label>
				<Input placeholder="height (in cm)" type="number" {...register("height")} />
				<InputError>{errors.height?.message}</InputError>
			</div>

			<div>
				<Label htmlFor="weight">Weight</Label>

				<Input type="number" placeholder="weight (in kg)" {...register("weight")} />
				<InputError>{errors.weight?.message}</InputError>
			</div>

			<div>
				<Label htmlFor="date">Taken on</Label>

				<Input type="date" {...register("date")} defaultValue={format(new Date(), "yyyy-MM-dd")} />
				<InputError>{errors.date?.message}</InputError>
			</div>

			<Button isLoading={isLoading || isSubmitting || fetching} className="mt-6">
				Upload
			</Button>
		</form>
	)
}

export default UploadBMIOfStudentForm
