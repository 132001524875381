import * as React from "react"
import { cn } from "../../lib/utils"

export type InputErrorProps = React.HTMLAttributes<HTMLParagraphElement> & React.PropsWithChildren

const InputError = React.forwardRef<HTMLParagraphElement, InputErrorProps>(({ className, children, ...props }, ref) => {
	return (
		<p className={cn("text-red-400 text-sm", className)} ref={ref} {...props}>
			{children}
		</p>
	)
})

InputError.displayName = "InputError"

export { InputError }
