/* eslint-disable react/react-in-jsx-scope */

import React, { useState } from "react"
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import twcolors from "tailwindcss/colors"
import { useAllSchoolsWithoutPaginationQuery, useAllClassWiseStatsQuery } from "../../../../graphql"
import { EmptyChart } from "../../../common"
import { AspectRatio, ErrorLabel, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, VStack, LoadingLabel } from "../../../ui"

const colors = [twcolors.red[400], twcolors.purple[400], twcolors.teal[400], twcolors.orange[400], twcolors.slate[400], twcolors.gray[400]]

export const GradeWiseBeneficiariesChart: React.FC = () => {
	const [selectedSchoolId, setSelectedSchoolId] = useState<string>("")

	const [{ data: ngoPartnersData, fetching: ngoPartnersFetching, error: ngoPartnersError }] = useAllSchoolsWithoutPaginationQuery()

	const [{ data, fetching }] = useAllClassWiseStatsQuery({ variables: { schoolId: selectedSchoolId } })

	const handleSchoolChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedSchoolId(event.target.value)
	}

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Grade Wise distribution of beneficiaries </CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="gap-4 select-none">
					{ngoPartnersFetching ? (
						<div>
							<h1>Loading ngo partners</h1>
							<LoadingLabel />
						</div>
					) : ngoPartnersError ? (
						<ErrorLabel className="flex-1">{ngoPartnersError.message.replace("[GraphQL] ", "")}</ErrorLabel>
					) : !ngoPartnersData?.allSchoolsWithoutPagination.length ? (
						<ErrorLabel className="flex-1">Couldn&apos;t fetch NGO partners</ErrorLabel>
					) : (
						<select value={selectedSchoolId} onChange={handleSchoolChange} className=" px-4 py-2  outline-none  ">
							<option value="">All Ngo Partners</option>
							{ngoPartnersData?.allSchoolsWithoutPagination.map((school) => (
								<option key={school._id} value={school._id}>
									{school.name}
								</option>
							))}
						</select>
					)}

					<AspectRatio ratio={16 / 9}>
						<ResponsiveContainer width="100%" height="100%">
							{fetching ? (
								<ChartSkeleton />
							) : !data?.allClassWiseStats.length ? (
								<EmptyChart />
							) : (
								<BarChart
									width={500}
									height={400}
									data={data.allClassWiseStats.map((d) => ({ label: d.label, Beneficiaries: d.count }))}
									margin={{
										top: 20,
										bottom: 50,
									}}
									barCategoryGap="2%"
								>
									<XAxis fontSize={12} dataKey="label" angle={-90} textAnchor="end" />
									<YAxis />
									<Tooltip />

									<Bar
										dataKey="Beneficiaries"
										fillOpacity={0.7}
										label={{
											position: "top",
										}}
									>
										{data.allClassWiseStats.map((_, index) => (
											<Cell key={`cell-${index}`} fill={colors[1]} className="cursor-pointe" />
										))}
									</Bar>
								</BarChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}
