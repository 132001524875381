import React from "react"
import AddNewSectionForm from "../../../forms/classes/AddNewClassSectionForm"

export const AddNewSection: React.FC = () => {
	return (
		<div className="px-10 py-4 ">
			<h1 className="text-gray-700 text-lg font-bold">New Class Section</h1>
			<div className="mt-10">
				<AddNewSectionForm></AddNewSectionForm>
			</div>
		</div>
	)
}
