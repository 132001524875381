import React, { PropsWithChildren, useCallback, useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import colors from "tailwindcss/colors"
import { Attendance } from "../../../icons/Attendance"
import { Home } from "../../../icons/Home"
import { Report } from "../../../icons/Report"
import { Devices } from "../../../icons/Devices"
import { Holiday } from "../../../icons/Holiday"
import { cn } from "../../../lib/utils"
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "../../ui"
import { Upload } from "../../../icons/Upload"
import { Classes } from "../../../icons/Classes"

enum Routes {
	BeneficiariesAttendanceReports = "/attendance/reports/beneficiaries",
	Attendance = "/attendance",
	Devices = "/devices",
	Classes = "/classes",
	Overview = "/",
}

export const BottomTabs: React.FC = () => {
	const { pathname } = useLocation()

	const activeRoute = useMemo(() => {
		for (const route of Object.values(Routes)) {
			if (pathname.startsWith(route)) {
				return route
			}
		}
	}, [pathname])

	const isActive = useCallback((...route: Routes[]) => activeRoute && route.includes(activeRoute), [activeRoute])

	return (
		<div className="md:hidden fixed bottom-0 z-50 w-full -translate-x-1/2 bg-white border-t border-gray-200 left-1/2 dark:bg-gray-700 dark:border-gray-600">
			<div className="h-full flex justify-evenly items-stretch">
				<Tab icon={Home} label="Overview" href="/" isActive={isActive(Routes.Overview)} />

				<SheetTab icon={Attendance} label="Attendance" isActive={isActive(Routes.Attendance, Routes.BeneficiariesAttendanceReports)}>
					<Tab icon={Attendance} label="Attendance" href="/attendance" isActive={isActive(Routes.Attendance)} asSheetTab />
					<Tab icon={Report} label="Beneficiaries average attendance report" href="/attendance/reports/beneficiaries-average" isActive={isActive(Routes.BeneficiariesAttendanceReports)} asSheetTab />
					<Tab icon={Report} label="Beneficiaries attendance report" href="/attendance/reports/beneficiaries" isActive={isActive(Routes.BeneficiariesAttendanceReports)} asSheetTab />
				</SheetTab>

				<SheetTab icon={Upload} label="Upload">
					<Tab icon={Upload} label="Upload BMI" href="/bmi/upload" asSheetTab />
					<Tab icon={Upload} label="Upload Class" href="/class/upload" asSheetTab />
				</SheetTab>

				<Tab icon={Classes} label="Classes" href="/classes" isActive={isActive(Routes.Classes)} />
				<Tab icon={Devices} label="Devices" href="/devices" isActive={isActive(Routes.Devices)} />

				<SheetTab icon={Holiday} label="Holiday">
					<Tab icon={Holiday} label="Holiday" href="/holiday" asSheetTab />
					<Tab icon={Holiday} label="Working Weekdays" href="/holiday/updateWorkingWeekdays" asSheetTab />
				</SheetTab>
			</div>
		</div>
	)
}

type TabProps = {
	icon: React.FC<React.SVGProps<SVGSVGElement>>
	label: string
	href?: string
	isActive?: boolean
	asSheetTab?: boolean
}

const Tab: React.FC<TabProps> = ({ icon: Icon, label, href, isActive = false, asSheetTab }) => {
	if (!href) {
		return (
			<button className={cn("w-full h-full inline-flex flex-col items-center justify-center py-2", isActive ? "bg-purple-100 gap-2" : asSheetTab ? "bg-gray-50" : "bg-white")}>
				{isActive ? <Icon fill={colors.purple[600]} className="scale-150" /> : <Icon />}

				{!isActive ? <span className="text-sm">{label}</span> : <span className="text-xs text-purple-800">{label}</span>}
			</button>
		)
	}

	return (
		<Link to={href} className="w-full h-full">
			<button className={cn("w-full h-full inline-flex flex-col items-center justify-center py-2", isActive ? "bg-purple-100 gap-2" : asSheetTab ? "bg-gray-50" : "bg-white")}>
				{isActive ? <Icon fill={colors.purple[600]} className="scale-150" /> : <Icon />}

				{!isActive ? <span className="text-sm">{label}</span> : <span className="text-xs text-purple-800">{label}</span>}
			</button>
		</Link>
	)
}

type SheetTabTabProps = {
	icon: React.FC<React.SVGProps<SVGSVGElement>>
	label: string
	isActive?: boolean
}

const SheetTab: React.FC<SheetTabTabProps & PropsWithChildren> = ({ icon: Icon, label, isActive = false, children }) => {
	const [isSheetOpen, setIsSheetOpen] = React.useState(false)

	return (
		<Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
			<SheetTrigger className="w-full h-full">
				<Tab icon={Icon} label={label} isActive={isActive} />
			</SheetTrigger>

			<SheetContent side="bottom" onClick={() => setIsSheetOpen(false)}>
				<SheetHeader className="pb-4">
					<SheetTitle className="text-sm text-slate-600">{label}</SheetTitle>
				</SheetHeader>
				<div className="grid grid-cols-2 gap-2">{children}</div>
			</SheetContent>
		</Sheet>
	)
}
