import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { ExtendedStudentFragment, PartialStudentFragment, StudentFragment } from "../../graphql"
import { Avatar, AvatarFallback, AvatarImage, HStack, VStack } from "../ui"

export type BeneficiaryTagProps = {
	beneficiary: ExtendedStudentFragment | StudentFragment | PartialStudentFragment
	disableLink?: boolean
}

export const BeneficiaryTag: React.FC<BeneficiaryTagProps> = ({ beneficiary, disableLink = false }) => {
	const user = useMemo(
		() => (
			<HStack className="gap-4">
				<Avatar>
					<AvatarImage src={beneficiary.picture?.url} />
					<AvatarFallback>
						{beneficiary.name
							.split(" ")
							.slice(0, 2)
							.map((o) => o[0])
							.join("")}
					</AvatarFallback>
				</Avatar>
				<VStack className="items-start gap-0">
					<span className="text-slate-600 text-sm font-bold">{beneficiary.name}</span>
					<span className="text-slate-500 text-xs">{beneficiary.regNo}</span>
				</VStack>
			</HStack>
		),
		[beneficiary]
	)

	return !disableLink ? <Link to={`/beneficiaries/${beneficiary._id}`}>{user}</Link> : user
}
