import React from "react"
import CreateMealSlotForm from "../../../forms/admin/meal/CreateMealSlotForm"

export const CreateMealSlot: React.FC = () => {
	return (
		<div className="px-10 py-4 ">
			<h1 className="text-gray-700 text-lg font-bold">Create Meal Slot</h1>
			<div className="mt-10">
				<CreateMealSlotForm />
			</div>
		</div>
	)
}
