import React from "react"
import { SESSION_TOKEN_KEY } from "../../../constants"
import { useLogoutMutation, useMeQuery } from "../../../graphql"
import { Avatar, AvatarFallback, AvatarImage, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger, useToast } from "../../ui"

export const AccountMenu: React.FC = () => {
	const [{ data }] = useMeQuery()

	const [{ fetching: loggingOut }, logout] = useLogoutMutation()

	const { toast } = useToast()

	const handleLogout = async () => {
		const { error } = await logout({})

		if (error) {
			return toast({ description: error.message.replace("GraphQL ", "") })
		}

		localStorage.removeItem(SESSION_TOKEN_KEY)
		window.location.replace("/")

		return toast({ description: "You've been logged out" })
	}

	if (!data?.me?.admin) return <></>

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Avatar className="h-8 w-8 cursor-pointer hover:scale-[1.1]">
					<AvatarImage src={data?.me?.admin?.picture?.url} />
					<AvatarFallback>
						{data.me.admin.name
							.split(" ")
							.slice(0, 2)
							.map((o) => o[0])
							.join("")}
					</AvatarFallback>
				</Avatar>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56">
				<DropdownMenuLabel>{data.me.admin.name}</DropdownMenuLabel>
				<DropdownMenuSeparator />

				<DropdownMenuItem isLoading={loggingOut} onClick={handleLogout}>
					Log out
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
