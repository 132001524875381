import format from "date-fns/format"
import { Image } from "lucide-react"
import React from "react"
import { PartialStudentFragment, PartialStudentWithAttendanceFragment, useAttendanceImageOfStudentQuery } from "../../../graphql"
import { BeneficiaryTag } from "../../common"
import { Button, Dialog, DialogContent, DialogHeader, DialogTrigger, HStack, VStack } from "../../ui"

type BeneficiaryAttendanceImageProps = {
	attendanceId: string
	beneficiary: PartialStudentWithAttendanceFragment
}

export const BeneficiaryAttendanceImage: React.FC<BeneficiaryAttendanceImageProps> = ({ attendanceId, beneficiary }) => {
	const [{ fetching, error, data }] = useAttendanceImageOfStudentQuery({ variables: { attendanceId } })

	return (
		<Dialog>
			<DialogTrigger>
				<Button variant="ghost" size="icon">
					<Image className="h-6 w-6" />
				</Button>
			</DialogTrigger>

			<DialogContent>
				<DialogHeader>
					<HStack className="w-full gap-4">
						<BeneficiaryTag beneficiary={beneficiary as PartialStudentFragment} />

						<VStack className="w-56 items-start gap-0">
							<span className="text-sm font-bold text-slate-600">{format(new Date(beneficiary.inAt), "p")}</span>
							<span className="text-xs font-medium text-slate-500">Time</span>
						</VStack>
					</HStack>
				</DialogHeader>

				{fetching ? (
					<span>Fetching image</span>
				) : error ? (
					<VStack>
						<span>Couldn&apos;t fetch image</span>
						<span>{error.message.replace("[GraphQL] ", "")}</span>
					</VStack>
				) : !data?.attendanceImageOfStudent ? (
					<VStack>
						<span>Couldn&apos;t fetch image</span>
					</VStack>
				) : (
					<>
						{data.attendanceImageOfStudent.imageBase64 && <img src={`data:image/png;base64,${data.attendanceImageOfStudent.imageBase64}`} alt="Student Attendance Image" className="w-full object-contain" />}
						{data.attendanceImageOfStudent.pictureUrl && <img src={`${data.attendanceImageOfStudent.pictureUrl}`} alt="Student Attendance Image" className="w-full object-contain" />}
					</>
				)}
			</DialogContent>
		</Dialog>
	)
}
