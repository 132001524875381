import FullCalendar, { DateSelectArg, EventClickArg } from "@fullcalendar/react"
import "@fullcalendar/common/main.css"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid"
import { zodResolver } from "@hookform/resolvers/zod"
import { Cross2Icon } from "@radix-ui/react-icons"
import { parseISO } from "date-fns"
import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { EditHoliday } from "../../../components/ngo-partners/holiday/EditHoliday"
import { Button, Dialog, DialogContent, DialogHeader, ErrorLabel, HStack, Input, InputError, Label, LoadingLabel, VStack, useToast } from "../../../components/ui"
import { useAllHolidaysOfSchoolWithoutPaginationQuery, useCreateHolidayMutation, useMeQuery, useSchoolWiseAttendanceSlotsOfSchoolQuery } from "../../../graphql"

type CreateHolidayFormValues = {
	name: string
	slotIds: string[]
}

const schema = z.object({
	name: z.string().nonempty({ message: "name is required" }),
	slotIds: z.array(z.string()).default([]),
})

export const Holidays: React.FC = () => {
	const [{ fetching, data }] = useAllHolidaysOfSchoolWithoutPaginationQuery()

	const [{ data: meData }] = useMeQuery()

	const [{ data: schoolWiseSlots, error: schoolWiseSlotsError, fetching: schoolWiseSlotsFetching }] = useSchoolWiseAttendanceSlotsOfSchoolQuery({
		variables: { schoolId: meData?.me?.school?._id || "" },
		pause: !meData?.me?.school?._id,
	})

	const [selectedSlotIds, setSelectedSlotIds] = useState<string[]>([])

	const { toast } = useToast()

	const {
		register,
		handleSubmit,
		setValue,
		formState: { isLoading, errors, isSubmitting },
	} = useForm<CreateHolidayFormValues>({ resolver: zodResolver(schema) as any })

	const [dialogOpen, setDialogOpen] = useState<boolean>(false)

	const [, createNewHoliday] = useCreateHolidayMutation()

	const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)

	const [eventClickInfo, setEventClickInfo] = useState<EventClickArg>()

	const [dateSelectInfo, setDateSelectInfo] = useState<DateSelectArg>()

	const handleDateSelect = async (selectInfo: DateSelectArg) => {
		const calendarApi = selectInfo.view.calendar
		calendarApi.unselect() // clear date selection

		setDateSelectInfo(selectInfo)
		// state to open the create dailog box
		setDialogOpen(true)
	}

	const handleEventClick = async (clickInfo: EventClickArg) => {
		setEventClickInfo(clickInfo)
		// state to open edit dailog box
		setEditDialogOpen(true)
	}

	// fuction to close create holiday dialog box
	const createHolidayDailogCloseHandler = () => {
		setValue("name", "")
		setValue("slotIds", [])
		setDialogOpen(false)
		setSelectedSlotIds([])
	}

	const handleSlotChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const slotId = event.target.value
		const isChecked = event.target.checked
		// if the parent meal slot is selected
		if (isChecked) {
			// get the child slot of the parent slot
			const childSlotIds = schoolWiseSlots?.schoolWiseAttendanceSlotsOfSchool
				.filter((slot) => String(slot._id) === slotId)
				.map((slot) => slot.childSlotIds)
				.flat()

			// // Enable child slots based on the parent slot's checked state
			if (childSlotIds && childSlotIds.length) {
				childSlotIds.forEach((childSlotId) => {
					const childSlotCheckbox = document.getElementById(childSlotId!) as HTMLInputElement
					if (childSlotCheckbox) {
						childSlotCheckbox.checked = isChecked
					}
				})
			}

			// Update slotIds in the form
			setSelectedSlotIds((prev) => [...prev, ...(childSlotIds as string[]), slotId])
			setValue("slotIds", [...selectedSlotIds])
			// setValue("slotIds", [...(getValues("slotIds") as string[]), ...(childSlotIds as string[]), slotId])
		} else {
			// Remove the parent slot and all its child slots from slotIds
			// Filter the selectedSlotIds array
			const updatedSelectedSlotIds = selectedSlotIds.filter(
				(id: string) =>
					id !== slotId &&
					!schoolWiseSlots?.schoolWiseAttendanceSlotsOfSchool
						.filter((slot) => String(slot._id) === slotId)
						.map((slot) => slot.childSlotIds)
						.flat()
						.includes(id)
			)

			// Update the selectedSlotIds state with the filtered array
			setSelectedSlotIds(updatedSelectedSlotIds)
			setValue("slotIds", [...updatedSelectedSlotIds])

			// get the child slot of the parent slot
			const childSlotIds = schoolWiseSlots?.schoolWiseAttendanceSlotsOfSchool
				.filter((slot) => String(slot._id) === slotId)
				.map((slot) => slot.childSlotIds)
				.flat()

			// disable child slots based on the parent slot's unchecked state
			if (childSlotIds && childSlotIds.length) {
				childSlotIds.forEach((childSlotId) => {
					const childSlotCheckbox = document.getElementById(childSlotId!) as HTMLInputElement
					if (childSlotCheckbox) {
						childSlotCheckbox.checked = false
					}
				})
			}
		}
	}

	const onSubmit: SubmitHandler<CreateHolidayFormValues> = async (values) => {
		// Check if the selected start date is after the current date
		if (dateSelectInfo && dateSelectInfo.start && new Date(dateSelectInfo.start) <= new Date()) {
			toast({
				variant: "destructive",
				title: "Invalid Date",
				description: "Please select a future date.",
			})
			// close the dialog
			createHolidayDailogCloseHandler()
			return
		}

		const startDate = dateSelectInfo!.startStr
		const endDate = dateSelectInfo!.endStr

		// Parse the IST date string to a Date object
		const parsedStartDate = parseISO(startDate)
		const parsedEndDate = parseISO(endDate)
		parsedEndDate.setMinutes(parsedEndDate.getMinutes() - 1)

		// Convert the parsed date to its equivalent UTC Date object
		const utcStartDate = new Date(parsedStartDate.toUTCString())
		const utcEndDate = new Date(parsedEndDate.toUTCString())

		// Check if the selected date range overlaps with existing holidays
		const isOverlapping = data?.allHolidaysOfSchoolWithoutPagination.some((holiday) => {
			const holidayStartDate = new Date(holiday.startDate)
			const holidayEndDate = new Date(holiday.endDate)
			return (
				(utcStartDate >= holidayStartDate && utcStartDate <= holidayEndDate) ||
				(utcEndDate >= holidayStartDate && utcEndDate <= holidayEndDate) ||
				(holidayStartDate >= utcStartDate && holidayStartDate <= utcEndDate) ||
				(holidayEndDate >= utcStartDate && holidayEndDate <= utcEndDate)
			)
		})

		if (isOverlapping) {
			toast({
				variant: "destructive",
				title: "Holiday Creation Failed",
				description: "The selected date range overlaps with an existing holiday.",
			})
			// close the dialog
			createHolidayDailogCloseHandler()
			return
		}

		const { data: createData, error } = await createNewHoliday({
			input: {
				...values,
				slotIds: [...new Set(selectedSlotIds)],
				startDate: utcStartDate,
				endDate: utcEndDate,
			},
		})

		if (error) {
			toast({
				variant: "destructive",
				title: "Holiday Creation Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})

			// close the dialog
			createHolidayDailogCloseHandler()
			return
		}

		if (createData?.createHoliday) {
			toast({
				variant: "success",
				title: "Holiday Created Successfully",
				description: "",
			})

			// when the form submit Successfully
			// close the dialog
			createHolidayDailogCloseHandler()
			return
		}
	}

	return (
		<div className="container p-2 lg:px-8 min-h-screen">
			<HStack className="w-full">
				<VStack>
					{fetching ? (
						<div className="mx-auto py-4">
							<LoadingLabel />
						</div>
					) : data?.allHolidaysOfSchoolWithoutPagination ? (
						<VStack>
							{eventClickInfo && editDialogOpen && <EditHoliday holidayId={eventClickInfo.event._def.publicId} editDialogOpen={editDialogOpen} setEditDialogOpen={setEditDialogOpen} />}
							<FullCalendar
								headerToolbar={{ left: "prev,next,today", center: "title", right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek" }}
								initialView="dayGridMonth"
								plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
								height="auto"
								events={data.allHolidaysOfSchoolWithoutPagination.map(({ name, startDate, endDate, _id, slotIds }) => {
									return {
										title: name,
										start: new Date(startDate),
										end: new Date(endDate),
										id: _id,
										backgroundColor: "#7c3aed",
										borderColor: "#7c3aed",
										extendedProps: {
											mealSlots: schoolWiseSlots?.schoolWiseAttendanceSlotsOfSchool.filter((slot) => slotIds.includes(slot._id)).map((slot) => slot.attendanceSlot.name) || [],
										},
									}
								})}
								eventContent={renderEventContent}
								editable={false}
								selectable={true}
								selectMirror={true}
								dayMaxEvents={true}
								select={handleDateSelect}
								eventClick={handleEventClick}
							/>

							<Dialog open={dialogOpen} onOpenChange={createHolidayDailogCloseHandler}>
								<DialogContent>
									<DialogHeader className="text-md font-semibold">Create Holiday</DialogHeader>

									<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-6">
										<div>
											<Input type="text" placeholder="Enter name of Holiday" className="max-w-md bg-white rounded-xl py-1" {...register("name")} />
											<InputError>{errors.name?.message}</InputError>
										</div>

										{schoolWiseSlotsFetching ? (
											<LoadingLabel className="flex-1">Loading NGO partners</LoadingLabel>
										) : schoolWiseSlotsError ? (
											<ErrorLabel className="flex-1">{schoolWiseSlotsError.message.replace("[GraphQL] ", "")}</ErrorLabel>
										) : !schoolWiseSlots?.schoolWiseAttendanceSlotsOfSchool.length ? (
											<ErrorLabel className="flex-1">Couldn&apos;t fetch NGO meal slots</ErrorLabel>
										) : (
											<>
												<div>
													<div className="flex flex-col gap-2">
														<Label className="text-sm font-medium text-gray-500">Select exception meal provided during the holiday</Label>
														<div className="flex gap-x-4 flex-wrap  justify-start align-middle">
															{schoolWiseSlots.schoolWiseAttendanceSlotsOfSchool
																.filter((s) => !s.isDeleted)
																.map((slot) => (
																	<div key={slot._id}>
																		<input
																			type="checkbox"
																			className={`${slot.parentSlotId ? "hover:cursor-not-allowed" : "hover:cursor-pointer"}`}
																			id={slot._id}
																			disabled={slot.parentSlotId ? true : false}
																			value={slot._id}
																			onChange={handleSlotChange}
																		/>
																		<Label htmlFor={slot._id} className={`ml-2 ${slot.parentSlotId ? "hover:cursor-not-allowed" : "hover:cursor-pointer"}`}>
																			{slot.attendanceSlot.name}
																		</Label>
																	</div>
																))}
														</div>
													</div>
													<InputError>{errors.slotIds?.message}</InputError>
													<div className="text-xs font-medium mt-2 mb-2 text-gray-500">Note*: Only meal slots with tapping required can be checked or unchecked and its child slot is automatically selected</div>
												</div>
											</>
										)}
										<div className="w-full flex gap-x-4 justify-end">
											<Button type="submit" isLoading={isLoading || isSubmitting || fetching} className="hover:cursor-pointer hover:opacity-80 hover:bg-green-600 w-32">
												Create
											</Button>
										</div>
									</form>
									<div className="absolute right-4 top-4 z-50 hover:cursor-pointer ">
										<Cross2Icon onClick={createHolidayDailogCloseHandler} />
									</div>
								</DialogContent>
							</Dialog>
						</VStack>
					) : (
						<div className="w-full py-4 mx-auto">
							<h2 className="text-md font-semibold text-gray-400">Couldn&apos;t find any holidays.</h2>
						</div>
					)}
				</VStack>
			</HStack>
		</div>
	)
}

export default Holidays

function renderEventContent(eventInfo: any) {
	const { extendedProps } = eventInfo.event
	const mealSlots = extendedProps.mealSlots || [] // assuming mealSlots is available in extendedProps

	return (
		<>
			<div className="p-2 bg-violet-600 w-full rounded-sm">
				<b className="text-white">{eventInfo.event.title}</b>
				<div className="flex flex-col flex-wrap">
					{mealSlots.map((slot: any) => (
						<div className="flex items-center gap-0.5" key={slot}>
							<p className="w-2 h-2 rounded-full bg-white"></p>
							<p className="text-xs text-white">{slot}</p>
						</div>
					))}
				</div>
			</div>
		</>
	)
}
