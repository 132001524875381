import { Button, Popover, PopoverContent, PopoverTrigger } from "../../../components/ui"
import React from "react"
import { ExtendedStudentFragment } from "../../../graphql"
import EditBeneficiaryClassForm from "../../../forms/classes/EditBeneficiaryClassForm"

export type EditBeneficiaryClassProps = { student: ExtendedStudentFragment }

export const EditBeneficiaryClass: React.FC<EditBeneficiaryClassProps> = ({ student }) => {
	return (
		<Popover>
			<PopoverTrigger className="w-full flex justify-end relative">
				<Button className="bg-blue-700 text-white font-medium absolute">Update</Button>
			</PopoverTrigger>
			<PopoverContent>
				<EditBeneficiaryClassForm beneficiary={student} />
			</PopoverContent>
		</Popover>
	)
}
