import { VStack } from "../../../components"
import React from "react"

import { ExtendedStudentFragment } from "../../../graphql"
import UploadBMIOfStudentForm from "../../../forms/UploadBMIOfStudentForm"

export type UploadBMIOfStudentProps = {
	beneficiary: ExtendedStudentFragment
}

const UploadBMIOfStudent: React.FC<UploadBMIOfStudentProps> = ({ beneficiary }) => {
	return (
		<VStack className="w-full mx-auto">
			<UploadBMIOfStudentForm beneficiary={beneficiary} />
		</VStack>
	)
}

export default UploadBMIOfStudent
