import React, { useState } from "react"
import { useAllSchoolAttendanceSlotsQuery, useAllSchoolsWithoutPaginationQuery } from "../../../graphql"
import { Card, CardContent, CardHeader, ErrorLabel, LoadingLabel, NGOPartnerTag } from "../../../components"
import { useNavigate } from "react-router-dom"

import Select from "react-select"

export const MealSlotsPage: React.FC = () => {
	const [schoolId, setSchoolId] = useState<string | null>()

	const [{ data, fetching, error }] = useAllSchoolAttendanceSlotsQuery({ variables: { schoolId: schoolId } })

	const [{ data: ngoPartnersData, fetching: ngoPartnersFetching, error: ngoPartnersError }] = useAllSchoolsWithoutPaginationQuery()

	const navigate = useNavigate()

	const handleSchoolChange = (selectedOption: { label: string; value: string } | null) => {
		if (selectedOption) {
			setSchoolId(selectedOption.value)
		} else {
			setSchoolId(null)
		}
	}

	return (
		<div className="container w-full min-h-screen">
			<h2 className="text-gray-700 text-lg font-semibold mt-4 mb-4">Meals slots</h2>

			{fetching ? (
				<div className=" flex gap-1 text-gray-500 ">
					<LoadingLabel />
					<h2>Loading meal slots</h2>
				</div>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.allSchoolAttendanceSlots && data.allSchoolAttendanceSlots.length ? (
				<>
					<div className="flex justify-end mb-4">
						{ngoPartnersFetching ? (
							<LoadingLabel className="flex-1">Loading NGO partners</LoadingLabel>
						) : ngoPartnersError ? (
							<ErrorLabel className="flex-1">{ngoPartnersError.message.replace("[GraphQL] ", "")}</ErrorLabel>
						) : !ngoPartnersData?.allSchoolsWithoutPagination.length ? (
							<ErrorLabel className="flex-1">Couldn&apos;t fetch NGO partners</ErrorLabel>
						) : (
							<div className="w-80">
								<Select
									name="NGO Partners"
									placeholder="Select NGO partners"
									options={ngoPartnersData.allSchoolsWithoutPagination.map((s) => ({ label: s.name, value: s._id }))}
									onChange={handleSchoolChange}
									value={ngoPartnersData.allSchoolsWithoutPagination
										.map((school) => ({
											label: school.name,
											value: school._id,
										}))
										.find((option) => option.value === schoolId)}
								/>
							</div>
						)}
					</div>
					<div className="flex flex-col gap-4">
						{data.allSchoolAttendanceSlots.map((slot) => {
							return (
								<Card key={slot._id} onClick={() => navigate(`/mealSlots/${slot._id}`)} className="hover:cursor-pointer hover:shadow-lg max-w-2xl">
									<CardHeader>
										<NGOPartnerTag ngoPartner={slot.school} />
										<h2 className="text-lg font-medium text-gray-900">
											{slot.school.name} - {slot?.attendanceSlot.name}
										</h2>
									</CardHeader>

									<CardContent>
										<div className="flex gap-4 md:gap-6 flex-wrap">
											<div className="flex flex-col gap-2 items-center">
												<p className="text-sm font-medium text-gray-600">{slot.attendanceSlot.name}</p>
												<p className="text-sm font-normal text-gray-400">Meal Name</p>
											</div>

											<div className="flex flex-col gap-2 items-center">
												<p className="text-sm font-medium text-gray-600">{slot.startAt}</p>
												<p className="text-sm font-normal text-gray-400">Start Time</p>
											</div>

											<div className="flex flex-col gap-2 items-center">
												<p className="text-sm font-medium text-gray-600">{slot.endAt}</p>
												<p className="text-sm font-normal text-gray-400">End Time</p>
											</div>
											<div className="flex flex-col gap-2 items-center">
												<p className="text-sm font-medium text-orange-600">{slot.studentIds.length}</p>
												<p className="text-sm font-normal text-gray-400">Total Beneficaries</p>
											</div>

											<div className="flex flex-col gap-2 items-center">
												{slot.isTappingRequired ? <p className="text-sm font-medium text-green-500">Required</p> : <p className="text-sm font-medium text-red-500"> Not Required</p>}
												<p className="text-sm font-normal text-gray-400">Tapping</p>
											</div>
											{slot.parentSlotId && (
												<div className="flex flex-col gap-2 items-center">
													{data && data.allSchoolAttendanceSlots && (
														<p className="text-sm font-medium text-orange-600">
															{data.allSchoolAttendanceSlots.filter((parentSlot) => parentSlot._id === slot.parentSlotId).map((parentSlot) => parentSlot.attendanceSlot.name)}
														</p>
													)}
													<p className="text-sm font-normal text-gray-400">Parent Slot</p>
												</div>
											)}
										</div>
									</CardContent>
								</Card>
							)
						})}
					</div>
				</>
			) : (
				<ErrorLabel>Couldn&apos;t find any Meals slots</ErrorLabel>
			)}
		</div>
	)
}
