import React from "react"
import AssignMealSlotForm from "../../../forms/admin/meal/AssignMealSlotForm"

export const AssignMealSlot: React.FC = () => {
	return (
		<div className="md:px-10 md:py-4 ">
			<h1 className="text-gray-700 text-lg font-bold">Assign Meal Slot</h1>
			<div className=" mt-2 md:mt-10">
				<AssignMealSlotForm />
			</div>
		</div>
	)
}
