import React from "react"
import { Link, Outlet } from "react-router-dom"
import { ValidTillBanner } from "../../common/ValidTillBanner"
import { HStack, Separator } from "../../ui"
import { AccountMenu } from "./AccountMenu"
import { BottomTabs } from "./BottomTabs"
import { TopNavigation } from "./TopNavigation"

export const Scaffold: React.FC = () => {
	return (
		<div className="min-h-[100dvh] pb-24">
			<div className="sticky top-0 py-2  backdrop-filter backdrop-blur-md z-10">
				<div className="container flex flex-row items-center justify-between">
					<HStack className="gap-6">
						<Link to="/">
							<div className="w-24 h-6 bg-[url('assets/feedingindia-192.png')] bg-contain bg-no-repeat" />
						</Link>

						<TopNavigation />
					</HStack>
					<AccountMenu />
				</div>
			</div>
			<Separator />
			<div className="w-full h-full">
				<ValidTillBanner />
				<Outlet />
			</div>
			<BottomTabs />
		</div>
	)
}

export default Scaffold
