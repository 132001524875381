import * as React from "react"
import * as ProgressPrimitive from "@radix-ui/react-progress"
import { cn } from "../../lib/utils"

const Progress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & { indicator?: ProgressPrimitive.ProgressIndicatorProps }>(
	({ className, value, indicator, ...props }, ref) => (
		<ProgressPrimitive.Root ref={ref} className={cn("relative h-2 w-full overflow-hidden rounded-full bg-primary/20", className)} {...props}>
			<ProgressPrimitive.Indicator {...indicator} className={cn("h-full w-full flex-1 bg-primary transition-all", indicator?.className)} style={{ transform: `translateX(-${100 - (value || 0)}%)` }} />
		</ProgressPrimitive.Root>
	)
)
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
