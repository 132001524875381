import { startCase } from "lodash"
import React, { useState, useEffect } from "react"
import Select, { SingleValue } from "react-select"
import { Input, VStack } from "../../../components"

import { BmiCategory, useMeQuery, useAllSchoolsWithoutPaginationQuery } from "../../../graphql"
import { useSearchParams } from "../../../hooks"

import { ErrorLabel, LoadingLabel } from "../../../components/ui"
import { SearchBeneficiariesBmi } from "../../../components/common/BMI/SearchBeneficiaryBMi"

export const BeneficiaryBmiPage: React.FC = () => {
	const [searchParams, { handleSearchParamUpdate, handleSearchParamDelete }] = useSearchParams()

	const [{ data: meData }] = useMeQuery()

	const [ngoPartnersQuery] = useAllSchoolsWithoutPaginationQuery()

	const [selectedNGOPartner, setSelectedNGOPartner] = useState<SingleValue<{ label: string; value: string }>>({ label: "", value: "" })

	useEffect(() => {
		if (selectedNGOPartner?.value !== searchParams.get("ngo-partner")) {
			const ngoPartner = ngoPartnersQuery.data?.allSchoolsWithoutPagination.find((s) => s._id === searchParams.get("ngo-partner"))

			if (ngoPartner) setSelectedNGOPartner({ label: ngoPartner.name, value: ngoPartner._id })
		}
	}, [searchParams.get("ngo-partner")])

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2 lg:gap-4">
			<VStack className="lg:flex-row items-stretch lg:items-center">
				<h1 className="w-full text-sm lg:text-md font-medium">Beneficiaries</h1>
				<div className="w-full grid lg:grid-cols-3 gap-2">
					<Input
						type="search"
						placeholder="Search..."
						value={(searchParams.get("keyword") as string) || ""}
						onChange={(e) => {
							if (e.target.value) handleSearchParamUpdate("keyword", e.target.value)
							else handleSearchParamDelete("keyword")
						}}
					/>
					{meData?.me?.school ? (
						<></>
					) : ngoPartnersQuery.fetching ? (
						<LoadingLabel className="flex-1">Loading NGO Partners</LoadingLabel>
					) : ngoPartnersQuery.error ? (
						<ErrorLabel className="flex-1">{ngoPartnersQuery.error.message.replace("[GraphQL] ", "")}</ErrorLabel>
					) : !ngoPartnersQuery.data?.allSchoolsWithoutPagination.length ? (
						<ErrorLabel className="flex-1">Couldn&apos;t fetch NGO Partners</ErrorLabel>
					) : (
						<Select
							name="NGO partner"
							placeholder="Select NGO partner"
							options={ngoPartnersQuery.data.allSchoolsWithoutPagination.map((s) => ({ label: s.name, value: s._id }))}
							value={selectedNGOPartner?.value ? selectedNGOPartner : undefined}
							onChange={(o) => {
								if (o?.value) handleSearchParamUpdate("ngo-partner", o.value)
								else handleSearchParamDelete("ngo-partner")
							}}
							className="basic-single w-full"
						/>
					)}
					<Select
						name="category"
						placeholder="Select Category"
						options={Object.values(BmiCategory).map((m) => ({ label: startCase(m), value: m }))}
						value={(searchParams.get("category") as BmiCategory) ? { label: startCase(searchParams.get("category") as string), value: searchParams.get("category") as BmiCategory } : undefined}
						onChange={(m) => {
							if (m?.value) handleSearchParamUpdate("category", m.value)
							else handleSearchParamDelete("category")
						}}
						className="basic-single w-full max-w-36"
					/>
				</div>
			</VStack>
			<SearchBeneficiariesBmi keyword={(searchParams.get("keyword") as string) || ""} schoolId={(searchParams.get("ngo-partner") as string) || undefined} category={(searchParams.get("category") as BmiCategory) || undefined} />
		</div>
	)
}

export default BeneficiaryBmiPage
