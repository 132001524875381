import { zodResolver } from "@hookform/resolvers/zod"
import { startOfDay } from "date-fns"
import format from "date-fns/format"
import { CalendarIcon } from "lucide-react"
import React from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { Button, Calendar, Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, Input, Popover, PopoverContent, PopoverTrigger, Textarea, useToast, VStack } from "../../../../../components"
import { useGenerateStudentsAttendanceReportMutation } from "../../../../../graphql"
import { cn } from "../../../../../lib/utils"

type BeneficiariesAttendanceReportFormValues = { label: { name: string; description?: string }; dateRange: [Date | undefined, Date | undefined] }

const schema = z.object({
	label: z.object({ name: z.string().nonempty({ message: "Name is required" }), description: z.string() }),
	dateRange: z.array(z.date()).length(2, { message: "Date range is required" }),
})

export const BeneficiariesAttendanceNewReportPage: React.FC = () => {
	const form = useForm<BeneficiariesAttendanceReportFormValues>({ resolver: zodResolver(schema) as any, defaultValues: { label: { name: "", description: "" }, dateRange: [startOfDay(new Date()), startOfDay(new Date())] } })

	const [, generate] = useGenerateStudentsAttendanceReportMutation()

	const { toast } = useToast()

	const navigate = useNavigate()

	const onSubmit = async (values: BeneficiariesAttendanceReportFormValues) => {
		const { data, error } = await generate({ input: { ...values, schoolIds: [] } })

		if (error) {
			toast({
				title: "Error",
				description: error.message,
				variant: "destructive",
			})
		}

		if (data?.generateStudentsAttendanceReport) {
			navigate(`/attendance/reports/beneficiaries/${data?.generateStudentsAttendanceReport._id}`)
		}
	}

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2 lg:gap-4">
			<VStack className="lg:flex-row items-stretch lg:items-center">
				<h1 className="w-full text-sm lg:text-md font-medium">Beneficiaries Attendance Report</h1>
			</VStack>

			{/* @ts-ignore */}
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-xl flex flex-col gap-2 lg:gap-4">
					<FormField
						control={form.control}
						name="label.name"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Name</FormLabel>
								<FormControl>
									<Input {...field} />
								</FormControl>
								<FormDescription>This is to reference this report in future.</FormDescription>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="label.description"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Description</FormLabel>
								<FormControl>
									<Textarea {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="dateRange"
						render={({ field }) => (
							<FormItem className="flex flex-col gap-2">
								<FormLabel>Date Range</FormLabel>
								<FormControl>
									<Popover>
										<PopoverTrigger asChild>
											<Button id="date" variant="outline" className={cn("flex-1/2 justify-start text-left font-normal", !(field.value[0] && field.value[1]) && "text-muted-foreground")}>
												<CalendarIcon className="mr-2 h-4 w-4" />
												{field.value[0] && field.value[1] ? (
													<>
														{format(field.value[0], "MMM dd, yyyy")} - {format(field.value[1], "MMM dd, yyyy")}
													</>
												) : (
													<span>Pick a date</span>
												)}
											</Button>
										</PopoverTrigger>
										<PopoverContent className="w-auto p-0" align="start">
											<Calendar
												initialFocus
												mode="range"
												defaultMonth={field.value[0]}
												selected={{ from: field.value[0], to: field.value[1] }}
												onSelect={(range) => {
													field.onChange([range?.from, range?.to])
												}}
												toDate={new Date()}
												numberOfMonths={1}
											/>
										</PopoverContent>
									</Popover>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<Button className="w-full lg:max-w-sm lg:self-end" isLoading={form.formState.isLoading || form.formState.isSubmitting}>
						Generate
					</Button>
				</form>
			</Form>
		</div>
	)
}

export default BeneficiariesAttendanceNewReportPage
