import React from "react"
import { Link } from "react-router-dom"
import colors from "tailwindcss/colors"
import { SchoolFragment } from "../../../graphql"
import { NGOPartners } from "../../../icons/NGOPartners"
import { NGOPartnerTag } from "../../common/NGOPartnerTag"
import { Card, CardContent, CardHeader, CardTitle } from "../../ui"

export type NGOPartnerListItemProps = {
	ngoPartner: SchoolFragment
}

export const NGOPartnerListItem: React.FC<NGOPartnerListItemProps> = ({ ngoPartner }) => {
	return (
		<Link to={`/ngo-partners/${ngoPartner._id}`}>
			<Card className="hover:shadow-purple-500">
				<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
					<CardTitle className="text-sm font-medium text-slate-500">Based in {ngoPartner.region} region</CardTitle>
					<NGOPartners fill={colors.purple[500]} className="lg:scale-125" />
				</CardHeader>
				<CardContent>
					<NGOPartnerTag ngoPartner={ngoPartner} />
				</CardContent>
			</Card>
		</Link>
	)
}
