import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { ReportFragment } from "../../../graphql"
import { VStack } from "../../ui"
import { ReportListItem } from "./ReportListItem"

export type ReportsListProps = {
	reports: ReportFragment[]
	loadMore: () => void
	linkPrefix: string
}

export const ReportsList: React.FC<ReportsListProps> = ({ reports, loadMore, linkPrefix }) => {
	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack className="items-stretch">
			{reports.map((report) => (
				<ReportListItem key={report._id} report={report} linkTo={linkPrefix + report._id} />
			))}
			<div ref={bottomRef as any} className="w-full h-4 pb-4" />
		</VStack>
	)
}
