/* eslint-disable react/react-in-jsx-scope */

/* eslint-disable react/react-in-jsx-scope */

import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import twcolors from "tailwindcss/colors"
import { useSchoolWiseProgressInStudentEnrollmentQuery } from "../../../../graphql"
import { EmptyChart } from "../../../common"
import { AspectRatio, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, VStack } from "../../../ui"

const colors = [twcolors.red[400], twcolors.purple[400], twcolors.teal[400], twcolors.orange[400], twcolors.slate[400], twcolors.gray[400]]
const darkColors = [twcolors.red[600], twcolors.purple[600], twcolors.teal[600], twcolors.orange[600]]

export const BeneficiariesProgressChart: React.FC = () => {
	const [{ data, fetching }] = useSchoolWiseProgressInStudentEnrollmentQuery()

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Progress in beneficiaries enrollment</CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="gap-4 select-none">
					<AspectRatio ratio={16 / 9}>
						<ResponsiveContainer width="100%" height="100%">
							{fetching ? (
								<ChartSkeleton />
							) : !data?.schoolWiseProgressInStudentEnrollment.length ? (
								<EmptyChart />
							) : (
								<BarChart
									width={500}
									height={400}
									data={data.schoolWiseProgressInStudentEnrollment.map((d) => ({ label: d.label, Active: d.active, Inactive: d.inActive }))}
									margin={{
										top: 20,
										bottom: 5,
									}}
								>
									<XAxis dataKey="label" />
									<YAxis />
									<Tooltip />

									<Bar
										dataKey="Active"
										fillOpacity={0.7}
										label={{
											position: "top",
										}}
									>
										{data.schoolWiseProgressInStudentEnrollment.map((_, index) => (
											<Cell key={`cell-${index}`} fill={darkColors[index]} className="cursor-pointer" />
										))}
									</Bar>
									<Bar
										dataKey="Inactive"
										fillOpacity={0.7}
										label={{
											position: "top",
										}}
									>
										{data.schoolWiseProgressInStudentEnrollment.map((_, index) => (
											<Cell key={`cell-${index}`} fill={colors[index]} className="cursor-pointer" />
										))}
									</Bar>
								</BarChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}
