import { differenceInDays, format, startOfDay, subMonths } from "date-fns"
import { CalendarIcon } from "lucide-react"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useInViewport } from "react-in-viewport"
import { useLocation, useParams } from "react-router-dom"
import { ResponsiveContainer } from "recharts"
import {
	AspectRatio,
	BeneficiaryTag,
	Button,
	Calendar,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	ChartSkeleton,
	EmptyChart,
	ErrorLabel,
	HStack,
	NGOPartnerAttendanceStatsChart,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
	VStack,
} from "../../../../components"

import { PartialStudentFragment, SchoolWiseAttendanceStatsQueryVariables, useSchoolWiseAttendanceStatsQuery } from "../../../../graphql"
import { usePagination } from "../../../../hooks"
import { cn } from "../../../../lib/utils"
import { roundDecimalNumber } from "../../../../utils"
import { NGOPartnerCriticalityWiseAttendanceStatsChart } from "../../../../components/admin"

export const NGOPartnerAttendanceOverviewPage: React.FC = () => {
	const { ngoPartnerId } = useParams<{ ngoPartnerId: string }>()

	const { state } = useLocation()

	const [filter, setFilter] = useState<SchoolWiseAttendanceStatsQueryVariables["filter"]>({ sinceDate: startOfDay(subMonths(new Date(), 1)), untilDate: startOfDay(new Date()), ...state?.filter, schoolIds: [ngoPartnerId] })

	const [{ data, fetching, error }] = useSchoolWiseAttendanceStatsQuery({ variables: { filter }, pause: !ngoPartnerId || !filter.sinceDate || !filter.untilDate })

	const ngoPartnersData = useMemo(() => data?.schoolWiseAttendanceStats?.[0], [data])

	const { criticalBeneficiaries, atRiskBeneficiaries, wellBeneficiaries } = useMemo(() => {
		const criticalBeneficiaries: { beneficiary: PartialStudentFragment; percent: number }[] = []
		const atRiskBeneficiaries: { beneficiary: PartialStudentFragment; percent: number }[] = []
		const wellBeneficiaries: { beneficiary: PartialStudentFragment; percent: number }[] = []

		const beneficiaries: { beneficiary: PartialStudentFragment; total: number; present: number; percent: number }[] = []

		ngoPartnersData?.stats.forEach((dayStat) => {
			dayStat.totalStudents.forEach((beneficiary) => {
				const isPresent = !!dayStat.presentStudents.find((s) => s._id === beneficiary._id)

				const index = beneficiaries.findIndex((s) => s.beneficiary._id === beneficiary._id)

				if (index >= 0) {
					beneficiaries[index].total++
					beneficiaries[index].present = beneficiaries[index].present + (isPresent ? 1 : 0)
					beneficiaries[index].percent = (beneficiaries[index].present / beneficiaries[index].total) * 100
				} else {
					beneficiaries.push({
						beneficiary,
						total: 1,
						present: isPresent ? 1 : 0,
						percent: isPresent ? 100 : 0,
					})
				}
			})
		})

		beneficiaries.map(({ percent, beneficiary }) => {
			if (percent >= 80) {
				wellBeneficiaries.push({ percent, beneficiary })
			} else if (percent >= 50) {
				atRiskBeneficiaries.push({ percent, beneficiary })
			} else {
				criticalBeneficiaries.push({ percent, beneficiary })
			}
		})

		return { criticalBeneficiaries, atRiskBeneficiaries, wellBeneficiaries }
	}, [ngoPartnersData])

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2">
			<VStack className="w-full lg:flex-row items-stretch">
				<h1 className="w-full text-sm lg:text-md font-medium">{ngoPartnersData?.school.name || "NGO Partner attendance overview"}</h1>

				<Popover>
					<PopoverTrigger asChild>
						<Button id="date" variant="outline" className={cn("w-full lg:max-w-xs justify-start text-left font-normal", !(filter.sinceDate && filter.untilDate) && "text-muted-foreground")}>
							<CalendarIcon className="mr-2 h-4 w-4" />
							{filter.sinceDate ? (
								filter.untilDate ? (
									<>
										{format(filter.sinceDate, "MMM dd, yyyy")} - {format(filter.untilDate, "MMM dd, yyyy")}
									</>
								) : (
									format(filter.sinceDate, "MMM dd, yyyy")
								)
							) : (
								<span>Pick a date</span>
							)}
						</Button>
					</PopoverTrigger>
					<PopoverContent className="w-auto p-0" align="start">
						<Calendar
							initialFocus
							mode="range"
							defaultMonth={filter.sinceDate}
							selected={{ from: filter.sinceDate, to: filter.untilDate }}
							onSelect={(date) => setFilter((prev) => ({ ...prev, sinceDate: date?.from || date?.to, untilDate: date?.to || date?.from }))}
							toDate={new Date()}
							numberOfMonths={differenceInDays(filter.untilDate, filter.sinceDate) > 30 ? 2 : 1}
						/>
					</PopoverContent>
				</Popover>
			</VStack>
			<div className="w-full h-full grid lg:grid-cols-2 gap-2 lg:gap-4">
				<Card>
					<CardHeader>
						<CardTitle className="text-sm font-medium text-slate-500">Daily Attendance</CardTitle>
					</CardHeader>
					<CardContent>
						<AspectRatio ratio={16 / 9}>
							<ResponsiveContainer width="100%" height="100%">
								{fetching ? <ChartSkeleton /> : error ? <ErrorLabel>{error.message}</ErrorLabel> : !ngoPartnersData ? <EmptyChart /> : <NGOPartnerAttendanceStatsChart data={ngoPartnersData} />}
							</ResponsiveContainer>
						</AspectRatio>
					</CardContent>
				</Card>
				<Card>
					<CardHeader>
						<CardTitle className="text-sm font-medium text-slate-500">Attendance Overview for beneficiaries</CardTitle>
					</CardHeader>
					<CardContent>
						<VStack className="gap-4 select-none">
							<div className="w-full h-full min-h-[200px]">
								{fetching ? (
									<AspectRatio ratio={16 / 9}>
										<ResponsiveContainer width="100%" height="100%">
											<ChartSkeleton />
										</ResponsiveContainer>
									</AspectRatio>
								) : error ? (
									<ErrorLabel>{error.message}</ErrorLabel>
								) : !ngoPartnersData ? (
									<AspectRatio ratio={16 / 9}>
										<ResponsiveContainer width="100%" height="100%">
											<EmptyChart />
										</ResponsiveContainer>
									</AspectRatio>
								) : (
									<NGOPartnerCriticalityWiseAttendanceStatsChart data={ngoPartnersData} />
								)}
							</div>
						</VStack>
					</CardContent>
				</Card>
				<Card>
					<CardHeader>
						<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Criticality-wise distribution of beneficiaries</CardTitle>
					</CardHeader>
					<CardContent>
						<Tabs defaultValue="critical">
							<TabsList className="w-full">
								<TabsTrigger value="critical" className="w-full">
									Critical
								</TabsTrigger>
								<TabsTrigger value="at-risk" className="w-full">
									At-risk
								</TabsTrigger>
								<TabsTrigger value="well" className="w-full">
									Well
								</TabsTrigger>
							</TabsList>
							<TabsContent value="critical" className="min-h-[100px]">
								{criticalBeneficiaries.length ? <BeneficiariesList beneficiaries={criticalBeneficiaries} type="critical" /> : <ErrorLabel>No beneficiaries found in this category</ErrorLabel>}
							</TabsContent>
							<TabsContent value="at-risk" className="min-h-[100px]">
								{atRiskBeneficiaries.length ? <BeneficiariesList beneficiaries={atRiskBeneficiaries} type="at-risk" /> : <ErrorLabel>No beneficiaries found in this category</ErrorLabel>}
							</TabsContent>
							<TabsContent value="well" className="min-h-[100px]">
								{wellBeneficiaries.length ? <BeneficiariesList beneficiaries={wellBeneficiaries} type="well" /> : <ErrorLabel>No beneficiaries found in this category</ErrorLabel>}
							</TabsContent>
						</Tabs>
					</CardContent>
				</Card>
			</div>
		</div>
	)
}

export default NGOPartnerAttendanceOverviewPage

type BeneficiariesListProps = {
	beneficiaries: {
		beneficiary: PartialStudentFragment
		percent: number
	}[]
	type: "well" | "at-risk" | "critical"
}

const BeneficiariesList: React.FC<BeneficiariesListProps> = ({ beneficiaries, type }) => {
	const { currentItems, loadMore } = usePagination<{
		beneficiary: PartialStudentFragment
		percent: number
	}>(beneficiaries, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack className="w-full items-stretch">
			{currentItems.map((s) => (
				<HStack key={s.beneficiary._id} className={cn("w-full p-4 rounded-xl justify-between", type === "well" ? "bg-green-100" : type === "at-risk" ? "bg-orange-100" : "bg-red-100")}>
					<BeneficiaryTag beneficiary={s.beneficiary} />
					<VStack className="w-24 gap-0">
						<span className="text-sm font-bold text-slate-600">{roundDecimalNumber(s.percent)}%</span>
						<span className="text-xs font-medium text-slate-500">Attendance</span>
					</VStack>
				</HStack>
			))}

			<div ref={bottomRef as any} className="w-full h-4 pb-4" />
		</VStack>
	)
}
