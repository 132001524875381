import React, { PropsWithChildren, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import colors from "tailwindcss/colors"
import { useAllSchoolsCountQuery } from "../../../graphql"
import { NGOPartners } from "../../../icons/NGOPartners"
import { Card, CardContent, CardFooter, CardHeader, CardTitle, ErrorLabel, LoadingLabel, VStack } from "../../ui"
import { ModelWiseNGOPartnersChart } from "../charts"

export const TotalNGOPartners: React.FC<PropsWithChildren> = () => {
	const [{ fetching, data, error }] = useAllSchoolsCountQuery()

	const [isHovered, setIsHovered] = useState(false)

	return (
		<Link to="/ngo-partners/list">
			<Card className="h-full hover:shadow-purple-500" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
				<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
					<CardTitle className="text-sm font-medium text-slate-500">Total NGO Partners</CardTitle>
					<NGOPartners fill={isHovered ? colors.purple[500] : undefined} className="lg:scale-125" />
				</CardHeader>
				<CardContent>
					{fetching ? (
						<LoadingLabel>Loading count</LoadingLabel>
					) : error ? (
						<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
					) : !data?.allSchoolsCount ? (
						<ErrorLabel>Could not load</ErrorLabel>
					) : (
						<div className="text-2xl lg:text-4xl font-bold text-purple-500">{data.allSchoolsCount}</div>
					)}
				</CardContent>
			</Card>
		</Link>
	)
}

export const TotalNGOPartnersWithModelSplit: React.FC = () => {
	const [{ fetching, data, error }] = useAllSchoolsCountQuery()

	const navigate = useNavigate()

	return (
		<Card className="h-full hover:shadow-purple-500 cursor-pointer">
			<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2" onClick={() => navigate("/ngo-partners/list")}>
				<CardTitle className="text-sm font-medium text-slate-500">Total NGO Partners</CardTitle>
				<NGOPartners fill={colors.purple[500]} className="lg:scale-125" />
			</CardHeader>
			<CardContent onClick={() => navigate("/ngo-partners/list")}>
				<VStack className="items-stretch">
					{fetching ? (
						<LoadingLabel>Loading count</LoadingLabel>
					) : error ? (
						<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
					) : !data?.allSchoolsCount ? (
						<ErrorLabel>Could not load</ErrorLabel>
					) : (
						<div className="text-2xl lg:text-4xl font-bold text-purple-500">{data.allSchoolsCount}</div>
					)}
				</VStack>
			</CardContent>
			<CardFooter className="w-full flex flex-col items-stretch">
				<ModelWiseNGOPartnersChart />
			</CardFooter>
		</Card>
	)
}
