import { format } from "date-fns"
import { startCase } from "lodash"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { Card, CardContent, CardHeader, CardTitle, ErrorLabel, Input, NGOPartnerTag, SearchBeneficiariesOfNGOPartner, Skeleton, VStack } from "../../../components"
import { useSchoolByIdQuery } from "../../../graphql"

export const NGOPartnerPage: React.FC = () => {
	const [keyword, setKeyword] = useState("")

	const { ngoPartnerId = "" } = useParams<{ ngoPartnerId: string }>()

	const [{ data, fetching, error }] = useSchoolByIdQuery({ variables: { schoolId: ngoPartnerId }, pause: !ngoPartnerId })

	return (
		<div className="container px-2 lg:px-8 flex flex-col gap-4">
			{fetching ? (
				<VStack className="w-full items-stretch py-2 lg:py-4 gap-2 lg:gap-4">
					<Skeleton className="w-full max-w-sm h-12" />
					<VStack className="w-full items-stretch lg:items-start gap-2 lg:gap-4 lg:flex-row">
						<Skeleton className="w-full max-w-2xl h-56" />
						<Skeleton className="w-full max-w-2xl h-[60dvh]" />
					</VStack>
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : !data?.schoolById ? (
				<ErrorLabel>Cound not find the NGO partner</ErrorLabel>
			) : (
				<VStack className="w-full items-stretch py-2 lg:py-4 gap-2 lg:gap-4">
					<NGOPartnerTag ngoPartner={data.schoolById} />
					<div className="w-full grid lg:grid-cols-2 gap-2 lg:gap-4">
						<Card className="w-full max-w-2xl">
							<CardHeader>
								<CardTitle className="text-xs lg:text-sm font-medium group-hover:font-bold text-slate-500">Profile</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="w-full grid grid-cols-2 md:grid-cols-3 gap-4">
									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">NGO Partner Code</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.schoolById.schoolCode}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Model</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{startCase(data.schoolById.model)}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Email</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.schoolById.email || "Not available"}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Phone</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.schoolById.phone || "Not available"}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Region</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.schoolById.region}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Address</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.schoolById.location?.address || "Not available"}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Created</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{format(new Date(data.schoolById.createdAt), "MMM dd, yyyy")}</span>
									</VStack>
								</div>
							</CardContent>
						</Card>

						<Card className="w-full max-w-2xl row-span-2">
							<CardHeader>
								<VStack className="items-stretch lg:items-center justify-between lg:flex-row">
									<CardTitle className="text-xs lg:text-sm font-medium group-hover:font-bold text-slate-500">Beneficiaries</CardTitle>
									<Input type="search" placeholder="Search..." className="md:w-[100px] lg:w-[300px]" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
								</VStack>
							</CardHeader>
							<CardContent>
								<SearchBeneficiariesOfNGOPartner ngoPartnerId={ngoPartnerId} keyword={keyword} />
							</CardContent>
						</Card>
					</div>
				</VStack>
			)}
		</div>
	)
}

export default NGOPartnerPage
