import { Popover, PopoverContent, PopoverTrigger, Button } from "../../../components/ui"
import React from "react"
import { ExtendedStudentFragment } from "../../../graphql"

import AssignBeneficiaryClassForm from "../../../forms/classes/AssignBeneficiaryClassForm"

export type AssignBeneficiaryClassProps = { student: ExtendedStudentFragment }

export const AssignBeneficiaryClass: React.FC<AssignBeneficiaryClassProps> = ({ student }) => {
	return (
		<Popover>
			<PopoverTrigger className="w-full flex justify-end relative">
				<Button className=" text-white text-xs font-bold px-2 py-1 rounded-lg absolute">Assign Class</Button>
			</PopoverTrigger>
			<PopoverContent>
				<AssignBeneficiaryClassForm beneficiary={student} />
			</PopoverContent>
		</Popover>
	)
}
