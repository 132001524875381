import React from "react"

import { UploadClassButton } from "../../../components/ngo-partners/beneficiary/UploadClassButton"
import { useMeQuery } from "../../../graphql"
import { ErrorLabel, LoadingLabel } from "../../../components"
import { GenrateClassTemplate } from "../../../components/ngo-partners/beneficiary/GenrateClassTemplate"

const UploadClassPage: React.FC = () => {
	const [{ data: meData, fetching, error }] = useMeQuery()
	return (
		<>
			<div className="container mx-auto md:mt-10 mt-4">
				<div>
					<div className="">
						{fetching ? (
							<LoadingLabel />
						) : error ? (
							<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
						) : !meData ? (
							<ErrorLabel>Not login</ErrorLabel>
						) : (
							<>{meData.me?.school && <GenrateClassTemplate schoolId={meData.me?.school?._id} />}</>
						)}
					</div>
				</div>
				<h1 className="text-lg text-gray-700 font-semibold text-left mt-4 md:mt-10">Upload Beneficiaries Class</h1>
				<div className="mt-4">
					<UploadClassButton />
				</div>
			</div>
		</>
	)
}

export default UploadClassPage
