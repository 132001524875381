import React from "react"
import { useDeviceByIdQuery, useDeviceUpdateSubscription } from "../../../graphql"
import { ErrorLabel, Skeleton, VStack } from "../../../components/ui"
import { useParams } from "react-router-dom"
import { Device } from "../../../components/admin/devices/Device"

export const DevicePage: React.FC = () => {
	const { deviceId = "" } = useParams<{ deviceId: string }>()

	const [{ data, fetching, error }] = useDeviceByIdQuery({
		variables: { deviceId },
		pause: !deviceId,
	})

	useDeviceUpdateSubscription({ variables: { deviceId } })

	return (
		<VStack className="max-w-xl items-stretch">
			{fetching ? (
				<VStack className="w-full max-w-xl items-stretch gap-2">
					<Skeleton className="w-full max-w-xl h-24" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.deviceById ? (
				<Device device={data.deviceById} />
			) : (
				<ErrorLabel>Couldn&apos;t find any Device.</ErrorLabel>
			)}
		</VStack>
	)
}
