import React from "react"
import { Link } from "react-router-dom"
import Select from "react-select"
import { buttonVariants, HStack, Input, VStack } from "../../../components"
import { SearchOverallBMIReports } from "../../../components/admin"
import { ReportStatus } from "../../../graphql"
import { useSearchParams } from "../../../hooks"

export const OverallBMIReportsListPage: React.FC = () => {
	const [searchParams, { handleSearchParamUpdate, handleSearchParamDelete }] = useSearchParams()

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2 lg:gap-4">
			<VStack className="lg:flex-row items-stretch lg:items-center">
				<HStack className="w-full justify-between lg:justify-start">
					<h1 className="text-sm lg:text-md font-medium">Overall BMI Reports</h1>
					<Link to="new" className={buttonVariants()}>
						New
					</Link>
				</HStack>
				<div className="w-full grid lg:grid-cols-2 gap-2">
					<Input
						type="search"
						placeholder="Search..."
						value={(searchParams.get("keyword") as string) || ""}
						onChange={(e) => {
							if (e.target.value) handleSearchParamUpdate("keyword", e.target.value)
							else handleSearchParamDelete("keyword")
						}}
					/>

					<Select
						name="Status"
						placeholder="Select status"
						options={Object.values(ReportStatus).map((m) => ({ label: m, value: m }))}
						value={(searchParams.get("status") as ReportStatus) ? { label: searchParams.get("status") as ReportStatus, value: searchParams.get("status") as ReportStatus } : undefined}
						onChange={(m) => {
							if (m?.value) handleSearchParamUpdate("status", m.value)
							else handleSearchParamDelete("status")
						}}
						className="basic-single w-full"
						isSearchable
						isClearable
					/>
				</div>
			</VStack>
			<SearchOverallBMIReports keyword={(searchParams.get("keyword") as string) || ""} status={(searchParams.get("status") as ReportStatus) || undefined} />
		</div>
	)
}

export default OverallBMIReportsListPage
