import React from "react"
import { BeneficiariesBMIStatsChart, BeneficiariesProgressChart, GradeWiseBeneficiariesChart, TodayAttendanceChart, TotalBeneficiariesWithGenderSplit } from "../../components/ngo-partners"

export const OverviewPage: React.FC = () => {
	return (
		<div className="container p-2 lg:px-8">
			<div className="grid gap-2 lg:gap-4 lg:grid-cols-2">
				<TotalBeneficiariesWithGenderSplit />

				<TodayAttendanceChart />
				{/* <TinyMonthlyAttendanceStatsChart /> */}
				<BeneficiariesBMIStatsChart />
				<GradeWiseBeneficiariesChart />
				<BeneficiariesProgressChart />
			</div>
		</div>
	)
}

export default OverviewPage
