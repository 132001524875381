import React, { PropsWithChildren, useCallback, useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import colors from "tailwindcss/colors"
import { Attendance } from "../../../icons/Attendance"
import { Home } from "../../../icons/Home"
import { NGOPartners } from "../../../icons/NGOPartners"
import { Report } from "../../../icons/Report"
import { Meal } from "../../../icons/Meal"
import { cn } from "../../../lib/utils"
import { Devices } from "../../../icons/Devices"
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "../../ui"
import { Classes } from "../../../icons/Classes"

enum Routes {
	NgoPartnersReports = "/ngo-partners/reports",
	NgoPartners = "/ngo-partners",
	BeneficiariesAttendanceReports = "/attendance/reports/beneficiaries",
	NGOPartnersAttendanceReports = "/attendance/reports/ngo-partners",
	OverallBMIReports = "OverallBMIReports",
	NgoPartnerBMIReports = "NgoPartnerBMIReports",
	Attendance = "/attendance",
	Devices = "/devices",
	Classes = "/classes",
	Meals = "/meal",
	AssignMeal = "/meal/assignMealSlot",
	MealSlots = "/mealSlots",
	Overview = "/",
}

export const BottomTabs: React.FC = () => {
	const { pathname } = useLocation()

	const activeRoute = useMemo(() => {
		for (const route of Object.values(Routes)) {
			if (pathname.startsWith(route)) {
				return route
			}
		}
	}, [pathname])

	const isActive = useCallback((...route: Routes[]) => activeRoute && route.includes(activeRoute), [activeRoute])

	return (
		<div className="md:hidden  fixed bottom-0 z-50 w-full h-16 -translate-x-1/2 bg-white border-t border-gray-200 left-1/2 dark:bg-gray-700 dark:border-gray-600">
			<div className="h-full flex items-stretch">
				<Tab icon={Home} label="Overview" href="/" isActive={isActive(Routes.Overview)} />

				<SheetTab icon={NGOPartners} label="NGOs" isActive={isActive(Routes.NgoPartners, Routes.NgoPartnersReports)}>
					<Tab icon={NGOPartners} label="NGO Partners" href="/ngo-partners" isActive={isActive(Routes.NgoPartners)} asSheetTab />
					<Tab icon={Report} label="NGO Partners Reports" href="/ngo-partners/reports" isActive={isActive(Routes.NgoPartnersReports)} asSheetTab />
					<Tab icon={Report} label="Overall BMI Reports" href="/ngo-partners/bmi/reports" isActive={isActive(Routes.OverallBMIReports)} asSheetTab />
					<Tab icon={Report} label="NGO Partner BMI Reports" href="/ngo-partners/school/bmi/reports" isActive={isActive(Routes.NgoPartnerBMIReports)} asSheetTab />
				</SheetTab>

				<SheetTab icon={Attendance} label="Attendance" isActive={isActive(Routes.Attendance, Routes.BeneficiariesAttendanceReports, Routes.NGOPartnersAttendanceReports)}>
					<Tab icon={Attendance} label="Attendance" href="/attendance" isActive={isActive(Routes.Attendance)} asSheetTab />
					<Tab icon={Report} label="Beneficiary Attendance Reports" href="/attendance/reports/beneficiaries" isActive={isActive(Routes.BeneficiariesAttendanceReports)} asSheetTab />
					<Tab icon={Report} label="Beneficiary Slot Wise Attendance Reports" href="/attendance/reports/beneficiaries/slot-wise" isActive={isActive(Routes.BeneficiariesAttendanceReports)} asSheetTab />
					<Tab icon={Report} label="NGO Partners Attendance Reports" href="/attendance/reports/ngo-partners" isActive={isActive(Routes.NGOPartnersAttendanceReports)} asSheetTab />
					<Tab icon={Report} label="NGO Partners Attendance Slot Wise Reports" href="/attendance/reports/ngo-partners/slot-wise" isActive={isActive(Routes.NGOPartnersAttendanceReports)} asSheetTab />
				</SheetTab>

				<SheetTab icon={Meal} label="Meals" isActive={isActive(Routes.Meals, Routes.AssignMeal, Routes.MealSlots)}>
					<Tab icon={Meal} label="Assign Meal Slot" href="/meal/assignMealSlot" isActive={isActive(Routes.AssignMeal)} asSheetTab />

					<Tab icon={Meal} label="Meal Slots" href="/mealSlots" isActive={isActive(Routes.MealSlots)} asSheetTab />
				</SheetTab>

				<Tab icon={Classes} label="Classes" href="/classes" isActive={isActive(Routes.Classes)} />
				<Tab icon={Devices} label="Devices" href="/devices" isActive={isActive(Routes.Devices)} />
			</div>
		</div>
	)
}

type TabProps = {
	icon: React.FC<React.SVGProps<SVGSVGElement>>
	label: string
	href?: string
	isActive?: boolean
	asSheetTab?: boolean
}

const Tab: React.FC<TabProps> = ({ icon: Icon, label, href, isActive = false, asSheetTab }) => {
	if (!href) {
		return (
			<button className={cn("w-full h-full inline-flex flex-col items-center justify-center py-2", isActive ? "bg-purple-100 gap-1" : asSheetTab ? "bg-gray-50" : "bg-white")}>
				{isActive ? <Icon fill={colors.purple[600]} className="scale-150" /> : <Icon />}

				{!isActive ? <span className="text-xs">{label}</span> : <span className="text-xs text-purple-800">{label}</span>}
			</button>
		)
	}

	return (
		<Link to={href} className="w-full h-full">
			<button className={cn("w-full h-full inline-flex flex-col items-center justify-center py-2", isActive ? "bg-purple-100 gap-1" : asSheetTab ? "bg-gray-50" : "bg-white")}>
				{isActive ? <Icon fill={colors.purple[600]} className="scale-150" /> : <Icon />}

				{!isActive ? <span className="text-xs">{label}</span> : <span className="text-xs text-purple-800">{label}</span>}
			</button>
		</Link>
	)
}

type SheetTabTabProps = {
	icon: React.FC<React.SVGProps<SVGSVGElement>>
	label: string
	isActive?: boolean
}

const SheetTab: React.FC<SheetTabTabProps & PropsWithChildren> = ({ icon: Icon, label, isActive = false, children }) => {
	const [isSheetOpen, setIsSheetOpen] = React.useState(false)

	return (
		<Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
			<SheetTrigger className="w-full h-full">
				<Tab icon={Icon} label={label} isActive={isActive} />
			</SheetTrigger>

			<SheetContent side="bottom" onClick={() => setIsSheetOpen(false)}>
				<SheetHeader className="pb-4">
					<SheetTitle className="text-sm text-slate-600">{label}</SheetTitle>
				</SheetHeader>
				<div className="grid grid-cols-3 gap-1">{children}</div>
			</SheetContent>
		</Sheet>
	)
}
