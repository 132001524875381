/* eslint-disable react/react-in-jsx-scope */

import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import twcolors from "tailwindcss/colors"
import { useAllSchoolsWithoutPaginationQuery, useSchoolWiseStudentsCountQuery } from "../../../../graphql"
import { EmptyChart } from "../../../common"
import { AspectRatio, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, VStack } from "../../../ui"

const colors = [twcolors.red[400], twcolors.purple[400], twcolors.teal[400], twcolors.orange[400], twcolors.slate[400], twcolors.gray[400]]

export const NGOPartnerWiseBeneficiariesChart: React.FC = () => {
	const [{ data, fetching }] = useSchoolWiseStudentsCountQuery()

	const navigate = useNavigate()

	const [{ data: ngoPartnersData }] = useAllSchoolsWithoutPaginationQuery()

	const ngoPartnerByName = useCallback((ngoPartnerName: string) => ngoPartnersData?.allSchoolsWithoutPagination.find((s) => s.name === ngoPartnerName), [ngoPartnersData])

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">School-wise distribution of beneficiaries</CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="gap-4 select-none">
					<AspectRatio ratio={16 / 9}>
						<ResponsiveContainer width="100%" height="100%">
							{fetching ? (
								<ChartSkeleton />
							) : !data?.schoolWiseStudentsCount.length ? (
								<EmptyChart />
							) : (
								<BarChart
									width={500}
									height={400}
									data={data.schoolWiseStudentsCount.map((d) => ({ label: d.label.split(" ")[0], Beneficiaries: d.value }))}
									margin={{
										top: 20,
										bottom: 5,
									}}
								>
									<XAxis dataKey="label" />
									<YAxis />
									<Tooltip />

									<Bar
										dataKey="Beneficiaries"
										fillOpacity={0.7}
										label={{
											position: "top",
										}}
									>
										{data.schoolWiseStudentsCount.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={colors[index]}
												className="cursor-pointer"
												onClick={ngoPartnerByName(entry.label) ? () => navigate(`/beneficiaries/list?ngo-partner=${ngoPartnerByName(entry.label)?._id}`) : undefined}
											/>
										))}
									</Bar>
								</BarChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}
