import loadable from "@loadable/component"
import React from "react"
import { Route, Routes } from "react-router-dom"
import { Scaffold } from "../components/admin"
import AttendancePage from "../pages/admin/attendance"
import NGOPartnersAttendancePage from "../pages/admin/attendance/ngo-partners"
import NGOPartnerAttendanceOverviewPage from "../pages/admin/attendance/overview"
import AtRiskNGOPartnersPage from "../pages/admin/attendance/overview/at-risk"
import CriticalNGOPartnersPage from "../pages/admin/attendance/overview/critical"
import WellNGOPartnersPage from "../pages/admin/attendance/overview/well"
import { BeneficiariesAttendanceNewReportPage } from "../pages/admin/attendance/report/beneficiaries/new"
import BeneficiariesAttendanceReportPage from "../pages/admin/attendance/report/beneficiaries/report"
import BeneficiariesAttendanceReportsListPage from "../pages/admin/attendance/report/beneficiaries/reports-list"
import { NGOPartnersAttendanceNewReportPage } from "../pages/admin/attendance/report/ngo-partners/new"
import NGOPartnersAttendanceReportPage from "../pages/admin/attendance/report/ngo-partners/report"
import NGOPartnersAttendanceReportsListPage from "../pages/admin/attendance/report/ngo-partners/reports-list"
import NGOPartnersPage from "../pages/admin/ngo-partners"
import NGOPartnerPage from "../pages/admin/ngo-partners/ngo-partner"
import NGOPartnersListPage from "../pages/admin/ngo-partners/ngo-partners-list"
import NGOPartnersNewReportPage from "../pages/admin/ngo-partners/reports/new"
import NGOPartnersReportPage from "../pages/admin/ngo-partners/reports/report"
import NGOPartnersReportsPage from "../pages/admin/ngo-partners/reports/reports-list"
import OverviewPage from "../pages/admin/overview"
import BeneficiarysListPage from "../pages/common/beneficiaries/beneficiaries-list"
import BeneficiaryPage from "../pages/common/beneficiaries/beneficiary"
import DevicesPage from "../pages/admin/devices/Devices"
import { DevicePage } from "../pages/admin/devices/Device"
import DeactivateBeneficiaryPage from "../pages/admin/beneficiary/DeactivateBeneficiary"
import ActivateBeneficiaryPage from "../pages/admin/beneficiary/ActivateBeneficiary"
import UpdateBeneficiaryProfilePage from "../pages/admin/beneficiary/UpdateBeneficiaryProfile"
import ClassPage from "../pages/ngo-partners/classes/Class"
import { ClassesPage } from "../pages/ngo-partners/classes/Classes"

import AddNewClassPage from "../pages/ngo-partners/classes/AddNewClass"

import AddNewSectionPage from "../pages/ngo-partners/classes/AddNewSection"
import BeneficiaryBmiPage from "../pages/admin/beneficiary/BeneficiaryBmi"
import OverallBMINewReportPage from "../pages/admin/bmi/new"
import OverallBMIReportPage from "../pages/admin/bmi/report"
import OverallBMIReportsListPage from "../pages/admin/bmi/reports-list"
import { SchoolBMINewReportPage } from "../pages/admin/bmi/ngo-partner/new"
import { SchoolBMIReportsListPage } from "../pages/admin/bmi/ngo-partner/reports-list"
import { SchoolBMIReportPage } from "../pages/admin/bmi/ngo-partner/report"
import CreateMealSlotPage from "../pages/admin/meal/CreateMealSlot"
import AssignMealSlotPage from "../pages/admin/meal/AssignMealSlot"
import { MealsPage } from "../pages/admin/meal/Meals"

import MealPage from "../pages/admin/meal/Meal"
import { MealSlotsPage } from "../pages/admin/meal/MealSlots"
import MealSlotPage from "../pages/admin/meal/MealSlot"
import DeleteMealSlotPage from "../pages/admin/meal/DeleteMealSlot"
import UpdateMealSlotPage from "../pages/admin/meal/UpdateMealSlot"
import NGOPartnersAttendanceSlotWiseReportsListPage from "../pages/admin/attendance/report/slotWise/reports-list"
import NGOPartnersAttendanceSlotWiseReportPage from "../pages/admin/attendance/report/slotWise/report"
import NGOPartnersAttendanceSlotWiseNewReportPage from "../pages/admin/attendance/report/slotWise/new"
import StudentsSlotWiseAttendanceNewReportPage from "../pages/admin/attendance/report/beneficiaries/slot-wise/new"
import StudentsSlotWiseAttendanceReportsListPage from "../pages/admin/attendance/report/beneficiaries/slot-wise/reports-list"
import StudentsSlotWiseAttendanceReportPage from "../pages/admin/attendance/report/beneficiaries/slot-wise/report"
const PageNotFoundPage = loadable(() => import("../pages/common/PageNotFound"))

export const AdminRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<Scaffold />}>
				<Route path="/" element={<OverviewPage />} />
				<Route path="/beneficiaries/list" element={<BeneficiarysListPage />} />
				<Route path="/beneficiaries/:beneficiaryId" element={<BeneficiaryPage />} />
				<Route path="/beneficiaries/:beneficiaryId/deactivate" element={<DeactivateBeneficiaryPage />} />
				<Route path="/beneficiaries/:beneficiaryId/activate" element={<ActivateBeneficiaryPage />} />
				<Route path="/beneficiaries/:beneficiaryId/updateBeneficiaryDetails" element={<UpdateBeneficiaryProfilePage />} />
				<Route path="/beneficiaries/bmi" element={<BeneficiaryBmiPage />} />
				<Route path="/devices" element={<DevicesPage />} />
				<Route path="/devices/:deviceId" element={<DevicePage />} />
				<Route path="/classes" element={<ClassesPage />} />
				<Route path="/classes/:classId" element={<ClassPage />} />
				<Route path="/classes/:classId/addNewSection" element={<AddNewSectionPage />} />
				<Route path="/classes/new" element={<AddNewClassPage />} />
				<Route path="/ngo-partners/list" element={<NGOPartnersListPage />} />

				<Route path="/ngo-partners/reports/new" element={<NGOPartnersNewReportPage />} />
				<Route path="/ngo-partners/reports" element={<NGOPartnersReportsPage />} />
				<Route path="/ngo-partners/reports/:reportId" element={<NGOPartnersReportPage />} />

				<Route path="/ngo-partners/bmi/reports/new" element={<OverallBMINewReportPage />} />
				<Route path="/ngo-partners/bmi/reports" element={<OverallBMIReportsListPage />} />
				<Route path="/ngo-partners/bmi/reports/:reportId" element={<OverallBMIReportPage />} />

				<Route path="/ngo-partners/school/bmi/reports/new" element={<SchoolBMINewReportPage />} />
				<Route path="/ngo-partners/school/bmi/reports" element={<SchoolBMIReportsListPage />} />
				<Route path="/ngo-partners/school/bmi/reports/:reportId" element={<SchoolBMIReportPage />} />

				<Route path="/meal/createMealSlot" element={<CreateMealSlotPage />} />

				<Route path="/meal/assignMealSlot" element={<AssignMealSlotPage />} />

				<Route path="/meals" element={<MealsPage />} />
				<Route path="/mealSlots" element={<MealSlotsPage />} />
				<Route path="/mealSlots/:slotId" element={<MealSlotPage />} />
				<Route path="/mealSlots/:slotId/delete" element={<DeleteMealSlotPage />} />
				<Route path="/mealSlots/:slotId/update" element={<UpdateMealSlotPage />} />
				<Route path="/meals/:attendanceSlotId" element={<MealPage />} />

				<Route path="/ngo-partners/:ngoPartnerId" element={<NGOPartnerPage />} />
				<Route path="/ngo-partners" element={<NGOPartnersPage />} />
				<Route path="/attendance/ngo-partners" element={<NGOPartnersAttendancePage />} />
				<Route path="/attendance/overview/critical" element={<CriticalNGOPartnersPage />} />
				<Route path="/attendance/overview/at-risk" element={<AtRiskNGOPartnersPage />} />
				<Route path="/attendance/overview/well" element={<WellNGOPartnersPage />} />
				<Route path="/attendance/overview/:ngoPartnerId" element={<NGOPartnerAttendanceOverviewPage />} />
				<Route path="/attendance/reports/ngo-partners/new" element={<NGOPartnersAttendanceNewReportPage />} />
				<Route path="/attendance/reports/ngo-partners/slot-wise/new" element={<NGOPartnersAttendanceSlotWiseNewReportPage />} />
				<Route path="/attendance/reports/beneficiaries/new" element={<BeneficiariesAttendanceNewReportPage />} />
				<Route path="/attendance/reports/ngo-partners" element={<NGOPartnersAttendanceReportsListPage />} />
				<Route path="/attendance/reports/ngo-partners/slot-wise" element={<NGOPartnersAttendanceSlotWiseReportsListPage />} />
				<Route path="/attendance/reports/beneficiaries" element={<BeneficiariesAttendanceReportsListPage />} />
				<Route path="/attendance/reports/ngo-partners/:reportId" element={<NGOPartnersAttendanceReportPage />} />
				<Route path="/attendance/reports/ngo-partners/slot-wise/:reportId" element={<NGOPartnersAttendanceSlotWiseReportPage />} />
				<Route path="/attendance/reports/beneficiaries/:reportId" element={<BeneficiariesAttendanceReportPage />} />

				<Route path="/attendance/reports/beneficiaries/slot-wise" element={<StudentsSlotWiseAttendanceReportsListPage />} />
				<Route path="/attendance/reports/beneficiaries/slot-wise/new" element={<StudentsSlotWiseAttendanceNewReportPage />} />
				<Route path="/attendance/reports/beneficiaries/slot-wise/:reportId" element={<StudentsSlotWiseAttendanceReportPage />} />
				<Route path="/attendance" element={<AttendancePage />} />
			</Route>
			<Route path="*" element={<PageNotFoundPage />} />
		</Routes>
	)
}
