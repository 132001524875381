import { format, startOfDay } from "date-fns"
import { CalendarIcon } from "lucide-react"
import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import Select from "react-select"
import { Badge, Button, Calendar, Card, CardContent, CardHeader, CardTitle, ErrorLabel, HStack, LoadingLabel, NGOPartnerAttendanceStats, Popover, PopoverContent, PopoverTrigger, Separator, Skeleton, VStack } from "../../../components"
import { SchoolWisePartialAttendanceStatsQueryVariables, useAllSchoolsWithoutPaginationQuery } from "../../../graphql"
import { useAttendanceStatsContext } from "../../../hooks"
import { cn } from "../../../lib/utils"

export const NGOPartnersAttendancePage: React.FC = () => {
	const { state } = useLocation()

	const [filter, setFilter] = useState<Required<SchoolWisePartialAttendanceStatsQueryVariables["filter"]>>({ sinceDate: startOfDay(new Date()), untilDate: startOfDay(new Date()), schoolIds: [], ...state?.filter })

	const [{ queries }, queryNew] = useAttendanceStatsContext()

	const currentQuery = useMemo(() => queries.find((q) => q.id === JSON.stringify(filter)), [filter, queries])

	useEffect(() => {
		queryNew({ filter })
	}, [filter])

	const [{ data: ngoPartnersData, fetching: ngosFetching, error: ngosError }] = useAllSchoolsWithoutPaginationQuery()

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2">
			<VStack className="lg:flex-row">
				<h1 className="w-full text-sm lg:text-md font-medium">Attendance in NGO Partners</h1>
				<VStack className="lg:flex-row items-stretch">
					{ngosFetching ? (
						<LoadingLabel className="flex-1">Loading NGO Partners</LoadingLabel>
					) : ngosError ? (
						<ErrorLabel className="flex-1">{ngosError.message.replace("[GraphQL] ", "")}</ErrorLabel>
					) : !ngoPartnersData?.allSchoolsWithoutPagination.length ? (
						<ErrorLabel className="flex-1">Couldn&apos;t fetch NGO partners</ErrorLabel>
					) : (
						<Select
							isMulti
							name="NGOPartners"
							placeholder="Select NGO partners"
							options={ngoPartnersData?.allSchoolsWithoutPagination.map((s) => ({ label: s.name, value: s._id }))}
							value={ngoPartnersData?.allSchoolsWithoutPagination.filter((s) => filter.schoolIds?.includes(s._id)).map((s) => ({ label: s.name, value: s._id }))}
							onChange={(values) => setFilter((prev) => ({ ...prev, schoolIds: values.map((o) => o.value) }))}
							className="basic-multi-select flex-1"
							isSearchable
						/>
					)}
					<Popover>
						<PopoverTrigger asChild>
							<Button id="date" variant="outline" className={cn("flex-1/2 justify-start text-left font-normal", !(filter.sinceDate && filter.untilDate) && "text-muted-foreground")}>
								<CalendarIcon className="mr-2 h-4 w-4" />
								{filter.sinceDate ? (
									filter.untilDate ? (
										<>
											{format(filter.sinceDate, "MMM dd, yyyy")} - {format(filter.untilDate, "MMM dd, yyyy")}
										</>
									) : (
										format(filter.sinceDate, "MMM dd, yyyy")
									)
								) : (
									<span>Pick a date</span>
								)}
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-auto p-0" align="start">
							<Calendar
								initialFocus
								mode="range"
								defaultMonth={filter.sinceDate}
								selected={{ from: filter.sinceDate, to: filter.untilDate }}
								onSelect={(date) => setFilter((prev) => ({ ...prev, sinceDate: date?.from || date?.to, untilDate: date?.to || date?.from }))}
								toDate={new Date()}
								numberOfMonths={1}
							/>
						</PopoverContent>
					</Popover>
				</VStack>
			</VStack>

			{currentQuery?.waiting ? (
				<VStack className="w-full max-w-xl items-stretch gap-2">
					<Skeleton className="w-full max-w-xl h-36" />
					<Skeleton className="w-full max-w-xl h-36" />
					<Skeleton className="w-full max-w-xl h-36" />
				</VStack>
			) : currentQuery?.failed || !currentQuery?.data ? (
				<ErrorLabel>Could not load NGO partners&apos; attendance</ErrorLabel>
			) : (
				<VStack className="w-full max-w-xl items-stretch">
					{currentQuery.data.schoolWisePartialAttendanceStats.map((s) => (
						<VStack key={s.school._id} className="w-full items-stretch gap-4">
							<Card tabIndex={0} className="group hover:shadow-purple-500 cursor-pointer" onClick={() => setFilter((prev) => ({ ...prev, schoolIds: [s.school._id] }))}>
								<CardHeader>
									<HStack className="w-full justify-between">
										<CardTitle className="text-sm font-medium group-hover:font-bold text-slate-500 group-hover:text-purple-500">{s.school.name}</CardTitle>
										<Badge variant="secondary">{s.stats.length} slots</Badge>
									</HStack>
								</CardHeader>
								<CardContent>
									<div className="w-full flex">
										<VStack className="items-start gap-0">
											<span className="text-sm text-slate-400">Beneficiaries</span>
											<span className="text-xl lg:text-2xl font-bold text-slate-500">{s.stats.map((d) => d.totalStudentsCount).reduce((a, b) => a + b, 0)}</span>
										</VStack>

										<Separator orientation="vertical" />

										<VStack className="items-start gap-0">
											<span className="text-sm text-slate-400">Expected</span>
											<span className="text-xl lg:text-2xl font-bold text-purple-500">{s.stats.map((d) => d.totalStudentsCount).reduce((a, b) => a + b, 0)}</span>
										</VStack>

										<Separator orientation="vertical" />

										<VStack className="items-start gap-0">
											<span className="text-sm text-slate-400">Actual</span>
											<span className="text-xl lg:text-2xl font-bold text-green-500">{s.stats.map((d) => d.presentStudentsCount).reduce((a, b) => a + b, 0)}</span>
										</VStack>

										<Separator orientation="vertical" />

										<VStack className="items-start gap-0">
											<span className="text-sm text-slate-400">Remaining</span>
											<span className="text-xl lg:text-2xl font-bold text-red-500">
												{s.stats.map((d) => d.totalStudentsCount).reduce((a, b) => a + b, 0) - s.stats.map((d) => d.presentStudentsCount).reduce((a, b) => a + b, 0)}
											</span>
										</VStack>
									</div>
								</CardContent>
							</Card>
						</VStack>
					))}
					{currentQuery.data?.schoolWisePartialAttendanceStats.length === 1 && filter.schoolIds?.[0] && <NGOPartnerAttendanceStats ngoPartnerId={filter.schoolIds[0]} sinceDate={filter.sinceDate} untilDate={filter.untilDate} />}
				</VStack>
			)}
		</div>
	)
}

export default NGOPartnersAttendancePage
