import { ValidTillBannerContextAction, ValidTillBannerContextActions, ValidTillBannerContextState, ValidTillBannerStatus } from "./types"

export const initialState: ValidTillBannerContextState = {
	isVisible: false,
	status: ValidTillBannerStatus.INFO,
}

export const validTillBannerContextReducer = (state: ValidTillBannerContextState, action: ValidTillBannerContextAction): ValidTillBannerContextState => {
	switch (action.type) {
		case ValidTillBannerContextActions.SET_VISIBLE: {
			return { ...state, isVisible: true }
		}

		case ValidTillBannerContextActions.SET_INVISIBLE: {
			return { ...state, isVisible: false, children: null }
		}

		case ValidTillBannerContextActions.SET_STATUS: {
			return { ...state, status: action.payload?.status || state.status }
		}

		case ValidTillBannerContextActions.SET_CHILDREN: {
			return { ...state, children: action.payload?.children }
		}

		default:
			return state
	}
}
