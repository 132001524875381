import React, { useCallback, useEffect, useState } from "react"
import { BmiCategory, useStudentsBmiBySchoolQuery, StudentsBmiBySchoolQueryVariables } from "../../../graphql"
import { ErrorLabel, Skeleton, VStack } from "../../ui"
import { BeneficiariesList } from "../../common/beneficiaries/BeneficiariesList"

type StudentsBmiBySchoolProps = {
	category?: BmiCategory
	keyword?: string
}

export const StudentsBmiBySchool: React.FC<StudentsBmiBySchoolProps> = ({ category, keyword }) => {
	const [filter, setFilter] = useState({ keyword, category })
	const [pagination, setPagination] = useState<StudentsBmiBySchoolQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useStudentsBmiBySchoolQuery({ variables: { ...filter, pagination } })

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilter({ keyword, category })
			setPagination((prev) => ({
				...prev,
				page: 1,
			}))
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword, category])

	const nextPage = useCallback(() => {
		if (data?.studentsBmiBySchool.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data])

	return (
		<VStack className="items-stretch">
			{fetching && !data?.studentsBmiBySchool ? (
				<VStack className="w-full items-stretch gap-2">
					<Skeleton className="w-full h-24" />
					<Skeleton className="w-full h-24" />
					<Skeleton className="w-full h-24" />
					<Skeleton className="w-full h-24" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.studentsBmiBySchool.students && data.studentsBmiBySchool.students.length ? (
				<BeneficiariesList beneficiaries={data.studentsBmiBySchool.students} loadMore={nextPage} />
			) : (
				<ErrorLabel>Couldn&apos;t find any beneficiaries.</ErrorLabel>
			)}
		</VStack>
	)
}
