import { startCase } from "lodash"
import React from "react"
import Select from "react-select"
import { Input, VStack } from "../../../components"
import { SearchNGOPartners } from "../../../components/admin"
import { Models, Regions } from "../../../graphql"
import { useSearchParams } from "../../../hooks"

export const NGOPartnersListPage: React.FC = () => {
	const [searchParams, { handleSearchParamUpdate, handleSearchParamDelete }] = useSearchParams()

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2 lg:gap-4">
			<VStack className="lg:flex-row items-stretch lg:items-center">
				<h1 className="w-full text-sm lg:text-md font-medium">NGO Partners</h1>
				<div className="w-full grid lg:grid-cols-3 gap-2">
					<Input
						type="search"
						placeholder="Search..."
						value={(searchParams.get("keyword") as string) || ""}
						onChange={(e) => {
							if (e.target.value) handleSearchParamUpdate("keyword", e.target.value)
							else handleSearchParamDelete("keyword")
						}}
					/>
					<Select
						name="Models"
						placeholder="Select model"
						options={Object.values(Models).map((m) => ({ label: startCase(m), value: m }))}
						value={(searchParams.get("model") as Models) ? { label: startCase(searchParams.get("model") as string), value: searchParams.get("model") as Models } : undefined}
						onChange={(m) => {
							if (m?.value) handleSearchParamUpdate("model", m.value)
							else handleSearchParamDelete("model")
						}}
						className="basic-single w-full max-w-36"
						isSearchable
						isClearable
					/>
					<Select
						name="Regions"
						placeholder="Select region"
						options={Object.values(Regions).map((m) => ({ label: startCase(m), value: m }))}
						value={(searchParams.get("region") as Models) ? { label: startCase(searchParams.get("region") as string), value: searchParams.get("region") as Regions } : undefined}
						onChange={(m) => {
							if (m?.value) handleSearchParamUpdate("region", m.value)
							else handleSearchParamDelete("region")
						}}
						className="basic-single w-full"
						isSearchable
						isClearable
					/>
				</div>
			</VStack>
			<SearchNGOPartners keyword={(searchParams.get("keyword") as string) || ""} model={(searchParams.get("model") as Models) || undefined} region={(searchParams.get("region") as Regions) || undefined} />
		</div>
	)
}

export default NGOPartnersListPage
