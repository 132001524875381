import React, { useEffect, useState } from "react"
import Select, { SingleValue } from "react-select"
import { ErrorLabel, Input, LoadingLabel, SearchBeneficiariesOfNGOPartner, VStack } from "../../../components"
import { Genders, useAllSchoolsWithoutPaginationQuery, useMeQuery } from "../../../graphql"
import { useSearchParams } from "../../../hooks"
import { cn } from "../../../lib/utils"

export const BeneficiariesListPage: React.FC = () => {
	const [{ data: meData }] = useMeQuery()

	const [searchParams, { handleSearchParamUpdate, handleSearchParamDelete }] = useSearchParams()

	const [ngoPartnersQuery] = useAllSchoolsWithoutPaginationQuery()

	const [selectedNGOPartner, setSelectedNGOPartner] = useState<SingleValue<{ label: string; value: string }>>({ label: "", value: "" })

	useEffect(() => {
		if (selectedNGOPartner?.value !== searchParams.get("ngo-partner")) {
			const ngoPartner = ngoPartnersQuery.data?.allSchoolsWithoutPagination.find((s) => s._id === searchParams.get("ngo-partner"))

			if (ngoPartner) setSelectedNGOPartner({ label: ngoPartner.name, value: ngoPartner._id })
		}
	}, [searchParams.get("ngo-partner")])

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2 lg:gap-4">
			<VStack className="lg:flex-row items-stretch lg:items-center">
				<h1 className="w-full text-sm lg:text-md font-medium">Beneficiaries</h1>

				<div className={cn("w-full grid gap-2", meData?.me?.school ? "lg:grid-cols-2" : "lg:grid-cols-3")}>
					<Input
						type="search"
						placeholder="Search..."
						value={(searchParams.get("keyword") as string) || ""}
						onChange={(e) => {
							if (e.target.value) handleSearchParamUpdate("keyword", e.target.value)
							else handleSearchParamDelete("keyword")
						}}
					/>
					{meData?.me?.school ? (
						<></>
					) : ngoPartnersQuery.fetching ? (
						<LoadingLabel className="flex-1">Loading NGO Partners</LoadingLabel>
					) : ngoPartnersQuery.error ? (
						<ErrorLabel className="flex-1">{ngoPartnersQuery.error.message.replace("[GraphQL] ", "")}</ErrorLabel>
					) : !ngoPartnersQuery.data?.allSchoolsWithoutPagination.length ? (
						<ErrorLabel className="flex-1">Couldn&apos;t fetch NGO Partners</ErrorLabel>
					) : (
						<Select
							name="NGO partner"
							placeholder="Select NGO partner"
							options={ngoPartnersQuery.data.allSchoolsWithoutPagination.map((s) => ({ label: s.name, value: s._id }))}
							value={selectedNGOPartner?.value ? selectedNGOPartner : undefined}
							onChange={(o) => {
								if (o?.value) handleSearchParamUpdate("ngo-partner", o.value)
								else handleSearchParamDelete("ngo-partner")
							}}
							className="basic-single w-full"
							isSearchable
							isClearable
						/>
					)}
					<Select
						name="Genders"
						placeholder="Select gender"
						options={Object.values(Genders).map((m) => ({ label: m, value: m }))}
						value={(searchParams.get("gender") as Genders) ? { label: searchParams.get("gender") as Genders, value: searchParams.get("gender") as Genders } : undefined}
						onChange={(m) => {
							if (m?.value) handleSearchParamUpdate("gender", m.value)
							else handleSearchParamDelete("gender")
						}}
						className="basic-single w-full max-w-36"
						isSearchable
						isClearable
					/>
				</div>
			</VStack>
			<VStack className="w-full max-w-2xl items-stretch">
				<SearchBeneficiariesOfNGOPartner ngoPartnerId={(searchParams.get("ngo-partner") as string) || ""} keyword={(searchParams.get("keyword") as string) || ""} gender={(searchParams.get("gender") as Genders) || undefined} />
			</VStack>
		</div>
	)
}

export default BeneficiariesListPage
