/* eslint-disable react/react-in-jsx-scope */

import { format, startOfDay } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import twcolors from "tailwindcss/colors"
import { SchoolWiseAttendanceStatsQueryVariables, useMeQuery, useSchoolAttendanceSlotsQuery, useSchoolWisePartialAttendanceStatsQuery } from "../../../../graphql"
import { cn } from "../../../../lib/utils"
import { roundDecimalNumber } from "../../../../utils"
import { EmptyChart } from "../../../common"
import { AspectRatio, Button, Calendar, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, Popover, PopoverContent, PopoverTrigger, VStack } from "../../../ui"

const colors = [twcolors.red[400], twcolors.purple[400], twcolors.teal[400], twcolors.orange[400], twcolors.amber[400], twcolors.blue[400], twcolors.green[400], twcolors.indigo[400], twcolors.fuchsia[400]]

type MealStats = {
	total: number
	persent: number
	percent: number
}

type SchoolStats = {
	[key: string]: MealStats
}

export const AttendanceStatsChart: React.FC = () => {
	const [mealKeys, setMealKeys] = useState<string[]>([])
	const [{ data: meData }] = useMeQuery()

	const [filter, setFilter] = useState<Required<SchoolWiseAttendanceStatsQueryVariables["filter"]>>({ sinceDate: startOfDay(new Date()), untilDate: startOfDay(new Date()), schoolIds: [] })

	const [{ data, fetching }] = useSchoolWisePartialAttendanceStatsQuery({ variables: { filter }, pause: !meData?.me?.school?._id })

	useEffect(() => {
		if (meData?.me?.school?._id) {
			setFilter((prev) => ({ ...prev, schoolIds: [meData.me?.school?._id || ""] }))
		}
	}, [meData])

	const [{ data: mealSlots }] = useSchoolAttendanceSlotsQuery()

	const attendanceData = useMemo(() => {
		return data?.schoolWisePartialAttendanceStats.map((s) => {
			const schoolStats: SchoolStats = {}

			s.stats
				.map((x) => x)

				.map((d) => {
					const key = d.meal.key as string
					if (schoolStats[key]) {
						const total = d.meal.totalStudentsCount + schoolStats[key].total
						const persent = d.meal.presentStudentsCount + schoolStats[key].persent
						schoolStats[key].total = total
						schoolStats[key].persent = persent

						schoolStats[key].percent = roundDecimalNumber((persent / total) * 100)
					} else {
						schoolStats[key] = {
							total: d.meal.totalStudentsCount,
							persent: d.meal.presentStudentsCount,
							percent: roundDecimalNumber((d.meal.presentStudentsCount / d.meal.totalStudentsCount) * 100) || 0,
						}
					}
				})

			const dataKeys = Object.keys(schoolStats)
			setMealKeys(dataKeys)

			return {
				school: s.school.name,
				...schoolStats,
			}
		})
	}, [JSON.stringify(data?.schoolWisePartialAttendanceStats ?? {})])

	const mealTypesData = useMemo(() => {
		const mealData = mealSlots?.schoolAttendanceSlots.map((meal) => meal).filter((meal) => mealKeys.includes(meal.attendanceSlot.key))
		const mealNamesArray = mealData?.map((meal) => meal.attendanceSlot.name)

		if (!mealNamesArray?.length) {
			return []
		}
		const sortedData = mealNamesArray
			.map((mealName) => {
				if (mealData) {
					const meal = mealData.find((m) => m.attendanceSlot.name === mealName)
					return meal
				}
			})
			.filter(Boolean)

		return sortedData
	}, [mealSlots, mealKeys])

	const navigate = useNavigate()

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Daily Attendance</CardTitle>
			</CardHeader>
			<CardContent className="relative">
				<VStack className="gap-4 select-none">
					<VStack className="lg:flex-row items-stretch">
						<Popover>
							<PopoverTrigger asChild>
								<Button id="date" variant="outline" className={cn("flex-1/2 justify-start text-left font-normal", !(filter.sinceDate && filter.untilDate) && "text-muted-foreground")}>
									<CalendarIcon className="mr-2 h-4 w-4" />
									{filter.sinceDate ? (
										filter.untilDate ? (
											<>
												{format(filter.sinceDate, "MMM dd, yyyy")} - {format(filter.untilDate, "MMM dd, yyyy")}
											</>
										) : (
											format(filter.sinceDate, "MMM dd, yyyy")
										)
									) : (
										<span>Pick a date</span>
									)}
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-auto p-0" align="start">
								<Calendar
									initialFocus
									mode="range"
									defaultMonth={filter.sinceDate}
									selected={{ from: filter.sinceDate, to: filter.untilDate }}
									onSelect={(date) => setFilter((prev) => ({ ...prev, sinceDate: date?.from || date?.to, untilDate: date?.to || date?.from }))}
									toDate={new Date()}
									numberOfMonths={1}
								/>
							</PopoverContent>
						</Popover>
					</VStack>

					<AspectRatio ratio={16 / 9}>
						<ResponsiveContainer width="100%" height="100%">
							{fetching ? (
								<ChartSkeleton />
							) : !attendanceData?.length ? (
								<EmptyChart />
							) : (
								<BarChart
									width={500}
									height={300}
									data={attendanceData}
									margin={{
										top: 20,
										bottom: 5,
									}}
									onClick={() => navigate("/attendance/ngo-partner", { state: { filter } })}
								>
									<XAxis dataKey="school" />
									<YAxis domain={[1, 100]} />
									<Tooltip formatter={(value) => (value ? <span>{value}%</span> : <span>0%</span>)} />

									<Legend verticalAlign="bottom" height={20} />

									{mealTypesData?.map((meal, i) => {
										return (
											<Bar
												dataKey={`${meal?.attendanceSlot.key}.percent`}
												key={meal?._id}
												label={{
													position: "top",
													fill: colors[i],
													formatter: (value: number) => (value ? value + "%" : "0%"),
												}}
												stackId={i}
												stroke={colors[i]}
												fill={colors[i]}
												id="aarea"
												name={meal?.attendanceSlot.name}
												cursor={"pointer"}
												minPointSize={3}
											/>
										)
									})}
								</BarChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}
