import React from "react"
import { CriticalityWiseAttendanceStatsChart, AttendanceStatsChart } from "../../../components/ngo-partners/charts"

export const AttendancePage: React.FC = () => {
	return (
		<div className="container p-2 lg:px-8">
			<div className="w-ful grid lg:grid-cols-2 gap-4">
				<AttendanceStatsChart />
				<CriticalityWiseAttendanceStatsChart />
				{/* <MonthlyAttendanceStatsChart /> */}
			</div>
		</div>
	)
}

export default AttendancePage
