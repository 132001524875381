import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { SchoolFragment } from "../../../graphql"
import { VStack } from "../../ui"
import { NGOPartnerListItem } from "./NGOPartnerListItem"

export type NGOPartnersListProps = {
	ngoPartners: SchoolFragment[]
	loadMore: () => void
}

export const NGOPartnersList: React.FC<NGOPartnersListProps> = ({ ngoPartners, loadMore }) => {
	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack className="items-stretch">
			{ngoPartners.map((ngoPartner) => (
				<NGOPartnerListItem key={ngoPartner._id} ngoPartner={ngoPartner} />
			))}
			<div ref={bottomRef as any} className="w-full h-4 pb-4" />
		</VStack>
	)
}
