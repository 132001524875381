import { AttendanceStatsContextAction, AttendanceStatsContextActions } from "./types"

export const queryNew = (query: AttendanceStatsContextAction["payload"]): AttendanceStatsContextAction => ({ type: AttendanceStatsContextActions.NEW, payload: query })

export const queryHandling = (query: AttendanceStatsContextAction["payload"]): AttendanceStatsContextAction => ({ type: AttendanceStatsContextActions.HANDLING, payload: query })

export const queryLoaded = (query: AttendanceStatsContextAction["payload"]): AttendanceStatsContextAction => ({ type: AttendanceStatsContextActions.LOADED, payload: query })

export const queryFailed = (query: AttendanceStatsContextAction["payload"]): AttendanceStatsContextAction => ({ type: AttendanceStatsContextActions.FAILED, payload: query })

export const queryLink = (query: AttendanceStatsContextAction["payload"]): AttendanceStatsContextAction => ({ type: AttendanceStatsContextActions.LINK, payload: query })
