/* eslint-disable react/react-in-jsx-scope */

import { startCase } from "lodash"
import { useNavigate } from "react-router-dom"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
import twcolors from "tailwindcss/colors"
import { useModelWiseSchoolsCountQuery } from "../../../../graphql"
import { useBreakpoint } from "../../../../hooks"
import { roundDecimalNumber } from "../../../../utils"
import { EmptyChart } from "../../../common"
import { AspectRatio, Card, CardContent, ChartSkeleton, VStack } from "../../../ui"

const colors: string[] = [twcolors.red[400], twcolors.purple[400], twcolors.orange[400], twcolors.teal[400]]

export const ModelWiseNGOPartnersChart: React.FC = () => {
	const [{ data, fetching }] = useModelWiseSchoolsCountQuery()

	const { isXl } = useBreakpoint("xl")
	const { isMd } = useBreakpoint("md")

	const navigate = useNavigate()

	return (
		<Card className="h-full">
			<CardContent>
				<VStack className="h-full gap-4 select-none">
					{fetching ? (
						<ChartSkeleton />
					) : !data?.modelWiseSchoolsCount ? (
						<EmptyChart />
					) : (
						<AspectRatio ratio={isXl ? 3 / 4 : isMd ? 16 / 9 : 4 / 3}>
							<ResponsiveContainer width="100%" height="100%">
								<PieChart>
									<Tooltip />
									<Legend />
									<defs>
										{data.modelWiseSchoolsCount.map((entry, index) => (
											<linearGradient key={index} id={String(index)} x1="0" y1="0" x2="0" y2="1">
												<stop offset="5%" stopColor={colors[index]} stopOpacity={0.8} />
												<stop offset="95%" stopColor={colors[index]} stopOpacity={0.2} />
											</linearGradient>
										))}
									</defs>
									<Pie
										data={data.modelWiseSchoolsCount.map((o) => ({ name: startCase(o.label), value: o.value }))}
										cx="50%"
										cy="50%"
										outerRadius={isXl ? 100 : 80}
										dataKey="value"
										activeIndex={0}
										labelLine={false}
										label={renderCustomizedLabel}
									>
										{data.modelWiseSchoolsCount.map((entry, index) => (
											<Cell key={`cell-${index}`} className="cursor-pointer" fill={colors[index]} onClick={() => navigate(`/ngo-partners/list?model=${entry.label}`)} />
										))}
									</Pie>
								</PieChart>
							</ResponsiveContainer>
						</AspectRatio>
					)}
				</VStack>
			</CardContent>
		</Card>
	)
}

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5
	const x = cx + radius * Math.cos(-midAngle * RADIAN)
	const y = cy + radius * Math.sin(-midAngle * RADIAN)

	return (
		<text x={x} y={y} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
			{roundDecimalNumber(percent * 100)}%
		</text>
	)
}
