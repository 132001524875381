import React from "react"
import { useStudentsOfSchoolWithoutExtensionQuery } from "../../../graphql"
import { exportCSVData } from "../../../utils"
import { format } from "date-fns"
import { Button } from "../../ui"

export type GenrateClassTemplateProps = {
	schoolId: string
}

export const GenrateClassTemplate: React.FC<GenrateClassTemplateProps> = ({ schoolId }) => {
	const [{ data }] = useStudentsOfSchoolWithoutExtensionQuery({ variables: { schoolId } })

	const handleExport = () => {
		if (!data?.studentsOfSchoolWithoutExtension.length) return

		const csv = "Name,RegNo,Gender,Class,ClassSection".concat("\n", ...(data?.studentsOfSchoolWithoutExtension || []).map((s) => ` ${s.name},${s.regNo},${s.gender}, , ,\n`))

		return exportCSVData(csv, `Class Template ${format(new Date(), "MMM dd, yyyy p")}`)
	}

	return <>{data?.studentsOfSchoolWithoutExtension.length && <Button onClick={handleExport}>Generate Class Template</Button>}</>
}
