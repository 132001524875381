import React from "react"
import { useParams } from "react-router-dom"
import { LoadingLabel } from "../../../components/ui/loading-label"

import { useStudentByIdQuery } from "../../../graphql"
import UpdateBeneficiaryProfile from "../../../components/ngo-partners/beneficiary/UpdateBeneficiaryProfile"

const UpdateBeneficiaryProfilePage: React.FC = () => {
	const { beneficiaryId: studentId = "" } = useParams<{ beneficiaryId: string }>()

	const [{ data, fetching, error }] = useStudentByIdQuery({ variables: { studentId }, pause: !studentId })

	return (
		<div className="container w-full mx-auto p-8 m-10">
			<h1 className="text-lg w-fit mx-auto font-bold mb-4 text-gray-800">Update Student Profile</h1>
			{fetching ? (
				<div className="mx-auto w-44">
					<div className=" flex gap-1 text-gray-500 ">
						<h2 className="text-sm">Loading student</h2>
						<LoadingLabel />
					</div>
				</div>
			) : error ? (
				<div className="p-8 mx-auto w-fit">
					<h2 className="text-sm text-red-600">{error.message.replace("[GraphQL] ", "")}</h2>
				</div>
			) : data?.studentById ? (
				<UpdateBeneficiaryProfile beneficiary={data?.studentById} />
			) : (
				<div className="p-8 lg:mx-auto w-fit">
					<h2 className="text-sm text-red-600">Couldn&apos;t find the student.</h2>
				</div>
			)}
		</div>
	)
}

export default UpdateBeneficiaryProfilePage
