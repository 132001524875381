import { ArrowLeft, ArrowRight } from "lucide-react"
import React from "react"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { ValidTillBanner } from "../../common/ValidTillBanner"
import { Button, HStack, Separator } from "../../ui"
import { AccountMenu } from "./AccountMenu"
import { BottomTabs } from "./BottomTabs"
import { TopNavigation } from "./TopNavigation"

export const Scaffold: React.FC = () => {
	const navigate = useNavigate()

	return (
		<div className="min-h-[100dvh] pb-24">
			<HStack className="sticky top-0 py-2 backdrop-filter backdrop-blur-md z-10 justify-center">
				<HStack className="px-4 gap-4 hidden lg:flex">
					<Button
						variant="link"
						disabled={history.state.idx === 0}
						size="icon"
						className="group w-8 h-8"
						onClick={() => {
							if (history.state.idx > 0) navigate(-1)
						}}
					>
						<ArrowLeft className="w-4 h-4 group-hover:-translate-x-2 group-hover:scale-125 transition-transform ease-in-out" />
					</Button>

					<Button
						variant="link"
						size="icon"
						className="group w-8 h-8"
						disabled={history.length === history.state.idx + 2}
						onClick={() => {
							if (history.length > history.state.idx + 2) navigate(1)
						}}
					>
						<ArrowRight className="w-4 h-4 group-hover:translate-x-2 group-hover:scale-125 transition-transform ease-in-out" />
					</Button>
				</HStack>
				<div className="container flex flex-row items-center justify-between">
					<HStack className="gap-6">
						<Link to="/">
							<div className="w-24 h-6 bg-[url('assets/feedingindia-192.png')] bg-contain bg-no-repeat" />
						</Link>

						<TopNavigation />
					</HStack>
					<AccountMenu />
				</div>
			</HStack>
			<Separator />
			<div className="w-full h-full">
				<ValidTillBanner />
				<Outlet />
			</div>
			<BottomTabs />
		</div>
	)
}

export default Scaffold
