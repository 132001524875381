import React from "react"
import { AddNewClass } from "../../../components/ngo-partners/class/AddNewClass"

const AddNewClassPage: React.FC = () => {
	return (
		<div className="container">
			<AddNewClass />
		</div>
	)
}

export default AddNewClassPage
