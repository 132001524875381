import * as React from "react"
import { cn } from "../../lib/utils"

export type VStackProps = React.HTMLAttributes<HTMLDivElement> & React.PropsWithChildren

const VStack = React.forwardRef<HTMLDivElement, VStackProps>(({ className, children, ...props }, ref) => {
	return (
		<div className={cn("flex flex-col gap-2 w-full items-center", className)} ref={ref} {...props}>
			{children}
		</div>
	)
})

VStack.displayName = "VStack"

export { VStack }
