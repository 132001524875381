import React from "react"
import { CreateMealSlot } from "../../../components/admin/meal/CreateMealSlot"

const CreateMealSlotPage: React.FC = () => {
	return (
		<div className="container">
			<CreateMealSlot />
		</div>
	)
}

export default CreateMealSlotPage
