/* eslint-disable react/react-in-jsx-scope */

import { format, startOfDay, subMonths } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from "recharts"
import colors from "tailwindcss/colors"
import { PartialStudentFragment, SchoolWiseAttendanceStatsQueryVariables, useMeQuery, useSchoolWiseAttendanceStatsQuery } from "../../../../graphql"
import { useBreakpoint } from "../../../../hooks"
import { cn } from "../../../../lib/utils"
import { EmptyChart } from "../../../common"
import { AspectRatio, Button, Calendar, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, ErrorLabel, Popover, PopoverContent, PopoverTrigger, VStack } from "../../../ui"

export const CriticalityWiseAttendanceStatsChart: React.FC = () => {
	const [{ data: meData }] = useMeQuery()

	const [filter, setFilter] = useState<Required<SchoolWiseAttendanceStatsQueryVariables["filter"]>>({ sinceDate: startOfDay(subMonths(new Date(), 1)), untilDate: startOfDay(new Date()), schoolIds: [] })

	useEffect(() => {
		if (meData?.me?.school?._id) {
			setFilter((prev) => ({ ...prev, schoolIds: [meData.me?.school?._id || ""] }))
		}
	}, [meData])

	const [{ data, fetching, error }] = useSchoolWiseAttendanceStatsQuery({ variables: { filter }, pause: !meData?.me?.school?._id || !filter.sinceDate || !filter.untilDate })

	const ngoPartnersData = useMemo(() => data?.schoolWiseAttendanceStats?.[0], [data])

	const { beneficiaries, criticalBeneficiaries, atRiskBeneficiaries, wellBeneficiaries } = useMemo(() => {
		const criticalBeneficiaries: { beneficiary: PartialStudentFragment; percent: number }[] = []
		const atRiskBeneficiaries: { beneficiary: PartialStudentFragment; percent: number }[] = []
		const wellBeneficiaries: { beneficiary: PartialStudentFragment; percent: number }[] = []

		const beneficiaries: { beneficiary: PartialStudentFragment; total: number; present: number; percent: number }[] = []

		ngoPartnersData?.stats.forEach((dayStat) => {
			dayStat.totalStudents.forEach((beneficiary) => {
				const isPresent = !!dayStat.presentStudents.find((s) => s._id === beneficiary._id)

				const index = beneficiaries.findIndex((s) => s.beneficiary._id === beneficiary._id)

				if (index >= 0) {
					beneficiaries[index].total++
					beneficiaries[index].present = beneficiaries[index].present + (isPresent ? 1 : 0)
					beneficiaries[index].percent = (beneficiaries[index].present / beneficiaries[index].total) * 100
				} else {
					beneficiaries.push({
						beneficiary,
						total: 1,
						present: isPresent ? 1 : 0,
						percent: isPresent ? 100 : 0,
					})
				}
			})
		})

		beneficiaries.map(({ percent, beneficiary }) => {
			if (percent >= 80) {
				wellBeneficiaries.push({ percent, beneficiary })
			} else if (percent >= 50) {
				atRiskBeneficiaries.push({ percent, beneficiary })
			} else {
				criticalBeneficiaries.push({ percent, beneficiary })
			}
		})

		return { beneficiaries, criticalBeneficiaries, atRiskBeneficiaries, wellBeneficiaries }
	}, [ngoPartnersData])

	const criticalChartData = useMemo(
		() => [
			{
				name: "Critical",
				value: criticalBeneficiaries.length,
			},
			{ name: "Remaining", value: beneficiaries.length - criticalBeneficiaries.length },
		],
		[criticalBeneficiaries]
	)

	const atRiskChartData = useMemo(
		() => [
			{
				name: "At-risk",
				value: atRiskBeneficiaries.length,
			},
			{ name: "Remaining", value: beneficiaries.length - atRiskBeneficiaries.length },
		],
		[atRiskBeneficiaries]
	)

	const wellChartData = useMemo(
		() => [
			{
				name: "Well",
				value: wellBeneficiaries.length,
			},
			{ name: "Remaining", value: beneficiaries.length - wellBeneficiaries.length },
		],
		[wellBeneficiaries]
	)

	const { isXl } = useBreakpoint("xl")

	const navigate = useNavigate()

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Attendance Overview for beneficiaries</CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="h-full gap-4 select-none">
					<VStack className="lg:flex-row items-stretch">
						<Popover>
							<PopoverTrigger asChild>
								<Button id="date" variant="outline" className={cn("flex-1/2 justify-start text-left font-normal", !(filter.sinceDate && filter.untilDate) && "text-muted-foreground")}>
									<CalendarIcon className="mr-2 h-4 w-4" />
									{filter.sinceDate ? (
										filter.untilDate ? (
											<>
												{format(filter.sinceDate, "MMM dd, yyyy")} - {format(filter.untilDate, "MMM dd, yyyy")}
											</>
										) : (
											format(filter.sinceDate, "MMM dd, yyyy")
										)
									) : (
										<span>Pick a date</span>
									)}
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-auto p-0" align="start">
								<Calendar
									initialFocus
									mode="range"
									defaultMonth={filter.sinceDate}
									selected={{ from: filter.sinceDate, to: filter.untilDate }}
									onSelect={(date) => setFilter((prev) => ({ ...prev, sinceDate: date?.from || date?.to, untilDate: date?.to || date?.from }))}
									toDate={new Date()}
									numberOfMonths={1}
								/>
							</PopoverContent>
						</Popover>
					</VStack>

					{fetching ? (
						<div className="w-full h-full min-h-[200px]">
							<AspectRatio ratio={16 / 9}>
								<ChartSkeleton />
							</AspectRatio>
						</div>
					) : error ? (
						<ErrorLabel className="flex-1">{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
					) : !ngoPartnersData ? (
						<EmptyChart />
					) : (
						<div tabIndex={0} className="w-full h-full grid md:grid-cols-3 gap-2" onClick={() => navigate("/attendance/overview", { state: { filter: { sinceDate: filter.sinceDate, untilDate: filter.untilDate } } })}>
							<AspectRatio ratio={isXl ? 3 / 4 : 16 / 9}>
								<VStack className="h-full">
									<ResponsiveContainer width="100%" height="100%">
										<PieChart>
											<Tooltip />
											<Legend payload={[{ value: "<50%", legendIcon: <></> }]} />
											<defs>
												<linearGradient id="critical" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.red[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.red[400]} stopOpacity={0.2} />
												</linearGradient>
												<linearGradient id="remaining" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.gray[400]} stopOpacity={0.2} />
												</linearGradient>
											</defs>
											<Pie data={criticalChartData} cx="50%" cy="50%" innerRadius={isXl ? 60 : 40} outerRadius={isXl ? 80 : 60} paddingAngle={5} dataKey="value" activeIndex={0} activeShape={renderActiveShape}>
												{criticalChartData.map((entry, index) => (
													<Cell key={`cell-${index}`} fill={["url(#critical)", "url(#remaining)"][index]} />
												))}
											</Pie>
										</PieChart>
									</ResponsiveContainer>
								</VStack>
							</AspectRatio>

							<AspectRatio ratio={isXl ? 3 / 4 : 16 / 9}>
								<VStack className="h-full">
									<ResponsiveContainer width="100%" height="100%">
										<PieChart>
											<Tooltip />
											<Legend payload={[{ value: "50-80%", legendIcon: <></> }]} />
											<defs>
												<linearGradient id="at-risk" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.orange[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.orange[400]} stopOpacity={0.2} />
												</linearGradient>
												<linearGradient id="remaining" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.gray[400]} stopOpacity={0.2} />
												</linearGradient>
											</defs>

											<Pie data={atRiskChartData} cx="50%" cy="50%" innerRadius={isXl ? 60 : 40} outerRadius={isXl ? 80 : 60} paddingAngle={5} dataKey="value" activeIndex={0} activeShape={renderActiveShape}>
												{atRiskChartData.map((entry, index) => (
													<Cell key={`cell-${index}`} fill={["url(#at-risk)", "url(#remaining)"][index]} />
												))}
											</Pie>
										</PieChart>
									</ResponsiveContainer>
								</VStack>
							</AspectRatio>

							<AspectRatio ratio={isXl ? 3 / 4 : 16 / 9}>
								<VStack className="h-full">
									<ResponsiveContainer width="100%" height="100%">
										<PieChart>
											<Tooltip />
											<Legend payload={[{ value: "80% +", legendIcon: <></> }]} />
											<defs>
												<linearGradient id="well" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.green[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.green[400]} stopOpacity={0.2} />
												</linearGradient>
												<linearGradient id="remaining" x1="0" y1="0" x2="0" y2="1">
													<stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
													<stop offset="95%" stopColor={colors.gray[400]} stopOpacity={0.2} />
												</linearGradient>
											</defs>
											<Pie data={wellChartData} cx="50%" cy="50%" innerRadius={isXl ? 60 : 40} outerRadius={isXl ? 80 : 60} paddingAngle={5} dataKey="value" activeIndex={0} activeShape={renderActiveShape}>
												{wellChartData.map((entry, index) => (
													<Cell key={`cell-${index}`} fill={["url(#well)", "url(#remaining)"][index]} />
												))}
											</Pie>
										</PieChart>
									</ResponsiveContainer>
								</VStack>
							</AspectRatio>
						</div>
					)}
				</VStack>
			</CardContent>
		</Card>
	)
}

const renderActiveShape = (props: any) => {
	const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, value } = props

	return (
		<g>
			<text x={cx} y={cy} dy={8} className="text-3xl font-bold" textAnchor="middle" fill={fill}>
				{value}
			</text>
			<Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
		</g>
	)
}
