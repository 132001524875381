import React, { Fragment } from "react"
import { useParams } from "react-router-dom"
import { useSchoolAttendanceSlotByIdQuery } from "../../../graphql"
import { NGOPartnerTag } from "../../../components"
import { LoadingLabel } from "../../../components/ui/loading-label"
import { DeleteMealSlot } from "../../../components/admin/meal/DeleteMealSlot"

const DeleteMealSlotPage: React.FC = () => {
	const { slotId = "" } = useParams<{ slotId: string }>()

	const [{ data, fetching, error }] = useSchoolAttendanceSlotByIdQuery({ variables: { slotId }, pause: !slotId })

	return (
		<Fragment>
			<div className="container mx-auto py-4 px-8">
				<h1 className="text-lg font-bold text-gray-900 mt-8 mb-6">Delete Meal Slot</h1>

				{fetching ? (
					<div className="mx-auto w-44">
						<div className=" flex gap-1 text-gray-500 ">
							<h2 className="text-sm">Loading meal slot</h2>
							<LoadingLabel />
						</div>
					</div>
				) : error ? (
					<div className="p-8 mx-auto w-fit">
						<h2 className="text-sm text-red-600">{error.message.replace("[GraphQL] ", "")}</h2>
					</div>
				) : data?.schoolAttendanceSlotById ? (
					<>
						<NGOPartnerTag ngoPartner={data.schoolAttendanceSlotById.school} />

						<DeleteMealSlot slot={data.schoolAttendanceSlotById} />
					</>
				) : (
					<div className="p-8 mx-auto w-fit">
						<h2 className="text-sm text-red-600">Couldn&apos;t find the meal slot.</h2>
					</div>
				)}
			</div>
		</Fragment>
	)
}

export default DeleteMealSlotPage
