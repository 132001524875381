import React, { useMemo } from "react"

import { Input, Button } from "../../../components/ui"
import { useSearchParams } from "../../../hooks"
import { SearchResultClasses } from "../../../components/ngo-partners/class/SearchResultClasses"
import { useNavigate } from "react-router-dom"

import { useMeQuery } from "../../../graphql"

export const ClassesPage: React.FC = () => {
	const [searchParams, { handleSearchParamUpdate, handleSearchParamDelete }] = useSearchParams()

	const navigate = useNavigate()

	const [meQuery] = useMeQuery()
	const canCreateClass: boolean = useMemo(() => {
		// if the login user is admin then it can create the class
		if (meQuery && meQuery.data && meQuery.data.me && meQuery.data.me.admin) return true

		return false
	}, [meQuery])

	return (
		<div className="container w-full min-h-screen">
			<div className="flex flex-col gap-y-4 md:gap-y-0 md:gap-x-4 items-center   md:flex-row md:justify-end mt-4 md:mt-10">
				<div>
					<Input
						type="search"
						placeholder="Search..."
						value={(searchParams.get("keyword") as string) || ""}
						onChange={(e) => {
							if (e.target.value) handleSearchParamUpdate("keyword", e.target.value)
							else handleSearchParamDelete("keyword")
						}}
					/>
				</div>
				{canCreateClass && (
					<div>
						<Button className="text-white bg-green-500  md:text-lg text-sm  font-bold hover:cursor-pointer hover:opacity-50 md:px-5 md:py-5 py-4 px-4 shadow-sm rounded-lg" onClick={() => navigate("/classes/new")}>
							Create Class
						</Button>
					</div>
				)}
			</div>

			{/* <h2 className="text-gray-700 text-lg font-semibold mt-4 mb-4">Classes</h2> */}
			<SearchResultClasses keyword={(searchParams.get("keyword") as string) || undefined} />
		</div>
	)
}
