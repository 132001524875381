import { AttendanceStatsContextAction, AttendanceStatsContextActions, AttendanceStatsContextState } from "./types"

export const initialState: AttendanceStatsContextState = { queries: [] }

export const reducer = (state: AttendanceStatsContextState = initialState, action: AttendanceStatsContextAction): AttendanceStatsContextState => {
	if (action.type === AttendanceStatsContextActions.NEW) {
		const id = JSON.stringify(action.payload.filter)
		const isUnique = !state.queries.find((q) => q.id === id)

		if (isUnique) return { ...state, queries: [...state.queries, { id, filter: action.payload.filter, waiting: true }] }

		return state
	}

	if (action.type === AttendanceStatsContextActions.HANDLING) {
		const updatedQueries = state.queries.map((q) => {
			if (q.id === JSON.stringify(action.payload.filter)) {
				return { ...q, handling: true, data: action.payload.data }
			}

			return q
		})

		return { ...state, queries: updatedQueries }
	}

	if (action.type === AttendanceStatsContextActions.LOADED) {
		const updatedQueries = state.queries.map((q) => {
			if (q.id === JSON.stringify(action.payload.filter)) {
				return { ...q, waiting: false, handling: false, data: action.payload.data }
			}

			return q
		})

		return { ...state, queries: updatedQueries }
	}

	if (action.type === AttendanceStatsContextActions.FAILED) {
		const updatedQueries = state.queries.map((q) => {
			if (q.id === JSON.stringify(action.payload.filter)) {
				return { ...q, waiting: false, handling: false, failed: true }
			}

			return q
		})

		return { ...state, queries: updatedQueries }
	}

	if (action.type === AttendanceStatsContextActions.LINK) {
		const updatedQueries = state.queries.map((q) => {
			if (q.id === JSON.stringify(action.payload.filter)) {
				return { ...q, superQueryIds: action.payload.superQueryIds }
			}

			return q
		})

		return { ...state, queries: updatedQueries }
	}

	return initialState
}
