import React, { useCallback, useState } from "react"
import { PastBmIofStudentQueryVariables, usePastBmIofStudentQuery } from "../../../graphql"
import { ErrorLabel, Skeleton, VStack } from "../../ui"
import { BMIsList } from "./BMIsList"

export type BeneficiaryPastBMIsProps = {
	beneficiaryId: string
}

export const BeneficiaryPastBMIs: React.FC<BeneficiaryPastBMIsProps> = ({ beneficiaryId }) => {
	const [pagination, setPagination] = useState<PastBmIofStudentQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = usePastBmIofStudentQuery({ variables: { studentId: beneficiaryId, pagination } })

	const nextPage = useCallback(() => {
		if (data?.pastBMIofStudent.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data?.pastBMIofStudent.hasNextPage])

	return (
		<>
			{fetching ? (
				<VStack className="w-full max-w-xl items-stretch gap-2">
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.pastBMIofStudent.pastBMIs && data?.pastBMIofStudent.pastBMIs.length ? (
				<BMIsList bmis={data?.pastBMIofStudent.pastBMIs} loadMore={nextPage} />
			) : (
				<ErrorLabel>Cound not find any past BMIs of the beneficiary</ErrorLabel>
			)}
		</>
	)
}
