/* eslint-disable @typescript-eslint/indent */
import { useContext, useEffect } from "react"
import { Subject } from "rxjs"
import { urqlClient } from ".."
import { AttendanceStatsContext, AttendanceStatsContextState, Query } from "../context"
import * as actions from "../context/AttendanceStats/actions"
import { SchoolWisePartialAttendanceStatsDocument, SchoolWisePartialAttendanceStatsQuery, SchoolWisePartialAttendanceStatsQueryVariables, SchoolWisePartialAttendanceStatsResponse } from "../graphql"

const subject$ = new Subject<Query>()

export const useAttendanceStatsContext = (
	isMaster?: boolean
): [
	state: AttendanceStatsContextState,
	queryNew: (
		query: Omit<Query, "waiting" | "id"> & {
			superQueryIds?: string[] | undefined
		}
	) => void
] => {
	const [state, dispatch] = useContext(AttendanceStatsContext)

	const handleQuery = async (query: Query) => {
		dispatch(actions.queryHandling(query))

		const superQueries = state.queries.filter(
			(q) =>
				(!q.filter.schoolIds?.length || query.filter.schoolIds?.every((id) => q.filter.schoolIds?.includes(id))) &&
				new Date(q.filter.sinceDate) <= new Date(query.filter.sinceDate) &&
				new Date(q.filter.untilDate) >= new Date(query.filter.untilDate) &&
				q.id !== query.id
		)

		if (!superQueries.length) {
			const { data, error } = await urqlClient.query<SchoolWisePartialAttendanceStatsQuery, SchoolWisePartialAttendanceStatsQueryVariables>(SchoolWisePartialAttendanceStatsDocument, { filter: query.filter }).toPromise()

			if (error) {
				return dispatch(actions.queryFailed(query))
			}

			if (data) {
				dispatch(actions.queryLoaded({ ...query, data }))

				subject$.next({ ...query, data })

				return
			}
		}

		const loadedSuperQuery = superQueries.find((q) => !q.waiting)

		if (!loadedSuperQuery) {
			return dispatch(actions.queryLink({ ...query, superQueryIds: superQueries.map((q) => q.id) }))
		}

		let subQueryData: SchoolWisePartialAttendanceStatsResponse[] = []

		if (query.filter.schoolIds?.length) {
			subQueryData =
				loadedSuperQuery.data?.schoolWisePartialAttendanceStats
					.filter((s) => query.filter.schoolIds?.includes(s.school._id))
					.map((stat) => ({ ...stat, stats: stat.stats.filter((s) => new Date(s.date) >= new Date(query.filter.sinceDate) && new Date(s.date) <= new Date(query.filter.untilDate)) })) || []
		} else {
			subQueryData =
				loadedSuperQuery.data?.schoolWisePartialAttendanceStats.map((stat) => ({
					...stat,
					stats: stat.stats.filter((s) => new Date(s.date) >= new Date(query.filter.sinceDate) && new Date(s.date) <= new Date(query.filter.untilDate)),
				})) || []
		}

		dispatch(actions.queryLoaded({ ...query, data: { ...query.data, schoolWisePartialAttendanceStats: subQueryData } }))

		return dispatch(actions.queryLoaded({ ...query, data: { ...query.data, schoolWisePartialAttendanceStats: subQueryData } }))
	}

	const queryNew = (
		query: Omit<Query, "waiting" | "id"> & {
			superQueryIds?: string[] | undefined
		}
	) => dispatch(actions.queryNew(query))

	useEffect(() => {
		if (isMaster && state.queries.length) {
			Promise.all(state.queries.filter((q) => q.waiting && !q.handling && !q.failed && !q.data).map(handleQuery))
		}
	}, [isMaster, state.queries])

	subject$.subscribe((query) => {
		state.queries
			.filter((q) => q.superQueryIds?.includes(query.id) && q.waiting)
			.forEach((subQuery) => {
				let subQueryData: SchoolWisePartialAttendanceStatsResponse[] = []

				if (subQuery.filter.schoolIds?.length) {
					subQueryData =
						query.data?.schoolWisePartialAttendanceStats
							.filter((s) => subQuery.filter.schoolIds?.includes(s.school._id))
							.map((stat) => ({ ...stat, stats: stat.stats.filter((s) => new Date(s.date) >= new Date(subQuery.filter.sinceDate) && new Date(s.date) <= new Date(subQuery.filter.untilDate)) })) || []
				} else {
					subQueryData =
						query.data?.schoolWisePartialAttendanceStats.map((stat) => ({
							...stat,
							stats: stat.stats.filter((s) => new Date(s.date) >= new Date(subQuery.filter.sinceDate) && new Date(s.date) <= new Date(subQuery.filter.untilDate)),
						})) || []
				}

				dispatch(actions.queryLoaded({ ...subQuery, data: { ...subQuery.data, schoolWisePartialAttendanceStats: subQueryData } }))
			})
	})

	return [state, queryNew]
}
