import { format } from "date-fns"
import React, { useEffect, useMemo, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { BeneficiaryTag } from ".."
import { Badge, HStack, Progress, VStack } from "../.."
import { PartialStudentFragment, PartialStudentWithAttendanceFragment, StudentAttendanceStatFragment, useMeQuery, useSchoolAttendanceSlotByIdQuery, useSchoolWiseAttendanceStatsQuery } from "../../../graphql"
import { usePagination } from "../../../hooks"
import { roundDecimalNumber } from "../../../utils"
import { BeneficiaryAttendanceImage } from "../../ngo-partners/attendance"
import { ErrorLabel, Skeleton } from "../../ui"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../ui/accordion"

export type NGOPartnerAttendanceStatsProps = {
	ngoPartnerId: string
	sinceDate: Date
	untilDate: Date
}

export const NGOPartnerAttendanceStats: React.FC<NGOPartnerAttendanceStatsProps> = ({ ngoPartnerId, sinceDate, untilDate }) => {
	const [{ data: _data, fetching, error }] = useSchoolWiseAttendanceStatsQuery({ variables: { filter: { sinceDate, untilDate, schoolIds: [ngoPartnerId] } } })

	const data = useMemo(() => _data?.schoolWiseAttendanceStats[0], [_data])

	return (
		<VStack className="w-full items-stretch">
			{fetching ? (
				<VStack className="w-full items-stretch gap-2">
					<Skeleton className="w-full max-w-xl h-36" />
					<Skeleton className="w-full max-w-xl h-8 bg-green-100" />
					<Skeleton className="w-full max-w-xl h-8 bg-red-100" />
					<Skeleton className="w-full max-w-xl h-8 bg-green-100" />
					<Skeleton className="w-full max-w-xl h-8 bg-red-100" />
					<Skeleton className="w-full max-w-xl h-8 bg-green-100" />
					<Skeleton className="w-full max-w-xl h-8 bg-red-100" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message}</ErrorLabel>
			) : !data?.stats.length ? (
				<ErrorLabel>No meal slot is available</ErrorLabel>
			) : (
				<VStack className="w-full items-stretch">
					{data.stats.map((stat) => (
						<StatItem key={stat._id} stat={stat} />
					))}
				</VStack>
			)}
		</VStack>
	)
}

type StatItemProps = {
	stat: StudentAttendanceStatFragment
}

const StatItem: React.FC<StatItemProps> = ({ stat }) => {
	const [{ data }] = useSchoolAttendanceSlotByIdQuery({ variables: { slotId: stat.schoolWiseAttendanceSlotId }, pause: !stat.schoolWiseAttendanceSlotId })

	const totalStudents = useMemo(() => [...new Set(stat.totalStudents.map((o) => o._id))].map((id) => stat.totalStudents.find((o) => o._id === id)) as PartialStudentFragment[], [stat])

	const presentStudents = useMemo(() => [...new Set(stat.presentStudents.map((o) => o._id))].map((id) => stat.presentStudents.find((o) => o._id === id)) as PartialStudentWithAttendanceFragment[], [stat])

	const absentBeneficiaries = useMemo(() => totalStudents.filter((s) => !presentStudents.find((p) => p._id === s._id)), [stat])

	return (
		<VStack className="w-full items-stretch">
			<h2 className="text-lg mt-4 mb-4 font-medium text-gray-700">
				{data?.schoolAttendanceSlotById.school.name} - {data?.schoolAttendanceSlotById.attendanceSlot.name}
			</h2>
			<HStack className="w-full justify-start">
				<h2 className="w-36 text-sm font-medium">{format(new Date(stat.date), "MMM dd, yyyy")}</h2>
				<div className="relative w-full ">
					<Progress value={roundDecimalNumber(presentStudents.length / stat.totalStudents.length) * 100 || 0} className="bg-red-400 h-6" indicator={{ className: "bg-green-400" }} />
					<span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-slate-100 text-xs font-bold">{roundDecimalNumber((presentStudents.length / totalStudents.length) * 100 || 0)}%</span>
				</div>
			</HStack>

			<Accordion type="single" collapsible>
				{presentStudents.length ? (
					<AccordionItem value="present">
						<AccordionTrigger>
							<HStack className="w-full justify-between pr-4">
								<span className="text-green-600">Present beneficiaries</span>
								<Badge className="bg-green-100 text-green-600">{presentStudents.length}</Badge>
							</HStack>
						</AccordionTrigger>
						<AccordionContent>
							<PresentBeneficiariesItem beneficiaries={presentStudents} schoolId={stat.schoolId} />
						</AccordionContent>
					</AccordionItem>
				) : (
					<></>
				)}
				{absentBeneficiaries.length ? (
					<AccordionItem value="absent">
						<AccordionTrigger>
							<HStack className="w-full justify-between pr-4">
								<span className="text-red-600">Absent beneficiaries</span>
								<Badge className="bg-red-100 text-red-600">{absentBeneficiaries.length}</Badge>
							</HStack>
						</AccordionTrigger>
						<AccordionContent>
							<AbsentBeneficiariesItem beneficiaries={absentBeneficiaries} />
						</AccordionContent>
					</AccordionItem>
				) : (
					<></>
				)}
			</Accordion>
		</VStack>
	)
}

type PresentBeneficiariesItemProps = {
	beneficiaries: PartialStudentWithAttendanceFragment[]
	schoolId?: string
}

const PresentBeneficiariesItem: React.FC<PresentBeneficiariesItemProps> = ({ beneficiaries, schoolId }) => {
	const [{ data: meData }] = useMeQuery()

	const canSeeAttendanceImage = useMemo(() => meData?.me?.school?._id === schoolId, [meData])

	const { currentItems, loadMore } = usePagination<PartialStudentWithAttendanceFragment>(beneficiaries, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack className="w-full items-stretch">
			{currentItems.map((beneficiary) => (
				<HStack key={beneficiary.attendanceId} className="w-full p-4 bg-green-200 rounded-xl justify-between">
					<BeneficiaryTag beneficiary={beneficiary as PartialStudentFragment} />
					<VStack className="w-56 gap-0">
						<span className="text-sm font-bold text-slate-600">{format(new Date(beneficiary.inAt), "p")}</span>
						<span className="text-xs font-medium text-slate-500">Time</span>
					</VStack>
					{canSeeAttendanceImage ? <BeneficiaryAttendanceImage attendanceId={beneficiary.attendanceId} beneficiary={beneficiary} /> : <></>}
				</HStack>
			))}

			<div ref={bottomRef as any} className="w-full h-4 pb-4" />
		</VStack>
	)
}

type AbsentBeneficiariesItemProps = {
	beneficiaries: PartialStudentFragment[]
}

const AbsentBeneficiariesItem: React.FC<AbsentBeneficiariesItemProps> = ({ beneficiaries }) => {
	const { currentItems, loadMore } = usePagination<PartialStudentFragment>(beneficiaries, 15)

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<VStack className="w-full items-stretch">
			{currentItems.map((beneficiary) => (
				<HStack key={beneficiary._id} className="p-4 bg-red-200 rounded-xl">
					<BeneficiaryTag beneficiary={beneficiary as PartialStudentFragment} />
				</HStack>
			))}
			<div ref={bottomRef as any} className="w-full h-4 pb-4" />
		</VStack>
	)
}
