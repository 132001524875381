import React from "react"
import AddNewClassForm from "../../../forms/classes/AddNewClassForm"

export const AddNewClass: React.FC = () => {
	return (
		<div className="px-10 py-4 ">
			<h1 className="text-gray-700 text-lg font-bold">New Class</h1>
			<div className="mt-10">
				<AddNewClassForm />
			</div>
		</div>
	)
}
