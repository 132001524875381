/* eslint-disable react/react-in-jsx-scope */

import { AspectRatio } from "@radix-ui/react-aspect-ratio"
import { useMemo, useState } from "react"
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import colors from "tailwindcss/colors"
import { SchoolWiseAttendanceStatsResponse } from "../../../graphql"
import { roundDecimalNumber } from "../../../utils"

export type NGOPartnerAttendanceStatsChartProps = {
	data: SchoolWiseAttendanceStatsResponse
}

export const NGOPartnerAttendanceStatsChart: React.FC<NGOPartnerAttendanceStatsChartProps> = ({ data }) => {
	const [hiddenDataKeys, setHiddenDataKeys] = useState<string[]>([])

	const toggleDataKeys = (dataKey: string) => {
		const hidden = [...hiddenDataKeys]
		if (hidden.includes(dataKey)) {
			hidden.splice(hidden.indexOf(dataKey), 1)
			setHiddenDataKeys(hidden)
		} else {
			setHiddenDataKeys([...hidden, dataKey])
		}
	}

	const chartData = useMemo(() => {
		const total = data.stats.map((s) => s.totalStudents.length).reduce((a, b) => a + b, 0) || 0
		const present = data.stats.map((s) => s.presentStudents.length).reduce((a, b) => a + b, 0) || 0

		return [
			{ name: data.school.name, Present: hiddenDataKeys.includes("Present") ? 0 : roundDecimalNumber((present / total) * 100), Absent: hiddenDataKeys.includes("Absent") ? 0 : roundDecimalNumber(((total - present) / total) * 100) },
		]
	}, [data, hiddenDataKeys])

	return (
		<AspectRatio ratio={16 / 9}>
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					data={chartData}
					margin={{
						top: 20,
						bottom: 5,
					}}
				>
					<defs>
						<linearGradient id="colorPresent" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor={colors.green[400]} stopOpacity={0.8} />
							<stop offset="95%" stopColor={colors.green[400]} stopOpacity={0.2} />
						</linearGradient>
						<linearGradient id="colorAbsent" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor={colors.red[400]} stopOpacity={0.8} />
							<stop offset="95%" stopColor={colors.red[400]} stopOpacity={0.2} />
						</linearGradient>
					</defs>
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip formatter={(v) => `${v}%`} />
					<Legend
						cursor="pointer"
						onClick={({ dataKey }) => toggleDataKeys(dataKey)}
						formatter={(value) => {
							if (hiddenDataKeys.includes(value)) return <span className="line-through cursor-pointer">{value}</span>

							return <span className="cursor-pointer">{value}</span>
						}}
					/>
					<Bar
						dataKey="Present"
						label={{
							position: "top",
							formatter: (v: string) => v + "%",
						}}
						fillOpacity={1}
						fill="url(#colorPresent)"
						cursor="pointer"
					/>
					<Bar
						dataKey="Absent"
						label={{
							position: "top",
							formatter: (v: string) => v + "%",
						}}
						fillOpacity={1}
						fill="url(#colorAbsent)"
						cursor="pointer"
					/>
				</BarChart>
			</ResponsiveContainer>
		</AspectRatio>
	)
}
