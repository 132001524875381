import React from "react"

import { Input } from "../../../components/ui"
import { useSearchParams } from "../../../hooks"

import { SearchResultMeals } from "../../../components/admin/meal/SearchResultMeals"

export const MealsPage: React.FC = () => {
	const [searchParams, { handleSearchParamUpdate, handleSearchParamDelete }] = useSearchParams()

	return (
		<div className="container w-full min-h-screen">
			<div className="flex flex-col gap-y-4 md:gap-y-0 md:gap-x-4 items-center   md:flex-row md:justify-end mt-4 md:mt-10">
				<div>
					<Input
						type="search"
						placeholder="Search..."
						value={(searchParams.get("keyword") as string) || ""}
						onChange={(e) => {
							if (e.target.value) handleSearchParamUpdate("keyword", e.target.value)
							else handleSearchParamDelete("keyword")
						}}
					/>
				</div>
			</div>

			<h2 className="text-gray-700 text-lg font-semibold mt-4 mb-4">Meals</h2>
			<SearchResultMeals keyword={(searchParams.get("keyword") as string) || undefined} />
		</div>
	)
}
