import { cacheExchange as cE } from "@urql/exchange-graphcache"

import { betterQueryUpdate } from "../../utils"

import {
	ActivateStudentMutation,
	ActivateStudentMutationVariables,
	CreateClassMutation,
	CreateClassSectionMutation,
	CreateClassSectionMutationVariables,
	DeactivateStudentMutation,
	DeactivateStudentMutationVariables,
	EditClassOfStudentMutation,
	EditClassOfStudentMutationVariables,
	ExtendedClassFragment,
	ExtendedClassFragmentDoc,
	ExtendedStudentFragment,
	ExtendedStudentFragmentDoc,
	LoginMutation,
	LogoutMutation,
	MeDocument,
	MeQuery,
	ReportByIdDocument,
	ReportByIdQuery,
	ReportByIdQueryVariables,
	ReportUpdateSubscription,
	ReportUpdateSubscriptionVariables,
	UpdateStudentMutation,
	UpdateStudentMutationVariables,
	AllClassesOfSchoolQuery,
	AllClassesOfSchoolDocument,
	AllSchoolStudentBmiQueryVariables,
	UploadBmiOfStudentMutation,
	CurrentBmIofStudentDocument,
	DevicesUpdateSubscription,
	SearchDevicesQuery,
	SearchDevicesDocument,
	DeviceUpdateSubscription,
	DeviceByIdQuery,
	DeviceByIdDocument,
	SchoolWiseDeviceUpdateSubscription,
	SchoolWiseDevicesQuery,
	SchoolWiseDevicesDocument,
	DeviceStatusStatsUpdateSubscription,
	DeviceStatusStatsQuery,
	DeviceStatusStatsDocument,
	DeviceStatusStatsUpdateSubscriptionVariables,
	StudentsOfMySchoolWithPaginationQueryVariables,
	CreateSchoolWiseAttendanceSlotOfSchoolMutation,
	AllSchoolAttendanceSlotsQuery,
	AllSchoolAttendanceSlotsDocument,
	DeleteSchoolWiseAttendanceSlotOfSchoolMutationVariables,
	DeleteSchoolWiseAttendanceSlotOfSchoolMutation,
	UpdateSchoolWiseAttendanceSlotOfSchoolMutation,
	UpdateSchoolWiseAttendanceSlotOfSchoolDocument,
	CreateHolidayMutation,
	AllHolidaysOfSchoolWithoutPaginationQuery,
	AllHolidaysOfSchoolWithoutPaginationDocument,
	UpdateWorkingWeekdaysOfSchoolMutation,
	SchoolByIdQuery,
	SchoolByIdDocument,
	DeleteHolidayMutationVariables,
} from "../generated"
import schema from "../generated/schema.json"
import { searchClassesPagination, searchReportsPagination, searchSchoolsPagination, searchStudentsPagination, customPagination } from "../pagination"

export const cacheExchange = cE({
	schema: schema as any,
	keys: {
		MeResponse: () => null,
		EmbeddedPicture: () => null,
		SchoolWiseAttendanceStatsResponse: () => null,
		SchoolWisePartialAttendanceStatsResponse: () => null,

		SchoolWiseBMIStatsResponse: () => null,
		CategoryWiseBMIStats: () => null,
		StatResponse: () => null,
		ClassWiseStatsResponse: () => null,
		StudentStatusLog: () => null,
		AllBMIStats: () => null,
		ProgressInBeneceficiaryEnrollmentStatResponse: () => null,
		PaginatedClassesResponse: () => null,
		Label: () => null,
		PaginatedReportsResponse: () => null,
		PaginatedPastBMIofStudentResponse: () => null,
		PaginatedDevicesResponse: () => null,
		DeviceStatusStats: () => null,
		PaginatedAttendanceSlotsResponse: () => null,
		MealSlot: () => null,
		ReportObject: () => null,
		AttendanceImageResponse: () => null,
		PaginatedStudentsResponse: () => null,
		PartialStudentWithAttendance: () => null,
		PartialStudentFragment: () => null,
		StudentAttendanceStat: () => null,
	},
	resolvers: {
		Query: {
			searchStudents: searchStudentsPagination("students", "PaginatedStudentsResponse"),
			searchSchools: searchSchoolsPagination("schools", "PaginatedSchoolsResponse"),
			searchReports: searchReportsPagination("reports", "PaginatedReportsResponse"),
			searchClasses: searchClassesPagination("classes", "PaginatedClassesResponse"),
			allSchoolStudentBmi: customPagination(
				"students",
				"PaginatedStudentsResponse",
				(args: AllSchoolStudentBmiQueryVariables, vars: AllSchoolStudentBmiQueryVariables) => args.keyword === vars.keyword && args.category === vars.category && args.schoolId == vars.schoolId
			),
			studentsOfMySchoolWithPagination: customPagination(
				"students",
				"PaginatedStudentsResponse",
				(args: StudentsOfMySchoolWithPaginationQueryVariables, vars: StudentsOfMySchoolWithPaginationQueryVariables) => args.keyword === vars.keyword && args.schoolId == vars.schoolId
			),
		},
	},
	updates: {
		Subscription: {
			reportUpdate: (_result: ReportUpdateSubscription, variables: ReportUpdateSubscriptionVariables, cache) => {
				betterQueryUpdate<ReportUpdateSubscription, ReportByIdQuery>(
					cache,
					{
						query: ReportByIdDocument,
						variables: {
							reportId: variables.reportId,
						} as ReportByIdQueryVariables,
					},
					_result,
					(result, query) => {
						if (result) {
							return {
								...query,
								reportById: {
									...query?.reportById,
									...result.reportUpdate,
								},
							}
						}

						return query
					}
				)
			},

			devicesUpdate: (_result: DevicesUpdateSubscription, _, cache) => {
				betterQueryUpdate<DevicesUpdateSubscription, SearchDevicesQuery>(cache, { query: SearchDevicesDocument }, _result, (result, query) => {
					if (result && query) {
						return {
							...query,
							searchDevices: {
								...query.searchDevices,
								devices: result.devicesUpdate,
							},
						}
					}

					return query
				})
			},

			schoolWiseDeviceUpdate: (_result: SchoolWiseDeviceUpdateSubscription, _, cache) => {
				betterQueryUpdate<SchoolWiseDeviceUpdateSubscription, SchoolWiseDevicesQuery>(cache, { query: SchoolWiseDevicesDocument }, _result, (result, query) => {
					if (result && query) {
						return {
							...query,
							schoolWiseDevices: {
								...query.schoolWiseDevices,
								devices: result.schoolWiseDeviceUpdate,
							},
						}
					}

					return query
				})
			},

			deviceStatusStatsUpdate: (_result: DeviceStatusStatsUpdateSubscription, variables: DeviceStatusStatsUpdateSubscriptionVariables, cache) => {
				betterQueryUpdate<DeviceStatusStatsUpdateSubscription, DeviceStatusStatsQuery>(
					cache,
					{
						query: DeviceStatusStatsDocument,
						variables: {
							schoold: variables.schoolId || "",
						},
					},
					_result,
					(result, query) => {
						if (result.deviceStatusStatsUpdate && query) {
							return {
								deviceStatusStats: {
									...result.deviceStatusStatsUpdate,
									__typename: "DeviceStatusStats",
								},
							}
						}

						return query
					}
				)
			},

			deviceUpdate: (_result: DeviceUpdateSubscription, _, cache) => {
				betterQueryUpdate<DeviceUpdateSubscription, DeviceByIdQuery>(cache, { query: DeviceByIdDocument }, _result, (result, query) => {
					if (result && query) {
						return {
							...query,
							deviceById: {
								...result.deviceUpdate,
							},
						}
					}

					return query
				})
			},
		},
		Mutation: {
			login: (_result, _, cache) => {
				betterQueryUpdate<LoginMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.login.admin) {
						return {
							__typename: "Query",
							me: {
								__typename: "MeResponse",
								admin: result.login.admin,
								errors: [],
								school: null,
							},
						}
					}

					if (result.login.school) {
						return {
							__typename: "Query",
							me: {
								__typename: "MeResponse",
								school: result.login.school,
								errors: [],
								admin: null,
							},
						}
					}

					return query
				})
			},
			logout: (result, _, cache) => {
				betterQueryUpdate<LogoutMutation, MeQuery>(cache, { query: MeDocument }, result, () => ({ me: null }))
			},

			updateStudent: (result: UpdateStudentMutation, variables: UpdateStudentMutationVariables, cache) => {
				if (!result.updateStudent) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

				if (!key) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key)
				if (!student) return

				return cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, {
					...student,
					...result.updateStudent,
				})
			},

			activateStudent: (result: ActivateStudentMutation, variables: ActivateStudentMutationVariables, cache) => {
				if (!result.activateStudent) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

				if (!key) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key)

				if (!student) return

				return cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, {
					...student,
					status: result.activateStudent.status,
					statusLogs: result.activateStudent.statusLogs,
				})
			},

			deactivateStudent: (result: DeactivateStudentMutation, variables: DeactivateStudentMutationVariables, cache) => {
				if (!result.deactivateStudent) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

				if (!key) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key)

				if (!student) return

				return cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, {
					...student,
					status: result.deactivateStudent.status,
					statusLogs: result.deactivateStudent.statusLogs,
				})
			},

			editClassOfStudent: (result: EditClassOfStudentMutation, variables: EditClassOfStudentMutationVariables, cache) => {
				if (!result.editClassOfStudent) return

				const key = cache.keyOfEntity({ __typename: "ExtendedStudent", _id: variables.studentId })

				if (!key) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, key)

				if (!student) return

				return cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, {
					...student,
					...result.editClassOfStudent,
				})
			},

			createClassSection: (result: CreateClassSectionMutation, variables: CreateClassSectionMutationVariables, cache) => {
				if (!result.createClassSection) return

				const key = cache.keyOfEntity({ __typename: "ExtendedClass", _id: variables.input.classId })

				if (!key) return

				const _class = cache.readFragment<ExtendedClassFragment>(ExtendedClassFragmentDoc, key)

				if (!_class) return

				return cache.writeFragment<ExtendedClassFragment>(ExtendedClassFragmentDoc, {
					..._class,
					sections: [...(_class.sections || []), result.createClassSection],
				})
			},

			createClass: (result: CreateClassMutation, _, cache) => {
				if (!result.createClass) return

				betterQueryUpdate<CreateClassMutation, AllClassesOfSchoolQuery>(cache, { query: AllClassesOfSchoolDocument }, result, (result, query) => {
					if (result && query) {
						return {
							...query,
							allClassesOfSchool: [...(query?.allClassesOfSchool ?? []), result.createClass],
						}
					}

					return query
				})
			},

			createSchoolWiseAttendanceSlotOfSchool: (result: CreateSchoolWiseAttendanceSlotOfSchoolMutation, _, cache) => {
				if (!result.createSchoolWiseAttendanceSlotOfSchool) return

				betterQueryUpdate<CreateSchoolWiseAttendanceSlotOfSchoolMutation, AllSchoolAttendanceSlotsQuery>(cache, { query: AllSchoolAttendanceSlotsDocument }, result, (result, query) => {
					if (result && query) {
						return {
							...query,
							allSchoolAttendanceSlots: [...(query?.allSchoolAttendanceSlots ?? []), result.createSchoolWiseAttendanceSlotOfSchool],
						}
					}

					return query
				})
			},

			createHoliday: (result: CreateHolidayMutation, _, cache) => {
				if (!result.createHoliday) return

				betterQueryUpdate<CreateHolidayMutation, AllHolidaysOfSchoolWithoutPaginationQuery>(cache, { query: AllHolidaysOfSchoolWithoutPaginationDocument }, result, (result, query) => {
					if (result && query) {
						return {
							...query,
							allHolidaysOfSchoolWithoutPagination: {
								...query.allHolidaysOfSchoolWithoutPagination,
								...result.createHoliday.holiday,
							},
						}
					}

					return query
				})
			},

			updateWorkingWeekdaysOfSchool: (result: UpdateWorkingWeekdaysOfSchoolMutation, _, cache) => {
				if (!result.updateWorkingWeekdaysOfSchool) return

				betterQueryUpdate<UpdateWorkingWeekdaysOfSchoolMutation, SchoolByIdQuery>(cache, { query: SchoolByIdDocument }, result, (result, query) => {
					if (result && query) {
						return {
							...query,
							schoolById: {
								...result.updateWorkingWeekdaysOfSchool,
							},
						}
					}

					return query
				})
			},

			deleteHoliday: (_, { holidayId }: DeleteHolidayMutationVariables, cache) => {
				return cache.invalidate({ __typename: "Holiday", _id: holidayId })
			},

			deleteSchoolWiseAttendanceSlotOfSchool: (result: DeleteSchoolWiseAttendanceSlotOfSchoolMutation, variables: DeleteSchoolWiseAttendanceSlotOfSchoolMutationVariables, cache) => {
				if (!result.deleteSchoolWiseAttendanceSlotOfSchool) return

				const deleteSlotId = variables.schoolWiseAttendanceSlotId

				betterQueryUpdate<DeleteSchoolWiseAttendanceSlotOfSchoolMutation, AllSchoolAttendanceSlotsQuery>(cache, { query: AllSchoolAttendanceSlotsDocument }, result, (result, query) => {
					if (result && query) {
						return {
							...query,
							allSchoolAttendanceSlots: [...(query?.allSchoolAttendanceSlots.filter((m) => m._id != deleteSlotId) ?? [])],
						}
					}

					return query
				})
			},

			updateSchoolWiseAttendanceSlotOfSchool: (result: UpdateSchoolWiseAttendanceSlotOfSchoolMutation, _, cache) => {
				if (!result.updateSchoolWiseAttendanceSlotOfSchool) return

				const updatedMealSlot = result.updateSchoolWiseAttendanceSlotOfSchool

				if (updatedMealSlot) {
					const query = UpdateSchoolWiseAttendanceSlotOfSchoolDocument

					const queryVariables = {
						schoolWiseAttendanceSlotId: updatedMealSlot._id,
					}

					cache.updateQuery({ query, variables: queryVariables }, () => {
						return {
							...updatedMealSlot,
						}
					})
				}
			},

			uploadBMIOfStudent: (result: UploadBmiOfStudentMutation, _, cache) => {
				if (!result.uploadBMIOfStudent) return

				const newStudentBMI = result.uploadBMIOfStudent

				if (newStudentBMI) {
					const query = CurrentBmIofStudentDocument

					const queryVariables = {
						studentId: newStudentBMI.studentId,
					}

					cache.updateQuery({ query, variables: queryVariables }, () => {
						return {
							currentBMIofStudent: newStudentBMI,
						}
					})
				}
			},
		},
	},
})
