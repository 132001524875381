import React from "react"
import { useParams } from "react-router-dom"
import { useAttendanceSlotByIdQuery } from "../../../graphql"
import { LoadingLabel } from "../../../components/ui"

import MealDetail from "../../../components/admin/meal/MealDetail"

const MealPage: React.FC = () => {
	const { attendanceSlotId = "" } = useParams<{ attendanceSlotId: string }>()

	const [{ data, fetching, error }] = useAttendanceSlotByIdQuery({ variables: { attendanceSlotId }, pause: !attendanceSlotId })

	return (
		<div className="container py-5">
			{fetching ? (
				<div className="container mx-auto ">
					<div className=" flex gap-1 text-gray-500 ">
						<h2 className="text-sm">Loading meal</h2>
						<LoadingLabel />
					</div>
				</div>
			) : error ? (
				<div className="p-8  w-fit">
					<h2 className="text-sm text-red-600">{error.message.replace("[GraphQL] ", "")}</h2>
				</div>
			) : data?.attendanceSlotById ? (
				<MealDetail mealInfo={data.attendanceSlotById} />
			) : (
				<div className="p-8  w-fit">
					<h2 className="text-sm text-red-600">Couldn&apos;t find the meal.</h2>
				</div>
			)}
		</div>
	)
}

export default MealPage
