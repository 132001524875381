import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { StudentFragment } from "../../../graphql"
import { VStack } from "../../ui"
import { BeneficiaryListItem } from "./BeneficiaryListItem"

export type BeneficiariesListProps = {
	beneficiaries: StudentFragment[]
	loadMore: () => void
}

export const BeneficiariesList: React.FC<BeneficiariesListProps> = ({ beneficiaries, loadMore }) => {
	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack className="items-stretch">
			{beneficiaries.map((beneficiary) => (
				<BeneficiaryListItem key={beneficiary._id} beneficiary={beneficiary} />
			))}
			<div ref={bottomRef as any} className="w-full h-4 pb-4" />
		</VStack>
	)
}
