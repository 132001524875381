/* eslint-disable @typescript-eslint/indent */

import React from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useToast, InputError, Button, Label, Input, Textarea } from "../components/ui"
import { useNavigate } from "react-router-dom"
import { ExtendedStudentFragment, useUpdateStudentMutation, Genders } from "../graphql"
import { format } from "date-fns"

type UpdateStudentProfileFormValues = {
	phone: string
	email: string
	gender: Genders

	address: string
	dateOfBirth: string
}

const schema = z.object({
	phone: z.string().optional(),
	email: z.string().optional(),
	gender: z.string().nonempty({ message: "gender is required" }),

	address: z.string().optional(),
	dateOfBirth: z.string().nonempty({ message: "dateOfBirth is required" }),
})

export type UpdateBeneficiaryProfileFormProps = {
	beneficiary: ExtendedStudentFragment
}

const UpdateBeneficiaryProfileForm: React.FC<UpdateBeneficiaryProfileFormProps> = ({ beneficiary }) => {
	const initialValues: UpdateStudentProfileFormValues = {
		phone: beneficiary.phone || "",
		address: beneficiary.address || "",
		email: beneficiary.email || "",
		gender: beneficiary.gender || Genders.Others,

		dateOfBirth: beneficiary.dateOfBirth ? format(new Date(beneficiary.dateOfBirth), "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd"),
	}

	const [{ fetching }, updateStudent] = useUpdateStudentMutation()

	const { toast } = useToast()
	const navigate = useNavigate()

	const {
		register,
		handleSubmit,
		formState: { isLoading, errors, isSubmitting },
	} = useForm<UpdateStudentProfileFormValues>({ resolver: zodResolver(schema) as any, defaultValues: initialValues })

	const onSubmit: SubmitHandler<UpdateStudentProfileFormValues> = async (values) => {
		const { data, error } = await updateStudent({
			studentId: beneficiary._id,
			input: { ...values, name: beneficiary.name },
		})

		if (error) {
			toast({
				variant: "destructive",
				title: "Student updation Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})

			return
		}

		if (data?.updateStudent) {
			toast({
				variant: "success",
				title: "Student Profile updated Successfully",
				description: "",
			})
			navigate(`/beneficiaries/${data.updateStudent._id}`, { replace: true })

			return
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col lg:justify-center justify-start px-4 gap-4 lg:w-96">
			<div>
				<Label htmlFor="email">Email</Label>
				<Input placeholder="email" type="text" {...register("email")} />
				<InputError>{errors.email?.message}</InputError>
			</div>

			<div>
				<Label htmlFor="phone">Phone</Label>
				<Input placeholder="phone" type="text" {...register("phone")} />
				<InputError>{errors.phone?.message}</InputError>
			</div>

			<div className="flex gap-2 items-center">
				<Label htmlFor="gender">Gender</Label>

				<select {...register("gender")} className="px-4 py-2 outline-none">
					{Object.values(Genders).map((option) => (
						<option key={option} value={option}>
							{option}
						</option>
					))}
				</select>
				<InputError>{errors.gender?.message}</InputError>
			</div>

			<div>
				<Label htmlFor="dateOfBirth">Date of Birth</Label>

				<Input type="date" {...register("dateOfBirth")} />
				<InputError>{errors.dateOfBirth?.message}</InputError>
			</div>

			<div>
				<Label htmlFor="address">Address</Label>
				<Textarea placeholder="address" {...register("address")} />
				<InputError>{errors.address?.message}</InputError>
			</div>

			<Button isLoading={isLoading || isSubmitting || fetching} className="mt-6">
				Update
			</Button>
		</form>
	)
}

export default UpdateBeneficiaryProfileForm
