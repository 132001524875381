import React, { useEffect } from "react"
import { AssignMealSlot } from "../../../components/admin/meal/AssignMealSlot"

const AssignMealSlotPage: React.FC = () => {
	useEffect(() => {
		// Disable scrolling when the component mounts
		document.body.classList.add("overflow-hidden")

		// Re-enable scrolling when the component unmounts
		return () => {
			document.body.classList.remove("overflow-hidden")
		}
	}, [])

	return (
		<div className="container">
			<AssignMealSlot />
		</div>
	)
}

export default AssignMealSlotPage
