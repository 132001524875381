import React from "react"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { Button, InputError, useToast, Label } from "../../components"
import { useNavigate } from "react-router-dom"
import { School, WorkingWeekdays, useUpdateWorkingWeekdaysOfSchoolMutation } from "../../graphql"

export type UpdateWorkingWeekdaysOfSchoolFormProps = {
	school: School
}

type UpdateWorkingWeekdaysOfSchoolFormValues = {
	weekdays: WorkingWeekdays[]
}

const schema = z.object({
	weekdays: z.array(z.string()).nonempty({ message: "Select atleast one day" }),
})

export const UpdateWorkingWeekdaysOfSchoolForm: React.FC<UpdateWorkingWeekdaysOfSchoolFormProps> = ({ school }) => {
	const [{ fetching }, updateWorkingWeekdays] = useUpdateWorkingWeekdaysOfSchoolMutation()

	const { toast } = useToast()
	const navigate = useNavigate()

	const {
		handleSubmit,

		setValue,
		watch,
		formState: { isLoading, errors, isSubmitting },
	} = useForm<UpdateWorkingWeekdaysOfSchoolFormValues>({
		resolver: zodResolver(schema) as any,
		defaultValues: { weekdays: school.workingWeekdays || [] },
	})

	const onSubmit: SubmitHandler<UpdateWorkingWeekdaysOfSchoolFormValues> = async (values) => {
		const { data, error } = await updateWorkingWeekdays({
			workingWeekdays: values.weekdays,
		})

		if (error) {
			toast({
				variant: "destructive",
				title: "Working Weekdays Editing Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})

			return
		}

		if (data?.updateWorkingWeekdaysOfSchool) {
			toast({
				variant: "success",
				title: "Working Weekdays Edited Successfully",
				description: "",
			})

			navigate("/holiday/updateWorkingWeekdays")

			return
		}
	}
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-10">
				<div>
					<div className="flex flex-col gap-4">
						<Label className="text-lg font-medium text-gray-600">Working Weekdays</Label>
						<div className="flex gap-4 flex-wrap">
							{[WorkingWeekdays.Mon, WorkingWeekdays.Tue, WorkingWeekdays.Wed, WorkingWeekdays.Thu, WorkingWeekdays.Fri, WorkingWeekdays.Sat, WorkingWeekdays.Sun].map((day) => (
								<Button
									key={day}
									type="button"
									size="sm"
									variant="secondary"
									value={day}
									className={watch("weekdays").includes(day) ? "p-2 border-2 border-green-500 bg-green-50 text-lg  text-gray-700" : "p-2 bg-red-50 border-2 border-red-500 text-lg  text-gray-700"}
									onClick={() => {
										const currentWeekdays = watch("weekdays")

										if (currentWeekdays.includes(day)) {
											const updatedWeekdays = currentWeekdays.filter((d) => d !== day)

											setValue("weekdays", [...updatedWeekdays])
										} else {
											const updated = new Set(currentWeekdays).add(day)
											setValue("weekdays", [...updated])
										}
									}}
								>
									{day}
								</Button>
							))}
						</div>
					</div>
					<InputError>{errors.weekdays?.message}</InputError>
				</div>

				<Button type="submit" isLoading={isLoading || isSubmitting || fetching} className="hover:cursor-pointer hover:opacity-80 hover:bg-green-600 max-w-lg">
					Save
				</Button>
			</form>
		</>
	)
}
