import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"

import { VStack } from "../../ui"
import { format } from "date-fns"
import { useNavigate } from "react-router-dom"

export type ClassListProps = {
	classes: any[]
	loadMore: () => void
}

export const ClassList: React.FC<ClassListProps> = ({ classes, loadMore }) => {
	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	const navigate = useNavigate()

	return (
		<VStack>
			<div className="lg:container w-full rounded-xl overflow-hidden">
				<div>
					<h2 className="text-gray-700 text-lg font-semibold mt-4 mb-4">Classes</h2>
					<table className="lg:min-w-full w-full divide-y divide-gray-200">
						<thead>
							<tr>
								<th className="lg:px-6 px-2 py-2 lg:py-3  bg-gray-100 text-left text-xs md:text-lg  font-medium text-gray-500  ">Name</th>
								<th className="lg:px-6 px-2 py-2 lg:py-3 bg-gray-100 text-left text-xs  md:text-lg font-medium text-gray-500  ">Sections</th>
								<th className="lg:px-6 lg:py-3 py-2 px-2 bg-gray-100 text-left text-xs  md:text-lg font-medium text-gray-500">Created</th>
							</tr>
						</thead>
						<tbody className="bg-gray-50 divide-y divide-gray-200">
							{classes.map((data) => {
								return (
									<tr key={data._id} className="hover:cursor-pointer hover:bg-blue-50 text-sm md:text-lg font-medium text-gray-600" onClick={() => navigate(`/classes/${data._id}`)}>
										<td className="lg:px-6 px-2 py-2 lg:py-3">{data.name}</td>
										<td className="lg:px-6 px-2 py-2 lg:py-3">
											{data.sections.length > 0 && (
												<>
													{data.sections.map((section: any, index: number) => {
														return (
															<>
																<span key={section._id}>{section.name}</span>
																{index < data.sections.length - 1 && <span>,</span>}
															</>
														)
													})}
												</>
											)}

											{data.sections.length === 0 && <span className="text-red-400"> Not Available</span>}
										</td>
										<td className="lg:px-6 px-2 py-2 lg:py-3">{format(new Date(data.createdAt), "dd MMM yyyy")}</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div ref={bottomRef as any} className="w-full h-4 pb-4" />
		</VStack>
	)
}
