/* eslint-disable @typescript-eslint/indent */

/* eslint-disable @typescript-eslint/indent */

import React, { useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useToast, InputError, Button, Label, LoadingLabel } from "../../components/ui"
import { useNavigate } from "react-router-dom"
import { ExtendedStudentFragment, useEditClassOfStudentMutation, useAllClassesOfSchoolQuery } from "../../graphql"

type AssignBeneficiaryClassFormValues = {
	classId: string
	classSectionId: string | null
}

const schema = z.object({
	classId: z.string().nonempty({ message: "class is required" }),
	classSectionId: z.string().optional(),
})

export type AssignBeneficiaryClassFormProps = {
	beneficiary: ExtendedStudentFragment
}

const AssignBeneficiaryClassForm: React.FC<AssignBeneficiaryClassFormProps> = ({ beneficiary }) => {
	const [selectedClassId, setSelectedClassId] = useState<string | undefined>()

	const [selectedClassSectionId, setSelectedClassSectionId] = useState<string | undefined>()

	const onClassIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const newClassId = e.target.value
		setSelectedClassId(newClassId)
		const selectedClass = classData?.allClassesOfSchool.find((_class) => _class._id === newClassId)
		const defaultSectionId = selectedClass?.sections[0]?._id || undefined
		setSelectedClassSectionId(defaultSectionId)
	}

	const onClassSectionIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedClassSectionId(e.target.value)
	}

	const [{ fetching }, assignStudentClass] = useEditClassOfStudentMutation()

	const { toast } = useToast()
	const navigate = useNavigate()

	const {
		register,
		handleSubmit,
		formState: { isLoading, errors, isSubmitting },
	} = useForm<AssignBeneficiaryClassFormValues>({ resolver: zodResolver(schema) as any })

	const onSubmit: SubmitHandler<AssignBeneficiaryClassFormValues> = async (values) => {
		const { data, error } = await assignStudentClass({
			studentId: beneficiary._id,
			input: {
				...values,
				classId: selectedClassId || values.classId,
				classSectionId: selectedClassSectionId || null, // Set classSectionId to null if it's not selected
			},
		})

		if (error) {
			toast({
				variant: "destructive",
				title: "Student class assign Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})

			return
		}

		if (data?.editClassOfStudent) {
			toast({
				variant: "success",
				title: "Student Assign class Successfully",
				description: "",
			})
			navigate(`/beneficiaries/${data.editClassOfStudent._id}`, { replace: true })

			return
		}
	}

	const [{ data: classData, fetching: classFetching, error: classError }] = useAllClassesOfSchoolQuery()

	return (
		<>
			{classFetching ? (
				<div className="container mx-auto">
					<h1 className="text-sm font-bold">Loading Classes</h1>
					<LoadingLabel />
				</div>
			) : classError ? (
				<div className="container mx-auto">
					<p className="text-sm text-red-500 font-bold">{classError.message.replace("[GraphQL] ", "")}</p>
				</div>
			) : (
				<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
					<div className="flex flex-col gap-1 ">
						<Label htmlFor="ClassId" className="text-sm font-semibold text-gray-700">
							Class
						</Label>

						<select {...register("classId")} className="px-4 py-2 outline-none    hover:cursor-pointer " onChange={onClassIdChange}>
							<option value="" disabled selected>
								Select class
							</option>
							{classData?.allClassesOfSchool.map(({ _id, name }) => (
								<option key={_id} value={_id}>
									{name}
								</option>
							))}
						</select>

						<InputError>{errors.classId?.message}</InputError>
					</div>

					<div className="flex flex-col gap-1 ">
						<Label htmlFor="ClassSectionId" className="text-sm text-gray-600 font-semibold">
							Class Section
						</Label>

						<select {...register("classSectionId")} className="px-4 py-2  hover:cursor-pointer outline-none" onChange={onClassSectionIdChange}>
							{classData?.allClassesOfSchool?.find((_class) => _class._id === selectedClassId)?.sections.length === 0 && (
								<option value="" disabled selected>
									section not available
								</option>
							)}

							{classData?.allClassesOfSchool?.find((_class) => _class._id === selectedClassId)?.sections.length !== 0 && (
								<option value="" disabled selected>
									Select section
								</option>
							)}

							{classData?.allClassesOfSchool
								?.find((_class) => _class._id === selectedClassId)
								?.sections?.map((section) => (
									<option key={section.name} value={section._id}>
										{section.name}
									</option>
								))}
						</select>
						<InputError>{errors.classSectionId?.message}</InputError>
					</div>

					<Button isLoading={isLoading || isSubmitting || classFetching || fetching} className="w-full">
						Assign
					</Button>
				</form>
			)}
		</>
	)
}

export default AssignBeneficiaryClassForm
