import React from "react"
import { Route, Routes } from "react-router-dom"
import { Scaffold } from "../components/ngo-partners"
import BeneficiarysListPage from "../pages/common/beneficiaries/beneficiaries-list"
import BeneficiaryPage from "../pages/common/beneficiaries/beneficiary"
import { AttendancePage } from "../pages/ngo-partners/attendance"
import NGOPartnerAttendancePage from "../pages/ngo-partners/attendance/ngo-partner"
import NGOPartnerAttendanceOverviewPage from "../pages/ngo-partners/attendance/overview"
import BeneficiariesAverageAttendanceNewReportPage from "../pages/ngo-partners/attendance/report/beneficiaries-average/new"
import BeneficiariesAverageAttendanceReportPage from "../pages/ngo-partners/attendance/report/beneficiaries-average/report"
import BeneficiariesAverageAttendanceReportsListPage from "../pages/ngo-partners/attendance/report/beneficiaries-average/reports-list"
import BeneficiariesAttendanceNewReportPage from "../pages/ngo-partners/attendance/report/beneficiaries/new"
import BeneficiariesAttendanceReportPage from "../pages/ngo-partners/attendance/report/beneficiaries/report"
import BeneficiariesAttendanceReportsListPage from "../pages/ngo-partners/attendance/report/beneficiaries/reports-list"
import OverviewPage from "../pages/ngo-partners/overview"
import UpdateBeneficiaryProfilePage from "../pages/ngo-partners/beneficiary/UpdateBeneficiaryProfile"
import { ClassesPage } from "../pages/ngo-partners/classes/Classes"
import ClassPage from "../pages/ngo-partners/classes/Class"
import DevicesPage from "../pages/ngo-partners/devices/Devices"
import { DevicePage } from "../pages/ngo-partners/devices/Device"
import BeneficiaryBmiPage from "../pages/ngo-partners/beneficiary/BeneficiaryBmi"
import UploadBMIsPage from "../pages/ngo-partners/beneficiary/UploadBMIs"
import UploadBMIOfStudentPage from "../pages/ngo-partners/beneficiary/UploadBMIOfStudent"
import BeneficiariesSlotWiseAttendanceNewReportPage from "../pages/ngo-partners/attendance/report/beneficiaries-slot-wise/new"
import BeneficiariesSlotWiseAttendanceReportsListPage from "../pages/ngo-partners/attendance/report/beneficiaries-slot-wise/reports-list"
import BeneficiariesSlotWiseAttendanceReportPage from "../pages/ngo-partners/attendance/report/beneficiaries-slot-wise/report"
import Holidays from "../pages/ngo-partners/holidays"

import UploadClassPage from "../pages/ngo-partners/beneficiary/UploadClass"

import UpdateWorkingWeekdaysOfSchoolPage from "../pages/ngo-partners/holidays/updateWorkingWeeksOfSchoolPage"

export const NGOPartnerRootRouter: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<Scaffold />}>
				<Route path="/" element={<OverviewPage />} />
				<Route path="/beneficiaries/list" element={<BeneficiarysListPage />} />
				<Route path="/beneficiaries/:beneficiaryId" element={<BeneficiaryPage />} />
				<Route path="/beneficiaries/:beneficiaryId/uploadBMI" element={<UploadBMIOfStudentPage />} />
				<Route path="/beneficiaries/:beneficiaryId/updateBeneficiaryDetails" element={<UpdateBeneficiaryProfilePage />} />
				<Route path="/beneficiaries/bmi" element={<BeneficiaryBmiPage />} />
				<Route path="/bmi/upload" element={<UploadBMIsPage />} />
				<Route path="/class/upload" element={<UploadClassPage />} />
				{/* <Route path="/fiRegNo/upload" element={<UploadFiRegNoPage />} /> */}
				<Route path="/classes" element={<ClassesPage />} />
				<Route path="/classes/:classId" element={<ClassPage />} />
				<Route path="/devices" element={<DevicesPage />} />
				<Route path="/devices/:deviceId" element={<DevicePage />} />
				<Route path="/attendance/reports/beneficiaries/new" element={<BeneficiariesAttendanceNewReportPage />} />
				<Route path="/attendance/reports/beneficiaries/:reportId" element={<BeneficiariesAttendanceReportPage />} />
				<Route path="/attendance/reports/beneficiaries" element={<BeneficiariesAttendanceReportsListPage />} />
				<Route path="/attendance/reports/beneficiaries-average/new" element={<BeneficiariesAverageAttendanceNewReportPage />} />
				<Route path="/attendance/reports/beneficiaries-average/:reportId" element={<BeneficiariesAverageAttendanceReportPage />} />
				<Route path="/attendance/reports/beneficiaries-average" element={<BeneficiariesAverageAttendanceReportsListPage />} />

				<Route path="/attendance/reports/beneficiaries/slot-wise/new" element={<BeneficiariesSlotWiseAttendanceNewReportPage />} />
				<Route path="/attendance/reports/beneficiaries/slot-wise/:reportId" element={<BeneficiariesSlotWiseAttendanceReportPage />} />
				<Route path="/attendance/reports/beneficiaries/slot-wise" element={<BeneficiariesSlotWiseAttendanceReportsListPage />} />

				<Route path="/attendance/overview" element={<NGOPartnerAttendanceOverviewPage />} />
				<Route path="/attendance/ngo-partner" element={<NGOPartnerAttendancePage />} />
				<Route path="/attendance" element={<AttendancePage />} />
				<Route path="/holiday" element={<Holidays />} />
				<Route path="/holiday/updateWorkingWeekdays" element={<UpdateWorkingWeekdaysOfSchoolPage />} />
			</Route>
		</Routes>
	)
}
