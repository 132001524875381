import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { DeviceFragment } from "../../../graphql"
import { VStack } from "../../ui"
import DeviceListItem from "./DeviceListItem"

export type DevicesListProps = {
	devices: DeviceFragment[]
	loadMore: () => void
}

export const DevicesList: React.FC<DevicesListProps> = ({ devices, loadMore }) => {
	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack className="items-stretch">
			{devices.map((device) => (
				<DeviceListItem key={device._id} device={device} />
			))}
			<div ref={bottomRef as any} className="w-full h-4 pb-4" />
		</VStack>
	)
}
