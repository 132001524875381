import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { StudentBmiFragment } from "../../../graphql"
import { Card, CardContent, VStack } from "../../ui"
import { BMI } from "./BMI"

export type BMIsListProps = {
	bmis: StudentBmiFragment[]
	loadMore: () => void
}

export const BMIsList: React.FC<BMIsListProps> = ({ bmis, loadMore }) => {
	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	useEffect(() => {
		if (inViewport) loadMore()
	}, [inViewport, loadMore])

	return (
		<VStack className="items-stretch">
			{bmis.map((bmi) => (
				<Card key={bmi._id}>
					<CardContent className="pt-6">
						<BMI bmi={bmi} />
					</CardContent>
				</Card>
			))}
			<div ref={bottomRef as any} className="w-full h-4 pb-4" />
		</VStack>
	)
}
