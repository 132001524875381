import React from "react"
import { Card, CardContent, CardHeader, CardTitle } from "../../components"
import { BeneficiariesBMIStatsChart, RegionWiseNGOPartnersChart, TodayAttendanceChart, TotalBeneficiaries, TotalNGOPartners } from "../../components/admin"

export const OverviewPage: React.FC = () => {
	return (
		<div className="container p-2 lg:px-8 bg-red">
			<div className="w-full grid gap-2 lg:gap-4 lg:grid-cols-2">
				<Card>
					<CardHeader>
						<CardTitle className="text-sm font-medium text-slate-500">Our scale</CardTitle>
					</CardHeader>
					<CardContent>
						<div className="grid gap-2 xl:gap-4 grid-cols-2">
							<TotalNGOPartners />
							<div className="xl:hidden">
								<TotalBeneficiaries />
							</div>
							<div className="col-span-2 xl:col-auto xl:row-span-2">
								<RegionWiseNGOPartnersChart />
							</div>
							<div className="hidden xl:block">
								<TotalBeneficiaries />
							</div>
						</div>
					</CardContent>
				</Card>
				<Card className="row-span-2">
					<CardHeader>
						<CardTitle className="text-sm font-medium text-slate-500">Beneficiaries overview</CardTitle>
					</CardHeader>
					<CardContent>
						<div className="grid gap-2 xl:gap-4 xl:grid-cols-2">
							<TodayAttendanceChart />
							<BeneficiariesBMIStatsChart />
							{/* <div className="xl:col-span-2">
								<TinyNGOPartnerWiseMonthlyAttendanceStatsChart />
							</div> */}
						</div>
					</CardContent>
				</Card>
			</div>
		</div>
	)
}

export default OverviewPage
