/* eslint-disable react/react-in-jsx-scope */

import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
import twcolors from "tailwindcss/colors"
import { useGenderWiseStudentsOfMySchoolCountQuery } from "../../../../graphql"
import { useBreakpoint } from "../../../../hooks"
import { EmptyChart } from "../../../common"
import { AspectRatio, Card, CardContent, ChartSkeleton, VStack } from "../../../ui"

const colors: string[] = [twcolors.blue[400], twcolors.pink[400], twcolors.gray[400]]

export const GenderWiseBeneficiariesChart: React.FC = () => {
	const [{ data, fetching }] = useGenderWiseStudentsOfMySchoolCountQuery()

	const { isXl } = useBreakpoint("xl")

	const [hoveredCellIndex, setHoveredCellIndex] = useState(-1)

	const navigate = useNavigate()

	return (
		<Card className="h-full">
			<CardContent>
				<VStack className="h-full gap-4 select-none">
					<AspectRatio ratio={isXl ? 20 / 9 : 4 / 3}>
						<ResponsiveContainer width="100%" height="100%">
							{fetching ? (
								<ChartSkeleton />
							) : !data?.genderWiseStudentsOfMySchoolCount.length ? (
								<EmptyChart />
							) : (
								<PieChart>
									<Tooltip />
									<Legend />
									<defs>
										{data.genderWiseStudentsOfMySchoolCount.map((entry, index) => (
											<linearGradient key={entry.label} id={entry.label} x1="0" y1="0" x2="0" y2="1">
												<stop offset="5%" stopColor={colors[index]} stopOpacity={0.8} />
												<stop offset="95%" stopColor={colors[index]} stopOpacity={0.2} />
											</linearGradient>
										))}
									</defs>
									<Pie
										data={data.genderWiseStudentsOfMySchoolCount.map((d) => ({ name: d.label, value: d.value }))}
										cx="50%"
										cy="50%"
										outerRadius={isXl ? 100 : 80}
										dataKey="value"
										activeIndex={0}
										labelLine={false}
										label={renderCustomizedLabel}
									>
										{data.genderWiseStudentsOfMySchoolCount.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												className="cursor-pointer"
												fill={hoveredCellIndex === index ? colors[index] : `url(#${entry.label})`}
												onMouseEnter={() => setHoveredCellIndex(index)}
												onMouseLeave={() => setHoveredCellIndex(-1)}
												onClick={() => navigate(`/beneficiaries/list?gender=${entry.label}`)}
											/>
										))}
									</Pie>
								</PieChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, fill }: any) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5
	const x = cx + radius * Math.cos(-midAngle * RADIAN)
	const y = cy + radius * Math.sin(-midAngle * RADIAN)

	return (
		<text x={x} y={y} fill={fill === "url(#Male)" ? twcolors.blue[800] : fill === "url(#Others)" ? twcolors.gray[800] : twcolors.pink[800]} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
			{value}
		</text>
	)
}
