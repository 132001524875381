/* eslint-disable react/react-in-jsx-scope */

import { format, startOfDay } from "date-fns"
import { useMemo, useState } from "react"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from "recharts"
import colors from "tailwindcss/colors"
import { useAllStudentsCountQuery, useSchoolWisePartialAttendanceStatsQuery } from "../../../../graphql"
import { useBreakpoint } from "../../../../hooks"
import { roundDecimalNumber } from "../../../../utils"
import { EmptyChart } from "../../../common"
import { AspectRatio, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, VStack } from "../../../ui"

const today = startOfDay(new Date())

export const TodayAttendanceChart: React.FC = () => {
	const [{ data, fetching }] = useSchoolWisePartialAttendanceStatsQuery({ variables: { filter: { sinceDate: today, untilDate: today, schoolIds: [] } } })

	const [totalMeals, setTotalMeals] = useState<number>(0)

	const [{ data: allStudentsCountData }] = useAllStudentsCountQuery()

	const attendanceData = useMemo(() => {
		let presentStudents = 0
		let totalStudents = 0

		data?.schoolWisePartialAttendanceStats.map((s) => {
			s.stats.map((m) => {
				presentStudents += m.meal.presentStudentsCount
				totalStudents += m.meal.totalStudentsCount
			})
		})

		setTotalMeals(presentStudents)

		return [
			{
				name: "Present",
				value: presentStudents,
			},
			{
				name: "Absent",
				value: totalStudents - presentStudents || allStudentsCountData?.allStudentsCount || 0,
			},
		]
	}, [JSON.stringify(data?.schoolWisePartialAttendanceStats ?? {}), JSON.stringify(allStudentsCountData?.allStudentsCount ?? {})])

	const presentPercent = useMemo(() => (attendanceData[0].value / (attendanceData[1].value + attendanceData[0].value)) * 100, [JSON.stringify(attendanceData)])

	const { isXl } = useBreakpoint("xl")

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">
					Today&apos;s Attendance of <strong className="text-primary">{totalMeals} meals</strong>
				</CardTitle>
				<CardTitle className="text-xs font-normal text-slate-400">as on {format(today, "MMM dd, yyyy")}</CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="h-full gap-4 select-none">
					<AspectRatio ratio={isXl ? 1 : 4 / 3}>
						<ResponsiveContainer width="100%" height="100%">
							{fetching ? (
								<ChartSkeleton />
							) : !attendanceData.length ? (
								<EmptyChart />
							) : (
								<PieChart>
									<Tooltip />
									<Legend />
									<defs>
										<linearGradient id="critical" x1="0" y1="0" x2="0" y2="1">
											<stop offset="5%" stopColor={colors.green[400]} stopOpacity={0.8} />
											<stop offset="95%" stopColor={colors.green[400]} stopOpacity={0.2} />
										</linearGradient>
										<linearGradient id="remaining" x1="0" y1="0" x2="0" y2="1">
											<stop offset="5%" stopColor={colors.red[400]} stopOpacity={0.8} />
											<stop offset="95%" stopColor={colors.red[400]} stopOpacity={0.2} />
										</linearGradient>
									</defs>
									<Pie
										data={attendanceData}
										cx="50%"
										cy="50%"
										innerRadius={isXl ? 70 : 50}
										outerRadius={isXl ? 100 : 80}
										paddingAngle={5}
										dataKey="value"
										activeIndex={0}
										activeShape={(props) => renderActiveShape({ ...props, presentPercent })}
									>
										{attendanceData.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={["url(#critical)", "url(#remaining)"][index]} />
										))}
									</Pie>
								</PieChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}

const renderActiveShape = (props: any) => {
	const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, presentPercent } = props

	return (
		<g>
			<text
				x={cx}
				y={cy}
				dy={8}
				className="text-3xl font-bold"
				textAnchor="middle"
				fill={Number(presentPercent) > 90 ? colors.green[400] : Number(presentPercent) <= 90 && Number(presentPercent) > 75 ? colors.orange[400] : colors.red[400]}
			>
				{roundDecimalNumber(Number(presentPercent))}%
			</text>
			<Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
		</g>
	)
}
