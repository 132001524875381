import React from "react"
import { Link } from "react-router-dom"
import colors from "tailwindcss/colors"
import { StudentFragment } from "../../../graphql"
import { Beneficiaries } from "../../../icons/Beneficiaries"
import { cn } from "../../../lib/utils"
import { Badge, Card, CardContent, CardHeader, CardTitle } from "../../ui"
import { BeneficiaryTag } from "../BeneficiaryTag"

export type BeneficiaryListItemProps = {
	beneficiary: StudentFragment
}

export const BeneficiaryListItem: React.FC<BeneficiaryListItemProps> = ({ beneficiary }) => {
	return (
		<Link to={`/beneficiaries/${beneficiary._id}`}>
			<Card className="hover:shadow-purple-500 ">
				<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
					<CardTitle>
						<Badge className={cn("text-sm font-medium", beneficiary.status === "Active" ? "text-green-600 bg-green-100" : beneficiary.status === "Inactive" ? "text-red-600 bg-red-100" : "text-slate-600 bg-slate-100")}>
							{beneficiary.status || "Not available"}
						</Badge>
					</CardTitle>
					<Beneficiaries fill={colors.purple[500]} className="lg:scale-125" />
				</CardHeader>
				<CardContent>
					<BeneficiaryTag beneficiary={beneficiary} />
				</CardContent>
			</Card>
		</Link>
	)
}
