import axios from "axios"
import format from "date-fns/format"
import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Badge, Button, ErrorLabel, Skeleton, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, VStack } from "../../../../../components"
import { ReportStatus, ReportTypes, useReportByIdQuery, useReportUpdateSubscription } from "../../../../../graphql"

type Report = {
	Name: string
	RegNo: string
	Class: string
	fiRegNo: string
}

export const BeneficiariesAttendanceReportPage: React.FC = () => {
	const { reportId = "" } = useParams<{ reportId: string }>()

	const [{ fetching, data, error }] = useReportByIdQuery({ variables: { reportId }, pause: !reportId })

	useReportUpdateSubscription({ variables: { reportId } })

	const [report, setReport] = useState<{ data?: Report[]; fetching?: boolean; error?: Error }>({})

	useEffect(() => {
		if (!data) {
			setReport({ fetching: true })
		} else if (data?.reportById?.json?.url) {
			setReport({ fetching: true })

			axios
				.get(data.reportById.json.url)
				.then(({ data }: { data: Report[] }) => {
					setReport({ data, fetching: false })
				})
				.catch((err: any) => {
					setReport({ error: err, fetching: false })
				})
		} else {
			setReport({ fetching: false })
		}
	}, [data])

	const labels: string[] = useMemo(() => {
		if (!data?.reportById?.dateRange?.[0] || !data?.reportById?.dateRange?.[1]) {
			return []
		}

		const _labels: string[] = []

		// // Extract unique labels from the report data
		const mealLabelsSet = new Set<string>()
		report.data?.forEach((item) => {
			Object.keys(item).forEach((key) => {
				if (key !== "Name" && key !== "RegNo" && key !== "Class" && key !== "fiRegNo") {
					mealLabelsSet.add(key)
				}
			})
		})

		const mealLabels = Array.from(mealLabelsSet)

		mealLabels.forEach((label) => {
			_labels.push(label)
		})

		return _labels
	}, [data, report])

	return (
		<div className="container p-2 lg:px-8 flex flex-col gap-2 lg:gap-4">
			{fetching ? (
				<VStack className="w-full items-stretch py-2 lg:py-4 gap-2 lg:gap-4">
					<Skeleton className="w-full max-w-sm h-12" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : !data?.reportById || data.reportById.type !== ReportTypes.StudentsAttendance ? (
				<ErrorLabel>Cound not find the report</ErrorLabel>
			) : (
				<VStack className="w-full items-stretch py-2 lg:py-4 gap-2 lg:gap-4">
					<VStack className="lg:flex-row items-stretch lg:items-center">
						<VStack className="w-full items-start">
							{data.reportById.status === ReportStatus.Queued ? (
								<Badge variant="outline" className="border-yellow-500 text-yellow-500">
									{data.reportById.status}
								</Badge>
							) : data.reportById.status === ReportStatus.Processing ? (
								<Badge className="bg-yellow-500">{data.reportById.status}</Badge>
							) : data.reportById.status === ReportStatus.Failed ? (
								<Badge variant="destructive">{data.reportById.status}</Badge>
							) : (
								<Badge className="bg-green-500">
									{data.reportById.status} on {format(new Date(data.reportById.createdAt), "MMM dd, yyyy")}
								</Badge>
							)}
							<h1 className="text-sm lg:text-sm font-medium group-hover:font-bold text-slate-500">{data.reportById.label.name}</h1>
							{data.reportById.dateRange?.length ? <p className="text-xs text-slate-500">{data.reportById.dateRange.map((d) => format(new Date(d), "MMM dd, yyyy")).join(" - ")}</p> : <></>}
						</VStack>
						{data.reportById.status === ReportStatus.Generated && data.reportById.excel?.url && <Button onClick={() => window.open(data.reportById?.excel?.url)}>Download</Button>}
					</VStack>
					{data.reportById.status === ReportStatus.Generated ? (
						report.fetching ? (
							<VStack className="w-full items-stretch py-2 lg:py-4 gap-2 lg:gap-4">
								<Skeleton className="w-full max-w-sm h-12" />
							</VStack>
						) : report.error ? (
							<ErrorLabel>{report.error.message.replace("[GraphQL] ", "")}</ErrorLabel>
						) : !report?.data ? (
							<ErrorLabel>Cound not find the report</ErrorLabel>
						) : (
							<Table>
								<TableHeader>
									<TableRow>
										<TableHead>Name</TableHead>
										<TableHead>School RegNo</TableHead>
										<TableHead>FeedingIndia RegNo</TableHead>
										<TableHead>Class</TableHead>
										{labels.map((key, i) => (
											<TableHead key={i}>{key}</TableHead>
										))}
									</TableRow>
								</TableHeader>
								<TableBody>
									{report.data.map((r: any) => (
										<TableRow key={r["RegNo"]}>
											<TableCell>{r.Name}</TableCell>
											<TableCell>{r["RegNo"]}</TableCell>
											<TableCell>{r.fiRegNo}</TableCell>
											<TableCell>{r.Class}</TableCell>
											{labels.map((label) => (
												<TableCell key={label}>{r[label]}</TableCell>
											))}
										</TableRow>
									))}
								</TableBody>
							</Table>
						)
					) : (
						<></>
					)}
				</VStack>
			)}
		</div>
	)
}

export default BeneficiariesAttendanceReportPage
