import React from "react"

import { AddNewSection } from "../../../components/ngo-partners/class/AddNewSection"

const AddNewSectionPage: React.FC = () => {
	return (
		<div className="container">
			<AddNewSection />
		</div>
	)
}

export default AddNewSectionPage
