/* eslint-disable react/react-in-jsx-scope */

import { differenceInDays, startOfDay, subDays, subMonths } from "date-fns"
import { useEffect, useMemo, useState } from "react"
import Select from "react-select"
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis } from "recharts"
import twcolors from "tailwindcss/colors"
import { SchoolWiseAttendanceStatsQueryVariables, useAllSchoolsWithoutPaginationQuery } from "../../../../graphql"
import { useAttendanceStatsContext } from "../../../../hooks"
import { roundDecimalNumber } from "../../../../utils"
import { AspectRatio, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, ErrorLabel, LoadingLabel, VStack } from "../../../ui"

const sinceDate = startOfDay(subMonths(new Date(), 1))
const untilDate = startOfDay(new Date())

const colors = [twcolors.red[400], twcolors.purple[400], twcolors.green[400], twcolors.orange[400], twcolors.slate[400], twcolors.gray[400]]

export const NGOPartnerWiseMonthlyAttendanceStatsChart: React.FC = () => {
	const [filter, setFilter] = useState<Required<SchoolWiseAttendanceStatsQueryVariables["filter"]>>({ sinceDate, untilDate, schoolIds: [] })

	const [{ queries }, queryNew] = useAttendanceStatsContext()

	const currentQuery = useMemo(() => queries.find((q) => q.id === JSON.stringify(filter)), [filter, queries])

	useEffect(() => {
		if (filter.sinceDate && filter.untilDate) queryNew({ filter })
	}, [filter])

	const [{ data: ngoPartnersData, fetching: ngoPartnersFetching, error: ngoPartnersError }] = useAllSchoolsWithoutPaginationQuery()

	const [hiddenDataKeys, setHiddenDataKeys] = useState<string[]>([])

	const toggleDataKeys = (dataKey: string) => {
		const hidden = [...hiddenDataKeys]
		if (hidden.includes(dataKey)) {
			hidden.splice(hidden.indexOf(dataKey), 1)
			setHiddenDataKeys(hidden)
		} else {
			setHiddenDataKeys([...hidden, dataKey])
		}
	}

	const data = useMemo(() => {
		const _data: Record<string, string | number>[] = []

		for (let i = differenceInDays(untilDate, sinceDate); i > 0; i--) {
			const date = subDays(untilDate, i - 1).toLocaleString("en-US", { month: "short", day: "numeric" })

			const o: Record<string, any> = { name: date, Attendance: 0 }

			currentQuery?.data?.schoolWisePartialAttendanceStats.forEach(({ school, stats }) => {
				if (!hiddenDataKeys.includes(school.name.split(" ")[0])) {
					const total = stats
						.flat(1)
						.filter((s) => new Date(s.date).toLocaleString("en-US", { month: "short", day: "numeric" }) === date)
						.map((o) => o.meal.totalStudentsCount)
						.reduce((a, b) => a + b, 0)

					const actual = stats
						.flat(1)
						.filter((s) => new Date(s.date).toLocaleString("en-US", { month: "short", day: "numeric" }) === date)
						.map((o) => o.meal.presentStudentsCount)
						.reduce((a, b) => a + b, 0)

					o[school.name.split(" ")[0]] = roundDecimalNumber((actual / total) * 100)
				}
			})

			_data.push(o)
		}

		return _data
	}, [currentQuery, hiddenDataKeys])

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Monthly Attendance</CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="h-full gap-4 select-none items-stretch">
					{ngoPartnersFetching ? (
						<LoadingLabel className="flex-1">Loading NGO partners</LoadingLabel>
					) : ngoPartnersError ? (
						<ErrorLabel className="flex-1">{ngoPartnersError.message.replace("[GraphQL] ", "")}</ErrorLabel>
					) : !ngoPartnersData?.allSchoolsWithoutPagination.length ? (
						<ErrorLabel className="flex-1">Couldn&apos;t fetch NGO partners</ErrorLabel>
					) : (
						<Select
							isMulti
							name="NGO Partners"
							placeholder="Select NGO partners"
							options={ngoPartnersData.allSchoolsWithoutPagination.map((s) => ({ label: s.name, value: s._id }))}
							onChange={(ngoPartners) => setFilter((prev) => ({ ...prev, schoolIds: ngoPartners.map((o) => o.value) }))}
							className="basic-multi-select flex-1"
							isSearchable
						/>
					)}

					<AspectRatio ratio={20 / 9}>
						<ResponsiveContainer width="100%" height="100%">
							{currentQuery?.waiting ? (
								<ChartSkeleton />
							) : !data?.length ? (
								<div className="w-full h-full flex items-center justify-center">
									<ErrorLabel>No data</ErrorLabel>
								</div>
							) : (
								<AreaChart data={data} margin={{ top: 24, right: 40, left: 40, bottom: 0 }}>
									<XAxis dataKey="name" />

									<Tooltip formatter={(v) => <span>{v || 0}%</span>} />
									<Legend
										cursor="pointer"
										onClick={({ dataKey }) => toggleDataKeys(dataKey)}
										formatter={(value) => {
											if (hiddenDataKeys.includes(value)) return <span className="line-through cursor-pointer">{value}</span>

											return <span className="cursor-pointer">{value}</span>
										}}
									/>

									<defs>
										{ngoPartnersData?.allSchoolsWithoutPagination.map((s, i) => (
											<linearGradient key={i} id={i.toString()} x1="0" y1="0" x2="0" y2="1">
												<stop offset="5%" stopColor={colors[i]} stopOpacity={0.8} />
												<stop offset="95%" stopColor={colors[i]} stopOpacity={0.2} />
											</linearGradient>
										))}
									</defs>

									{ngoPartnersData?.allSchoolsWithoutPagination.map((s, i) => (
										<Area key={s._id} type="monotone" stackId="1" dataKey={s.name.split(" ")[0]} stroke="none" fillOpacity={1} fill={`url(#${i})`} />
									))}
								</AreaChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}
