import React, { useCallback, useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import { cn } from "../../../lib/utils"
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger, navigationMenuTriggerStyle } from "../../ui"

enum Routes {
	Attendance = "/attendance",
	Devices = "/devices",
	Classes = "/classes",
	BMI = "/bmi",
	Upload = "/upload",
	Holiday = "/holiday",
	Overview = "/",
}

export const TopNavigation: React.FC = () => {
	const { pathname } = useLocation()

	const activeRoute = useMemo(() => {
		for (const route of Object.values(Routes)) {
			if (pathname.startsWith(route)) {
				return route
			}
		}
	}, [pathname])

	const isActive = useCallback((route: Routes) => activeRoute === route, [activeRoute])

	return (
		<NavigationMenu className="hidden md:flex">
			<NavigationMenuList>
				<NavigationMenuItem>
					<Link to="/">
						<NavigationMenuLink className={cn(navigationMenuTriggerStyle(), isActive(Routes.Overview) ? "bg-purple-100 text-purple-800" : "text-purple-500")}>Overview</NavigationMenuLink>
					</Link>
				</NavigationMenuItem>
				<NavigationMenuItem>
					<NavigationMenuContent>
						<ul className="grid w-full max-w-[400px] gap-3 p-4 md:max-w-[500px] md:grid-cols-2 lg:w-[600px] ">
							<ListItem title="NGO partners report" href="/ngo-partners/reports">
								Generate a list of NGO Partners with their beneficiaries summary
							</ListItem>
						</ul>
					</NavigationMenuContent>
				</NavigationMenuItem>
				<NavigationMenuItem>
					<NavigationMenuTrigger className={cn(navigationMenuTriggerStyle(), isActive(Routes.Attendance) ? "bg-purple-100 text-purple-800" : "text-purple-500")}>
						<Link to="/attendance">
							<NavigationMenuLink>Attendance</NavigationMenuLink>
						</Link>
					</NavigationMenuTrigger>
					<NavigationMenuContent>
						<ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
							<ListItem title="Beneficiaries average attendance report" href="/attendance/reports/beneficiaries-average">
								Generate a list of beneficiaries with their average attendance summary
							</ListItem>
							<ListItem title="Beneficiaries attendance report" href="/attendance/reports/beneficiaries">
								Generate a list of beneficiaries with their date wise attendance
							</ListItem>
						</ul>
					</NavigationMenuContent>
				</NavigationMenuItem>

				<NavigationMenuItem>
					<NavigationMenuTrigger className={cn(navigationMenuTriggerStyle(), isActive(Routes.Upload) ? "bg-purple-100 text-purple-800" : "text-purple-500")}>
						<Link to="/">
							<NavigationMenuLink>Upload</NavigationMenuLink>
						</Link>
					</NavigationMenuTrigger>
					<NavigationMenuContent>
						<ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
							<ListItem title="Upload BMI" href="/bmi/upload">
								Upload the BMI of the Beneficiaries
							</ListItem>

							<ListItem title="Upload Class" href="/class/upload">
								Upload the Class of the Beneficiaries
							</ListItem>
						</ul>
					</NavigationMenuContent>
				</NavigationMenuItem>

				<NavigationMenuItem>
					<Link to="/classes">
						<NavigationMenuLink className={cn(navigationMenuTriggerStyle(), isActive(Routes.Classes) ? "bg-purple-100 text-purple-800" : "text-purple-500")}>Classes</NavigationMenuLink>
					</Link>
				</NavigationMenuItem>

				<NavigationMenuItem>
					<Link to="/devices">
						<NavigationMenuLink className={cn(navigationMenuTriggerStyle(), isActive(Routes.Devices) ? "bg-purple-100 text-purple-800" : "text-purple-500")}>Devices</NavigationMenuLink>
					</Link>
				</NavigationMenuItem>

				<NavigationMenuItem>
					<NavigationMenuTrigger className={cn(navigationMenuTriggerStyle(), isActive(Routes.Holiday) ? "bg-purple-100 text-purple-800" : "text-purple-500")}>
						<Link to="/holiday">
							<NavigationMenuLink>Holiday</NavigationMenuLink>
						</Link>
					</NavigationMenuTrigger>
					<NavigationMenuContent>
						<ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
							<ListItem title="Holiday" href="/holiday">
								Create or edit holidays
							</ListItem>

							<ListItem title="Working Weekdays" href="/holiday/updateWorkingWeekdays">
								Update your working weekdays
							</ListItem>
						</ul>
					</NavigationMenuContent>
				</NavigationMenuItem>
			</NavigationMenuList>
		</NavigationMenu>
	)
}

const ListItem = React.forwardRef<React.ElementRef<"a">, React.ComponentPropsWithoutRef<"a">>(({ className, title, children, ...props }, ref) => {
	return (
		<li>
			<NavigationMenuLink asChild>
				<a
					ref={ref}
					className={cn("block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground", className)}
					{...props}
				>
					<div className="text-sm font-bold leading-none text-primary">{title}</div>
					<p className="line-clamp-2 text-sm leading-snug text-muted-foreground">{children}</p>
				</a>
			</NavigationMenuLink>
		</li>
	)
})

ListItem.displayName = "ListItem"
