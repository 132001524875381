import { differenceInYears } from "date-fns"
import format from "date-fns/format"
import { startCase } from "lodash"
import React, { useMemo } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import {
	BeneficiaryLatestBMI,
	BeneficiaryPastBMIs,
	BeneficiaryTag,
	BeneficiaryTodayAttendance,
	Button,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	ErrorLabel,
	Skeleton,
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
	VStack,
} from "../../../components"
import { StudentStatus, useMeQuery, useStudentByIdQuery } from "../../../graphql"
import { cn } from "../../../lib/utils"
import { EditBeneficiaryClass } from "../../../components/ngo-partners/beneficiary/EditBeneficiaryClass"
import { AssignBeneficiaryClass } from "../../../components/ngo-partners/beneficiary/AssignBeneficiaryClass"

export const BeneficiaryPage: React.FC = () => {
	const { beneficiaryId = "" } = useParams<{ beneficiaryId: string }>()

	const [{ data, fetching, error }] = useStudentByIdQuery({ variables: { studentId: beneficiaryId }, pause: !beneficiaryId })

	const [meQuery] = useMeQuery()

	const navigate = useNavigate()

	const canUpdate: boolean = useMemo(() => {
		// if the login user is school then it can update the student profile with particular feild
		if (meQuery && meQuery.data && meQuery.data.me && meQuery.data.me.school) return true

		// if the login user is admin then it can update the student profile
		if (meQuery && meQuery.data && meQuery.data.me && meQuery.data.me.admin) return true

		return false
	}, [meQuery])

	// Deactivate the beneficiary
	const canDeactivate: boolean = useMemo(() => {
		// if the login user is admin then it can deactivate the student profile
		if (meQuery && meQuery.data && meQuery.data.me && meQuery.data.me.admin) {
			if (data?.studentById?.status === StudentStatus.Inactive)
				// return if the student is already Inactive
				return false

			// deactivate only if the student is active
			if (data?.studentById?.status === StudentStatus.Active) return true
		}
		return false
	}, [data])

	// Activate the beneficiary
	const canActivate: boolean = useMemo(() => {
		// if the login user is admin then it can activate the student profile
		if (meQuery && meQuery.data && meQuery.data.me && meQuery.data.me.admin) {
			if (data?.studentById?.status === StudentStatus.Active)
				// return if the student is already Active
				return false

			// Activate only if the student is Inactive
			if (data?.studentById?.status === StudentStatus.Inactive) return true
		}
		return false
	}, [data])

	const canUpdateClass: boolean = useMemo(() => {
		if (meQuery && meQuery.data && meQuery.data.me && meQuery.data.me.school) {
			return true
		}
		return false
	}, [data])

	return (
		<div className="container px-2 lg:px-8 flex flex-col gap-4">
			{fetching ? (
				<VStack className="w-full items-stretch py-2 lg:py-4 gap-2 lg:gap-4">
					<Skeleton className="w-full max-w-sm h-12" />
					<div className="grid lg:grid-cols-2 gap-2 lg:gap-4">
						<Skeleton className="w-full max-w-2xl h-56" />
						<Skeleton className="w-full max-w-2xl h-56" />
						<Skeleton className="w-full max-w-2xl h-36" />
					</div>
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : !data?.studentById ? (
				<ErrorLabel>Cound not find the beneficiary</ErrorLabel>
			) : (
				<VStack className="w-full items-stretch py-2 lg:py-4 gap-2 lg:gap-4">
					<div className="flex gap-3">
						<BeneficiaryTag beneficiary={data.studentById} />
					</div>

					<div className="grid lg:grid-cols-2 gap-2 lg:gap-4">
						<Card className="w-full max-w-2xl relative">
							<CardHeader>
								<CardTitle className="text-xs lg:text-sm font-medium group-hover:font-bold text-slate-500">Profile</CardTitle>
								<div className="absolute top-2 right-4 flex gap-2 ">
									{canUpdate && (
										<Link to={`/beneficiaries/${data.studentById._id}/updateBeneficiaryDetails`} className="text-sm font-medium text-white  rounded-lg w-fit hover:bg-blue-500 hover:cursor-pointer bg-blue-600 px-4 py-2">
											Update
										</Link>
									)}

									{canActivate && (
										<Link to={`/beneficiaries/${data.studentById._id}/activate`} className="text-sm font-medium text-white  rounded-lg w-fit hover:bg-green-500 hover:cursor-pointer bg-green-600 px-4 py-2">
											Activate
										</Link>
									)}

									{canDeactivate && (
										<Link to={`/beneficiaries/${data.studentById._id}/deactivate`} className="text-sm font-medium text-white  rounded-lg w-fit hover:bg-red-500 hover:cursor-pointer bg-red-600 px-4 py-2">
											Deactivate
										</Link>
									)}
								</div>
							</CardHeader>
							<CardContent>
								<div className="w-full grid grid-cols-2 md:grid-cols-3 gap-4">
									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Tag</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.tag?.tagDecimalId || "Not available"}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Regn. No.</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.regNo}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Date of Birth</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{format(new Date(data.studentById.dateOfBirth), "MMM dd, yyyy")}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Age</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{differenceInYears(new Date(), new Date(data.studentById.dateOfBirth))} years</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Gender</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.gender || "Not available"}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Email</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.email || "Not available"}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Phone</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.phone || "Not available"}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Status</span>
										<span
											className={cn(
												"text-sm lg:text-md font-bold",
												data.studentById.status === StudentStatus.Active ? "text-green-500" : data.studentById.status === StudentStatus.Inactive ? "text-red-500" : "text-slate-500"
											)}
										>
											{data.studentById.status || "Not available"}
										</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Created</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{format(new Date(data.studentById.createdAt), "MMM dd, yyyy")}</span>
									</VStack>
								</div>
							</CardContent>
						</Card>

						<Card className="w-full max-w-2xl">
							<CardHeader>
								<CardTitle className="text-xs lg:text-sm font-medium group-hover:font-bold text-slate-500">NGO Partner</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="w-full grid grid-cols-2 md:grid-cols-3 gap-4">
									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">NGO Partner</span>
										{meQuery.data?.me?.school ? (
											<span className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.school.name}</span>
										) : (
											<Link to={`/ngo-partners/${data.studentById.schoolId}`} className="text-sm lg:text-md font-bold text-slate-500 hover:underline hover:text-purple-500">
												{data.studentById.school.name}
											</Link>
										)}
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">NGO Partner Code</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.school.schoolCode}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Model</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{startCase(data.studentById.school.model)}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Email</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.school.email || "Not available"}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Phone</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.school.phone || "Not available"}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Region</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.school.region}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Address</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.school.location?.address || "Not available"}</span>
									</VStack>

									<VStack className="w-fit items-start gap-0">
										<span className="text-xs lg:text-sm text-slate-400">Created</span>
										<span className="text-sm lg:text-md font-bold text-slate-500">{format(new Date(data.studentById.school.createdAt), "MMM dd, yyyy")}</span>
									</VStack>
								</div>
							</CardContent>
						</Card>
						<Card className="w-full max-w-2xl">
							<CardHeader>
								<CardTitle className="text-xs lg:text-sm font-medium group-hover:font-bold text-slate-500">Today&apos;s attendance</CardTitle>
							</CardHeader>
							<CardContent>
								<BeneficiaryTodayAttendance beneficiaryId={beneficiaryId} />
							</CardContent>
						</Card>

						<Card className="w-full max-w-2xl row-span-2">
							<CardHeader>
								<CardTitle className="text-xs lg:text-sm font-medium group-hover:font-bold text-slate-500">BMI</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="flex w-full relative justify-end">
									{meQuery.data?.me?.school && data.studentById.status === StudentStatus.Active && (
										<Button className="text-xs font-semibold absolute" onClick={() => navigate(`/beneficiaries/${data.studentById?._id}/uploadBMI`)}>
											Upload BMI
										</Button>
									)}
								</div>
								<Tabs defaultValue="latest">
									<TabsList>
										<TabsTrigger value="latest">Latest</TabsTrigger>
										<TabsTrigger value="past">Past</TabsTrigger>
									</TabsList>
									<TabsContent value="latest">
										<BeneficiaryLatestBMI beneficiaryId={beneficiaryId} />
									</TabsContent>
									<TabsContent value="past">
										<BeneficiaryPastBMIs beneficiaryId={beneficiaryId} />
									</TabsContent>
								</Tabs>
							</CardContent>
						</Card>

						<Card className="w-full max-w-2xl">
							<CardHeader>
								<CardTitle className="text-xs lg:text-sm font-medium group-hover:font-bold text-slate-500">Beneficiary Class</CardTitle>
							</CardHeader>
							<CardContent>
								{canUpdateClass && data.studentById.class && data.studentById.status === StudentStatus.Active && <EditBeneficiaryClass student={data.studentById} />}
								{canUpdateClass && !data.studentById.class && data.studentById.status === StudentStatus.Active && <AssignBeneficiaryClass student={data.studentById} />}

								<div className="flex gap-4 flex-wrap">
									<div className="flex flex-col">
										<h2 className="text-xs lg:text-sm text-slate-400">Class Name</h2>
										<h2 className="text-sm lg:text-md font-bold text-slate-500">
											{data.studentById.class?.name ? data.studentById.class.name : <span className="text-xs text-red-500 font-semibold">Not Assigned yet</span>}
										</h2>
									</div>
									{data.studentById.classSection && (
										<div className="flex flex-col">
											<h2 className="text-xs lg:text-sm text-slate-400">Section</h2>
											<h2 className="text-sm lg:text-md font-bold text-slate-500">{data.studentById.classSection.name}</h2>
										</div>
									)}
								</div>
							</CardContent>
						</Card>
					</div>
				</VStack>
			)}
		</div>
	)
}

export default BeneficiaryPage
