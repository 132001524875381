import React, { useCallback, useState } from "react"
import { Models, Regions, SearchSchoolsQueryVariables, useSearchSchoolsQuery } from "../../../graphql"
import { ErrorLabel, Skeleton, VStack } from "../../ui"
import { NGOPartnersList } from "./NGOPartnersList"

export type SearchNGOPartnersProps = {
	keyword?: string
	model?: Models
	region?: Regions
}

export const SearchNGOPartners: React.FC<SearchNGOPartnersProps> = ({ keyword, model, region }) => {
	const [pagination, setPagination] = useState<SearchSchoolsQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useSearchSchoolsQuery({
		variables: { keyword, model, region, pagination },
	})

	const nextPage = useCallback(() => {
		if (data?.searchSchools.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data?.searchSchools.hasNextPage])

	return (
		<VStack className="max-w-xl items-stretch">
			{fetching ? (
				<VStack className="w-full max-w-xl items-stretch gap-2">
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.searchSchools.schools && data.searchSchools.schools.length ? (
				<NGOPartnersList ngoPartners={data.searchSchools.schools} loadMore={nextPage} />
			) : (
				<ErrorLabel>Couldn&apos;t find any NGO partner.</ErrorLabel>
			)}
		</VStack>
	)
}
