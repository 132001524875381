import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useInViewport } from "react-in-viewport"

import { BeneficiaryTag, Card, CardContent, ErrorLabel, HStack, Input, LoadingLabel, Skeleton, Table, VStack } from "../../../components"
import { StudentsOfMySchoolWithPaginationQueryVariables, useStudentsOfMySchoolWithPaginationQuery } from "../../../graphql"
import { cn } from "../../../lib/utils"
import { Checkbox } from "../../../components"

export type StudentsSelectorProps = {
	selectedStudentIds: string[]
	onChange: (studentIds: string[]) => void
	schoolId: string
}

export const StudentsSelector: React.FC<StudentsSelectorProps> = ({ selectedStudentIds, onChange, schoolId }) => {
	const [filter, setFilter] = useState<StudentsOfMySchoolWithPaginationQueryVariables>({ keyword: "", schoolId: schoolId, pagination: { limit: 2000, page: 1 } })

	const [keyword, setKeyword] = useState("")

	useEffect(() => {
		onChange([])
	}, [])

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setFilter((prev) => ({
				...prev,
				keyword: keyword,
				pagination: { ...prev.pagination, page: 1 },
			}))
		}, 400)

		return () => {
			clearTimeout(timeoutId)
		}
	}, [keyword])

	useEffect(() => {
		setFilter((prev) => ({
			...prev,
			schoolId: schoolId,
			pagination: { ...prev.pagination, page: 1 },
		}))

		onChange([])
	}, [schoolId])

	const [{ data, fetching, error }] = useStudentsOfMySchoolWithPaginationQuery({
		variables: { ...filter },
		requestPolicy: "network-only",
	})

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	const nextPage = useCallback(() => {
		if (data?.studentsOfMySchoolWithPagination.hasNextPage) {
			setFilter((prev) => ({
				...prev,
				pagination: { ...prev.pagination, page: (prev.pagination.page || 0) + 1 },
			}))
		}
	}, [data?.studentsOfMySchoolWithPagination.hasNextPage])

	useEffect(() => {
		if (inViewport && data?.studentsOfMySchoolWithPagination.hasNextPage) nextPage()
	}, [inViewport, nextPage])

	const handleStudentClick = (studentId: string) => {
		if (selectedStudentIds.includes(studentId)) {
			return onChange(selectedStudentIds.filter((id) => id !== studentId))
		} else {
			return onChange([...selectedStudentIds, studentId])
		}
	}

	const isSelected = useMemo(() => (studentId: string) => selectedStudentIds.includes(studentId), [selectedStudentIds])
	const areAllSelected = useMemo(
		() => selectedStudentIds.length === data?.studentsOfMySchoolWithPagination.students?.length && data.studentsOfMySchoolWithPagination.students.map((s) => s._id).every((sid) => selectedStudentIds.includes(sid)),
		[selectedStudentIds, data?.studentsOfMySchoolWithPagination.students]
	)

	const selectAllStudents = useCallback(() => onChange(data?.studentsOfMySchoolWithPagination.students?.map((s) => s._id) || []), [data?.studentsOfMySchoolWithPagination.students])
	const deselectAllStudents = useCallback(() => onChange([]), [])

	return (
		<Card className="items-stretch w-full">
			<CardContent className="p-4 flex flex-col gap-2 lg:gap-4 overflow-y-auto overflow-x-hidden">
				<div className="w-full grid lg:grid-cols-3 gap-2">
					<Input type="search" placeholder="Search..." value={keyword} onChange={(e) => setKeyword(e.target.value)} />
				</div>
				{fetching && !data?.studentsOfMySchoolWithPagination.students?.length ? (
					<VStack className="w-full max-w-xl items-stretch gap-2">
						<Skeleton className="w-full max-w-xl h-24" />
						<Skeleton className="w-full max-w-xl h-24" />
					</VStack>
				) : error ? (
					<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
				) : data?.studentsOfMySchoolWithPagination.students && data.studentsOfMySchoolWithPagination.students.length ? (
					<VStack className="items-stretch w-full h-40 md:h-80 overflow-y-auto overflow-x-hidden ">
						<Table>
							<thead className="sticky top-0 w-full bg-white z-10">
								<tr>
									<th className="flex gap-4 items-center">
										<Checkbox id="select-all-student" checked={areAllSelected} onCheckedChange={() => (areAllSelected ? deselectAllStudents() : selectAllStudents())} />
										<span className="text-sm text-purple-400">{selectedStudentIds.length} Beneficiary selected</span>
									</th>
									{/* <th>Beneficiary</th> */}
								</tr>
							</thead>
							<tbody>
								{data.studentsOfMySchoolWithPagination.students.map((student) => (
									<>
										<Card key={student._id} className={cn("cursor-pointer", "mt-2", isSelected(student._id) ? "shadow-purple-500 hover:shadow-red-500" : "hover:shadow-purple-500")}>
											<label htmlFor={student._id} className="cursor-pointer">
												<CardContent className="py-2 px-4">
													<HStack className="gap-4">
														<Checkbox id={student._id} checked={isSelected(student._id)} onCheckedChange={() => handleStudentClick(student._id)} />

														<BeneficiaryTag beneficiary={student} disableLink></BeneficiaryTag>
													</HStack>
												</CardContent>
											</label>
										</Card>
									</>
								))}
								<div ref={bottomRef as any} className="w-full h-4" />
							</tbody>
						</Table>
						{fetching && (
							<div className="flex gap-2">
								<LoadingLabel />
								<p className="text-sm font-medium text-gray-600">Loading more Beneficiaries</p>
							</div>
						)}
					</VStack>
				) : (
					<ErrorLabel>Couldn&apos;t find any Beneficiary.</ErrorLabel>
				)}
			</CardContent>
		</Card>
	)
}
