import React, { Fragment, useEffect } from "react"
import { ValidTillBannerStatus } from "../../context/ValidTillBanner/types"
import { useMeQuery } from "../../graphql"
import { useValidTillBannerContext } from "../../hooks"
import { format, differenceInDays } from "date-fns"

export const CheckValidTillDate: React.FC = () => {
	const [{ data }] = useMeQuery()

	const { isVisible, dispatch, setVisible, setInvisible, setChildren, setStatus } = useValidTillBannerContext()

	useEffect(() => {
		if (data?.me) {
			if (data.me.admin) {
				if (data.me.admin.validTill) {
					// check if the valid is 15 days away from Expiring
					const remainingDay = differenceInDays(new Date(data.me.admin.validTill), new Date())

					if (remainingDay <= 15) {
						dispatch(
							setChildren(
								<p className="text-sm  text-left md:text-lg flex justify-center items-center  w-full md:text-center  font-medium h-10 bg-orange-400 text-white">{`Expiring soon on ${format(
									new Date(data.me.admin.validTill),
									"PP"
								)}`}</p>
							)
						)
						dispatch(setStatus(ValidTillBannerStatus.WARNING))
						dispatch(setVisible())
					}
				}
			} else if (data.me.school) {
				if (data.me.school.validTill) {
					// check if the valid is 15 days away from Expiring
					const remainingDay = differenceInDays(new Date(data.me.school.validTill), new Date())
					if (remainingDay <= 15) {
						dispatch(
							setChildren(
								<p className="text-sm  text-left md:text-lg flex justify-center items-center  w-full md:text-center  font-medium h-10 bg-orange-400 text-white">{`Expiring soon on ${format(
									new Date(data.me.school.validTill),
									"PP"
								)}`}</p>
							)
						)
						dispatch(setStatus(ValidTillBannerStatus.WARNING))
						dispatch(setVisible())
					}
				}
			}
		} else if (isVisible) {
			dispatch(setInvisible())
		}

		return () => {
			dispatch(setInvisible())
		}
	}, [JSON.stringify(data?.me)])

	return <Fragment />
}
