import React, { useCallback, useState } from "react"
import { useSchoolWiseDevicesQuery, SchoolWiseDevicesQueryVariables, useSchoolWiseDeviceUpdateSubscription } from "../../../graphql"
import { ErrorLabel, Skeleton, VStack } from "../../ui"
import { DevicesList } from "../../admin/devices/DevicesList"

export type SchoolWiseDevicesProps = {
	keyword?: string
	schoolId: string
}

export const SchoolWiseDevices: React.FC<SchoolWiseDevicesProps> = ({ keyword, schoolId }) => {
	const [pagination, setPagination] = useState<SchoolWiseDevicesQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useSchoolWiseDevicesQuery({
		variables: { keyword, pagination },
	})

	useSchoolWiseDeviceUpdateSubscription({ variables: { schoolId } })

	const nextPage = useCallback(() => {
		if (data?.schoolWiseDevices.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data?.schoolWiseDevices.hasNextPage])

	return (
		<VStack className="max-w-xl items-stretch">
			{fetching ? (
				<VStack className="w-full max-w-xl items-stretch gap-2">
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.schoolWiseDevices.devices && data.schoolWiseDevices.devices.length ? (
				<DevicesList devices={data.schoolWiseDevices.devices} loadMore={nextPage} />
			) : (
				<ErrorLabel>Couldn&apos;t find any Devices.</ErrorLabel>
			)}
		</VStack>
	)
}
