import { Resolver } from "@urql/exchange-graphcache"
import { stringifyVariables } from "urql"

export const searchStudentsPagination =
	(field: string, __typename: string): Resolver =>
	(_parent, fieldArgs, cache, info) => {
		const { parentKey: entityKey, fieldName, variables } = info

		const allFields = cache.inspectFields(entityKey)
		const fieldInfos = allFields.filter(
			(info) => info.fieldName === fieldName && (info.arguments as any).keyword === variables.keyword && (info.arguments as any).schoolId === variables.schoolId && (info.arguments as any).gender === variables.gender
		)
		const size = fieldInfos.length

		if (size === 0) {
			return undefined
		}

		const fieldKey = `${fieldName}(${stringifyVariables(fieldArgs)})`
		const isItInTheCache = cache.resolve(cache.resolve(entityKey, fieldKey) as string, fieldName)

		info.partial = !isItInTheCache

		let hasNextPage = true
		const items: string[] = []

		fieldInfos.forEach((fi) => {
			const key = cache.resolve(entityKey, fi.fieldKey) as string
			const data = cache.resolve(key, field) as string[]
			const _hasNextPage = cache.resolve(key, "hasNextPage")

			if (!_hasNextPage) {
				hasNextPage = _hasNextPage as boolean
			}

			if (data) items.push(...data)
		})

		return {
			__typename,
			hasNextPage,
			[field]: items,
		}
	}
