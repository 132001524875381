import React from "react"
import { Skeleton } from "./skeleton"

export const ChartSkeleton: React.FC = () => {
	return (
		<div className="w-full h-full flex flex-col gap-2">
			<div className="w-full h-full grid grid-cols-6 gap-2 place-items-end">
				<Skeleton className="w-full h-full bg-purple-100" />
				<Skeleton className="w-full h-[50%] bg-gray-100" />
				<Skeleton className="w-full h-[60%] bg-red-100" />
				<Skeleton className="w-full h-[80%] bg-orange-100" />
				<Skeleton className="w-full h-[10%] bg-slate-100" />
				<Skeleton className="w-full h-[40%] bg-green-100" />
			</div>
			<div className="w-full flex justify-center gap-4">
				<div className="flex justify-center gap-1">
					<Skeleton className="w-4 h-2 bg-green-100" />
					<Skeleton className="w-28 h-2 bg-green-100" />
				</div>

				<div className="flex justify-center gap-1">
					<Skeleton className="w-4 h-2 bg-red-100" />
					<Skeleton className="w-28 h-2 bg-red-100" />
				</div>
			</div>
		</div>
	)
}
