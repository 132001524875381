import React from "react"
import { useStudentsOfSchoolWithoutExtensionQuery } from "../../../graphql"
import { exportCSVData } from "../../../utils"
import { format } from "date-fns"
import { Button } from "../../ui"

export type GenrateBMITemplateProps = {
	schoolId: string
}

export const GenrateBMITemplate: React.FC<GenrateBMITemplateProps> = ({ schoolId }) => {
	const [{ data }] = useStudentsOfSchoolWithoutExtensionQuery({ variables: { schoolId } })

	const handleExport = () => {
		if (!data?.studentsOfSchoolWithoutExtension.length) return

		const csv = "Name,RegNo,Gender,Height in cms,Weight in kgs".concat("\n", ...(data?.studentsOfSchoolWithoutExtension || []).map((s) => ` ${s.name},${s.regNo},${s.gender}, , ,\n`))

		return exportCSVData(csv, `BMI Template ${format(new Date(), "MMM dd, yyyy p")}`)
	}

	return <>{data?.studentsOfSchoolWithoutExtension.length && <Button onClick={handleExport}>Generate BMI Template</Button>}</>
}
