import React from "react"
import { BeneficiariesProgressChart, GradeWiseBeneficiariesChart, NGOPartnerWiseBeneficiariesChart, TotalBeneficiariesWithGenderSplit, TotalNGOPartnersWithModelSplit } from "../../../components/admin"

export const NGOPartnersPage: React.FC = () => {
	return (
		<div className="container p-2 lg:px-8">
			<div className="grid gap-2 lg:gap-4 lg:grid-cols-2">
				<div className="grid gap-2 lg:gap-4 lg:grid-cols-2">
					<TotalNGOPartnersWithModelSplit />
					<TotalBeneficiariesWithGenderSplit />
				</div>
				<NGOPartnerWiseBeneficiariesChart />
				<GradeWiseBeneficiariesChart />
				<BeneficiariesProgressChart />
			</div>
		</div>
	)
}

export default NGOPartnersPage
