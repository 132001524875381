import React from "react"
import { VStack, LoadingLabel, ErrorLabel } from "../../../components"
import UpdateWorkingWeekdaysOfSchool from "../../../components/ngo-partners/holiday/UpdateWorkingWeeksOfSchool"
import { useMeQuery } from "../../../graphql"

const UpdateWorkingWeekdaysOfSchoolPage = () => {
	const [{ data: MeData, error, fetching }] = useMeQuery()

	return (
		<>
			<div className="container p-2 lg:px-8">
				<VStack className="max-w-xl items-stretch">
					{fetching ? (
						<VStack className="w-full max-w-xl items-stretch gap-2">
							<LoadingLabel />
						</VStack>
					) : error ? (
						<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
					) : MeData?.me?.school ? (
						<>
							<UpdateWorkingWeekdaysOfSchool schoolId={MeData.me.school._id}></UpdateWorkingWeekdaysOfSchool>
						</>
					) : (
						<ErrorLabel>Couldn&apos;t find any school.</ErrorLabel>
					)}
				</VStack>
			</div>
		</>
	)
}

export default UpdateWorkingWeekdaysOfSchoolPage
