const timeStringRegex = /^([0-2]|)[0-9]:[0-9]+$/

export const parseTimeString = (timeString: string, customDate: Date): Date => {
	if (!timeStringRegex.test(timeString)) {
		throw new Error("The time is not a valid formatted time string")
	}

	const timeSplits = timeString.split(":")

	const hours = parseInt(timeSplits![0]!, 10)
	const minutes = parseInt(timeSplits![1]!, 10)

	const date = new Date(customDate)

	date.setHours(hours)
	date.setMinutes(minutes)
	date.setSeconds(0, 0)

	return date
}
