/* eslint-disable react/react-in-jsx-scope */

import { useMemo } from "react"
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from "recharts"
import colors from "tailwindcss/colors"
import { PartialStudentFragment, SchoolWiseAttendanceStatsResponse } from "../../../../graphql"
import { useBreakpoint } from "../../../../hooks"
import { AspectRatio, VStack } from "../../../ui"

export type NGOPartnerCriticalityWiseAttendanceStatsChartProps = {
	data: SchoolWiseAttendanceStatsResponse
}

export const NGOPartnerCriticalityWiseAttendanceStatsChart: React.FC<NGOPartnerCriticalityWiseAttendanceStatsChartProps> = ({ data }) => {
	const { criticalBeneficiaries, atRiskBeneficiaries, wellBeneficiaries, beneficiaries } = useMemo(() => {
		const criticalBeneficiaries: { student: PartialStudentFragment; percent: number }[] = []
		const atRiskBeneficiaries: { student: PartialStudentFragment; percent: number }[] = []
		const wellBeneficiaries: { student: PartialStudentFragment; percent: number }[] = []

		const beneficiaries: { student: PartialStudentFragment; total: number; present: number; percent: number }[] = []

		data.stats.forEach((dayStat) => {
			dayStat.totalStudents.forEach((student) => {
				const isPresent = !!dayStat.presentStudents.find((s) => s._id === student._id)

				const index = beneficiaries.findIndex((s) => s.student._id === student._id)

				if (index >= 0) {
					beneficiaries[index].total++
					beneficiaries[index].present = beneficiaries[index].present + (isPresent ? 1 : 0)
					beneficiaries[index].percent = (beneficiaries[index].present / beneficiaries[index].total) * 100
				} else {
					beneficiaries.push({
						student: student,
						total: 1,
						present: isPresent ? 1 : 0,
						percent: isPresent ? 100 : 0,
					})
				}
			})
		})

		beneficiaries.map(({ percent, student }) => {
			if (percent >= 80) {
				wellBeneficiaries.push({ percent, student })
			} else if (percent >= 50) {
				atRiskBeneficiaries.push({ percent, student })
			} else {
				criticalBeneficiaries.push({ percent, student })
			}
		})

		return { criticalBeneficiaries, atRiskBeneficiaries, wellBeneficiaries, beneficiaries }
	}, [data])

	const criticalChartData = useMemo(
		() => [
			{
				name: "Critical",
				value: criticalBeneficiaries.length,
			},
			{ name: "Remaining", value: beneficiaries.length - criticalBeneficiaries.length },
		],
		[criticalBeneficiaries]
	)

	const atRiskChartData = useMemo(
		() => [
			{
				name: "At-risk",
				value: atRiskBeneficiaries.length,
			},
			{ name: "Remaining", value: beneficiaries.length - atRiskBeneficiaries.length },
		],
		[atRiskBeneficiaries]
	)

	const wellChartData = useMemo(
		() => [
			{
				name: "Well",
				value: wellBeneficiaries.length,
			},
			{ name: "Remaining", value: beneficiaries.length - wellBeneficiaries.length },
		],
		[wellBeneficiaries]
	)

	const { isXl } = useBreakpoint("xl")

	return (
		<div className="w-full h-full grid md:grid-cols-3 gap-2">
			<AspectRatio ratio={isXl ? 3 / 4 : 16 / 9}>
				<VStack className="h-full">
					<ResponsiveContainer width="100%" height="100%">
						<PieChart>
							<Tooltip />
							<Legend payload={[{ value: "<50%", legendIcon: <></> }]} />
							<defs>
								<linearGradient id="critical" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor={colors.red[400]} stopOpacity={0.8} />
									<stop offset="95%" stopColor={colors.red[400]} stopOpacity={0.2} />
								</linearGradient>
								<linearGradient id="remaining" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
									<stop offset="95%" stopColor={colors.gray[400]} stopOpacity={0.2} />
								</linearGradient>
							</defs>
							<Pie data={criticalChartData} cx="50%" cy="50%" innerRadius={isXl ? 60 : 40} outerRadius={isXl ? 80 : 60} paddingAngle={5} dataKey="value" activeIndex={0} activeShape={renderActiveShape}>
								{criticalChartData.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={["url(#critical)", "url(#remaining)"][index]} />
								))}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</VStack>
			</AspectRatio>

			<AspectRatio ratio={isXl ? 3 / 4 : 16 / 9}>
				<VStack className="h-full">
					<ResponsiveContainer width="100%" height="100%">
						<PieChart>
							<Tooltip />
							<Legend payload={[{ value: "50-80%", legendIcon: <></> }]} />
							<defs>
								<linearGradient id="at-risk" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor={colors.orange[400]} stopOpacity={0.8} />
									<stop offset="95%" stopColor={colors.orange[400]} stopOpacity={0.2} />
								</linearGradient>
								<linearGradient id="remaining" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
									<stop offset="95%" stopColor={colors.gray[400]} stopOpacity={0.2} />
								</linearGradient>
							</defs>

							<Pie data={atRiskChartData} cx="50%" cy="50%" innerRadius={isXl ? 60 : 40} outerRadius={isXl ? 80 : 60} paddingAngle={5} dataKey="value" activeIndex={0} activeShape={renderActiveShape}>
								{atRiskChartData.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={["url(#at-risk)", "url(#remaining)"][index]} />
								))}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</VStack>
			</AspectRatio>

			<AspectRatio ratio={isXl ? 3 / 4 : 16 / 9}>
				<VStack className="h-full">
					<ResponsiveContainer width="100%" height="100%">
						<PieChart>
							<Tooltip />
							<Legend payload={[{ value: "80% +", legendIcon: <></> }]} />
							<defs>
								<linearGradient id="well" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor={colors.green[400]} stopOpacity={0.8} />
									<stop offset="95%" stopColor={colors.green[400]} stopOpacity={0.2} />
								</linearGradient>
								<linearGradient id="remaining" x1="0" y1="0" x2="0" y2="1">
									<stop offset="5%" stopColor={colors.gray[400]} stopOpacity={0.8} />
									<stop offset="95%" stopColor={colors.gray[400]} stopOpacity={0.2} />
								</linearGradient>
							</defs>
							<Pie data={wellChartData} cx="50%" cy="50%" innerRadius={isXl ? 60 : 40} outerRadius={isXl ? 80 : 60} paddingAngle={5} dataKey="value" activeIndex={0} activeShape={renderActiveShape}>
								{wellChartData.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={["url(#well)", "url(#remaining)"][index]} />
								))}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</VStack>
			</AspectRatio>
		</div>
	)
}

const renderActiveShape = (props: any) => {
	const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, value } = props

	return (
		<g>
			<text x={cx} y={cy} dy={8} className="text-3xl font-bold" textAnchor="middle" fill={fill}>
				{value}
			</text>
			<Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
		</g>
	)
}
