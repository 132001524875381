import React, { useCallback, useState } from "react"
import { ErrorLabel, Skeleton, VStack } from "../../.."
import { ReportStatus, ReportTypes, SearchReportsQueryVariables, useSearchReportsQuery } from "../../../../graphql"
import { ReportsList } from "../../../common"

export type SearchNSchoolBMIReportsProps = {
	keyword?: string
	status?: ReportStatus
}

export const SearchNSchoolBMIReports: React.FC<SearchNSchoolBMIReportsProps> = ({ keyword, status }) => {
	const [pagination, setPagination] = useState<SearchReportsQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useSearchReportsQuery({
		variables: { keyword, type: ReportTypes.SchoolBmi, status, pagination },
	})

	const nextPage = useCallback(() => {
		if (data?.searchReports.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data?.searchReports.hasNextPage])

	return (
		<VStack className="max-w-xl items-stretch">
			{fetching ? (
				<VStack className="w-full max-w-xl items-stretch gap-2">
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
					<Skeleton className="w-full max-w-xl h-24" />
				</VStack>
			) : error ? (
				<ErrorLabel>{error.message.replace("[GraphQL] ", "")}</ErrorLabel>
			) : data?.searchReports.reports && data.searchReports.reports.length ? (
				<ReportsList reports={data.searchReports.reports} loadMore={nextPage} linkPrefix="/ngo-partners/school/bmi/reports/" />
			) : (
				<ErrorLabel>Couldn&apos;t find any reports.</ErrorLabel>
			)}
		</VStack>
	)
}
