import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSchoolAttendanceSlotByIdQuery } from "../../../graphql"
import { Button, Card, CardContent, CardHeader, LoadingLabel } from "../../../components/ui"
import { format } from "date-fns"
import { NGOPartnerTag } from "../../../components"

const MealSlotPage: React.FC = () => {
	const { slotId = "" } = useParams<{ slotId: string }>()

	const [parentSlotId, setParentSlotId] = useState<string>("")

	const navigate = useNavigate()

	const [{ data, fetching, error }] = useSchoolAttendanceSlotByIdQuery({ variables: { slotId }, pause: !slotId })

	const [parentSlot] = useSchoolAttendanceSlotByIdQuery({ variables: { slotId: parentSlotId }, pause: !parentSlotId })

	useEffect(() => {
		if (data?.schoolAttendanceSlotById && data.schoolAttendanceSlotById.parentSlotId) setParentSlotId(data?.schoolAttendanceSlotById.parentSlotId)
	}, [data?.schoolAttendanceSlotById])

	return (
		<div className="m-10 w-fit">
			{fetching ? (
				<div className="container mx-auto ">
					<div className=" flex gap-1 text-gray-500 ">
						<h2 className="text-sm">Loading meal slot</h2>
						<LoadingLabel />
					</div>
				</div>
			) : error ? (
				<div className="p-8  w-fit">
					<h2 className="text-sm text-red-600">{error.message.replace("[GraphQL] ", "")}</h2>
				</div>
			) : data?.schoolAttendanceSlotById ? (
				<>
					<Card className="hover:cursor-pointer md:max-w-xl hover:shadow-lg">
						<CardHeader className="flex flex-col gap-4">
							<div className="flex justify-end gap-4">
								<Button className="bg-blue-500 text-sm text-white font-medium" onClick={() => navigate(`/mealSlots/${data.schoolAttendanceSlotById._id}/update`)}>
									Update
								</Button>
								<Button className="bg-red-500 text-sm text-white font-medium" onClick={() => navigate(`/mealSlots/${data.schoolAttendanceSlotById._id}/delete`)}>
									Delete
								</Button>
							</div>
							<div>
								<NGOPartnerTag ngoPartner={data.schoolAttendanceSlotById.school} />
								<h2 className="text-lg font-medium text-gray-900">
									{data.schoolAttendanceSlotById.school.name} - {data.schoolAttendanceSlotById?.attendanceSlot.name}
								</h2>
							</div>
						</CardHeader>

						<CardContent>
							<div className="flex gap-4 md:gap-6 flex-wrap ">
								<div className="flex flex-col gap-2 items-center">
									<p className="text-sm font-medium text-gray-600">{data.schoolAttendanceSlotById.attendanceSlot.name}</p>
									<p className="text-sm font-normal text-gray-400">Meal Name</p>
								</div>

								<div className="flex flex-col gap-2 items-center">
									<p className="text-sm font-medium text-gray-600">{data.schoolAttendanceSlotById.startAt}</p>
									<p className="text-sm font-normal text-gray-400">Start Time</p>
								</div>

								<div className="flex flex-col gap-2 items-center">
									<p className="text-sm font-medium text-gray-600">{data.schoolAttendanceSlotById.endAt}</p>
									<p className="text-sm font-normal text-gray-400">End Time</p>
								</div>
								<div className="flex flex-col gap-2 items-center">
									<p className="text-sm font-medium text-orange-600">{data.schoolAttendanceSlotById.studentIds.length}</p>
									<p className="text-sm font-normal text-gray-400">Total Beneficaries</p>
								</div>

								<div className="flex flex-col gap-2 items-center">
									{data.schoolAttendanceSlotById.isTappingRequired ? <p className="text-sm font-medium text-green-500">Required</p> : <p className="text-sm font-medium text-red-500"> Not Required</p>}

									<p className="text-sm font-normal text-gray-400">Tapping</p>
								</div>
								{data.schoolAttendanceSlotById.parentSlotId && (
									<div className="flex flex-col gap-2 items-center">
										<p className="text-sm font-medium text-orange-600">{parentSlot.data?.schoolAttendanceSlotById.attendanceSlot.name}</p>
										<p className="text-sm font-normal text-gray-400">Parent Slot</p>
									</div>
								)}

								<div className="flex flex-col gap-2 items-center">
									<p className="text-sm font-medium text-gray-600">{format(new Date(data.schoolAttendanceSlotById.createdAt), "PPp")}</p>
									<p className="text-sm font-normal text-gray-400">Since</p>
								</div>
							</div>
						</CardContent>
					</Card>
				</>
			) : (
				<div className="p-8  w-fit">
					<h2 className="text-sm text-red-600">Couldn&apos;t find the meal slot.</h2>
				</div>
			)}
		</div>
	)
}

export default MealSlotPage
