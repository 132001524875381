import format from "date-fns/format"
import React, { useMemo } from "react"
import { BmiCategory, StudentBmiFragment } from "../../../graphql"
import { roundDecimalNumber } from "../../../utils"
import { Card, CardContent, CardHeader, CardTitle, Skeleton, VStack } from "../../ui"

export type BMIProps = {
	bmi: StudentBmiFragment
}

export const BMI: React.FC<BMIProps> = ({ bmi }) => {
	const categoryColor = useMemo(() => {
		switch (bmi.category) {
			case BmiCategory.Normal:
				return "text-green-500"
			case BmiCategory.Obese:
				return "text-red-500"
			case BmiCategory.Overweight:
				return "text-orange-500"
			case BmiCategory.Underweight:
				return "text-yellow-600"

			default:
				return "text-slate-500"
		}
	}, [bmi.category])

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Taken on {format(new Date(bmi.createdAt), "MMM dd, yyyy")}</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="w-full grid grid-cols-2 md:grid-cols-3 gap-4">
					<VStack className="w-fit items-start gap-0">
						<span className="text-xs lg:text-sm text-slate-400">Category</span>
						<span className={`text-sm lg:text-md font-bold ${categoryColor}`}>{bmi.category}</span>
					</VStack>

					<VStack className="w-fit items-start gap-0">
						<span className="text-xs lg:text-sm text-slate-400">Height</span>
						<span className="text-sm lg:text-md font-bold text-slate-500">{bmi.height} CMs</span>
					</VStack>

					<VStack className="w-fit items-start gap-0">
						<span className="text-xs lg:text-sm text-slate-400">Weight</span>
						<span className="text-sm lg:text-md font-bold text-slate-500">{bmi.weight} KGs</span>
					</VStack>

					<VStack className="w-fit items-start gap-0">
						<span className="text-xs lg:text-sm text-slate-400">BMI</span>
						<span className={`text-sm lg:text-md font-bold ${categoryColor}`}>{roundDecimalNumber(bmi.bmiValue)}</span>
					</VStack>
				</div>
			</CardContent>
		</Card>
	)
}

export const BMISkeleton = () => (
	<div className="w-full grid grid-cols-2 md:grid-cols-3 gap-4">
		<Skeleton className="w-24 h-10" />

		<Skeleton className="w-24 h-10" />

		<Skeleton className="w-24 h-10" />

		<Skeleton className="w-24 h-10" />
	</div>
)
