import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { ExtendedStudentFragment, useDeactivateStudentMutation } from "../../../graphql"

import { useToast, Button } from "../../../components"

export type DeactivateBeneficiaryProps = {
	beneficiary: ExtendedStudentFragment
}

export const DeactivateBeneficiary: React.FC<DeactivateBeneficiaryProps> = ({ beneficiary }) => {
	const [{ fetching }, deactivate] = useDeactivateStudentMutation()

	const navigate = useNavigate()
	const { toast } = useToast()

	const handleDeactivate = async () => {
		const { error, data } = await deactivate({ studentId: beneficiary._id })

		if (error) {
			toast({
				variant: "destructive",
				title: "Beneficiary Deactivation Failed",
				description: error.message.replace("[GraphQL] ", ""),
			})
		}

		if (data?.deactivateStudent) {
			toast({
				variant: "success",
				title: "Beneficiary Deactivated Successfully",
			})
			navigate(`/beneficiaries/${data.deactivateStudent._id}`)
		} else {
			return toast({
				variant: "destructive",
				title: "Beneficiary Deactivation Failed",
			})
		}
	}

	return (
		<div className="mt-6 w-fit">
			<h2 className="text-sm text-gray-600 font-normal">Are you sure you want to deactivate {beneficiary.name}?</h2>
			<div className="flex gap-6 mt-4 justify-end">
				<Link className="w-fit rounded-lg px-4 py-2 font-medium bg-slate-500 text-white hover:cursor-pointer hover:opacity-70" to={`/beneficiaries/${beneficiary._id}`}>
					Cancel
				</Link>
				<Button className="w-fit rounded-lg px-4 py-2 font-medium bg-red-500 text-white hover:cursor-pointer hover:opacity-70" onClick={handleDeactivate} isLoading={fetching}>
					Deactivate
				</Button>
			</div>
		</div>
	)
}
