/* eslint-disable react/react-in-jsx-scope */

import { differenceInDays, startOfDay, subDays, subMonths } from "date-fns"
import { useEffect, useMemo, useState } from "react"
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis } from "recharts"
import colors from "tailwindcss/colors"
import { SchoolWiseAttendanceStatsQueryVariables, useMeQuery } from "../../../../graphql"
import { useAttendanceStatsContext, useBreakpoint } from "../../../../hooks"
import { roundDecimalNumber } from "../../../../utils"
import { AspectRatio, Card, CardContent, CardHeader, CardTitle, ChartSkeleton, ErrorLabel, VStack } from "../../../ui"

const sinceDate = startOfDay(subMonths(new Date(), 1))
const untilDate = startOfDay(new Date())

export const TinyMonthlyAttendanceStatsChart: React.FC = () => {
	const [{ data: meData }] = useMeQuery()

	const [filter, setFilter] = useState<Required<SchoolWiseAttendanceStatsQueryVariables["filter"]>>({ sinceDate, untilDate, schoolIds: [] })

	useEffect(() => {
		if (meData?.me?.school?._id) {
			setFilter((prev) => ({ ...prev, schoolIds: [meData.me?.school?._id || ""] }))
		}
	}, [meData])

	const [{ queries }, queryNew] = useAttendanceStatsContext()

	const currentQuery = useMemo(() => queries.find((q) => q.id === JSON.stringify(filter)), [filter, queries])

	useEffect(() => {
		if (filter.schoolIds?.length === 1) {
			queryNew({ filter })
		}
	}, [filter])

	const data = useMemo(() => {
		if (!currentQuery || !currentQuery.data || !currentQuery.data.schoolWisePartialAttendanceStats) {
			return []
		}

		const _data: {
			name: string
			Attendance: number
		}[] = []

		for (let i = differenceInDays(untilDate, sinceDate); i > 0; i--) {
			const date = subDays(untilDate, i - 1).toLocaleString("en-US", { month: "short", day: "numeric" })

			const o = { name: date, Attendance: 0 }

			const mealSlots = currentQuery.data.schoolWisePartialAttendanceStats
				.map((s) => {
					return s.stats.map((x) => x)
				})
				.flat(1)

			const total =
				mealSlots
					.filter((meal) => new Date(meal.date).toLocaleString("en-US", { month: "short", day: "numeric" }) === date)
					.map((d) => d.meal.totalStudentsCount)
					.reduce((a, b) => a + b, 0) || 0

			const present =
				mealSlots
					.filter((meal) => new Date(meal.date).toLocaleString("en-US", { month: "short", day: "numeric" }) === date)
					.map((d) => d.meal.presentStudentsCount)
					.reduce((a, b) => a + b, 0) || 0

			o.Attendance = roundDecimalNumber((present / total) * 100)

			_data.push(o)
		}

		return _data
	}, [currentQuery])

	const { isXl } = useBreakpoint("xl")

	return (
		<Card>
			<CardHeader>
				<CardTitle className="text-xs lg:text-sm font-medium text-slate-500">Monthly Attendance</CardTitle>
				<CardTitle className="text-xs lg:text-sm font-normal text-slate-400">Attendance in the last 1 month</CardTitle>
			</CardHeader>
			<CardContent>
				<VStack className="h-full gap-4 select-none">
					<AspectRatio ratio={isXl ? 20 / 9 : 16 / 9}>
						<ResponsiveContainer width="100%" height="100%">
							{currentQuery?.waiting ? (
								<ChartSkeleton />
							) : !data?.length ? (
								<div className="w-full h-full flex items-center justify-center">
									<ErrorLabel>No data</ErrorLabel>
								</div>
							) : (
								<AreaChart width={730} height={250} data={data} margin={{ left: 40, right: 40, top: 20 }}>
									<Tooltip label="Attendance" formatter={(value) => `${value || 0}%`} />
									<Legend formatter={() => "Attendance %"} />
									<defs>
										<linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
											<stop offset="5%" stopColor={colors.purple[400]} stopOpacity={0.8} />
											<stop offset="95%" stopColor={colors.purple[100]} stopOpacity={0.2} />
										</linearGradient>
									</defs>

									<XAxis dataKey="name" />
									<Area type="monotone" dataKey="Attendance" stroke="none" fillOpacity={1} fill="url(#colorValue)" label={<CustomLabel />} />
								</AreaChart>
							)}
						</ResponsiveContainer>
					</AspectRatio>
				</VStack>
			</CardContent>
		</Card>
	)
}

const CustomLabel = ({ x, y, stroke, value }: any) => {
	if (!value) return <></>

	return (
		<text x={x} y={y} dy={-8} fill={stroke} fontSize={10} textAnchor="middle">
			{value || 0}%
		</text>
	)
}
